import React, { useContext } from "react";
import { UserContext } from "../../maincontext";
import { ic_euro_symbol } from "react-icons-kit/md/ic_euro_symbol";
import Icon from "react-icons-kit";
import { Table } from "react-bootstrap";
import { UserCostCenter, UserProfileType } from "../../../models/models";

export interface IUserCostCentersWidget {
	list: UserCostCenter[]
}

export default function UserCostCentersWidget(props: IUserCostCentersWidget) {
	const UserInfo = useContext(UserContext);

	return (
		<div className="widget p-2 border-0">
			<h6 className={"title"}>
				{/* <Icon icon={ic_euro_symbol} className="mr-1" size={20} /> */}
				<span style={{ verticalAlign: "middle" }}>Centri di costo</span>
			</h6>
			{props.list.map(function (item) {
				return <div key={"CostCenter_div_" + item.CostCenterID}>
					{item.CostCenterName} ({item.Percentage}%)
				</div>
			})
			}
		</div>
	)
}

