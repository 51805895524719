import { CostCenter } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class CostCenterServices {

	//elenco completo 
	async getCostCentersList() {
		return axios.get<CostCenter[]>(`${await SVC.getApiBaseUri()}/api/costcenters`, { headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getCostCenter(id: number) {
		const params = {
			id: id
		};
		return axios.get<CostCenter>(`${await SVC.getApiBaseUri()}/api/costcenters`, { params, headers: await SVC.setAzureAuth() });
	}

	//update centro di costo
	async CostCenterUpdate(costCenter: CostCenter) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/costcenters`, costCenter, { headers: await SVC.setAzureAuth() });
	}

	async CostCenterDelete(costCenter: CostCenter) {

		const params = {
			id: costCenter.CostCenterID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/costcenters`,
			{ params, headers: await SVC.setAzureAuth() });
	}
}