import { SubOrderStepProfileUser, DateWorkingHours, QuickAssignmentParameters } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class SubOrderStepProfileUserServices {


	async getSubOrderStepProfileUsers(id: number) {
		const params = {
			id: id
		};
		return axios.get<SubOrderStepProfileUser[]>(`${await SVC.getApiBaseUri()}/api/SubOrderStepProfileUsers`, { params, headers: await SVC.setAzureAuth() });
	}

	async getSubOrderStepProfileUser(SubOrderStepProfileID: number, UserID: number) {
		const params = {
			SubOrderStepProfileID: SubOrderStepProfileID,
			UserID: UserID
		};
		return axios.get<SubOrderStepProfileUser>(`${await SVC.getApiBaseUri()}/api/SubOrderStepProfileUser`, { params, headers: await SVC.setAzureAuth() });
	}

	async SubOrderStepProfileUserUpdate(subOrderStepProfileID: number, userID: number, workingHours: DateWorkingHours[]) {

		return axios.post(`${await SVC.getApiBaseUri()}/api/UsersAssignedWorkingHours/${subOrderStepProfileID}/${userID}`, workingHours, { headers: await SVC.setAzureAuth() });

	}

	// async SubOrderStepProfileUserQuickAssignment(
	// 	SubOrderStepProfileID: number,
	// 	UserID: number,
	// 	StartDate: Date,
	// 	EndDate: Date,
	// 	TotalHours: number,
	// 	DayHours: number,
	// 	SaturdayIncluded: boolean,
	// 	SundayIncluded: boolean,
	// 	HolidayIncluded: boolean
	// ) {
	// 	const params = {
	// 		SubOrderStepProfileID: SubOrderStepProfileID,
	// 		UserID: UserID,
	// 		StartDate: StartDate,
	// 		EndDate: EndDate,
	// 		TotalHours: TotalHours,
	// 		DayHours: DayHours,
	// 		SaturdayIncluded: SaturdayIncluded,
	// 		SundayIncluded: SundayIncluded,
	// 		HolidayIncluded: HolidayIncluded
	// 	};
	// 	return axios.post(`${await SVC.getApiBaseUri()}/api/UsersAssignedWorkingHours/QuickAssignment`, params, { headers: await SVC.setAzureAuth() });

	// }

	async QuickAssignment(
		params: QuickAssignmentParameters
	) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/UsersAssignedWorkingHours/QuickAssignment`, params, { headers: await SVC.setAzureAuth() });
	}

	async SubOrderStepProfileUserDelete(subOrderStepProfileID: number, UserID: number, ) {
		const params = {
			ProfileID: subOrderStepProfileID,
			UserID: UserID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/UsersAssignedWorkingHours`,
			{ params, headers: await SVC.setAzureAuth() });
	}

}