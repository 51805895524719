import React, { useContext, useState, useEffect } from "react";
import { Maincontext } from "../../maincontext";
import { wrench as ic_wrench } from "react-icons-kit/icomoon/wrench";
import Icon from "react-icons-kit";
import { ic_list } from "react-icons-kit/md/ic_list";
import ReportServices from "../../../api/ReportServices";
import { UserWOrderSummaryItem } from "../../../models/reports";
import { minutesToDays, thousandSeparator } from "../../../core/Utilities";
import SubOrdersSummaryPanel from "../UserWidgets/SubOrdersSummaryPanel";

export interface IOrdersSummaryWidget {
}

export default function OrdersSummaryWidget(props: IOrdersSummaryWidget) {
	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const [list, setList] = useState<UserWOrderSummaryItem[]>([]);
	const [filteredList, setFilteredList] = useState<UserWOrderSummaryItem[]>([]);

	const [showPanel, setShowPanel] = useState<boolean>(false);
	const [orderName, setOrderName] = useState<string>("");
	const [orderID, setOrderID] = useState<number>(0);

	useEffect(() => {
		GetList();
	}, []);


	function GetList() {
		SVC.GetRptOrderSummary().then((d) => {
			if (d.data) {
				setList(d.data);
				setFilteredList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento commesse: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function DataFilter(value: string) {

		if (value.length == 0) {
			setFilteredList(list);
		}
		else if (value.length > 2) {
			setFilteredList(list.filter(function (item) {
				return item.OrderName.toLowerCase().indexOf(value.toLowerCase()) > -1
					|| item.CustomerName.toLowerCase().indexOf(value.toLowerCase()) > -1;
			}))
		}
	}

	function onFindChange(event: React.ChangeEvent<HTMLInputElement>): void {
		DataFilter(event.currentTarget.value);
	}

	function closeDialog(){
		setShowPanel(false);
	}

	function ShowPanel(orderID: number, orderName: string){
		setOrderID(orderID);
		setOrderName(orderName);		
		setShowPanel(true);
	}

	return (

		<div className="widget border-0" >
			<h6 className={filteredList.length > 0 ? "title" : "title"}>
				<Icon icon={ic_wrench} className="mr-1" size={20} />Commesse attive (clienti) per i propri profili: {list.length}
				<input
					className="find ml-2"
					type="text"
					placeholder="Cerca..."
					onChange={onFindChange}
				/>
			</h6>
			{filteredList.length > 0 ?
				<table className="table table-sm table-list">
					<thead>
						<tr>
							<th>Cliente</th>
							<th>Commessa</th>
							<th className="date-width">Data inizio</th>
							<th className="date-width">Data fine</th>
							<th className="date-width text-right">Giornate</th>
							<th className="date-width text-right">Lavorate</th>
							<th className="date-width text-right">Tot lavorate</th>
							<th className="date-width text-right">Residuo</th>
						</tr>
					</thead>
					<tbody>
						{filteredList.map(function (item) {
							return <tr key={"Ord_tr_" + item.OrderID}>
								<td>{item.CustomerName}
								<span title="Deliverables" className="float-right">
									<Icon
									className="btn btn-link btn-sm p-0 btn-menu-context" 
									icon={ic_list} size={24}
									onClick={() => ShowPanel(item.OrderID, item.OrderName)} />									
									</span>
								</td>
								<td>
									{item.OrderName}
								</td>
								<td>
									{new Date(item.StartDate).toLocaleDateString()}
								</td>
								<td>
									{new Date(item.EndDate).toLocaleDateString()}
								</td>
								<td className="text-right">
									{thousandSeparator(item.WorkingDays)}
								</td>
								<td className="text-right">
									{minutesToDays(item.UserWorkedMinutes)}
								</td>
								<td className="text-right">
									{minutesToDays(item.TotalWorkedMinutes)}
								</td>
								<td className="text-right">
									{minutesToDays((item.WorkingDays * 8 * 60) - item.TotalWorkedMinutes)}
								</td>
							</tr>;
						}
						)
						}
					</tbody>
				</table >
				: <span>Nessuna commessa attiva</span>
			}
			<SubOrdersSummaryPanel 
			orderName={orderName} 
			orderID={orderID}
			closeDialog={closeDialog}
			show={showPanel}  />
		</div >
	);
}

