import { PermitType } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class PermitTypesServices {

	//elenco completo 
	async getPermitTypesList() {
		return axios.get<PermitType[]>(`${await SVC.getApiBaseUri()}/api/permittypes`, { headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getPermitType(id: number) {
		const params = {
			id: id
		};
		return axios.get<PermitType>(`${await SVC.getApiBaseUri()}/api/permittypes`, { params, headers: await SVC.setAzureAuth() });
	}

	//update singolo elemento
	async PermitTypeUpdate(permitType: PermitType) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/permittypes`, permitType, { headers: await SVC.setAzureAuth() });
	}

	async PermitTypeDelete(permitType: PermitType) {
		const params = {
			id: permitType.PermitTypeID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/permittypes`,
			{ params, headers: await SVC.setAzureAuth() });
	}

}