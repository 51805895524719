import React, { useContext, useEffect, useState } from "react";
import { Maincontext, UserContext } from "../../maincontext";
import { ic_local_mall } from "react-icons-kit/md/ic_local_mall";
import Icon from "react-icons-kit";
import { Table } from "react-bootstrap";
import { UserProfileType } from "../../../models/models";
import { getMaxListeners } from "process";
import UserServices from "../../../api/UserServices";

export interface IUserProfilesWidget {
	list: UserProfileType[]
}

export default function UserProfilesWidget(props: IUserProfilesWidget) {
	const SVC = new UserServices();
	const UserInfo = useContext(UserContext);
	const context = useContext(Maincontext);


	return (
		<div className="widget p-2 border-0">
			<h6 className={"title"}>
				{/* <Icon icon={ic_local_mall} className="mr-1" size={20} /> */}
				<span style={{ verticalAlign: "middle" }}>Profili professionali</span>
			</h6>
			{props.list.map(function (item) {
				return <div key={"div_" + item.ProfileTypeID}>
					{item.ProfileTypeName}
				</div>;
			}
			)
			}
		</div>
	);
}

