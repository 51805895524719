import { Customer, IDValue, User } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class CustomerServices {

	//elenco completo di CUSTOMERS
	async getCustomers() {
		return axios.get<Customer[]>(`${await SVC.getApiBaseUri()}/api/customers`, { headers: await SVC.setAzureAuth() });
	}

	//restituisce un CUSTOMER passando l'id
	async getCustomer(id: number) {
		const params = {
			id: id
		};
		return axios.get<Customer>(`${await SVC.getApiBaseUri()}/api/customers`, { params, headers: await SVC.setAzureAuth() });
	}
	//restituisce l'elenco delle risorse assegnate al cliente
	async getCustomerUsers(id: number) {
		const params = {
			id: id
		};
		return axios.get<IDValue[]>(`${await SVC.getApiBaseUri()}/api/customers/users`, {params, headers: await SVC.setAzureAuth() });	
	}

	//update risorse assegnati al cliente
	async CustomerUsersUpdate(customerID: number, users: number[]) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/customers/${customerID}/users`, users, { headers: await SVC.setAzureAuth() });
	}

	//insert/update di un CUSTOMER
	async CustomerUpdate(customer: Customer) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/customers`, customer, { headers: await SVC.setAzureAuth() });
	}

	//eliminaizone del CUSTOMER passato per argomento
	async CustomerDelete(customer: Customer) {
		const params = {
			id: customer.CustomerID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/customers`,
			{ params, headers: await SVC.setAzureAuth() });
	}
}