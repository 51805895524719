import { ContractType } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class ContractTypeServices {
	async getContractTypesList() {
		return axios.get<ContractType[]>(`${await SVC.getApiBaseUri()}/api/contracttypes`, { headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getContractType(id: number) {
		const params = {
			id: id
		};
		return axios.get<ContractType>(`${await SVC.getApiBaseUri()}/api/contracttypes`,
			{ params, headers: await SVC.setAzureAuth() });
	}

	//update contract type    
	async ContractTypeUpdate(contractType: ContractType) {

		return axios.post<string>(`${await SVC.getApiBaseUri()}/api/contracttypes`,
			contractType, { headers: await SVC.setAzureAuth() });
	}

	async ContractTypeDelete(contractType: ContractType) {

		const params = {
			id: contractType.ContractTypeID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/contracttypes`,
			{ params, headers: await SVC.setAzureAuth() });
	}
}