//USERS (risolse umane)
export interface User {
	UserID: number,
	WorkingHoursTypeID?: number,
	WorkingHoursTypeName: string,
	FirstName: string,
	LastName: string,
	FullName: string,
	Initials: string,
	EmailAddress: string,
	Language: string,
	Enabled: boolean,
	SuperAdmin: boolean,
	ApproverID?: number,
	ApproverFullName: string,
	ProfilePhoto: string,
	CreationDate: Date
	UserAuthorizationTypes: UserAuthorizationType[],
	UserProfileTypes: UserProfileType[],
	UserContractTypes: UserContractType[],
	UserCostCenters: UserCostCenter[],
	UserActivityLines: UserActivityLine[],
	UserLogins: Date[],	
	IsApprover: boolean,
	Available: boolean,
	DefaultApprover: boolean,
	ConfirmButton: boolean,
	RecentActivitiesList: boolean,
	TimesheetDuplicated: boolean
}

export interface UserList {
	UserID: number,
	FirstName: string,
	LastName: string,
	FullName: string,
	Initials: string,
	EmailAddress: string,
	Enabled: boolean,
	SuperAdmin: boolean,
	CreationDate: Date,
	HasProfiles: boolean,
	CostCentersComplete: boolean,
	ApproverFullName: string,
	Available: boolean
}

//profili dell'utente
export interface UserProfileType {
	UserID: number,
	ProfileTypeID: number,
	ProfileTypeName: string
}

//linee attività dell'utente
export interface UserActivityLine {
	UserID: number,
	ActivityLineID: number,
	ActivityLineName: string
}

//costo mensile risorsa
export interface UserMonthlyCost {
	UserMontlyCostID: number,
	UserFullName: string,
	UserID: number,
	Year: number,
	Month: number,
	TimeSheetMonthName: string,
	MonthlyCost: number,
	CreationDate: Date
}

//COST CENTERS
export interface CostCenter {
	CostCenterID: number;
	UserID: number;
	Manager: string;
	CostCenterName: string;
	Enabled: boolean;
	CreationDate: Date;
}
export interface UserCostCenter {
	CostCenterID: number;
	CostCenterName: string;
	Percentage: number;
}

export interface UserCostCenter {
	CostCenterID: number;
	CostCenterName: string;
	Percentage: number;
}

//CUSTOMERS
export interface Customer {
	CustomerID: number;
	NationID: number;
	CountryID: number;
	CountryName: string;    //max 100
	Address: string;    	//max 100
	ZipCode: string;    	//max 100
	ProvinceCode: string;   //readonly
	RegionName: string;     //readonly
	CustomerName: string;   //max 100
	CustomerCode: string;   //max 50
	VatNumber: string;      //max 16
	Internal: boolean;
	Enabled: boolean;
	CreationDate: Date;     //readonly
}

//ORDERS
export interface Order {
	OrderID: number;
	CustomerID: number;
	UserID: number;
	OrderName: string;
	SalesForceID: string;
	OrderWorkingDays: number;
	OrderCode: string;
	OrderDescription: string;
	OrderNote: string;
	WorkingDays: number;
	CustomerName: string; 	//readonly
	Internal: boolean;		//readonly
	Manager: string; 		//readonly
	StartDate: Date;
	EndDate: Date;
	SubOrdersCount: number; //readonly
	SubOrdersAssignedWorkingDays: number;     //readonly
	CreationDate: Date;     //readonly
	AvailableDays: number;
}

//SUBORDERS
export interface SubOrder {
	SubOrderID: number;
	OrderID: number;
	UserID: number;
	OrderUserID: number;
	ActivityLineID: number;
	OrderCode: string;
	OrderName: string;
	SubOrderName: string;
	SubOrderNote: string;
	WorkingDays: number;
	CustomerName: string;	//readonly
	Internal: boolean;		//readonly
	ActivityLineName: string;
	Manager: string;
	StartDate: Date;
	EndDate: Date;
	OrderStartDate: Date;
	OrderEndDate: Date;
	CreationDate: Date;     //readonly
	StepsWorkingHours: number;
	ProfilesWorkingHours: number;
	AssignedWorkingHours: number;
}

export interface SubOrderStep {
	SubOrderStepID: number;
	SubOrderID: number;
	ActivityStepID: number;
	WorkingHours: number;
	CreationDate: Date;
	ActivityStepName: string;
	ActivityLineID: number; //readonly
}

export interface SubOrderStepProfile {
	SubOrderStepProfileID: number;
	SubOrderStepID: number;
	ProfileTypeID: number;
	WorkingHours: number;
	CreationDate: Date;
	ProfileTypeName: string;
}

export interface SubOrderStepProfileUser {
	UserID: number;
	UserFullName: string;
	SubOrderStartDate: Date;
	SubOrderEndDate: Date;
	AssignedWorkingHoursList: AssignedWorkingHours[]
}

export interface ProfileType {
	ProfileTypeID: number;
	ProfileTypeName: string;
	Enabled: boolean;
	CreationDate: Date;
}
export interface OrderRemainingDays {
	OrderID: number;
	OrderCode: string;
	OrderName: string;
	CustomerName: string;
	OrderWorkingDays: number;
	OrderWorkedDays: number;	
	OrderRemainDays: number;
	OrderRemainDaysPerc: number;
}

// ACTIVITY LINES
export interface ActivityLine {
	ActivityLineID: number;
	ActivityLineName: string;
	Enabled: boolean;
	CreationDate: Date;
}
// ACTIVITY LINES PROFILES
export interface ActivityLineProfileType {
	ActivityLineID: number,
	ProfileTypeID: number,
	ProfileTypeName: string
}

// ACTIVITY STEPS
export interface ActivityStep {
	ActivityStepID: number;
	ActivityLineID: number;
	ActivityStepName: string;
	ActivityLineName: string;
	Enabled: boolean;
	CreationDate: Date;
}

// PERMIT TYPES
export interface PermitType {
	PermitTypeID: number;
	PermitTypeDescription: string;
	FullDay: boolean;
}

//CONTRACT TYPE
export interface ContractType {
	ContractTypeID: number;
	ContractTypeName: string;
	CreationDate: Date;
}

//USER CONTRACT TYPES
export interface UserContractType {
	ContractTypeID: number;
	ContractTypeName: string;
	StartDate: Date;
	EndDate?: Date;
	edit?: boolean;
	isNew?: boolean;
}

//USER AUTHORIZATION TYPES
export interface UserAuthorizationType {
	UserID: number;
	AuthorizationTypeID: UserAuthorizationTypes;
	AuthorizationTypeName: string;
}

//WORKINGHOURS TYPE (orari di lavoro)
export interface WorkingHoursType {
	WorkingHoursTypeID: number;
	WorkingHoursTypeName: string;
	Sunday: number,
	Monday: number,
	Tuesday: number,
	Wednesday: number,
	Thursday: number,
	Friday: number,
	Saturday: number,
	Holiday: number,
	CreationDate: Date;
}
export interface AssignedWorkingHours {
	UserWorkingHours: number;
	WorkingHours: number;
	AssignedWorkingHours: number;
	WorkingHoursDate: Date;
	IsSaturday: boolean;
	IsSunday: boolean;
	IsHoliday: boolean;
}

export interface DateWorkingHours {
	WorkingHours: number;
	WorkingHoursDate: Date;
}

//elemento di un componente SELECT
//enum delle tipologie di lista
export enum SelectItemsListTypes {
	USERS = 1,
	COST_CENTERS = 2,
	CONTRACT_TYPES = 3,
	CUSTOMERS = 4,
	COUNTRIES = 5,
	PROVINCES = 6,
	ACTIVITY_LINES = 7,
	PROFILE_TYPES = 8,
	//ACTIVITY_LINE_PROFILE_TYPE = 9,
	COST_CENTER_MANAGERS = 10,
	APPROVED_TIMESHEET_YEARS = 11,
	APPROVED_TIMESHEET_USERS = 12,
	WORKING_HOURS_TYPE = 13,
	NATIONS = 14,
	ACTIVITY_DESCRIPTIONS = 15,
	ORDERS = 16,
	OVERTIME_ORDERS = 17,
	COST_CENTERS_MANAGED = 18,
	ORDERS_WITH_AVAILABLE = 19,
	ORDER_YEARS = 20
}

//tipi di contratto
export interface ContractType {
	ContractTypeID: number;
	ContractTypeName: string;
	CreationDate: Date;
}

//SUBORDERS TREE VIEW ===================================

//primo livello
export interface SubOrderStepsTreeView {
	SubOrderStepID: number;
	ActivityStepID: number;
	WorkingHours: number;
	ActivityStepName: string;
	StepProfiles: StepProfilesTreeView[];
}

//secondo livello
export interface StepProfilesTreeView {
	SubOrderStepProfileID: number;
	SubOrderStepID: number;
	ProfileTypeID: number;
	WorkingHours: number;
	ProfileTypeName: string;
	StepProfileUsers: StepProfileUsersTreeView[];
}

//terzo livello
export interface StepProfileUsersTreeView {
	SubOrderStepProfileUserID: number;
	SubOrderStepProfileID: number;
	UserID: number;
	WorkingHours: number;
	UserFullName: string;
}

//============================================================

//elemento di un componente MULTISELECT
//enum delle tipologie di lista
export enum MultiSelectItemsListTypes {
	USER_PROFILE_TYPES = 1,
	USER_AUTHORIZATION_TYPES = 2,
	COUNTRIES = 3,
	ACTIVITY_LINE_PROFILE_TYPES = 4
}
//elemento di una select
export interface SelectListItem {
	Value: string;
	Text: string;
	Selected?: boolean;
	Disabled?: boolean;
}

export interface columnDetail {
	name: string;
	text: string;
	position: number;
	type?: DataColumnTypes;
	size?: number;
}

// ---- TimeSheet Model

export interface TimeSheet {
	TimeSheetID: number;
	TimeSheetType: TimesheetTypes;
	TimeSheetDate: Date;
	UserID?: number;
	SubOrderStepProfileID?: number;
	OrderID?: number;
	PermitTypeID?: number;
	Minutes: number;
	OvertimeMinutes: number;
	OvertimeNightMinutes: number;
	AssignedHours: number;
	UserFullname?: string;
	Description: string;
	CreationDate?: Date;
	ActivityDescriptionID?: number;
	ActivityLineID: number;
	Available: boolean;
	Support: boolean,
	UserNote: string;
}

export interface TimeSheetDay {
	TimeSheetDate: Date;
	DayTimeSheetType: DayTimeSheetTypes;
	IsSaturday: Boolean;
	IsSunday: Boolean;
	IsHoliday: Boolean;
}

export interface TimeSheetMonth {
	UserID: number;
	TimeSheetYear: number;
	TimeSheetMonthID: number;
	TimeSheetMonth: number;
	TimeSheetMonthStateID: MonthStates;
	TimeSheetMonthStateDate: Date;
	CreationDate: Date;
	TimeSheetMonthStateDescription: string;
	MonthName: string;
	ApproverID?: number;
	ApproverFullName: string;
	TimeSheetDays: TimeSheetDay[];
	Summary: SummaryMonthHours;
}

export interface SummaryMonthHours {
	TotalOrdinaryHours: number;
	TotalOvertimeHours: number;
	TotalNightOvertimeHours: number;
	TotalPermitHours: number;
	TotalAbsenceDays: number;
}

export interface HistoricalTimeSheetMonthState {
	//TimeSheetMonthID: number;
	HistoricalTimeSheetMonthStateID: number;
	TimeSheetMonthID: number;
	TimeSheetMonthStateID: MonthStates;
	UserID: number;
	Note: string;
	TimeSheetMonthStateDescription: string;
	ApproverID?: number;
	ApproverFullName: string;
	CreationDate: Date;
}

//ore assegnate oggi alla risorsa corrente
export interface DayAssignedWorkingHours {
	CustomerName: string;
	OrderName: string;
	SubOrderName: string;
	ActivityLineName: string;
	ActivityStepName: string;
	WorkingHours: number;
}

//ore lavorate in un giorno dalla risorsa
export interface UserDayTimeSheet {
	UserID: number;
	TimeSheetDate: Date;
	TimeSheets: TimeSheet[];
	AuthorizedOverTimes: OverTime[];
}

//dati riepilogativi per mese di un anno del ts
export interface UserTimeSheetYearMonth {
	MonthID: number; 
	Minutes: number;
	OvertimeMinutes: number;
	OvertimeNightMinutes: number;
	Permits: number;
	FullDayPermits: number;
	MonthTimesheetTypeID: MonthStates;
	MonthTimesheetTypeDescription: string;
}
//oggetto completo per popopolare la view di compilatione mensile
export interface UserTimeSheetMonth {
	TimeSheetMonthID: number;
	TimeSheetMonthStateID: MonthStates;
	TimeSheetMonthStateDescription: string;
	UserID: number;
	UserFullName: string;
	Year: number;	
	Month: number;	
	Minutes: number;	
	OvertimeMinutes: number;	
	OvertimeNightMinutes: number;
	PermitMinutes: number;
	FullDayPermitsCount: number;
	AvailablesCount: number;
	WorkingDates: WorkingDate[];
	Orders: TimeSheetMonthOrder[];
	Permits: TimeSheetMonthPermit[];
	FullDayPermits: TimeSheetMonthFullDayPermit[];
	Availables: TimeSheetMonthAvailable[];
}

export interface WorkingDate {
	WorkingDate: Date;
	IsHolidayOrWeekEnd: Boolean;
	DayTimeSheetType: number;
}
export interface TimeSheetMonthOrder {
	OrderID: number;
	OrderName: string;
	CustomerCode: string;
	CustomerName: string;
	TotalMinutes: number;
	WorkedDates: WorkedDate[];
}
export interface WorkedDate {
	WorkingDate: Date;
	Value: number; //minutes or days
}

export interface TimeSheetMonthPermit {
	PermitTypeID: number;
	PermitTypeDescription: string;
	TotalMinutes: number;
	WorkedDates: WorkedDate[];
}

export interface TimeSheetMonthFullDayPermit {
	PermitTypeID: number;
	PermitTypeDescription: string;
	TotalDays: number;
	WorkedDates: WorkedDate[];
}

export interface TimeSheetMonthAvailable {
	OrderID: number;
	OrderName: string;
	TotalDays: number;
	WorkedDates: WorkedDate[];
}
//Approving timesheet month
export interface TimeSheetApprovingMonth {
	TimeSheetMonthID: number;
	UserID: number;
	UserFullName: string;
	TimeSheetYear: number;
	TimeSheetMonth: number;
	TimeSheetMonthName: string;
	Minutes: number;
	OvertimeMinutes: number;
	OvertimeNightMinutes: number;
	TotalMinutes: number;
	Permits: number;
	Absences: number;
	Activities: TimeSheetMonthActivity[];
}

export interface TimeSheetApprovedMonth {
	TimeSheetMonthID: number;
	TimeSheetMonthStateDescription: string;
	UserID: number;
	UserFullName: string;
	ApproverID: number;
	ApproverFullName: string;
	TimeSheetYear: number;
	TimeSheetMonth: number;
	TimeSheetMonthName: string;
	Minutes: number;
	OvertimeMinutes: number;
	OvertimeNightMinutes: number;
	Permits: number;
	TotalMinutes: number;
	Absences: number;
	Editable: boolean;
	Activities: TimeSheetMonthActivity[];
}

export interface TimeSheetMonthActivity {
	Minutes: number;
	Description: string;
}

//parametri per la ricerca di risorse disponibili
export interface AvailableUsersParameters {
	ProfileTypeID: number;
	StartDate: Date;
	EndDate: Date;
	Days: number;
	AllDay: boolean;
	StartDateMandatory: boolean;
	DayHours: number;
	OvertimeMaxHours: number;
	Monday: boolean;
	Tuesday: boolean;
	Wednesday: boolean;
	Thursday: boolean;
	Friday: boolean;
	Saturday: boolean;
	Sunday: boolean;
	Holidays: boolean;
}

export interface QuickAssignmentParameters {
	UserID: number;
	SubOrderStepProfileID: number;
	Parameters: AvailableUsersParameters;
}

//risorse disponibili per l'assegnazione
export interface AvailableUser {
	UserID: number;
	UserFullName: string;
	Hours: number;
	OvertimeHours: number;
	Days: number;
	AssignedOrders: UserAssignedOrder[];
}

export interface UserAssignedOrder{
	CustomerName: string;
	OrderName: string;
	Hours: number;
}

export interface OverTime{
	OverTimeID: number;
	OverTimeStateID: OverTimeStates;
	OrderID: number;
	UserID: number;
	ApproverID: number;
	OverTimeDate: Date;
	OverTimeMinutes: number;
	OverTimeNightMinutes: number;
	UserNote: string;
	ApproverNote: string;
	UserFullName: string;
	ApproverFullName: string;
	Minutes: number;
	OrderName: string;	
}

export interface UserNotificationType{
	UserNotificationTypeID: number;
	UserNotificationTypeDescription: string;
	UserNotifications: UserNotification[];
}
export interface UserNotification{
	UserNotificationID: number;
	UserNotificationDescription: string;
}

export interface IDValue{
	ID: number;
	Value: string;	
}

//  ENUMS
//tipi selectedObject nel context
export enum SelectedObjectTypes {
	USER,
	COST_CENTER,
	CONTRACT_TYPE,
	PROFILE_TYPE,
	WORKING_HOURS_TYPE,
	USER_PROFILE_TYPE,
	USER_AUTHORIZATION_TYPE,
	USER_CONTRACT_TYPE,
	USER_COST_CENTER,
	CUSTOMER,
	ORDER,
	SUBORDER,
	SUBORDERSTEP,
	ACTIVITY_LINE,
	ACTIVITY_STEP,
	ACTIVITY_LINE_PROFILE_TYPE,
	PERMIT_TYPE,
	COSTOMER_USERS
}


//tipi selectedObject nel context
export enum MenuList {
	UNDEFINED,
	DASHBOARD,
	ORDERS,
	EXPIRED_ORDERS,
	SUBORDERS,
	EXPIRED_SUBORDERS,
	USERS,
	COST_CENTERS,
	CONTRACT_TYPES,
	PROFILE_TYPES,
	WORKING_HOURS_TYPES,
	CUSTOMERS,
	ACTIVITY_LINES,
	ACTIVITY_STEPS,
	ARCHIVIO_TIMESHEET,
	TIMESHEET,
	APPROVAL_REQUEST,
	APPROVED_TIMESHEETS,
	WORKING_TIMESHEETS,
	PERMIT_TYPES,
	RPT_COSTCENTERSUSERS,
	RPT_ORDERS,
	RPT_SUBORDERUSERS,
	OVERTIME,
	RPT_COSTCENTERUSERS,
	RPT_ORDERSWORKINGHOURS,
	RPT_USERSWORKINGHOURS,
	RPT_ORDERUSERSWORKINGHOURS,
	USERS_MONTHLY_COST,
	CUSTOMERS_MONTHLY_COST,
	COMMERCIAL_CUSTOMERS_MONTHLY_COST,
	SUBORDERS_MONTHLY_WORKED_HOURS
}

export enum MonthStates {
	COMPILAZIONE = 1,
	RICHIESTA_APPROVAZIONE = 2,
	APPROVATO = 3,
	RIFIUTATO = 4,
	RITIRATO = 5
}

export enum DayTimeSheetTypes {
	EMPTY = 1,
	TOO_FEW = 2,
	TOO_MANY = 3,
	EXACT = 4
}

//tipi di autorizzazioni
export enum UserAuthorizationTypes {
	USERS_AUTH = 1,
	CUSTOMERS_AUTH = 2,
	ORDERS_AUTH = 3,
	TIMESHEETS_AUTH = 4,
	SUPPORT_REGISTRIES_AUTH = 5,
	REPORTS_AUTH = 6,
	ECONOMIC_DATA_AUTH = 7,
	COMMERCIAL_DATA_AUTH = 8,
	TECHNICAL_INTERVENTIONS = 9
}

//tipologie di contenuti
export enum ContentTypes {
	USERS = 1,
	CUSTOMERS = 2,
	ORDERS = 3,
	TIMESHEETS = 4,
	SUPPORT_REGISTRIES = 5,
	REPORTS = 6,
	ECONOMIC_DATA = 7,
	COMMERCIAL_DATA = 8,
	TECHNICAL_INTERVENTIONS = 9
}

export enum DataColumnTypes {
	CIRCLE = 1,
	STRING,
	BOOLEAN,
	DATE,
	NUMBER,
	HH_MM
}

export enum TimesheetTypes {
	ASSIGNED = 1,
	EXTRA = 2,
	PERMIT = 3,
	FULL_DAY_PERMIT = 4
}

export enum OverTimeStates {
	WAITING = 0,
	APPROVED = 1,
	REFUSED = 2
}

export interface IOrderTimesheetSearhParams {
    orderID: number;
    fromDate: Date;
    toDate: Date;
  }



