import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeSheetServices from "../../../api/TimeSheetServices";
import { minutesToHours } from "../../../core/Utilities";
import { MONTHS } from "../../../models/locale";
import { DayTimeSheetTypes, MenuList, UserTimeSheetMonth } from "../../../models/models";
import TitleBar from "../../common/TitleBar";
import { Maincontext } from "../../maincontext";

export default function MonthTimeSheetReport() {

	const context = useContext(Maincontext);
	const SVC = new TimeSheetServices();
	const toDay: string = new Date().toDateString();
	let params: any = useParams();
	const monthTimesheetID: number = Number(params.id);

	const [userMonthTimeSheet, setUserMonthTimeSheet] = useState<UserTimeSheetMonth | undefined>();

	const [title, setTitle] = useState<string>("");
	const [subTitle, setSubTitle] = useState<string>("");

	// (document.querySelector(".menu-laterale") as HTMLElement).style.display = "none";
	// (document.querySelector(".navbar-nav") as HTMLElement).style.display = "none";
	// (document.querySelector(".principale") as HTMLElement).style.marginLeft = "0";

	useEffect(() => {
		context.setContext({ ...context, selectedMenu: MenuList.APPROVAL_REQUEST });
		GetUserTimeSheetMonth();
	}, []);


	const dateClass = (type: number): string => {

		switch (type) {
			case DayTimeSheetTypes.EMPTY:
				return "empty"
			case DayTimeSheetTypes.TOO_FEW:
				return "too-few";
			case DayTimeSheetTypes.EXACT:
				return "exact";
			case DayTimeSheetTypes.TOO_MANY:
				return "too-many";
			default:
				return "";
		}
	}

	function GetUserTimeSheetMonth() {
		SVC.GetMonthTimesheetFromID(monthTimesheetID).then((d) => {
			setUserMonthTimeSheet(d.data);
			let sub_title: string = MONTHS["it"][d.data.Month - 1]
				+ " " + d.data.Year.toString();

			setTitle(d.data.UserFullName);
			setSubTitle(sub_title);

		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento TimeSheet: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	return (
		<>
			<TitleBar title={title} />
			<div className="Container p-3">
				<h2>{title}</h2>
				<h5>{subTitle}</h5>
				<table className="table table-sm table-bordered table-timesheet">
					<tbody>
						<tr>
							<td className="name pt-3" style={{ "border": "none" }}>Descrizione</td>

							{userMonthTimeSheet && userMonthTimeSheet.WorkingDates ?
								userMonthTimeSheet.WorkingDates.map(function (item, i) {
									return (
										<td className={item.IsHolidayOrWeekEnd ? "holiday" : "border-0"}>
											<div
												className={dateClass(item.DayTimeSheetType)}
											>
												{new Date(item.WorkingDate).getDate()}
											</div>
										</td>);
								})
								:
								""
							}
							<td className="total">Tot.</td>
						</tr>
						{userMonthTimeSheet && userMonthTimeSheet.Orders ?
							userMonthTimeSheet.Orders.map(function (item, i) {
								return (<tr>
									<td className="name" title={item.CustomerCode + ' ' + item.CustomerName}>
										<span>Commessa</span><br />{item.OrderName}
									</td>

									{item.WorkedDates ?
										item.WorkedDates.map(function (wd, i) {
											return (
												<td className={
													new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
												}>
													{
														wd.Value == 0 ? ""
															:
															minutesToHours(wd.Value)
													}
												</td>
											);
										})
										:
										''
									}
									<td className="total">
										{minutesToHours(item.TotalMinutes)}
									</td>
								</tr>
								);
							})
							:
							''
						}

						{userMonthTimeSheet && userMonthTimeSheet.Permits ?
							userMonthTimeSheet.Permits.map(function (item, i) {
								return (<tr>
									<td className="name">
										<span>Permesso</span><br />{item.PermitTypeDescription}
									</td>

									{item.WorkedDates ?
										item.WorkedDates.map(function (wd, i) {
											return (
												<td className={
													new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
												}>
													{
														wd.Value == 0 ? ""
															:
															minutesToHours(wd.Value)
													}
												</td>
											);
										})
										:
										''
									}
									<td className="total">
										{minutesToHours(item.TotalMinutes)}
									</td>
								</tr>
								);
							})
							:
							''
						}


						{userMonthTimeSheet && userMonthTimeSheet.FullDayPermits ?
							userMonthTimeSheet.FullDayPermits.map(function (item, i) {
								return (<tr>
									<td className="name">
										<span>Assenza</span><br />{item.PermitTypeDescription}
									</td>

									{item.WorkedDates ?
										item.WorkedDates.map(function (wd, i) {
											return (
												<td className={
													new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
												}>
													{
														wd.Value == 0 ? ""
															:
															"x"
													}
												</td>
											);
										})
										:
										''
									}
									<td className="total">
										{item.TotalDays}
									</td>
								</tr>
								);
							})
							:
							''
						}


						{userMonthTimeSheet && userMonthTimeSheet.Availables ?
							userMonthTimeSheet.Availables.map(function (item, i) {
								return (<tr>
									<td className="name">
										<span>Reperibile</span><br />{item.OrderName}
									</td>

									{item.WorkedDates ?
										item.WorkedDates.map(function (wd, i) {
											return (
												<td className={
													new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
												}>
													{
														wd.Value == 0 ? ""
															:
															"x"
													}
												</td>
											);
										})
										:
										''
									}
									<td className="total">
										{item.TotalDays}
									</td>
								</tr>
								);
							})
							:
							''
						}

					</tbody>
				</table>

				<div className="text-right">
					<table className="table table-sm table-bordered float-right" style={{ "width": "180px" }}>
						<tbody>
							<tr>
								<td className="bg-light">Ore ordinarie</td>
								<td className="text-right">{minutesToHours(
									userMonthTimeSheet && userMonthTimeSheet.Minutes ?
										userMonthTimeSheet.Minutes : 0)}</td>
							</tr>
							<tr>
								<td className="bg-light">Straordinari</td>
								<td className="text-right">{minutesToHours(
									userMonthTimeSheet && userMonthTimeSheet.OvertimeMinutes ?
										userMonthTimeSheet.OvertimeMinutes : 0)}</td>
							</tr>
							<tr>
								<td className="bg-light">Straordinari notturni</td>
								<td className="text-right">{minutesToHours(
									userMonthTimeSheet && userMonthTimeSheet.OvertimeNightMinutes ?
										userMonthTimeSheet.OvertimeNightMinutes : 0)}</td>
							</tr>
							<tr>
								<td className="bg-light">Permessi</td>
								<td className="text-right">{minutesToHours(
									userMonthTimeSheet && userMonthTimeSheet.PermitMinutes ?
										userMonthTimeSheet.PermitMinutes : 0)}</td>
							</tr>
							<tr>
								<td className="bg-light">Giorni di assenza</td>
								<td>{
									userMonthTimeSheet && userMonthTimeSheet.FullDayPermitsCount ?
										userMonthTimeSheet.FullDayPermitsCount : 0}</td>
							</tr>
							<tr>
								<td className="bg-light">Giorni di reperibilità</td>
								<td>{
									userMonthTimeSheet && userMonthTimeSheet.AvailablesCount ?
										userMonthTimeSheet.AvailablesCount : 0}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}