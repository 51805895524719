import React, { useEffect, useState } from "react";
import { hoursToDays, minutesToHours } from "../../../../../core/Utilities";
import { SubOrderStepProfile } from "../../../../../models/reports";
import OrderWorkedPercentageTableRow from "./OrderWorkedPercentage";
import UserTimesheetReport from "./UserTimesheetReport";
export interface ISubOrderStepProfileReport {
	data: SubOrderStepProfile
}
export default function SubOrderStepProfileReport(props: ISubOrderStepProfileReport) {
	let assigned: number = 0;
	const [workedHours, setWorkedHours] = useState<string>("");
	const [workedDays, setWorkedDays] = useState<string>("");
	const [profilesAssignedWorkingHours, setProfilesAssignedWorkingHours] = useState<number>(0);

	useEffect(() => {

		SetComputedHours();

	}, [props.data]);

	async function SetComputedHours(){

		let minutes: number = 0;
		let prWorkingHours: number = 0;
		let asWorkingHours: number = 0;

		if (props.data) {
			if (props.data && props.data.UserTimesheets) {
				if (props.data && props.data.UserTimesheets) {
					props.data.UserTimesheets.forEach(ts => {
						if (ts.UserID) {
							minutes += ts.Minutes;
							minutes += ts.OvertimeMinutes;
							minutes += ts.OvertimeNightMinutes;
						}
					});
				}
				if (props.data && props.data.UserAssignedHours) {
					props.data.UserAssignedHours.forEach(us => {
						asWorkingHours += us.WorkingHours ? us.WorkingHours : 0;
					});
				}
			}
		}

		setProfilesAssignedWorkingHours(asWorkingHours);
		setWorkedHours(minutesToHours(minutes));
		setWorkedDays(hoursToDays(minutes / 60));
	}

	return (
		<div className="Container">
			{props.data && props.data.ProfileTypeID && props.data.WorkingHours ?
				<div>
					<div className="mt-1 d-flex w-100">
						<div className="bg-light pl-1 mb-2 w-100">
							<label className="m-0 small">Profilo</label>
							<h6 className="m-0">{props.data.ProfileTypeName}</h6>
						</div></div>
					<div className="w-100 d-flex">
						<div className="w-50 pr-3">
							<table className="table table-sm">
								<thead>
									<tr>
										<th>Attività</th>
										<th className="text-right">Ore</th>
										<th className="text-right">Giorni</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Pianificate</td>
										<td className="text-right">{props.data.WorkingHours}</td>
										<td className="text-right">{hoursToDays(props.data.WorkingHours)}</td>
									</tr>
									<tr>
										<td>Assegnate</td>
										<td className="text-right">{profilesAssignedWorkingHours}</td>
										<td className="text-right">{hoursToDays(profilesAssignedWorkingHours)}</td>
									</tr>
									{
										props.data.UserAssignedHours ?
											props.data.UserAssignedHours
												.filter(function (element) {
													return (element.UserID != undefined);
												})
												.map(function (item, i) {
													// item.UserID ?
													assigned += item.WorkingHours;
													return <tr>
														<td className="nowrap"><ul className="mb-0"><li>{item.FirstName + " " + item.LastName}</li></ul></td>
														<td className="text-right">{item.WorkingHours}</td>
														<td className="text-right">{hoursToDays(item.WorkingHours)}</td>
													</tr>;
													// :
													// ''
												}
												)
											:
											""}

									<tr>
										<td>Lavorate</td>
										<td className="text-right">{workedHours}</td>
										<td className="text-right">{workedDays}</td>
									</tr>
									<OrderWorkedPercentageTableRow 
										OrderWorkedDays={parseFloat(workedDays)} 
										OrderWorkingDays={parseFloat(hoursToDays(props.data.WorkingHours))}
									/>									
								</tbody>
							</table>
						</div>
						<div className="w-50">
							{props.data.UserTimesheets ?
								props.data.UserTimesheets.map(function (item, i) {
									return <UserTimesheetReport data={item} />;
								}
								)
								:
								""
							}
						</div>
					</div>
				</div>
				:
				""
			}
		</div>
	);
}