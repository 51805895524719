import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../../../maincontext";
import { SubOrder, SelectedObjectTypes } from "../../../../models/models";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_person } from "react-icons-kit/md/ic_person";
import { ic_drag_handle } from "react-icons-kit/md/ic_drag_handle";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import { wrench } from "react-icons-kit/icomoon/wrench";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { ic_undo } from "react-icons-kit/md/ic_undo";
import { ic_show_chart } from "react-icons-kit/md/ic_show_chart";

import DetailPanel from "../../../common/DetailPanel";

import SubOrderDetail from "../SubOrderDetail";

export interface ISubOrderComponentProps {
	subOrder: SubOrder | undefined;
	onEdit?(e: MouseEvent<HTMLButtonElement>): void;
}
export default function SubOrderComponent(props: ISubOrderComponentProps) {
	const context = useContext(Maincontext);
	const [panelSize, setPanelSize] = useState<"sm" | "lg" | "xl" | undefined>("sm");
	const [dettaglio, setDettaglio] = useState(<div></div>);
	const [titoloDettaglio, setTitoloDettaglio] = useState("");

	useEffect(() => {

	}, [context.refreshList]);

	function Edit(e: MouseEvent<HTMLButtonElement>) {
		setPanelSize("lg");
		setDettaglio(<SubOrderDetail />);
		setTitoloDettaglio("Deliverable");
		context.setContext({
			...context, showAlert: false, messaggioAlert: "", titoloAlert: "",
			showDetail: true, hiddenDetail: true, objSelected: props.subOrder,
			saveEnabled: false, objTypeSelected: SelectedObjectTypes.SUBORDER,
			panelReadonly: false
		});
	}

	return (
		<div className={"bg-light p-2"}>
			<div className="form-row">
				<div className="form-group col">
					<label>{props.subOrder === undefined ? "" : props.subOrder.SubOrderID.toString() + " " + props.subOrder.SubOrderName}</label>
				</div>
				<div className="form-group col text-right">
					<Link className={"bg-btn mr-1"} to="/suborders" title="Torna ai deliverable" >
						<Icon icon={ic_undo} size={22} /></Link>

					<button onClick={Edit} className={"bg-btn mr-1"} title="Modifica">
						<Icon icon={ic_edit} size={16} />
					</button>
					<button
						onClick={(e: MouseEvent<HTMLButtonElement>) => {
							window.open(`/reports/suborderusers/${props.subOrder?.SubOrderID.toString()}?toolbar=no`);
							(e.target as HTMLButtonElement).blur();
						}}
						title="Ore disponibili per risorsa"
						className={"bg-btn"}>
						<Icon icon={ic_show_chart} size={16} />
					</button>
				</div>
			</div>

			<div className="form-row">
				<div className="form-group col">
					<Icon icon={ic_person} className="mr-1" size={24} />
					<label className="form-label" id="Manager">{props.subOrder?.Manager}</label>
				</div>
				<div className="form-group col">
					<Icon icon={ic_drag_handle} className="mr-1" size={24} />
					<label className="form-label" id="ActivityLineName">{props.subOrder?.ActivityLineName}</label>
				</div>
			</div>

			<div className="form-row">
				<div className="form-group col">
					<Icon icon={ic_date_range} className="mr-1" size={24} />
					<label className="form-label" id="StartDate">{props.subOrder === undefined ? "" : new Date(props.subOrder?.StartDate).toLocaleDateString()}</label>
					<span className="divider"></span>
					<label className="form-label" id="EndDate">{props.subOrder === undefined ? "" : new Date(props.subOrder?.EndDate).toLocaleDateString()}</label>
				</div>
				<div className="form-group col">
					<Icon icon={wrench} className="mr-1" size={16} />
					<label className="form-label" id="WorkingDays">{props.subOrder?.WorkingDays} giornate ({props.subOrder ? props.subOrder.WorkingDays * 8 : 0} ore)</label>
				</div>
			</div>

			<DetailPanel
				body={dettaglio}
				title={titoloDettaglio}
				obj={props.subOrder}
				size={panelSize}
			/>
		</div>
	);
}
