import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form"
import { Maincontext } from "../maincontext";
import { SelectItemsListTypes, SelectListItem, IDValue } from "../../models/models";

import SelectItemServices from "../../api/SelectItemServices";
import CustomerServices from "../../api/CustomerServices";
import Select from "react-select";
import { selectFilter } from "../../core/Utilities";
import { Icon } from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import { ic_delete } from "react-icons-kit/md/ic_delete";

import { Button } from "react-bootstrap";

export interface ICustomerUsersList {
}

export default function CustomerUsersList(props: ICustomerUsersList) {
	const context = useContext(Maincontext);
	const { handleSubmit } = useForm();
	const customerID = context.objSelected.CustomerID as number;



	const svc = new CustomerServices();
	const ISVC = new SelectItemServices();

	const [usersList, setUsersList] = useState<IDValue[]>([]);
	const [userItemsList, setUserItemsList] = useState<SelectListItem[] | []>([]);
	const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(undefined);

	useEffect(() => {
		GetUserItemsList();
		GetList();
	}, []);

	useEffect(() => {
		setContext()
	}, [usersList]);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		svc.getCustomerUsers(customerID).then((d) => {

			if (d.data) {
				setUsersList(d.data);
				context.parentID = customerID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento risorse: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
		context.objectName = context.objSelected.FullName;
	}

	function GetUserItemsList() {

		ISVC.getSelectItemList(SelectItemsListTypes.USERS, 0).then((d) => {
			if (d.data) {
				setUserItemsList(d.data);
				context.parentID = customerID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento risorse: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
		context.objectName = context.objSelected.FullName;
	}

	// function ListSync(list: SelectListItem[]) {

	// 	usersList.forEach(function (e: IDValue, i: number) {
	// 		var removeIndex = list.map(function (item: SelectListItem) {
	// 			return item.Value;
	// 		}).indexOf(e.toString());
	// 		list.splice(removeIndex, 1);
	// 	});
	// 	return list;
	// }


	const isValid = (): boolean => {
		return true;
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = usersList;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setUserID(v: any, i: any) {
		const userID: number = Number(v.Value);
		setSelectedUser(v);
		setContext();
	}

	function addUser() {
		const userID: number = Number(selectedUser ? selectedUser.Value : 0);
		const userName: string = selectedUser ? selectedUser.Text : "";

		const user: IDValue = {
			ID: userID,
			Value: userName
		}

		const index = usersList.map(function (x) { return x.ID; }).indexOf(userID);

		if (index == -1) {
			let uList: IDValue[] = usersList.slice();
			uList.unshift(user);
			setUsersList(uList);
		}

	}
	function deleteUser(userID: number) {

		let uList: IDValue[] = usersList.slice();
		uList.forEach(function (e: IDValue, i: number) {
			if (e.ID == userID) {
				delete uList[i];
				setUsersList(uList);
				return;
			}
		});
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col-10">
						<label className="form-label">Risorse</label>

						<Select
							filterOption={selectFilter}
							value={selectedUser}
							options={userItemsList}
							isSearchable
							onChange={setUserID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</div>
					<div className="form-group col-2">
						<br />
						<Button variant="outline-primary" disabled={selectedUser ? false : true} className="btn-sm ml-2 mt-2"
							onClick={() => addUser()} title="Aggiungi"><Icon icon={ic_add} /></Button>
					</div>
				</div>
				<div className="fixed-scroll">
					<table className="table table-sm">
						<thead>
							<tr>
								<th colSpan={2}>
									Risorse assegnate
								</th>
							</tr>
						</thead>
						<tbody>
							{
								usersList.length > 0 ?
									usersList.map(function (item: IDValue, i: number) {
										return (
											<tr>
												<td>
													{item.Value}
												</td>
												<td className="text-right">

													<button
														className="btn btn-sm btn-link m-0 p-0"
														onClick={() => deleteUser(item.ID)}
													>
														<Icon icon={ic_delete} size="20" />
													</button>

												</td>
											</tr>
										);
									})
									:
									""
							}
						</tbody>
					</table>
				</div>
			</form >
		</div >
	);
}

