import { WorkingHoursType } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class WorkingHoursTypeServices {
	//elenco completo
	async getWorkingHoursTypes() {
		return axios.get<WorkingHoursType[]>(`${await SVC.getApiBaseUri()}/api/WorkingHoursTypes`, { headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getWorkingHoursType(id: number) {
		const params = {
			id: id
		};
		return axios.get<WorkingHoursType>(`${await SVC.getApiBaseUri()}/api/WorkingHoursTypes`, { params, headers: await SVC.setAzureAuth() });
	}

	//update orario di lavoro    
	async WorkingHoursTypeUpdate(workingHoursType: WorkingHoursType) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/WorkingHoursTypes`, workingHoursType, { headers: await SVC.setAzureAuth() });
	}

	async WorkingHoursTypeDelete(workingHoursType: WorkingHoursType) {

		const params = {
			id: workingHoursType.WorkingHoursTypeID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/WorkingHoursTypes`,
			{ params, headers: await SVC.setAzureAuth() });
	}
}