import { ProfileType } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class ProfileTypeServices {

	//elenco completo 
	async getProfileTypesList() {
		return axios.get<ProfileType[]>(`${await SVC.getApiBaseUri()}/api/profiletypes`, { headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getProfileType(id: number) {
		const params = {
			id: id
		};
		return axios.get<ProfileType>(`${await SVC.getApiBaseUri()}/api/profiletypes`, { params, headers: await SVC.setAzureAuth() });
	}

	//update profilo profesisonale
	async ProfileTypeUpdate(profileType: ProfileType) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/profiletypes`, profileType, { headers: await SVC.setAzureAuth() });
	}

	async ProfileTypeDelete(profileType: ProfileType) {

		const params = {
			id: profileType.ProfileTypeID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/profiletypes`,
			{ params, headers: await SVC.setAzureAuth() });
	}
}