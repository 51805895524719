import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { ActivityLine } from "../../models/models";
import ActivityLinesServices from "../../api/ActivityLinesServices";
import { Form } from "react-bootstrap";

export interface IActivityLine {
}


export default function ProfileTypeDetail(props: IActivityLine) {
	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();
	const SVC = new ActivityLinesServices();

	//ID dell'elemento
	let id = 0;
	if (context.objSelected) {
		id = context.objSelected.ActivityLineID as number;
	}

	//elemento predefinito
	const defActivityLine: ActivityLine = {
		ActivityLineID: 0,
		ActivityLineName: "",	
		CreationDate: new Date(),
		Enabled: true
	};

	//impostazione dello stato dell'elemento corrente utilizzando useState
	const [activityLine, setActivityLine] = useState<ActivityLine>(defActivityLine);

	useEffect(() => {
		if (id) {
			//imposta l'elemento corrente
			GetItem();
		}
		else {
			//se è un nuovo elemento chiude il pannello loading 
			//e visualizza il pannnello di dettaglio
			context.setContext({ ...context, /*showAttesa: false,*/ hiddenDetail: false });
		}
	}, []);

	//carica l'elemento corrente utilizzando i service
	function GetItem() {

		//context.setContext({ ...context, showAttesa: true });

		SVC.getActivityLine(id).then((d) => {
			if (d.data) {
				//se restituisce il dato lo imposta nello stato
				setActivityLine(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Activity Line: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			//appena caricati i dati chiude il pannello loading 
			//e visualizza il pannnello di dettaglio
			context.setContext({ ...context, /*showAttesa: false,*/ hiddenDetail: false });
		});

		context.objectName = context.objSelected.ActivityLineName;
	}

	//validazione lato client
	const isValid = (): boolean => {
		return activityLine.ActivityLineName !== "";
	};

	function setContext() {

		const valid = isValid();
		if (valid) {
			//aggiorna i dati nel context
			context.objSelected = activityLine;
		}
		//se è valido abilita il tasto Salva
		context.setContext({ ...context, saveEnabled: valid });
	}

	//eseguita sul cambio valore del flag enabled
	function setEnabled(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
		activityLine.Enabled = enabled;
		setContext();
	}
	//eseguita sul cambio valore del campo input 
	function setName(e: ChangeEvent<HTMLInputElement>) {

		const name: string = e.target.value;
		activityLine.ActivityLineName = name;
		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Linea attività</label>
						<input className="form-control" name="ActivityLineName"
							defaultValue={activityLine.ActivityLineName}
							onChange={setName}
							ref={register({ required: true, maxLength: 50 })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<Form.Check
							id="ActivityLineEnabled"
							type="switch"
							label={"Abilitato"}
							checked={activityLine.Enabled}
							onChange={setEnabled} />
					</div>
				</div>
			</form>
		</div>
	);
}