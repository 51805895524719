import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { authProvider } from "../../authProvider";
export default function Loading() {

	const history = useHistory();

	const [showAttesa, setShowAttesa] = useState<boolean | undefined>();

	const divStyle = {
		backgroundColor: "white",
		opacity: "0.4",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		position: "absolute",
		zIndex: 2001		
	} as React.CSSProperties;

	const centeredItem = {
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 2002
	} as React.CSSProperties;

	const divWrapperStyle = {
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		position: "fixed",
		zIndex: 2000,
		display: showAttesa ? "block" : "none"
	} as React.CSSProperties;

	let axiosRequestsCount:number = -1;

	if (showAttesa === undefined) {
		axios.interceptors.request.use((config) => {
			if (axiosRequestsCount < 0) {
				axiosRequestsCount = 0;	
				setTimeout(() => {
					if (axiosRequestsCount > 0) setShowAttesa(true);
				}, 200);			
			};		
			axiosRequestsCount++; 
			return config;
		}, (error) => {
			return Promise.reject(error);
		});
		
		axios.interceptors.response.use((response) => {		
			axiosRequestsCount--;		
			setTimeout(() => {
				if (axiosRequestsCount === 0) {
					axiosRequestsCount = -1;	
					setShowAttesa(false);				
				}; 
			}, 500);
			return response;
		}, (error) => {
			axiosRequestsCount--;		
			setTimeout(() => {
				if (axiosRequestsCount === 0) {					
					axiosRequestsCount = -1;	
					setShowAttesa(false);				
				}; 
			}, 500);
			if (error.response.status === 401) {
				const infoAccount = authProvider.getAccountInfo();
				if (infoAccount) {
					if(parseInt((+ new Date() / 1000).toString()) > parseInt(infoAccount.account.idToken.exp)) {
						//window.location.href = "/";
						history.push("/expired");
					}
					else {
						history.push("/unauthorized");
					}		
				}	
			}
			return Promise.reject(error);
		});
	}

	return (		
		<div style={divWrapperStyle}>
			<div style={divStyle}>
			</div>
			<div style={centeredItem}>
				<Spinner animation="border" variant="primary" />
			</div>
		</div>
	);
	
}