import React, { useEffect, useState } from "react";
import { SubOrder } from "../../../../../models/reports";
import SubOrderStepReport from "./SubOrderStepReport";
import OrderActivityReport from "./OrderActivityReport";
import { hoursToDays, minutesToHours } from "../../../../../core/Utilities";
import { Collapse } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { ic_expand_less } from "react-icons-kit/md/ic_expand_less";
import { ic_expand_more } from "react-icons-kit/md/ic_expand_more";
import OrderWorkedPercentageTableRow from "./OrderWorkedPercentage";
export interface ISubOrderReport {
	data: SubOrder,
	collapsed?:boolean
}

export default function SubOrderReport(props: ISubOrderReport) {
	//campi calcolati
	const [workedHours, setWorkedHours] = useState<string>("");
	const [workedDays, setWorkedDays] = useState<string>("");
	const [stepsWorkingWorkingHours, setStepsWorkingHours] = useState<number>(0);
	const [profilesWorkingHours, setProfilesWorkingHours] = useState<number>(0);
	const [profilesAssignedWorkingHours, setProfilesAssignedWorkingHours] = useState<number>(0);
	const [open, setOpen] = useState(false);

	useEffect(() => {

		SetComputedHours();

	}, [props.data]);
	
	useEffect(() => {

		if(props.collapsed != undefined){
			setOpen(props.collapsed);
		}

	}, [props.collapsed]);

	async function SetComputedHours(){

		let minutes: number = 0;
		let stWorkingHours: number = 0;
		let prWorkingHours: number = 0;
		let asWorkingHours: number = 0;

		if (props.data) {
			if (props.data && props.data.SubOrderSteps) {
				props.data.SubOrderSteps.forEach(step => {
					if (step && step.SubOrderStepProfiles) {
						stWorkingHours += step.WorkingHours ? step.WorkingHours : 0;
						step.SubOrderStepProfiles.forEach(prof => {
							prWorkingHours += prof.WorkingHours ? prof.WorkingHours : 0;
							if (prof && prof.UserTimesheets) {
								prof.UserTimesheets.forEach(ts => {
									if (ts.UserID) {
										minutes += ts.Minutes;
										minutes += ts.OvertimeMinutes;
										minutes += ts.OvertimeNightMinutes;
									}
								});
							}

							if (prof && prof.UserAssignedHours) {
								prof.UserAssignedHours.forEach(ts => {
									if (ts.UserID) {
										asWorkingHours += ts.WorkingHours;
									}
								});
							}
						});
					}
				});
			}
		}
		setStepsWorkingHours(stWorkingHours);
		setProfilesWorkingHours(prWorkingHours);
		setProfilesAssignedWorkingHours(asWorkingHours);
		setWorkedHours(minutesToHours(minutes));
		setWorkedDays(hoursToDays(minutes / 60));
	};
	const toggle = () => setOpen(!open);
	return (
		<div className="Container">
			{props.data ?
				<div>
					<div className="mt-3 d-flex w-100">
						<div onClick={toggle} className="text-left border border-0 bg-light pl-1 pb-1 mb-2 w-100 pointer">
							<div className="float-left">
								<label className="m-0 small text-dark">Deliverable</label>
								<h4 className="m-0 text-dark">{props.data.SubOrderName}</h4>
							</div>
							<Icon icon={open ? ic_expand_less : ic_expand_more}
								size={26}
								className="float-right text-muted mt-2 mr-2" />
						</div>
					</div>
					<div className="d-flex">
						<Collapse in={open}>
							<div className="w-100">
								{props.data.ActivityLineID === 0 ?
									<div className="w-100">
										<OrderActivityReport
											descriptionsData={props.data.ActivityByDescriptions}
											usersData={props.data.ActivityByUsers} />
									</div>
									:
									<div className="w-100 d-flex">
										<div className="w-25 pr-3">
											<table className="table table-sm">
												<tbody>
													<tr>
														<td>Responsabile</td>
														<td>{props.data.FirstName
															+ " " +
															props.data.LastName}</td>
													</tr>
													<tr>
														<td>Data inizio</td>
														<td>{new Date(props.data.StartDate).toLocaleDateString()}</td>
													</tr>
													<tr>
														<td>Data fine</td>
														<td>{new Date(props.data.EndDate).toLocaleDateString()}</td>
													</tr>
												</tbody>
											</table>
											<table className="table table-sm">
												<thead>
													<tr>
														<th>Attività</th>
														<th className="text-right">Ore</th>
														<th className="text-right">Giorni</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className="nowrap">Pianificate</td>
														<td className="text-right">{props.data.WorkingDays * 8}</td>
														<td className="text-right">{props.data.WorkingDays}</td>
													</tr>
													<tr>
														<td className="nowrap">Pianificate fasi</td>
														<td className="text-right">{stepsWorkingWorkingHours}</td>
														<td className="text-right">{hoursToDays(stepsWorkingWorkingHours)}</td>
													</tr>
													<tr>
														<td className="nowrap">Pianificate profili</td>
														<td className="text-right">{profilesWorkingHours}</td>
														<td className="text-right">{hoursToDays(profilesWorkingHours)}</td>
													</tr>
													<tr>
														<td className="nowrap">Assegnate</td>
														<td className="text-right">{profilesAssignedWorkingHours}</td>
														<td className="text-right">{hoursToDays(profilesAssignedWorkingHours)}</td>
													</tr>
													<tr>
														<td className="nowrap">Lavorate</td>
														<td className="text-right">{workedHours}</td>
														<td className="text-right">{workedDays}</td>
													</tr>
													<OrderWorkedPercentageTableRow 
														OrderWorkedDays={parseFloat(workedDays)} 
														OrderWorkingDays={props.data.WorkingDays}
													/>
												</tbody>
											</table>
										</div>
										<div className="w-75">
											{
												props.data.SubOrderSteps ?
													props.data.SubOrderSteps.map(function (item, i) {
														return <SubOrderStepReport data={item} />;
													}
													)
													:
													""
											}
										</div>
									</div>
								}
							</div>
						</Collapse>
					</div>
				</div>
				:
				""
			}
		</div >
	);
}