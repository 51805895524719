
export const WEEKDAYS_SHORT = {
	it: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
};

export const MONTHS = {
	it: [
		"Gennaio",
		"Febbraio",
		"Marzo",
		"Aprile",
		"Maggio",
		"Giugno",
		"Luglio",
		"Agosto",
		"Settembre",
		"Ottobre",
		"Novembre",
		"Dicembre",
	],
};

export const MONTHS_SHORT = {
	it: [
		"gen",
		"feb",
		"mar",
		"apr",
		"mag",
		"giu",
		"lug",
		"ago",
		"set",
		"ott",
		"nov",
		"dic",
	],
};

export const WEEKDAYS_LONG = {
	it: [
		"Domenica",
		"Lunedì",
		"Martedì",
		"Mercoledì",
		"Giovedì",
		"Venerdì",
		"Sabato",
	],
};

export const FIRST_DAY_OF_WEEK = {
	it: 1,
};

// Translate aria-labels
export const LABELS = {
	it: { nextMonth: "Prossimo mese", previousMonth: "Mese precedente" },
};
export const TODAY_LABEL = {
	it: "Oggi",
};

export function formatDate(date?: Date): string {
	return date ? new Date(date).toLocaleDateString() : new Date().toLocaleTimeString();
}
export function formatDateTime(date: Date, timezone?: boolean): string {
	let d: Date = new Date();
	if (!timezone) {
		//elimino la Z che genera il timezone e sposta avanti di 2 ore
		const s = new Date(date).toISOString().replace("Z", "");
		d = new Date(s);
	}
	else {
		d = new Date(date);
	}
	return d.toLocaleDateString() + " " + d.toLocaleTimeString();
}