import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import ActivityStepServices from "../../api/ActivityStepServices";
import { ActivityStep, columnDetail, SelectedObjectTypes, MenuList, DataColumnTypes } from "../../models/models";
import ActivityStepDetail from "./ActivityStepDetail";
import TitleBar from "../common/TitleBar";

export interface IAppProps {
}

export default function ActivityStepsList(props: IAppProps) {
	const context = useContext(Maincontext);
	const SVC = new ActivityStepServices();
	const [list, setList] = useState<ActivityStep[]>([]);

	//Dichiarazione della colonna di dettaglio che determina se una colonna viene visualizzata e in che posizione se non viene passato viene fatto il rendering dell'oggetto
	const columnDetails: columnDetail[] = [
		{ name: "ActivityLineName", text: "Linea attività", position: 1 },
		{ name: "ActivityStepName", text: "Fase attività", position: 2 },
		{ name: "Enabled", text: "Abilitato", position: 3, /*size: 100*/ },
		{ name: "CreationDate", text: "Data creazione", position: 4, type: DataColumnTypes.DATE }
	];

	useEffect(() => {
		GetList();
	}, []);
	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList]);


	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getActivityStepsList().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Activity Steps List: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: MenuList.ACTIVITY_STEPS, });
		});
	}

	function onDelete(e: MouseEvent<HTMLButtonElement>) {
		const ActivityStep: ActivityStep = context.objSelected;

		SVC.ActivityStepDelete(ActivityStep).then((d) => {
			if (d.data !== "") {
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione fase attività";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			<TitleBar title="Fasi attività" />
			<Lista obj={list} details={[
				{
					detail: <ActivityStepDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.ACTIVITY_STEP
				},
				{
					detail: <ActivityStepDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.ACTIVITY_STEP,
					isFunction: true,
					onClick: onDelete,
					confirm: true,
					confirmMessage: "Eliminare la fase attività?"
				}
			]}
			columnDetails={columnDetails}
			addButton={true}
			addElement={<ActivityStepDetail />}
			addType={SelectedObjectTypes.ACTIVITY_STEP}
			addPanelSize={undefined}
			detailPosition={1}
			/>
		</div>
	);
}

