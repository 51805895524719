import React, { useContext, useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { ValueForItem } from "../../../models/charts";
import ChartsServices from "../../../api/ChartsServices";
import { Maincontext } from "../../maincontext";
import ItemValuesBarChart from "../../common/charts/ItemValuesBarChart";

export interface ICostCentersUsersChart {

}

export default function CostCentersUsersChart(props: ICostCentersUsersChart) {

	const SVC = new ChartsServices();
	const context = useContext(Maincontext);
	const [chartData, setChartData] = useState<ValueForItem[]>([]);

	function GetData() {

		SVC.GetCostCentersUsersCount().then((d) => {
			if (d.data) {
				setChartData(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento dati garfico centri di costo " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	useEffect(() => {
		GetData();
	}, [context.refreshList]);

	return (
		<div className="widget p-2 border-0">
			<ItemValuesBarChart Label="Risorse per centro di costo" List={chartData} />
		</div>
	);
}

