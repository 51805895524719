import React, { useContext, useState, useEffect } from "react";
import {
  Customer,
  SelectItemsListTypes,
  SelectListItem,
} from "../../../models/models";
import { Maincontext } from "../../maincontext";
import SelectItemServices from "../../../api/SelectItemServices";
import Select from "react-select";
import { selectFilter } from "../../../core/Utilities";
import ReportServices from "../../../api/ReportServices";
import { TechnicalIntervention } from "../../../models/reports";
import { Button, Form, Modal } from "react-bootstrap";
import { MONTHS } from "../../../models/locale";

export interface ITechnicalInterventionDialog {
  visible: boolean;
  closeModal(): void;
}

export default function TechnicalInterventionDialog(
  props: ITechnicalInterventionDialog
) {
  const context = useContext(Maincontext);

  var ti: TechnicalIntervention = {
    UserID: 0,
    OrderID: 0,
    Year: 0,
    Month: 0,
	SupportIncluded: false,
  };
  const [yearsList, setYearsList] = useState<SelectListItem[] | []>([]);
  const [monthList, setMonthList] = useState<SelectListItem[]>([]);
  const [selectedYear, setSelectedYear] = useState<SelectListItem | undefined>(
    undefined
  );
  const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();
  const [supportIncluded, setSupportIncluded] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<SelectListItem[]>([]);
  const [orderList, setOrderList] = useState<SelectListItem[]>([]);
  const [userList, setUserList] = useState<SelectListItem[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<
    SelectListItem | undefined
  >(undefined);
  const [selectedOrder, setSelectedOrder] = useState<
    SelectListItem | undefined
  >(undefined);
  const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(
    undefined
  );
  const [technicalIntervention, setTechnicalIntervention] =
    useState<TechnicalIntervention>(ti);
  const [downloadEnabled, setDownloadEnabled] = useState<boolean>(false);

  const ISVC = new SelectItemServices();
  const RSVC = new ReportServices();

  useEffect(() => {
    MonthListInitialize();
    GetYearList();
  }, []);

  useEffect(() => {
    if (yearsList[0]) {
      setSelectedYear(yearsList[0]);
    }
  }, [yearsList]);

  useEffect(() => {
    const month: number = new Date().getMonth();
    if (monthList[month]) {
      setSelectedMonth(monthList[month]);
    }
  }, [monthList]);
  useEffect(() => {
    if (customerList.length > 0) {
      setSelectedCustomer(customerList[0]);
    } else {
      setSelectedCustomer(undefined);
    }
  }, [customerList]);

  useEffect(() => {
    if (orderList.length > 0) {
      setSelectedOrder(orderList[0]);
    } else {
      setSelectedOrder(undefined);
    }
  }, [orderList]);

  useEffect(() => {
    if (userList.length > 0) {
      setSelectedUser(userList[0]);
    } else {
      setSelectedUser(undefined);
    }
  }, [userList]);

  useEffect(() => {
    GetCustomerList();
    setTechnicalIntervention({
      ...technicalIntervention,
      Month: selectedMonth ? Number(selectedMonth.Value) : 0,
      Year: selectedYear ? Number(selectedYear.Value) : 0,
	  SupportIncluded: supportIncluded,
    });
  }, [selectedMonth, selectedYear, supportIncluded]);

  useEffect(() => {
    GetOrderList();
  }, [selectedCustomer]);

  useEffect(() => {
    GetUserList();

    setTechnicalIntervention({
      ...technicalIntervention,
      OrderID: selectedOrder ? Number(selectedOrder.Value) : 0,
    });
  }, [selectedOrder]);

  useEffect(() => {
    setTechnicalIntervention({
      ...technicalIntervention,
      UserID: selectedUser ? Number(selectedUser.Value) : 0,
    });
  }, [selectedUser]);

  useEffect(() => {
    const enabled =
      technicalIntervention.Month != 0 &&
      technicalIntervention.Year != 0 &&
      technicalIntervention.OrderID != 0 &&
      technicalIntervention.UserID != 0;

    setDownloadEnabled(enabled);
  }, [technicalIntervention]);

  function GetYearList() {
    let ylist: SelectListItem[] = [];
    let item: SelectListItem;
    let y: number = new Date().getFullYear();
    item = { Text: y.toString(), Value: y.toString() };
    ylist.push(item);
    y -= 1;
    item = { Text: y.toString(), Value: y.toString() };
    ylist.push(item);

    setYearsList(ylist);
  }

  function MonthListInitialize() {
    let mlist: SelectListItem[] = [];
    let item: SelectListItem;
    for (let i: number = 1; i <= 12; i++) {
      const v = i.toString();
      const t = MONTHS["it"][i - 1];
      item = { Text: t, Value: v };
      mlist.push(item);
    }
    setMonthList(mlist);
  }

  function GetCustomerList() {
    if (selectedYear && selectedMonth) {
      const year = Number(selectedYear.Value);
      const month = Number(selectedMonth.Value);
      {
        ISVC.getCustomersWorkedItems(year, month)
          .then((l) => {
            if (l.data) {
              setCustomerList(l.data);
            }
          })
          .catch((e) => {
            context.setContext({
              ...context,
              showAlert: true,
              messaggioAlert: "Errore di caricamento Customers: " + e,
              titoloAlert: "Errore",
            });
          })
          .finally(() => {});
      }
    } else {
      setCustomerList([]);
    }
  }

  function GetOrderList() {
    if (selectedCustomer && selectedYear && selectedMonth) {
      const customerID = Number(selectedCustomer.Value);
      const year = Number(selectedYear.Value);
      const month = Number(selectedMonth.Value);

      ISVC.getOrdersWorkedItems(customerID, year, month)
        .then((l) => {
          if (l.data) {
            setOrderList(l.data);
          }
        })
        .catch((e) => {
          context.setContext({
            ...context,
            showAlert: true,
            messaggioAlert: "Errore di caricamento Orders: " + e,
            titoloAlert: "Errore",
          });
        })
        .finally(() => {});
    } else {
      setOrderList([]);
    }
  }

  function GetUserList() {
    if (selectedOrder && selectedYear && selectedMonth) {
      const orderID = Number(selectedOrder.Value);
      const year = Number(selectedYear.Value);
      const month = Number(selectedMonth.Value);

      ISVC.getUsersWorkedItems(orderID, year, month)
        .then((l) => {
          if (l.data) {
            setUserList(l.data);
          }
        })
        .catch((e) => {
          context.setContext({
            ...context,
            showAlert: true,
            messaggioAlert: "Errore di caricamento Users: " + e,
            titoloAlert: "Errore",
          });
        })
        .finally(() => {});
    } else {
      setUserList([]);
    }
  }

  function setCustomerID(v: any, i: any) {
    setSelectedCustomer(v);
  }

  function setOrderID(v: any, i: any) {
    setSelectedOrder(v);
  }

  function setUserID(v: any, i: any) {
    setSelectedUser(v);
  }

  function setYear(v: any, i: any) {
    setSelectedYear(v);
    setSelectedMonth(monthList[0]);
  }

  function changeMonth(v: any, i: any) {
    setSelectedMonth(v);
  }

  function DownloadDocument() {
    RSVC.GetTechnicalIntervention(technicalIntervention);
    props.closeModal();
  }

  return (
    <div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size={"lg"}
        show={props.visible}
        backdrop={"static"}
      >
        <Modal.Header>
          <Modal.Title className="fluid">
            Interventi tecnici mensili
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-row">
            <div className="form-group col-4">
              <label className="form-label">Anno</label>
              <Select
                filterOption={selectFilter}
                value={selectedYear}
                options={yearsList}
                isSearchable
                onChange={setYear}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </div>
            <div className="form-group col-4">
              <label>Mese</label>
              <Select
                filterOption={selectFilter}
                value={selectedMonth}
                options={monthList}
                isSearchable
                onChange={changeMonth}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </div>
            <div className="form-group col-4">
              <Form.Check
                style={{ marginTop: "36px" }}
                id="upport-chk"
                type="switch"
                label="Includi affiancamento"
                checked={supportIncluded}
                onChange={(event) => setSupportIncluded(event.target.checked)}
              />
            </div>
            <div className="form-group col-12">
              <label className="form-label">Cliente</label>
              <Select
                filterOption={selectFilter}
                value={selectedCustomer}
                options={customerList}
                isSearchable
                onChange={setCustomerID}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </div>
            <div className="form-group col-12">
              <label className="form-label">Commessa</label>
              <Select
                filterOption={selectFilter}
                value={selectedOrder}
                options={orderList}
                isSearchable
                onChange={setOrderID}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </div>
            <div className="form-group col-12">
              <label className="form-label">Risorsa</label>
              <Select
                filterOption={selectFilter}
                value={selectedUser}
                options={userList}
                isSearchable
                onChange={setUserID}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </div>
          </div>
          <div title="Esporta in pdf" className="d-inline"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => props.closeModal()}
          >
            Chiudi
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={!downloadEnabled}
            onClick={() => RSVC.GetTechnicalIntervention(technicalIntervention)}
          >
            Dowload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
