import React, { useContext } from "react";
import { Maincontext, UserContext } from "../../maincontext";

import UserServices from "../../../api/UserServices";
import { formatDateTime } from "../../../models/locale";

export interface IUserLoginsWidget {
	list: Date[]
}

export default function UserLoginsWidget(props: IUserLoginsWidget) {
	const SVC = new UserServices();
	const UserInfo = useContext(UserContext);
	const context = useContext(Maincontext);
	return (
		<div className="widget p-2 border-0">
			<table className="table table-sm">
				<thead>
					<tr>
						<th>Ultimi accessi</th>
					</tr>
				</thead>
				<tbody>
					{
						props.list.map(function (item, i) {
							return (
							<tr key={"tr_" + i.toString()}>
								<td>
									{formatDateTime(item)}
								</td>
							</tr>
							)
						})
					}
				</tbody>
			</table>
		</div>
	);
}

