import { SelectedObjectTypes, User, ContractType, ProfileType, CostCenter, WorkingHoursType, UserCostCenter, SelectListItem, UserAuthorizationType, UserContractType, Customer, UserProfileType, Order, SubOrder, ActivityLine, ActivityStep, ActivityLineProfileType, ContentTypes, UserAuthorizationTypes, PermitType, IDValue } from "../models/models";
import ContractTypeServices from "../api/ContractTypeServices";
import CostCenterServices from "../api/CostCenterServices";
import CustomerServices from "../api/CustomerServices";
import ProfileTypeServices from "../api/ProfileTypeServices";
import UserServices from "../api/UserServices";
import WorkingHoursTypeServices from "../api/WorkingHoursTypeServices";
import { Context } from "vm";
import OrderServices from "../api/OrderServices";
import SubOrderServices from "../api/SubOrderServices";
import ActivityLinesServices from "../api/ActivityLinesServices";
import ActivityStepServices from "../api/ActivityStepServices";

import { UserContext } from "../components/maincontext";
import { useContext } from "react";
import PermitTypesServices from "../api/PermitTypesServices";

//salva i dati del DetailPanel in base a objTypeSelected di tipo SelectedObjectTypes
//il contex deve essere passato come argomento
export const SaveDetailObject = (async (objTypeSelected: SelectedObjectTypes | undefined, context: Context) => {

	let SVC;
	const parentID: number = context.parentID;
	let selectListItems: SelectListItem[] = [];

	switch (context.objTypeSelected) {

		case SelectedObjectTypes.USER:

			SVC = new UserServices();
			const user: User = context.objSelected;
			return SVC.UserUpdate(user);

		case SelectedObjectTypes.CONTRACT_TYPE:

			SVC = new ContractTypeServices();
			const contractType: ContractType = context.objSelected;
			return SVC.ContractTypeUpdate(contractType);

		case SelectedObjectTypes.PROFILE_TYPE:

			SVC = new ProfileTypeServices();
			const profileType: ProfileType = context.objSelected;
			return SVC.ProfileTypeUpdate(profileType);

		case SelectedObjectTypes.ACTIVITY_LINE:

			SVC = new ActivityLinesServices();
			const activityLine: ActivityLine = context.objSelected;
			return SVC.ActivityLineUpdate(activityLine);

		case SelectedObjectTypes.PERMIT_TYPE:
			SVC = new PermitTypesServices();
			const permitType: PermitType = context.objSelected;
			return SVC.PermitTypeUpdate(permitType);

		case SelectedObjectTypes.ACTIVITY_LINE_PROFILE_TYPE:

			SVC = new ActivityLinesServices();
			selectListItems = context.objSelected;
			const activityLineProfileTypes: ActivityLineProfileType[] = [];

			selectListItems.forEach(item => {
				if (item.Selected) {
					activityLineProfileTypes.push({
						ProfileTypeID: Number(item.Value),
						ProfileTypeName: item.Text,
						ActivityLineID: parentID
					});
				}
			});
			return SVC.ActivityLineProfileTypesUpdate(parentID, activityLineProfileTypes);

		case SelectedObjectTypes.ACTIVITY_STEP:

			SVC = new ActivityStepServices();
			const activityStep: ActivityStep = context.objSelected;
			return SVC.ActivityStepUpdate(activityStep);

		case SelectedObjectTypes.COST_CENTER:

			SVC = new CostCenterServices();
			const costCenter: CostCenter = context.objSelected;
			return SVC.CostCenterUpdate(costCenter);

		case SelectedObjectTypes.WORKING_HOURS_TYPE:

			SVC = new WorkingHoursTypeServices();
			const workingHoursType: WorkingHoursType = context.objSelected;
			return SVC.WorkingHoursTypeUpdate(workingHoursType);

		case SelectedObjectTypes.USER_COST_CENTER:

			SVC = new UserServices();
			const userCostCenters: UserCostCenter[] = context.objSelected.filter(isPercentValid);
			return SVC.UserCostCentersUpdate(parentID, userCostCenters);

		case SelectedObjectTypes.USER_AUTHORIZATION_TYPE:

			SVC = new UserServices();
			selectListItems = context.objSelected;
			const userAuthorizationTypes: UserAuthorizationType[] = [];
			//cast da SelectListItem a UserAuthorizationType
			selectListItems.forEach(item => {
				if (item.Selected) {
					userAuthorizationTypes.push({
						AuthorizationTypeID: Number(item.Value),
						AuthorizationTypeName: item.Text, UserID: parentID
					});
				}
			});
			return SVC.UserAuthorizationTypesUpdate(parentID, userAuthorizationTypes);

		case SelectedObjectTypes.USER_PROFILE_TYPE:

			SVC = new UserServices();
			selectListItems = context.objSelected;
			const userProfileTypes: UserProfileType[] = [];
			//cast da SelectListItem a UserAuthorizationType
			selectListItems.forEach(item => {
				if (item.Selected) {
					userProfileTypes.push({
						ProfileTypeID: Number(item.Value),
						ProfileTypeName: item.Text,
						UserID: parentID
					});
				}
			});
			return SVC.UserProfileTypesUpdate(parentID, userProfileTypes);

		case SelectedObjectTypes.USER_CONTRACT_TYPE:

			SVC = new UserServices();
			const userContractTypes: UserContractType[] = context.objSelected;
			return SVC.UserContractTypesUpdate(parentID, userContractTypes);

		case SelectedObjectTypes.CUSTOMER:

			SVC = new CustomerServices();
			const customer: Customer = context.objSelected;
			return SVC.CustomerUpdate(customer);

		case SelectedObjectTypes.ORDER:

			SVC = new OrderServices();
			const order: Order = context.objSelected;
			return SVC.OrderUpdate(order);

		case SelectedObjectTypes.SUBORDER:

			SVC = new SubOrderServices();
			const suborder: SubOrder = context.objSelected;
			return SVC.SubOrderUpdate(suborder);

		case SelectedObjectTypes.COSTOMER_USERS:
			SVC = new CustomerServices();
			const customerUsers: IDValue[] = context.objSelected;
			let users: number[] = [];
			customerUsers.forEach(item => {
				users.push(item.ID);
			});
			return SVC.CustomerUsersUpdate(parentID, users);

	}
});

function isPercentValid(element: UserCostCenter) {
	return (element.Percentage > 0);
}

export function getIndexSelectedById(items: SelectListItem[], v: number | string): number {
	let ret = -1;
	items.forEach((c, i) => {
		if (typeof v == "string") {
			if (c.Value === v) {
				ret = i;
			}
		} else {
			if (parseInt(c.Value) === v) {
				ret = i;
			}
		}
	});
	return ret;
}

export function getItemByValue(items: SelectListItem[], v: any): SelectListItem | undefined {

	let ret: SelectListItem | undefined = undefined;
	items.forEach((c) => {
		if (c.Value === v) {
			ret = c;
		}
	});
	return ret;
}

export function selectFilter(a: any, b: string): boolean {
	return a.label.toString().toLowerCase().indexOf(b.toLowerCase()) !== -1;
}

export function sleep(ms: number): Promise<void> {
	return new Promise(resolve => setTimeout(resolve, ms));
}

//funzioni sulle date
export function addDays(date: Date, days: number): Date {
	let newDate: Date = new Date(date);
	newDate.setDate(date.getDate() + days);
	return newDate;
}

export function formatDateToString(inputData: Date) {
	function pad(s: number) { return (s < 10) ? "0" + s : s; }
	var d = new Date(inputData);
	return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

export function formatDateToStringDelimiter(inputData: Date, delim: string) {
	function pad(s: number) { return (s < 10) ? "0" + s : s; }
	var d = new Date(inputData);
	return [ d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join(delim);
}

export function minutesToHours(m: number) {

	//LUCIANO modificata per gestire il dettaglio fino a un minuto
	const hours = Math.trunc(m / 60);
	const minutes = m - (hours * 60);
	return hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0");

}
export function hoursToMinutes(h: string) {
	if (h.indexOf(":") > 0) h = h.replace(":", ".");
	if (h.indexOf(",") > 0) h = h.replace(",", ".");
	let t = parseFloat(h);
	let rhours = Math.floor(t);
	let mins = (t - rhours) * 100;
	return (mins + (rhours * 60));
}
export function hoursToDays(h: number): string {
	let days: string = (h / 8).toFixed(1);
	var num_parts = days.replace(".", ",").split("-");
	num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return num_parts.join("-");
}

export function minutesToDays(m: number): string {
	let days: string = (m / 60 / 8).toFixed(1);
	var num_parts = days.replace(".", ",").split("-");
	num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return num_parts.join("-");
}
export function GetMonthNumberOfDays(year: number, month: number) {
	return new Date(year, month + 1, 0).getDate();
}

export function thousandSeparator(num: number) {
	var num_parts = num.toString().replace(".", ",").split("-");
	num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return num_parts.join("-");
}

export function thousandSeparatorDecimal(num: number) {

	let result = thousandSeparator(num);
	var num_parts = result.split(",");

	if (num_parts.length == 1) {
		result += ",00";
	}
	else if (num_parts[1].length == 1) {
		result += "0";
	}

	return result;
}


//AUTHORIZATIONS

export const IsAuthorized = (
	content: ContentTypes
): boolean => {

	const userInfo = useContext(UserContext);
	let retval: boolean = false;

	let auth: UserAuthorizationTypes | undefined;

	switch (content) {
		case ContentTypes.CUSTOMERS: {
			auth = UserAuthorizationTypes.CUSTOMERS_AUTH;
			break;
		}
		case ContentTypes.ORDERS: {
			auth = UserAuthorizationTypes.ORDERS_AUTH;
			break;
		}
		case ContentTypes.SUPPORT_REGISTRIES: {
			auth = UserAuthorizationTypes.SUPPORT_REGISTRIES_AUTH;
			break;
		}
		case ContentTypes.TIMESHEETS: {
			auth = UserAuthorizationTypes.TIMESHEETS_AUTH;
			break;
		}
		case ContentTypes.USERS: {
			auth = UserAuthorizationTypes.USERS_AUTH;
			break;
		}
		case ContentTypes.REPORTS: {
			auth = UserAuthorizationTypes.REPORTS_AUTH;
			break;
		}
		case ContentTypes.ECONOMIC_DATA: {
			auth = UserAuthorizationTypes.ECONOMIC_DATA_AUTH;
			break;
		}
		case ContentTypes.COMMERCIAL_DATA: {
			auth = UserAuthorizationTypes.COMMERCIAL_DATA_AUTH;
			break;
		}
		case ContentTypes.TECHNICAL_INTERVENTIONS: {
			auth = UserAuthorizationTypes.TECHNICAL_INTERVENTIONS;
			break;
		}		
		default: {
			auth = undefined;
			break;
		}
	}


	if (auth !== undefined && userInfo.Me !== undefined) {
		userInfo.Me.UserAuthorizationTypes.forEach(function (value: UserAuthorizationType) {
			if (value.AuthorizationTypeID === auth) {
				retval = true;
			}
		});
	}
	return retval;
};

export const ListToArray = (List: any[]): any[][] => {
	let Array: any[][] = [];
	List.forEach((e, i) => {
		Array.push(Object.values(e));
	});
	return Array;
};


export const ArrayFiltred = (List: any[], value: string): any[] => {

	let Array: any[] = [];
	List.forEach((e, i) => {
		if (String(Object.values(e)).toLowerCase().indexOf(value.toLowerCase()) > -1) {
			Array.push(e);
		}
	});
	return Array;
};

export async function Log(value: object | string) {

	const newLine: string = "=======================================================";
	const datetime: string = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
	console.log(newLine);
	console.log(datetime);
	console.log(value);
	console.log(newLine);

};

//calcola la differenza in minuti tra due ore/minuti passate come stringa, es 20:30:00
//gestisce anche l'eventuale cambio data
export const TotalMinutes = (from: string, to: string): number => {

	let t: number;
	let t1: number;
	let t2: number;

	t1 = Number(from.substring(0, 2)) * 60 + Number(from.substring(3, 5));
	t2 = Number(to.substring(0, 2)) * 60 + Number(to.substring(3, 5));

	if (t1 <= t2) {
		t = t2 - t1;
	}
	else {
		t = (24 * 60) - (t1 - t2);
	}

	return t;
};

//calcola se l'arco di tempo passato come argomento comprende ore di straordinario
export const IsOverTime = (from: string, to: string): boolean => {

	let result: boolean = false;
	let t1: number;
	let t2: number;

	console.log('from = ' + from);
	console.log('to = ' + to);

	t1 = Number(from.substring(0, 2)) * 60;
	console.log('t1 = ' + t1);
	t1 += Number(from.substring(3, 5));
	console.log('t1 = ' + t1);

	t2 = Number(to.substring(0, 2)) * 60 + Number(to.substring(3, 5));
	console.log('t1 = ' + t1);
	console.log('t2 = ' + t2);
	//se inizia prima delle 22 o finisce dopo le 6 è straordinario
	if (t1 < (22 * 60) || t2 >= (6 * 60)) {
		result = true;
	}

	return result;
};

//calcola se l'arco di tempo passato come argomento comprende ore di straordinario notturno
export const IsOverTimeNight = (from: string, to: string): boolean => {

	let result: boolean = false;
	let t1: number;
	let t2: number;

	t1 = Number(from.substring(0, 2)) * 60 + Number(from.substring(3, 5));
	t2 = Number(to.substring(0, 2)) * 60 + Number(to.substring(3, 5));
	console.log('t1 = ' + t1);
	console.log('t2 = ' + t2);

	//se finisce dopo delle 22 o inizia prima delle 6 è straordinario notturno
	if (t2 > (22 * 60) || t1 < (6 * 60)) {
		result = true;
	}

	return result;
};

export const GetDatesArray = (start: Date, end: Date): Date[] => 
{
	let arr:Date[] = [];
	for(let curDate:Date= new Date(start);curDate <= new Date(end); curDate.setDate(curDate.getDate() + 1)){
		arr.push(new Date(curDate));
	}
	return arr;
};

export const GetStringDatesArray = (start: Date, end: Date): string[] => 
{
	let arr:string[] = [];
	for(let curDate:Date= new Date(start);curDate <= new Date(end); curDate.setDate(curDate.getDate() + 1)){
		arr.push(formatDateToString(new Date(curDate)));
	}
	return arr;
};

export const GetRandomColor = (): string => 
{
	return '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
};
