/*
	componente utilizzato visualizzare le liste
	implementa la ricerca e l'ordinamento
	implementa opzionalmente anche un tasto Aggiungi
*/
import React, { useContext, useState, ReactChild, ReactElement, useRef, useEffect, MouseEvent } from "react";
import { Table, Button, Form, Col, Modal, Popover, OverlayTrigger } from "react-bootstrap";
import { Maincontext } from "../maincontext";
import { Icon } from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { ic_cancel } from "react-icons-kit/md/ic_cancel";
import { ic_done } from "react-icons-kit/md/ic_done";
import { ic_vpn_key } from "react-icons-kit/md/ic_vpn_key";
import { ic_people } from "react-icons-kit/md/ic_people";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import { ic_euro_symbol } from "react-icons-kit/md/ic_euro_symbol";
import { ic_add } from "react-icons-kit/md/ic_add";
import { ic_more_vert } from "react-icons-kit/md/ic_more_vert";
import { sort as ic_sort } from "react-icons-kit/fa/sort";
import { sortUp as ic_sortUp } from "react-icons-kit/fa/sortUp";
import { sortDown as ic_sortDown } from "react-icons-kit/fa/sortDown";
import { refresh } from "react-icons-kit/fa/refresh";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import { ic_list } from "react-icons-kit/md/ic_list";
import { wrench } from "react-icons-kit/icomoon/wrench";
import { userPlus as ic_user_plus } from "react-icons-kit/fa/userPlus";
import { calendar as ic_calendar } from "react-icons-kit/fa/calendar";
import { ic_flash_on } from "react-icons-kit/md/ic_flash_on";
import { ic_contacts } from "react-icons-kit/md/ic_contacts";
import { ic_show_chart } from "react-icons-kit/md/ic_show_chart";

import Dettaglio from "./DetailPanel";
import ConfirmPopOver from "./ConfirmPopOver";
import { columnDetail, SelectedObjectTypes, DataColumnTypes } from "../../models/models";
import { ConfirmButton } from "./ConfirmButton";
//import { Placement } from "./../../../node_modules/react-bootstrap/Overlay";

import { minutesToHours, hoursToMinutes } from "../../core/Utilities";
import { isBreakOrContinueStatement } from "typescript";

export interface IListProps<T = unknown> {
	obj: T[];
	details?: IButtonDetail[];			//buttons per riga
	columnDetails?: columnDetail[]; 	//intestazioni di colonna
	dettaglio?: ReactChild; 			//il panello con il dettaglio da visualizzare
	addButton?: boolean;				//presenza o meno del tasto aggiungi
	addElement?: ReactChild;
	addType?: SelectedObjectTypes;		//tipo di oggetto che viene gestito con il tasto aggiungi
	addPanelSize?: "sm" | "lg" | "xl" | undefined;
	detailPosition?: number;			//posizione dell'icona per aprire i comandi
	completeListButton?: boolean;
	setCompleteList?(completeList: boolean): void;
	//se non è definita vengono posizionati in fondo
}
//interfaccia per la definizione dei button
interface IButtonDetail {
	detail: ReactElement;
	button: string;
	titoloDettaglio?: string;
	panelReadonly?: boolean;
	objTypeSelected?: SelectedObjectTypes;
	panelSize?: "sm" | "lg" | "xl" | undefined;
	isFunction?: boolean;
	onClick?(e: MouseEvent<HTMLButtonElement>): void;
	confirm?: boolean;
	confirmMessage?: string;
	isHidden?(sel: any): boolean;
	detailPosition?: number;
}

export default function List<T>(props: IListProps) {
	const context = useContext(Maincontext);
	const [selezionato] = useState({} as T);
	const [panelSize, setPanelSize] = useState<"sm" | "lg" | "xl" | undefined>("sm");
	const [dettaglio, setDettaglio] = useState(<div></div>);
	const [titoloDettaglio, setTitoloDettaglio] = useState("");
	const [TBodyRendered, setTBody] = useState([] as ReactElement[]);
	const [THeadRendered, setTHead] = useState([] as ReactElement[]);
	const [objState, setObj] = useState([] as T[]);
	const [sortType, setSortType] = useState("");
	const [sortDirection, setSortDirection] = useState(true);
	const inputCerca = useRef<HTMLInputElement | null>(null);
	const [colonnafiltrata, setColonnaFiltrata] = useState("");
	const [tipoColonnafiltrata, setTipoColonnaFiltrata] = useState(DataColumnTypes.STRING);
	const [showConfirm] = useState(false);
	const [showConfirmX] = useState(0);
	const [showConfirmY] = useState(0);
	const [showConfirmSaveFunction] = useState((): any => null);
	const [showConfirmEvent] = useState<MouseEvent<HTMLButtonElement> | null>(null);
	const [completeList, setCompleteList] = useState<boolean>(false);
	const [showPopoverModal, setShowPopoverModal] = useState(false);

	useEffect(() => {
		renderHead();
		setObj(props.obj as T[]);
		if (colonnafiltrata !== "") {
			orderColumn(colonnafiltrata, tipoColonnafiltrata);
		}
		if (inputCerca.current) {
			filtra(inputCerca.current.value);
		}
	}, [props.obj])

	useEffect(() => {
		rendering();
	}, [objState])

	useEffect(() => {
		if (props.setCompleteList) {
			props.setCompleteList(completeList);
		}
	}, [completeList]);

	useEffect(() => {
		//rendering();
		if (sortType !== "") {
			const selezionato = props.columnDetails!.find(function (c) { return c.name === sortType; });
			if (selezionato?.type) {
				orderColumn(sortType, selezionato.type);
			} else {
				orderColumn(sortType);
			}
			if (inputCerca.current) {
				filtra(inputCerca.current.value);
			}
			rendering();

		}
	}, [sortDirection])
	function addNew() {
		setDettaglio(props.addElement as JSX.Element);
		setTitoloDettaglio("Aggiungi");
		setPanelSize(props.addPanelSize);
		context.setContext({
			...context,
			showDetail: true,
			hiddenDetail: true,
			objSelected: null,
			objectName: "",
			saveEnabled: false,
			objTypeSelected: props.addType
		});
	}
	function filtra(value: string) {

		if (value) {
			let filtered = (props.obj as T[]).filter((o) => {
				var flag = false;
				Object.entries(o as T).forEach((kv) => {
					let objKey = kv[0];
					let objVal = kv[1];
					if (
						props.columnDetails
						&&
						props.columnDetails.some(e => e.name == kv[0])
						&&
						!objKey.match(/AzureID/i)
					) {
						if (String(objVal).toLowerCase().indexOf(value.toLowerCase()) > -1) {
							flag = true;
							return;
						}
					}
				});
				if (flag) return o;
				return undefined;
			});
			setObj(filtered);
		}
		else {
			setObj(props.obj as T[]);
		}
	}
	function orderColumn(e: string, tipo?: DataColumnTypes) {
		let t: DataColumnTypes = DataColumnTypes.STRING;
		if (props.obj) {
			switch (typeof (((props.obj as T[])[0] as any)[e])) {
				case "number":
					t = DataColumnTypes.NUMBER;
					break;
				case "boolean":
					t = DataColumnTypes.BOOLEAN;
					break;
				default:
					t = DataColumnTypes.STRING;
					break;
			}
		}
		if (tipo) {
			t = tipo;
		}
		let ordered = [] as T[];
		setTipoColonnaFiltrata(t as DataColumnTypes);

		switch (t) {
			case DataColumnTypes.STRING:
				ordered = (props.obj as T[]).sort(function (a, b) {
					var textA = (a as any)[e].toLowerCase();
					var textB = (b as any)[e].toLowerCase();
					if (sortDirection)
						return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					else
						return (textB < textA) ? -1 : (textB > textA) ? 1 : 0;
				});
				break;
			case DataColumnTypes.NUMBER:
			case DataColumnTypes.HH_MM:
				ordered = (props.obj as T[]).sort(function (a, b) {
					if (sortDirection)
						return ((a as any)[e] as number - (b as any)[e] as number);
					else
						return ((b as any)[e] as number - (a as any)[e] as number);
				});
				break;
			case DataColumnTypes.BOOLEAN:
				ordered = (props.obj as T[]).sort(function (a, b) {
					if (sortDirection)
						return ((a as any)[e] as boolean === (b as any)[e] as boolean) ? 0 : a ? -1 : 1;
					else
						return ((b as any)[e] as boolean === (a as any)[e] as boolean) ? 0 : a ? -1 : 1;
				});
				break;
			case DataColumnTypes.DATE:
				ordered = (props.obj as T[]).sort(function (a, b) {
					let dateA = new Date((a as any)[e] as string);
					let dateB = new Date((b as any)[e] as string);
					if (sortDirection)
						return (dateA.getTime() - dateB.getTime());
					else
						return (dateB.getTime() - dateA.getTime());
				});
				break;
		}
		setObj(ordered);
	}


	function generateButtonDetail(selected: T) {
		let ret: ReactElement[] = [];
		if (props.details) {

			props.details.forEach((e, i) => {
				if (e.isHidden == undefined || (e.isHidden != undefined && !e.isHidden(selected))) {
					if (e.isFunction) {
						if (e.confirm) {
							ret.push(
								<span key={"spnBtn_" + i}>
									<ConfirmButton title={e.titoloDettaglio}
										//key={"ConfBtn" + i.toString()}
										icon={iconaBottoni(e.button)}
										confirmMessage={e.confirmMessage}
										placement={"left"}
										confirmFunction={(event: React.MouseEvent<HTMLButtonElement>) => {
											context.objSelected = selected;
											if (e.onClick) { e.onClick(event); }
										}}
										toggleModal={() => { setShowPopoverModal(showState => !showState); }}
										className="m-0 p-0 btn-context"
										titleButton={true}
									/>
								</span>
							);
						} else {
							ret.push(
								<span key={"spnBtn_" + i}>
									<Button
										key={"DetailBtn_" + i}
										title={e.titoloDettaglio}
										variant="link"
										className="m-0 p-0 btn-context"
										onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
											context.objSelected = selected;
											if (e.onClick) { e.onClick(event); };
										}}
									>{iconaBottoni(e.button)} {e.titoloDettaglio}
									</Button>
								</span>
							);
						}
					}
					else {
						ret.push(
							<span key={"spnBtn_" + i}>
								<Button
									key={i}
									title={e.titoloDettaglio}
									variant="link"
									className="m-0 p-0 btn-context"
									onClick={() => {
										setPanelSize(e.panelSize);
										setDettaglio(e.detail);
										setTitoloDettaglio("" + e.titoloDettaglio);
										context.setContext({
											...context, showAlert: false, messaggioAlert: "", titoloAlert: "",
											showDetail: true, hiddenDetail: true, objSelected: selected,
											saveEnabled: false, objTypeSelected: e.objTypeSelected,
											panelReadonly: e.panelReadonly
										});
										document.body.click();
									}}>{iconaBottoni(e.button)} {e.titoloDettaglio}</Button>

							</span>
						);
					}
				}
			});
			return ret;
		} else {
			return ret;;
		}
	}
	function renderHead() {
		let THead = [] as ReactElement[];
		if (props.obj.length > 0) {
			//se viene non passata la column detail faccio il rendering dell'oggetto
			if (props.columnDetails) {
				let arrayColumns: string[] = [];
				//Ordinamento colonne per posizione
				props.columnDetails.sort(function (a, b) {
					return a.position - b.position;
				});
				//creo un array di stringhe contenente solo il nome della proprietà
				props.columnDetails.forEach((c) => {
					arrayColumns.push(c.name);
				});
				//Renderizzo l'header
				arrayColumns.forEach((e, i) => {
					if (props.detailPosition === i) {
						THead.push(<th key={"th_dp_" + i}><div>&nbsp;</div></th>);
					}
					const selezionato = props.columnDetails!.find(function (c) { return c.name === e; });
					if (selezionato?.type === DataColumnTypes.CIRCLE) {
						THead.push(<th key={"th_" + i} style={{ width: selezionato?.size ? selezionato.size + "px" : "auto" }}><div>&nbsp;</div></th>);
					}
					else {
						THead.push(<th key={"th_" + i} style={{ width: selezionato?.size ? selezionato.size + "px" : "auto" }}>
							<div>
								{selezionato?.text}
								<div onClick={() => {
									setSortType(selezionato?.name!);
									setSortDirection(sortDirection => !sortDirection);
									setColonnaFiltrata(selezionato?.name!);
								}} className="sort-icon"><Icon icon={colonnafiltrata === selezionato?.name ? (sortDirection === true ? ic_sortDown : ic_sortUp) : ic_sort} />
								</div>
							</div>
						</th>);
					}
				});
			}
			setTHead(THead);
		}
	}
	//Effettua il rendering dell'oggetto passato nelle props e lo passa agli array TBodyRendered e THeadRendered contenuti nello state
	function rendering() {
		renderHead();
		let TBody = [] as ReactElement[];

		if (objState.length > 0) {
			//deve essere passata la column details per poter renderizzare la lista
			if (props.columnDetails) {
				let arrayColumns: string[] = [];
				//Ordinamento colonne per posizione
				props.columnDetails.sort(function (a, b) {
					return a.position - b.position;
				});
				//creo un array di stringhe contenente solo il nome della proprietà
				props.columnDetails.forEach((c) => {
					arrayColumns.push(c.name);
				});
				objState.forEach((e, i) => {
					let colonne = [];
					if (e) {
						arrayColumns.forEach((element, index) => {
							if (props.detailPosition === index) {
								colonne.push(
									<td key={"td_" + index + "_button"} className={"text-right"}>
										<FinestraDetails
											paddingRight={36}
											id={"details_" + i}
										> {generateButtonDetail(e as T)} </FinestraDetails>
									</td>
								);
							}
							if ((e as any)[element] != null && (e as any)[element] !== undefined) {
								//let tipo: string = typeof ((e as any)[element]);
								let tipo: DataColumnTypes = DataColumnTypes.STRING;
								switch (typeof ((e as any)[element])) {
									case "number":
										tipo = DataColumnTypes.NUMBER;
										break;
									case "boolean":
										tipo = DataColumnTypes.BOOLEAN;
										break;
									default:
										tipo = DataColumnTypes.STRING;
										break;
								}
								const selezionato = props.columnDetails!.find(function (c) { return c.name === element; });
								if (selezionato?.type) {
									tipo = selezionato?.type;
								}
								//key univoca del td
								let id = "td_" + i + "_" + index;
								switch (tipo) {
									case DataColumnTypes.CIRCLE:
										colonne.push(<td key={id}><span key={id + "_span"} className={"circle"}>{(e as any)[element]}</span></td>);
										break;
									case DataColumnTypes.STRING:
										colonne.push(<td key={id}>{(e as any)[element]}</td>);
										break;
									case DataColumnTypes.BOOLEAN:
										colonne.push(<td key={id}>{(e as any)[element] === true ? <Icon icon={ic_done} key={id + "_icona"} /> : <div></div>}</td>);
										break;
									case DataColumnTypes.NUMBER:
										colonne.push(<td key={id}>{(e as any)[element]}</td>);
										break;
									case DataColumnTypes.DATE:
										if (typeof ((e as any)[element]) == "string") {
											let data = new Date((e as any)[element]).toLocaleDateString();
											colonne.push(<td key={id}>{data}</td>);
										}
										break;
									case DataColumnTypes.HH_MM:
										let minToHours: string = minutesToHours((e as any)[element]);
										colonne.push(<td key={id}>{minToHours}</td>);
										break;
									default:
										colonne.push(<td key={id}>-</td>);
										break;
								}
							}
						});
						if (!props.detailPosition) {
							colonne.push(
								<td key={"td_" + i + "_button"} className={"text-right"}>
									<FinestraDetails
										paddingRight={36}
										id={"details_" + i}
									> {generateButtonDetail(e as T)} </FinestraDetails>
								</td>
							);
						};
					} else {
						colonne.push(<tr></tr>);
					}
					TBody.push(
						<tr
							onMouseOver={(e) => {
								document.querySelectorAll("[id^='tr_']").forEach((el, i) => {
									if (e.currentTarget !== el) el.className = "";
								});
								if (e.currentTarget.className !== "selected") {
									document.body.click();
								};
							}}
							onMouseLeave={(e) => {
								if (!document.querySelector(`#popover-basic-details_${i}`)) {
									e.currentTarget.className = "";
									document.body.click();
								}
							}}
							id={"tr_" + i}
							key={"tr_" + i}>{colonne}
						</tr>
					);
				});
			}
		} else {
			TBody.push(<tr key={"tr_0"}><td key={"td_0"} colSpan={1000}>Nessun Elemento Trovato</td></tr>);
		}
		setTBody(TBody);
	}

	function CompleteListToggle() {
		setCompleteList(!completeList);
	}

	return (
		<div>
			<Form className="form-search-list">
				<Form.Row>
					<Col>
						<input type="text"
							onChange={() => {
								if (inputCerca.current) {
									filtra(inputCerca.current.value);
								}
							}}
							className="form-control"
							placeholder="Cerca..." ref={inputCerca} />
					</Col>
					<Col md={2}>
						<Button variant={"link"} style={{ width: "auto" }} className="btn-sm mb-8 h-100 btn-context" onClick={() => {
							context.setContext({ ...context, refreshList: !context.refreshList });
							if (inputCerca.current) {
								inputCerca.current.value = "";
							}
						}
						}><Icon icon={refresh} size="16" className="mr-1" />Aggiorna</Button>
						<span className={props.addButton ? "" : "d-none"}>
							<Button variant={"link"} onClick={() => { addNew(); }} className="btn-sm ml-2 h-100 btn-context" style={{ width: "auto" }}>
								<Icon icon={ic_add} size="16" />Aggiungi</Button>
						</span></Col>
					<Col md={7}>
						<span className={props.completeListButton ? "" : "d-none"}>
							<Form.Check type="radio" label="Solo attivi"
								onClick={() => CompleteListToggle()}
								checked={!completeList} />
							<Form.Check type="radio" label="Elenco completo"
								onClick={() => CompleteListToggle()}
								checked={completeList} />
						</span>
					</Col>
				</Form.Row>
			</Form>
			<div className="box-table-list">
				<Table className="table-list"  /*hover*/ size="sm">
					<thead>
						<tr>
							{THeadRendered}
							<th><div>&nbsp;</div></th>
						</tr>
					</thead>
					<tbody>
						{TBodyRendered}
					</tbody>
				</Table>
			</div>

			<Modal onHide={() => { setShowPopoverModal(false); }} className="modal-popover" show={showPopoverModal}></Modal>

			<ConfirmPopOver message="cancella" show={showConfirm} x={showConfirmX} y={showConfirmY} saveFunction={showConfirmSaveFunction} e={showConfirmEvent!}></ConfirmPopOver>

			<Dettaglio
				body={dettaglio}
				title={titoloDettaglio}
				obj={selezionato}
				size={panelSize}
			/>

		</div>
	);

}

//usata per restituire un'icona al menu a tendina
export function iconaBottoni(icona: string, size?: number) {

	let dim = 20;
	if (size) dim = size;

	switch (icona) {
		case "edit":
			return <Icon icon={ic_edit} size={dim} />;
		case "cancel":
			return <Icon icon={ic_cancel} size={dim} />;
		case "done":
			return <Icon icon={ic_done} size={dim} />;
		case "profile":
			return <Icon icon={ic_people} size={dim} />;
		case "permission":
			return <Icon icon={ic_vpn_key} size={dim} />;
		case "costcenter":
			return <Icon icon={ic_euro_symbol} size={dim} />;
		case "contract":
			return <Icon icon={ic_date_range} size={dim} />;
		case "delete":
			return <Icon icon={ic_delete} size={dim} />;
		case "list":
			return <Icon icon={ic_list} size={dim} />;
		case "add":
			return <Icon icon={ic_add} size={dim} />;
		case "manage":
			return <Icon icon={wrench} size={dim} />;
		case "calendar":
			return <Icon icon={ic_calendar} size={dim} />;
		case "plususer":
			return <Icon icon={ic_user_plus} size={dim} />;
		case "users":
			return <Icon icon={ic_people} size={dim} />;
		case "flash":
			return <Icon icon={ic_flash_on} size={dim} />;
		case "contact":
			return <Icon icon={ic_contacts} size={dim} />;
		case "chart":
			return <Icon icon={ic_show_chart} size={dim} />;
	}
}

interface FinestraDetailsProps {
	id?: string;
	placementRight?: boolean;
	popoverWidth?: number;
	paddingRight?: number;
	icon?: any;//React.ComponentType<IconProp>;
}
export function FinestraDetails(props: React.PropsWithChildren<FinestraDetailsProps>) {

	const popover = (
		<Popover
			id={`popover-basic-${props.id}`}
			style={{
				zIndex: 9999,
				minWidth: (props.popoverWidth ? props.popoverWidth : "auto"),
				paddingRight: (props.paddingRight ? props.paddingRight : "auto")
			}}>
			<Popover.Content className={"popover-body-context"}>
				<div className={"d-flex flex-column"} key={props.id}>
					{props.children}
				</div>
			</Popover.Content>
		</Popover>
	);
	return (
		<OverlayTrigger
			trigger="click"
			placement={props.placementRight ? "right" : "left"}
			overlay={popover}
			rootClose
		>
			<button onClick={() => {
				document.querySelectorAll("[id^='tr_']").forEach((e, i) => {
					e.className = "";
				});
				document.querySelector(`#tr_${props.id?.replace("details_", "")}`)!.className = "selected";
			}} className="btn btn-link btn-sm p-0 btn-menu-context" title="Menu">
				{props.icon ? props.icon : <Icon icon={ic_more_vert} size={24} />}
			</button>
		</OverlayTrigger>
	);
}