import React, { useContext, useState, useEffect, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { UserCostCenter } from "../../models/models";
import UserServices from "../../api/UserServices";
import { Table } from "react-bootstrap";

export interface IUserCostCentersList {
}

export default function UserCostCentersList(props: IUserCostCentersList) {
	const context = useContext(Maincontext);
	const { handleSubmit } = useForm();
	const userID = context.objSelected.UserID as number;
	const SVC = new UserServices();

	const [userCostCentersList, setUserCostCentersList] = useState<UserCostCenter[]>([]);
	const [totalPercentage, setTotalPercentage] = useState<number>(0);
	useEffect(() => {
		GetList();
		context.objectName = context.objSelected.FullName;
	}, []);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getUserCostCentersListAll(userID).then((d) => {
			if (d.data) {
				setUserCostCentersList(d.data);
				context.parentID = userID;

				let totalPercentage: number = 0;
				d.data.forEach(function (item: UserCostCenter) {
					totalPercentage += item.Percentage;
				});
				setTotalPercentage(totalPercentage);
			}
			context.setContext({ ...context, parentID: userID, });
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: UserCostCenters List" + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}

	const getTotPercentage = (): number => {

		let totalPercentage: number = 0;

		userCostCentersList.forEach(function (item: UserCostCenter) {
			totalPercentage += item.Percentage;
		});

		return totalPercentage;

	};

	const isValid = (): boolean => {

		const totalPercentage = getTotPercentage();
		setTotalPercentage(totalPercentage);
		return totalPercentage === 100;

	};

	function setContext() {

		const valid = isValid();
		if (valid) {
			context.objSelected = userCostCentersList;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}


	function setPercentage(e: ChangeEvent<HTMLInputElement>) {

		//abilita salva

		const percentage = Number(parseInt(e.target.value));
		const costCenterID = Number(e.currentTarget.getAttribute("data-id"));

		userCostCentersList.forEach(function (item: UserCostCenter) {

			if (item.CostCenterID === costCenterID) {
				item.Percentage = percentage;
			}

		});

		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Centro di costo</th>
									<th>Percentuale</th>
								</tr>
							</thead>
							<tbody>
								{userCostCentersList.map(function (item: UserCostCenter) {
									return <tr key={item.CostCenterID}>
										<td>{item.CostCenterName}</td>
										<td>
											<input type="number" min="0"
												data-id={item.CostCenterID} max="100" step="1"
												onChange={setPercentage}
												defaultValue={item.Percentage}>

											</input>
										</td>
									</tr>;
								})
								}</tbody>
							<tfoot>
								<tr><td>Percentuale totale</td>
									<td className={totalPercentage === 100 ? "text-success" : "text-danger"}><strong>{totalPercentage}</strong> di 100</td></tr>
							</tfoot>
						</Table>
					</div>
				</div>
			</form>
		</div>
	);
}

