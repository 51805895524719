import React, { MouseEvent, useEffect, useState, ChangeEvent } from "react";
import Icon from "react-icons-kit";
import { ic_keyboard_arrow_left } from "react-icons-kit/md/ic_keyboard_arrow_left";
import { ic_keyboard_arrow_right } from "react-icons-kit/md/ic_keyboard_arrow_right";

export interface IYearNavigatorProps {
	CurrentYear: string;
	OnYearChange(Year: string): void;
}

export default function YearNavigator(props: IYearNavigatorProps) {

	const [currentYear, setCurrentYear] = useState<string>(props.CurrentYear);

	function onYearChange(e: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>, add?: number) {
		if (add !== undefined) setCurrentYear((y) => (parseInt(y) + add || props.CurrentYear).toString());
		else {
			setCurrentYear(e.currentTarget.value);
		}
	}

	useEffect(() => {
		props.OnYearChange(currentYear);
	}, [currentYear]);

	return (

		<div className="d-inline-flex">
			<input type="text" maxLength={4} value={currentYear} onChange={(e) => onYearChange(e)} className="form-control text-center year-input" style={{ height: "auto", padding: "0", width: "200px" }} />
			<button className="btn btn-link btn-sm" style={{ position: "relative", left: "-200px", top: "1px" }} onClick={(e) => onYearChange(e, -1)} >
				<Icon icon={ic_keyboard_arrow_left} size={24} />
			</button>
			<button className="btn btn-link btn-sm" style={{ position: "relative", left: "-85px", top: "1px" }} onClick={(e) => onYearChange(e, 1)} >
				<Icon icon={ic_keyboard_arrow_right} size={24} />
			</button>
		</div>
	);

}