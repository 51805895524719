import React, { useContext, useEffect, useState } from 'react';
import { CostCenterUser } from '../../../../models/reports';
import ReportServices from '../../../../api/ReportServices';
import { Maincontext } from '../../../maincontext';

export interface IMonthCostCenterUserActivities {
	costCenterID: number;
	year: number;
	month: number;
}

export default function MonthCostCenterUserActivities(props: IMonthCostCenterUserActivities) {

	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const [list, setList] = useState<CostCenterUser[]>([]);
	const [hidden, setHidden] = useState<boolean>(true);

	useEffect(() => {
		if (props.costCenterID > 0 && props.year >= 2020 && props.month > 0) {
			GetList();
		}
		else {
			setHidden(true);
		}
	}, [props.costCenterID, props.year, props.month]);

	function GetList() {
		SVC.GetRptCostCenterUsers(props.costCenterID, props.year, props.month).then((d) => {
			if (d.data) {
				setList(d.data);
				setHidden(false);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento risorse centro di costo: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}
	return (
		<div className="Container">
			<div className={hidden?"d-none":"p-3"}>
				<table className="table table-sm mt-3">
					<tbody>
						{list ?
							list.map(function (item, i) {
								return <>
									<tr>
										<td className="pt-3"><h6>{item.FirstName + " " + item.LastName}</h6></td>
										{
											item.Dates.map(function (d, i) {
												return <td
													className="pt-3 text-center"
													style={d.IsHolidayOrWeekEnd ? { "color": "red" } : {}}
												>
													<span style={!d.IsWorkingDay ? { "textDecoration": "line-through" } : {}}><strong>{new Date(d.WorkingDate).getDate()}</strong></span>
												</td>
											}
											)
										}
									</tr>
									<tr>
										<td>Ore lavorative</td>
										{
											item.Dates.map(function (d, i) {
												return <td className="text-center">
													{d.WorkingHours}
												</td>
											}
											)
										}
									</tr>
									<tr>
										<td>Ore assegnate</td>
										{
											item.Dates.map(function (d, i) {
												return <td className="text-center">
													{d.AssiGnedWorkingHours}
												</td>
											}
											)
										}
									</tr>
									<tr>
										<td>Ore disponibili</td>
										{
											item.Dates.map(function (d, i) {
												return <td className="text-center">
													{d.AvailableHours}
												</td>
											}
											)
										}
									</tr>
									<tr>
										<td>Ore lavorate</td>
										{
											item.Dates.map(function (d, i) {
												return <td className="text-center">
													{d.WorkedHours}
												</td>
											}
											)
										}
									</tr>
								</>
							}
							)
							:
							''}
					</tbody>
				</table>
			</div>
		</div>
	);
}