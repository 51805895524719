/*
	componente per la visualizzazione dell'elenco dei SubOrders
	implementa il componene List
*/

import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { Maincontext, UserContext } from "../../maincontext";
import Lista from "../../common/List";
import SubOrderServices from "../../../api/SubOrderServices";
import { SubOrder, columnDetail, SelectedObjectTypes, MenuList, DataColumnTypes, ContentTypes } from "../../../models/models";
import SubOrderDetail from "./SubOrderDetail";
import TitleBar from "../../common/TitleBar";
import { IsAuthorized } from "../../../core/Utilities";

export interface ISubOrderListProps {
	expired?: boolean
}

export default function SubOrdersList(props: ISubOrderListProps) {
	const context = useContext(Maincontext);
	const userInfo = useContext(UserContext);
	const SVC = new SubOrderServices();
	const [list, setList] = useState<SubOrder[]>([]);

	const history = useHistory();

	const rpt_authorized: boolean = IsAuthorized(ContentTypes.REPORTS);

	//Dichiarazione della colonna di dettaglio che determina 
	//se una colonna viene visualizzata e in che posizione 
	//se non viene passato viene fatto il rendering dell'oggetto
	const columnDetails: columnDetail[] = [
		{ name: "Internal", text: "Interna", position: 1 },
		{ name: "CustomerName", text: "Cliente", position: 2 },
		{ name: "OrderCode", text: "Codice commessa", position: 3 },
		{ name: "OrderName", text: "Commessa", position: 4 },
		{ name: "SubOrderName", text: "Deliverable", position: 5 },
		{ name: "Manager", text: "Responsabile", position: 6 },
		{ name: "ActivityLineName", text: "Linea attività", position: 7 },
		{ name: "WorkingDays", text: "Giornate", position: 8, type: DataColumnTypes.NUMBER },
		{ name: "StartDate", text: "Data inizio", position: 9, type: DataColumnTypes.DATE },
		{ name: "EndDate", text: "Data fine", position: 10, type: DataColumnTypes.DATE },
	];

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList, props.expired]);

	function GetList() {
		
		SVC.getSubOrders(props.expired).then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento SubOrders: " + e, titoloAlert: "Errore" });
		}).finally(() => {

			context.setContext({
				...context,
				selectedMenu: props.expired === true ? MenuList.EXPIRED_SUBORDERS : MenuList.SUBORDERS,
			});
		});
	}

	function onEdit(e: MouseEvent<HTMLButtonElement>) {
		const SubOrder: SubOrder = context.objSelected;
		history.push(`/suborder/${SubOrder.SubOrderID.toString()}`);	
	}

	function onDelete(e: MouseEvent<HTMLButtonElement>) {
		const SubOrder: SubOrder = context.objSelected;

		SVC.SubOrderDelete(SubOrder).then((d) => {
			if (d.data !== "") {
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione deliverable";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	function isConfigDeliverableHidden(s: SubOrder){
		return s.UserID!=userInfo.Me!.UserID
	}
	
	function isDeleteDeliverableHidden(s: SubOrder){
		return s.OrderUserID!=userInfo.Me!.UserID
	}

	return (
		<div>
			<TitleBar title={props.expired === true ? "Deliverables scaduti" : "Deliverables attivi"} />
			<Lista obj={list} details={[
				{
					detail: <SubOrderDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.SUBORDER,
					panelSize: "lg"
				},
				{
					detail: <SubOrderDetail />,
					button: "manage",
					titoloDettaglio: "Gestisci",
					objTypeSelected: SelectedObjectTypes.SUBORDER,
					isFunction: true,
					onClick: onEdit,
					confirm: false,
					confirmMessage: "",
					isHidden: isConfigDeliverableHidden
				},
				{
					detail: <SubOrderDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.SUBORDER,
					isFunction: true,
					onClick: onDelete,
					confirm: true,
					confirmMessage: "Eliminare il deliverable?",
					isHidden: isDeleteDeliverableHidden
				},
				{
					detail: <SubOrderDetail />,
					button: "list",
					titoloDettaglio: "Visualizza report",
					objTypeSelected: SelectedObjectTypes.ORDER,
					isFunction: true,
					onClick: (e: MouseEvent<HTMLButtonElement>) => {
						const SubOrder: SubOrder = context.objSelected;
						window.open(`/reports/suborderusers/${SubOrder.SubOrderID}?toolbar=no`);
					},					
					isHidden: isConfigDeliverableHidden		
				},
				{
					detail: <SubOrderDetail />,
					button: "chart",
					titoloDettaglio: "Grafico ore lavorate",
					objTypeSelected: SelectedObjectTypes.ORDER,
					isFunction: true,
					onClick: (e: MouseEvent<HTMLButtonElement>) => {
						const SubOrder: SubOrder = context.objSelected;
						window.open(`/reports/SubOrderDailyWorkedHours/${SubOrder.SubOrderID}?toolbar=no`);
					}
				}
			]}
			columnDetails={columnDetails}
			addButton={false}
			addElement={<SubOrderDetail />}
			addType={SelectedObjectTypes.SUBORDER}
			addPanelSize="lg"
			detailPosition={3}
			/>
		</div>
	);
}