import { SubOrderStepProfile } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class SubOrderStepProfileServices {


	async getSubOrderStepProfiles(id: number) {
		const params = {
			id: id
		};
		return axios.get<SubOrderStepProfile[]>(`${await SVC.getApiBaseUri()}/api/SubOrderStepProfiles`, { params, headers: await SVC.setAzureAuth() });
	}

	async getSubOrderStepProfile(id: number) {
		const params = {
			id: id
		};
		return axios.get<SubOrderStepProfile>(`${await SVC.getApiBaseUri()}/api/SubOrderStepProfile`, { params, headers: await SVC.setAzureAuth() });
	}

	async SubOrderStepProfileUpdate(subOrderStepProfile: SubOrderStepProfile) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/SubOrderStepProfiles`, subOrderStepProfile, { headers: await SVC.setAzureAuth() });
	}

	async SubOrderStepProfileDelete(SubOrderStepProfileID: number) {
		const params = {
			id: SubOrderStepProfileID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/subOrderStepProfiles`,
			{ params, headers: await SVC.setAzureAuth() });
	}

	async GetOrderIDFromSubOrderStepProfileID(id: number) {
		const params = {
			id: id
		};
		return axios.get<number>(`${await SVC.getApiBaseUri()}/api/subOrderStepProfiles/GetOrderIDFromSubOrderStepProfileID`, { params, headers: await SVC.setAzureAuth() });
	}

}