import React, { useEffect, useState } from "react";
import Select from "react-select";
import { selectFilter } from "../../core/Utilities";
import { SelectListItem } from "../../models/models";
import {TimeSpanTypes} from "../../models/reports";

export interface ITimeSpanSelect {
	onChangeValue(type: TimeSpanTypes): void;
}

export default function TimeSpanSelect(props: ITimeSpanSelect) {

	const [typesList, setTypesList] = useState<SelectListItem[]>([]);
	const [selectedType, setSelectedType] = useState<SelectListItem>({Value: TimeSpanTypes.LAST_30_DAYS.toString(), Text: "Ultimi 30 giorni"});
	
	function changeValue(v: any, i: any) {
		setSelectedType(v);	
	}

	useEffect(() => {
		setList();
	}, []);

	useEffect(() => {
		props.onChangeValue(Number(selectedType.Value));
	}, [selectedType]);

	function setList(){

		let list: SelectListItem[] = [];
		list.push({Value: TimeSpanTypes.LAST_30_DAYS.toString(), Text: "Ultimi 30 giorni"});
		list.push({Value: TimeSpanTypes.LAST_90_DAYS.toString(), Text: "Ultimi 90 giorni"});
		list.push({Value: TimeSpanTypes.CUR_MONTH.toString(), Text: "Mese corrente"});
		list.push({Value: TimeSpanTypes.CUR_YEAR.toString(), Text: "Anno corrente"});
		list.push({Value: TimeSpanTypes.ALL.toString(), Text: "Tutto"});

		setTypesList(list);
	}

	return (
		<>
			<label className="form-label">Periodo</label>
			<Select
				filterOption={selectFilter}
				defaultValue={selectedType}
				options={typesList}
				onChange={changeValue}
				getOptionLabel={c => c.Text}
				getOptionValue={c => c.Value}
			/>
		</>
	);

}
