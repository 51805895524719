import React, { useState, useEffect, useContext, useRef, ChangeEvent } from "react";
import { MenuList, TimeSheetApprovedMonth, TimeSheetMonthActivity, MonthStates, HistoricalTimeSheetMonthState, SelectListItem, SelectItemsListTypes } from "../../models/models";
import TimesheetServices from "../../api/TimeSheetServices";
import TitleBar from "../common/TitleBar";
import { Maincontext } from "../maincontext";
import { minutesToHours, formatDateToString, selectFilter } from "../../core/Utilities";
import { OverlayTrigger, Popover, Button, Form, Col } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_history } from "react-icons-kit/md/ic_history";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import { ic_gavel } from "react-icons-kit/md/ic_gavel";
import { ic_done } from "react-icons-kit/md/ic_done";
import { ic_thumb_up } from "react-icons-kit/md/ic_thumb_up";
import { ic_thumb_down } from "react-icons-kit/md/ic_thumb_down";
import { ic_create } from "react-icons-kit/md/ic_create";
import { ic_undo } from "react-icons-kit/md/ic_undo";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import "../../styles/approving-timesheet.css";
import Select from "react-select";
import SelectItemServices from "../../api/SelectItemServices";
import { MONTHS } from "../../models/locale";

export interface IApprovedTimesheets {
}

export default function ApprovedTimesheets(props: IApprovedTimesheets) {
	const context = useContext(Maincontext);
	const svc = new TimesheetServices();
	const ISVC = new SelectItemServices();
	const [timeSheetApprovedMonths, setTimeSheetApprovedMonths] = useState<TimeSheetApprovedMonth[]>([]);
	const [filteredTimeSheetApprovedMonths, setFilteredTimeSheetApprovedMonths] = useState<TimeSheetApprovedMonth[]>([]);
	const [yearsList, setYearsList] = useState<SelectListItem[] | []>([]);
	const [monthList, setMonthList] = useState<SelectListItem[]>([]);
	const [userList, setUserList] = useState<SelectListItem[] | []>([]);

	const [selectedYear, setSelectedYear] = useState<SelectListItem | undefined>(undefined);
	const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();
	const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(undefined);

	const [HistoricalTSMonthState, setHistoricalTSMonthState] = useState<HistoricalTimeSheetMonthState[]>();
	const textAreaNotesRef = useRef<HTMLTextAreaElement>(null);
	const [operationType, setOperationType] = useState<MonthStates>();

	const [lastYear, setLastYear] = useState<number>(new Date().getFullYear());
	const [lastMonth, setLastMonth] = useState<number>(new Date().getMonth() + 1);

	const [note, setNote] = useState<string>("");
	const [searchValue, setSearchValue] = useState<string>("");

	useEffect(() => {

		context.setContext({ ...context, selectedMenu: MenuList.APPROVED_TIMESHEETS });

		let newDate = new Date();
		newDate = new Date(newDate.setMonth(newDate.getMonth() - 1));
		setLastYear(newDate.getFullYear());
		setLastMonth(newDate.getMonth() + 1);
		MonthListInitialize();
		GetYearList();

	}, []);

	useEffect(() => {

		if (yearsList.length > 0) {

			//ultimo elemento
			const maxitem: SelectListItem = yearsList[0];
			const maxyear: number = Number(maxitem.Value);

			//anno selezionato
			setSelectedYear(maxitem);

			const firstitem: SelectListItem = { Value: "0", Text: "Tutti" };
			//mese selezionato
			setSelectedMonth(firstitem);

			//lista approvatori
			GetApproverList(maxyear, 0);

		}

	}, [yearsList]);


	useEffect(() => {

		const year: number = selectedYear ? Number(selectedYear.Value) : 0;
		const month: number = selectedMonth ? Number(selectedMonth.Value) : 0;
		const approverID: number = selectedUser ? Number(selectedUser.Value) : 0;

		GetTimeSheetApprovedMonths(year, month, approverID);

	}, [selectedUser]);

	useEffect(() => {

		setSearchValue("");
		setFilteredTimeSheetApprovedMonths(timeSheetApprovedMonths);

	}, [timeSheetApprovedMonths]);

	useEffect(() => {

		Search();

	}, [searchValue]);

	function Search() {

		if (searchValue == "") {
			setFilteredTimeSheetApprovedMonths(timeSheetApprovedMonths);
		}
		else {
			const s: string = searchValue.toUpperCase();
			const filtered: TimeSheetApprovedMonth[] =
				timeSheetApprovedMonths.filter(x => x.UserFullName.toUpperCase().includes(s));
			setFilteredTimeSheetApprovedMonths(filtered);
		}
	}

	function GetYearList() {
		ISVC.getSelectItemList(SelectItemsListTypes.APPROVED_TIMESHEET_YEARS, 0).then((l) => {
			if (l.data) {
				setYearsList(l.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Users: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function MonthListInitialize() {

		let mlist: SelectListItem[] = [];
		let item: SelectListItem;
		for (let i: number = 1; i <= 12; i++) {
			const v = i.toString();
			const t = MONTHS["it"][i - 1];
			item = { Text: t, Value: v };
			mlist.push(item);
		}

		item = { Text: "Tutti", Value: "0" };
		mlist.unshift(item);

		setMonthList(mlist);

	}

	function GetApproverList(year: number, month: number) {

		ISVC.getApproverItems(year, month).then((l) => {
			if (l.data) {
				const item: SelectListItem = {
					Value: "0",
					Text: "Tutti"
				}
				l.data.unshift(item);
				setUserList(l.data);
				setSelectedUser(l.data[0]);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Users: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function GetTimeSheetApprovedMonths(year: number, month: number, approverID: number) {
		if (year != 0) {
			svc.GetTimeSheetApprovedMonths(year, month, approverID, true).then((d) => {

				if (d.data == null) {
					setTimeSheetApprovedMonths([]);
				}
				else {
					setTimeSheetApprovedMonths(d.data);
				}
			});
		}
	}

	function TimeSheetMonthReturnToUser(timeSheetMonthID: number) {

		svc.TimeSheetMonthReturnToUser(timeSheetMonthID, note).then((d) => {

			if (d.data == "") {

				const year: number = selectedYear ? Number(selectedYear.Value) : 0;
				const month: number = selectedMonth ? Number(selectedMonth.Value) : 0;
				const approverID: number = selectedUser ? Number(selectedUser.Value) : 0;

				GetTimeSheetApprovedMonths(year, month, approverID);
			}
			else {
				context.setContext({
					...context,
					showAlert: true,
					messaggioAlert: "Errore salvataggio: " + d.data,
					titoloAlert: "Attenzione!"
				});
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di salvataggio: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			closePopover();
		});
	}

	function setYear(v: any, i: any) {

		setSelectedYear(v);

		const item: SelectListItem = {
			Value: "0",
			Text: "Tutti"
		}

		setSelectedMonth(item);
		const year: number = Number(v.Value);

		GetApproverList(year, 0);

	}

	function setUserID(v: any, i: any) {
		setSelectedUser(v);
	}

	function changeMonth(v: any, i: any) {

		setSelectedMonth(v);

		const year: number = selectedYear ? Number(selectedYear.Value) : 0;
		const month: number = Number(v.Value);
		GetApproverList(year, month);
	}

	function onNoteChange(e: ChangeEvent<HTMLTextAreaElement>) {
		const note: string = e.target.value;
		setNote(note);
	}
	const closePopover = () => {
		document.body.click();
	};

	function onSearchValueChange(e: ChangeEvent<HTMLInputElement>) {
		const val: string = e.target.value;
		setSearchValue(val);
	}

	return (
		<>
			<TitleBar title="Timesheet approvati" />
			<Form className="form-search-list">
				<Form.Row>
					<Col md={2}>
						<label className="form-label">Anno</label>
						<Select
							filterOption={selectFilter}
							value={selectedYear}
							options={yearsList}
							isSearchable
							onChange={setYear}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col sm={2}>
						<label>Mese</label>
						<Select
							filterOption={selectFilter}
							value={selectedMonth}
							options={monthList}
							isSearchable
							onChange={changeMonth}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col md={3}>
						<label className="form-label">Approvatore</label>
						<Select
							filterOption={selectFilter}
							value={selectedUser}
							options={userList}
							isSearchable
							onChange={setUserID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col md={3}>
						<label className="form-label">Risorsa</label>
						<input type="text"
							onChange={onSearchValueChange}
							className="form-control"
							placeholder="Cerca..."
							value={searchValue} />
					</Col>
				</Form.Row>
			</Form>
			<div className="p-3">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>
								Risorsa
							</th>
							<th>
								Approvatore
							</th>
							<th className="text-center">
								Anno
							</th>
							<th className="text-center">
								Mese
							</th>
							<th className="text-center">
								Ore ord.
							</th>
							<th className="text-center">
								Ore str.
							</th>
							<th className="text-center">
								Str. nott.
							</th>
							<th className="text-center">
								Permessi
							</th>
							<th className="text-center">
								Tot. ore
							</th>
							<th className="text-center">
								Assenze
							</th>
						</tr>
					</thead>
					<tbody>
						{filteredTimeSheetApprovedMonths.map(function (item: TimeSheetApprovedMonth) {
							return (
								<tr className="ts-resource-row">
									<td>
										<div className="d-inline-flex justify-content-between w-100 align-items-center">
											<div className="fullname" title={`${item.UserFullName}`}>{item.UserFullName}</div>
											{

												(
													item.TimeSheetMonth >= lastMonth
													&& item.TimeSheetYear == lastYear
												)
													||
													(
														item.TimeSheetYear > lastYear
													) ?
													<div className="ts-approving-tools d-inline-flex">
														<OverlayTrigger
															onEntering={() => { setNote(""); }}
															rootClose
															trigger="click"
															placement="auto-start"
															overlay={
																<Popover id={`ol_${item.TimeSheetMonthID}`} style={{ maxWidth: "400px", minWidth: "280px" }}>
																	<Popover.Title as="h3" style={{
																		fontSize: "14px", margin: "8px",
																		border: "none", borderRadius: "4px"
																	}}>
																		<strong>{item.UserFullName}</strong>
																		<br />
																		{item.TimeSheetMonthName} {item.TimeSheetYear.toString()}
																	</Popover.Title>
																	<Popover.Content style={{ fontSize: "12px", minHeight: "100px", maxWidth: "400px" }}>
																		<div className="mt-3">
																			<textarea
																				onChange={onNoteChange}
																				className="form-control"
																				style={{ resize: "none" }}
																				name="note"
																				id={`note_${item.UserID}`}
																				rows={3}
																				placeholder="Note..."
																			>
																			</textarea>
																			<div className="d-flex align-items-center justify-content-around mt-3 mb-2">
																				<button
																					className="btn btn-danger w-100"
																					onClick={() => TimeSheetMonthReturnToUser(item.TimeSheetMonthID)}
																				>
																					<Icon icon={ic_undo} size={24} /> Rifiuta
																				</button>
																			</div>
																		</div>
																	</Popover.Content>
																</Popover>
															}
														>
															<button className="btn btn-link btn-sm p-0" title="Rimanda alla risorsa...">
																<Icon icon={ic_undo} size={24} />
															</button>
														</OverlayTrigger>
													</div>
													:
													''
											}

											<div className="ts-approving-tools d-inline-flex">

												<a className="btn btn-link btn-sm p-0" title="Dettaglio mese"
													href={`/reports/monthtimesheet/${item.TimeSheetMonthID.toString()}`}
												>
													<Icon icon={ic_date_range} size={24} />
												</a>

												<OverlayTrigger
													rootClose
													trigger="click"
													placement="auto-start"
													overlay={
														<Popover id={`ol_${item.TimeSheetMonthID}`} style={{ maxWidth: "400px" }}>
															<Popover.Title as="h3" style={{
																fontSize: "14px", margin: "8px",
																border: "none", borderRadius: "4px"
															}}>
																<strong>{item.UserFullName}</strong>
																<br />
																Riepilogo attività {item.TimeSheetMonthName} {item.TimeSheetYear.toString()}
															</Popover.Title>
															<Popover.Content style={{ minHeight: "100px", width: "400px" }}>
																<div className="container">
																	<div className="row pb-2">
																	{item.Activities != null && item.Activities.map(function (a: TimeSheetMonthActivity) {
																			return (
																				<>
																					<div className="col-10 p-1 pt-0">{a.Description}</div>
																					<div className="col-2 p-1 pt-0 text-right">
																						{minutesToHours(a.Minutes)}
																					</div>
																				</>
																			);
																		})
																		}
																	</div>
																</div>
															</Popover.Content>
														</Popover>
													}
												>
													<button className="btn btn-link btn-sm p-0" title="Riepilogo attività svolte">
														<Icon icon={ic_history} size={24} />
													</button>
												</OverlayTrigger>
											</div>
										</div>

									</td>
									<td>{item.ApproverFullName}</td>
									<td className="text-center">{item.TimeSheetYear}</td>
									<td className="text-center">{item.TimeSheetMonthName}</td>
									<td className="text-center">{minutesToHours(item.Minutes)}</td>
									<td className="text-center">{minutesToHours(item.OvertimeMinutes)}</td>
									<td className="text-center">{minutesToHours(item.OvertimeNightMinutes)}</td>
									<td className="text-center">{minutesToHours(item.Permits)}</td>
									<td className="text-center">{minutesToHours(item.TotalMinutes)}</td>
									<td className="text-center">{item.Absences}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
}
