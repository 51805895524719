import { ActivityLine, ActivityLineProfileType } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class ActivityLinesServices {

	//elenco completo 
	async getActivityLinesList() {
		return axios.get<ActivityLine[]>(`${await SVC.getApiBaseUri()}/api/activitylines`, { headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getActivityLine(id: number) {
		const params = {
			id: id
		};
		return axios.get<ActivityLine>(`${await SVC.getApiBaseUri()}/api/activitylines`, { params, headers: await SVC.setAzureAuth() });
	}

	//update singolo elemento
	async ActivityLineUpdate(activityLine: ActivityLine) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/activitylines`, activityLine, { headers: await SVC.setAzureAuth() });
	}

	async ActivityLineDelete(activityLine: ActivityLine) {

		const params = {
			id: activityLine.ActivityLineID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/activitylines`,
			{ params, headers: await SVC.setAzureAuth() });
	}

	//  PROFILES
	async ActivityLineProfileTypesUpdate(activityLineID: number, profileTypes: ActivityLineProfileType[]) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/ActivityLines/${activityLineID}/profiles`, profileTypes, { headers: await SVC.setAzureAuth() });
	}
}