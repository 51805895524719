import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../../models/reports";
import ReportServices from "../../../../api/ReportServices";
import { Maincontext } from "../../../maincontext";
import OrderReport from "./components/OrderReport";
import { MenuList } from "../../../../models/models";
import TitleBar from "../../../common/TitleBar";
import { useParams } from "react-router-dom";


export interface IOrderReportPage {
	
}

export default function OrderReportPage(props: IOrderReportPage) {

	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const [order, setOrder] = useState<Order>();
	let params: any = useParams();
	const orderID: number = Number(params.id);

	useEffect(() => {
		GetOrder();
		context.setContext({
			...context,
			selectedMenu: MenuList.RPT_ORDERS
		});
		var urlParams = new URLSearchParams(window.location.search);
		if(urlParams.has("toolbar") && urlParams.get("toolbar") === "no") {
			(document.querySelector(".menu-laterale") as HTMLElement).style.display = "none";
			(document.querySelector(".navbar-nav") as HTMLElement).style.display = "none";	
			(document.querySelector(".principale") as HTMLElement).style.marginLeft = "0";		
		}
	}, []);

	function GetOrder() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.GetOrder(orderID).then((d) => {
			if (d.data) {
				setOrder(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento commessa: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	return (
		<div className="Container">
			<div className="form-group col">
				{order ?
					<OrderReport onResetEvent={() => GetOrder()} data={order} />
					:
					''}
			</div>
		</div>
	);
}