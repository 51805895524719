import * as React from 'react';

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { formatDateTime } from '../../../models/locale';
import { ReportColumn } from '../../../models/reports';
import { useEffect, useState } from 'react';
export interface IPdfDocument {
	title: string,
	columns: ReportColumn[],
	rows: any[][]
}

// Create Document Component
export default function PdfDocument(props: IPdfDocument) {

	const [totalWidth, setTotalWidth] = useState<number>(0);

	//calcola il totale della larghezza 
	//da suddividere proporzionalmente per colonna
	useEffect(() => {
		let total: number = 0;
		props.columns.forEach(element => {
			total += element.columnWidth;
		});
		setTotalWidth(total);
	}, [props.columns]);

	const getColumnWidth = (index: number): string => {
		const w = props.columns[index].columnWidth;
		if (totalWidth > 0) {
			const retVal = 100 / totalWidth * w;
			return retVal.toString() + "%";
		}
		else { return "auto"; }
	}

	const styles = StyleSheet.create({
		page: { flexDirection: "column", padding: 25 },
		reportName: {
			backgroundColor: "#ffffff",
			color: "#0078d4",
			paddingTop: 10,
			paddingBottom: 8
		},
		table: {
			fontSize: 10,
			width: 550,
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
			alignContent: "stretch",
			flexWrap: "nowrap",
			alignItems: "stretch"
		},
		row: {
			display: "flex",
			flexDirection: "row",
			textAlign: "left",
			borderBottomWidth: 1,
			borderBottomColor: '#bed4e5'
		},
		cell: {
			flexGrow: 1,
			flexShrink: 1,
			alignSelf: "stretch",
			fontSize: 7,
			paddingTop: 2,
			paddingBottom: 2
		},
		header: {
			backgroundColor: "#ffffff",
			textAlign: "left"
		},
		headerText: {
			fontSize: 9,
			fontWeight: "bold",
			color: "#212529"
		},
		tableText: {
			margin: 12,
			fontSize: 9,
			color: "#212529"
		},
		reportHeader: {
			borderBottomWidth: 1,
			borderBottomColor: '#dddddd',
			color: "#ddd",
			flexDirection: "row"
		},
		factoryName: {
			textAlign: "left",
			width: "50%"
		},
		repordDate: {
			textAlign: "right",
			fontSize: 10,
			width: "50%",
			paddingTop: 8
		}
	});

	return (
		<Document>
			<Page style={styles.page} size="A4" wrap>
				<View style={styles.reportHeader}>
					<Text style={styles.factoryName}>Revobyte s.r.l.</Text>
					<Text style={styles.repordDate}>{formatDateTime(new Date(), true)}</Text>
				</View>
				<View style={styles.reportName}>
					<Text>{props.title}</Text>
				</View>
				<View style={styles.table}>
					<View style={[styles.row, styles.header]}>
						{props.columns.map(function (item: ReportColumn, i) {
							return <Text key={"h_" + i.toString()} style={[styles.headerText, { width: getColumnWidth(i) }]}>{item.columnName}</Text>
						}
						)}
					</View>
					{props.rows.map(function (item, x) {
						return <View key={"r_"+ x.toString()} style={[styles.row]}>
							{item.map(function (celtext, i) {
								return <Text key={"c_"+ x.toString() + "_" + i.toString()} style={[styles.cell, styles.cell, { width: getColumnWidth(i) }]}>{celtext}</Text>
							}
							)}
						</View>
					}
					)}
				</View>
			</Page>
		</Document>
	);
}
