import React, { useContext, useEffect } from "react";
import TitleBar from "../common/TitleBar";
import { Maincontext, UserContext } from "../maincontext";
import { MenuList } from "../../models/models";
import { authProvider } from "../../authProvider";
import AzureADServices from "../../api/AzureADServices";

export default function Unauthorized() {

	const SVC = new AzureADServices();
	
	const context = useContext(Maincontext);
	const userContext = useContext(UserContext);

	useEffect(() => {	

		context.setContext({...context, selectedMenu: MenuList.UNDEFINED });

		// aggiorna i permessi e di conseguenza le voci di menu e le routes
		(async () => {			

			try {

				const azureID = authProvider.getAccountInfo()?.account.accountIdentifier;
				const userInfo = await SVC.getUserFromAAD(azureID || "");
				userContext.setContext({ ...userContext, Me: userInfo.data });

			}
			catch (error) {
				
			}

		})();

	}, []);	

	return (
		<div>
			<TitleBar title="Utente non autorizzato" />
			<div>
				<p style={{padding: "40px", fontWeight: "bold"}}>
					Utente non autorizzato
				</p>				
			</div>
		</div>
	);
}