import React, { useContext, useEffect, useState } from "react";
import { authProvider } from "../authProvider";
import { UserContext } from "./maincontext";
import AzureADServices from "../api/AzureADServices";

import SidebarMenu from "../components/common/SideBarMenu"; //menu laterale
import Routes from "../components/common/Routes"; //contiene tutte le route verso le viste e le rispettive viste
import Loading from "../components/common/Loading"; // finestra di attesa richiamabile dappertutto usando il context showAttesa 
import SystemAlert from "../components/common/SystemAlert"; // modal di sistema richiamabile dappertutto usando il context showAlert e valorizzandola con le varibili messaggioAlert e titoloAlert
import MainMenu from "../components/common/MainMenu"; // menu principale in alto
import { Container, Button } from "react-bootstrap";//libreria di bootstrap
import { BrowserRouter as Router } from "react-router-dom"; //libreria di routing
import { MainProvider } from "../components/maincontext"; //provider che fornisce le variabili comuni a tutte i components contenuti istanziabile con il usecontext

import { Icon } from "react-icons-kit";
import { ic_developer_board } from "react-icons-kit/md/ic_developer_board";

import Spinner from "react-bootstrap/Spinner";

import { Log, sleep } from "../core/Utilities";
import UserServices from "../api/UserServices";
import SessionAlert from "./common/SessionAlert";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const centeredItem = {
	color: "#0078d4",
	fontSize: "16px",
	height: "100vh",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	zIndex: 1
} as React.CSSProperties;

const divWrapperStyle = {
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	position: "fixed"
} as React.CSSProperties;

const SVC = new AzureADServices();
const userSVC = new UserServices();
export default function SplashScreen() {

	const userContext = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(true);
	const [loadingError, setLoadingError] = useState("");

	useEffect(() => {
		
		if (userContext.Me === undefined) {

			(async () => {

				try {
					
					const infoAccount = authProvider.getAccountInfo();

					if (!infoAccount) {
						await authProvider.getAccessToken(authProvider.getAuthenticationParameters());
					}
					
					try {
						const azureID = authProvider.getAccountInfo()?.account.accountIdentifier;
						
						// verifica se è il primo caricamento del tab dopo il redirect
						// dal Microsoft login, per impedire così la registrazione del login 
						// sul nostro sistema ad ogni refresh della pagina
						if (sessionStorage.getItem("verAut") != null) {
							try {
								await userSVC.insertLogin(azureID!);
								sessionStorage.removeItem("verAut");
							} catch (error) {
								console.error(error);
							}
						}
						console.log('azureID', azureID);
						const userInfo = await SVC.getUserFromAAD(azureID || "");
						console.log('userInfo', userInfo);
						//imposta la lingua dell'utente
						window.changeLanguage(userInfo.data.Language.substr(0, 2));

						userContext.setContext({ ...userContext, Me: userInfo.data });

						setIsLoading(false);

					}
					catch (error) {
						console.log('ERRORE getAccountInfo', error);
						if ((error as Error).message.match(/network error/i)) {
							setLoadingError("Servizio non raggiungibile");
						}
						else {
							setLoadingError("Errore caricamento AzureAD info");
						}

					}

				} catch (error) {
					// imposta nel session storage la variabile che indica
					// che si tratta del primo caricamento della pagina
					// e quindi l'assenza del token di autorizzazione con 
					// conseguente redirect al Microsoft login
					sessionStorage.setItem("verAut", JSON.stringify((new Date()).toISOString()));
					setLoadingError("Verifica autorizzazione...");
					await sleep(2000);
					await authProvider.login(authProvider.getAuthenticationParameters());
				}

			})();

		}
	});

	if (isLoading) {
		return (
			<div style={divWrapperStyle}>
				<div style={centeredItem}>
					<div className="text-center">
						<Icon icon={ic_developer_board} size={64} className="pl-1" />
						<div className="mb-2">
							Timesheet 2.0
							{loadingError !== "" && (
								<>
									<br />
									<small>{loadingError}</small>
									{loadingError !== "Verifica autorizzazione..." && (
										<div className="mt-2">
											<Button className="bg-primary" onClick={() => setLoadingError("")}>Riprova</Button>
										</div>
									)}
								</>
							)}
						</div>
						{(loadingError === "" || loadingError === "Verifica autorizzazione...") && <Spinner animation="border" />}
					</div>
				</div>
			</div>
		);
	}
	else {
		return (
			<Router>
				<MainProvider>
					<SessionAlert />
					<Loading />
					<SystemAlert />
					<MainMenu />
					<Container fluid={true}>
						<SidebarMenu />
						<Routes />
					</Container>
				</MainProvider>
			</Router>
		);
	}

}