import React, { useContext, useState, useEffect } from "react";
import { UserContext, Maincontext } from "../../maincontext";
import { userTie as ic_userTie } from "react-icons-kit/icomoon/userTie";
import { wrench as ic_wrench } from "react-icons-kit/icomoon/wrench";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import Icon from "react-icons-kit";
import { Table, Button } from "react-bootstrap";
import OrderServices from "../../../api/OrderServices";
import { Order, SubOrder } from "../../../models/models";
import SubOrderServices from "../../../api/SubOrderServices";

export interface IUserSubOrdersWidget {
	UserID?: number
}

export default function UserSubOrdersWidget(props: IUserSubOrdersWidget) {
	const context = useContext(Maincontext);
	const SVC = new SubOrderServices();
	const [list, setList] = useState<SubOrder[]>([]);

	useEffect(() => {
		GetList();
	}, []);

	function GetList() {
		SVC.getUserActiveSubOrders(props.UserID).then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento deliverables: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	return (
		<div className="widget border-0" >
			<h6 className={list.length > 0 ? "" : "title"}>
					<Icon icon={ic_wrench} className="mr-1" size={20} />Deliverables attivi</h6>
			{list.length > 0 ?
				<Table size="sm">
					<thead>
						<tr>
							<th>Cliente</th>
							<th>Commessa</th>
							<th>Deliverable</th>
							<th>Data inizio</th>
							<th>Data fine</th>
						</tr>
					</thead>
					<tbody>
						{list.map(function (item) {
							return <tr key={"Sub_tr_" + item.SubOrderID}>
								<td key={"Sub_custname_td_" + item.SubOrderID}>
									{item.CustomerName}
								</td>
								<td key={"Sub_ordname_td_" + item.SubOrderID}>
									{item.OrderName}
								</td>
								<td key={"Sub_subordname_td_" + item.SubOrderID}>
									{item.SubOrderName}
								</td>
								<td className="date-width" key={"Sub_suborstrdate_td_" + item.SubOrderID}>
									{new Date(item.StartDate).toLocaleDateString()}
								</td>
								<td className="date-width" key={"Sub_suborenddate_td_" + item.SubOrderID}>
									{new Date(item.EndDate).toLocaleDateString()}
								</td>
							</tr>
						}
						)
						}
					</tbody>
				</Table>
				: <span>Nessun deliverable attivo assegnato</span>
			}
		</div>
	);
}

