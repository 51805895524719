import React, { useContext, useEffect, useState, MouseEvent } from "react";
import {
  CustomerOdresUsersWorkingHours,
  ReportColumn,
} from "../../../models/reports";
import ReportServices from "../../../api/ReportServices";
import { Maincontext } from "../../maincontext";
import {
  MenuList,
  SelectItemsListTypes,
  SelectListItem,
} from "../../../models/models";
import TitleBar from "../../common/TitleBar";
import Select from "react-select";
import SelectItemServices from "../../../api/SelectItemServices";
import {
  formatDateToString,
  hoursToDays,
  ListToArray,
  selectFilter,
  thousandSeparator,
} from "../../../core/Utilities";
import { Button, Col, Form } from "react-bootstrap";
import { MONTHS } from "../../../models/locale";
import Icon from "react-icons-kit";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcel } from "react-icons-kit/icomoon/fileExcel";
import { fileText2 } from "react-icons-kit/icomoon/fileText2";
import { refresh } from "react-icons-kit/fa/refresh";
import ReactExport from "react-export-excel";
import { CSVLink } from "react-csv";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "../../common/pdf/PdfDocument";

export default function OrdersWorkingHours() {
  interface iParams {
    orderID: number;
    year: number;
    month: number;
  }

  interface iTotals {
    hours: number;
    overtime: number;
    nigthovertime: number;
    total: number;
    days: number;
  }

  const defParams: iParams = {
    orderID: -1,
    year: 0,
    month: 0,
  };

  const defTotals: iTotals = {
    hours: 0,
    overtime: 0,
    nigthovertime: 0,
    total: 0,
    days: 0,
  };

  const [params, setParams] = useState<iParams>(defParams);
  const [totals, setTotals] = useState<iTotals>(defTotals);

  const context = useContext(Maincontext);
  const SVC = new ReportServices();
  const ISVC = new SelectItemServices();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const icon_size: number = 22;

  const [yearList, setYearList] = useState<SelectListItem[]>([]);
  const [monthList, setMonthList] = useState<SelectListItem[]>([]);
  const [ordersList, setOrdersList] = useState<SelectListItem[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<SelectListItem>();
  const [selectedYear, setSelectedYear] = useState<SelectListItem>();
  const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();

  const [list, setList] = useState<CustomerOdresUsersWorkingHours[]>([]);
  const [rows, setRows] = useState<any[][]>([]);
  const [totalWidth, setTotalWidth] = useState<number>(0);

  const columns: ReportColumn[] = [
    { columnName: "Cod. commessa", columnValue: "OrderCode", columnWidth: 2 },
    { columnName: "Commessa", columnValue: "OrderName", columnWidth: 4 },
    { columnName: "Cliente", columnValue: "CustomerName", columnWidth: 4 },
    {
      columnName: "Data inizio commessa",
      columnValue: "OrderStartDate",
      columnWidth: 2,
    },
    { columnName: "Risorsa", columnValue: "UserFullName", columnWidth: 2 },
    {
      columnName: "Ordinario",
      columnValue: "Hours",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Straordinario",
      columnValue: "OvertimeHours",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Notturno",
      columnValue: "OvertimeNightHours",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Totale",
      columnValue: "TotalHours",
      columnWidth: 1,
      textAlign: "right",
    },
  ];

  useEffect(() => {
    let total: number = 0;
    columns.forEach((element) => {
      total += element.columnWidth;
    });
    setTotalWidth(total);

    MonthListInitialize();

    GetOrdersList();

    context.setContext({
      ...context,
      selectedMenu: MenuList.RPT_ORDERSWORKINGHOURS,
    });
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      const list_rows: any[][] = ListToArray(list);
      setRows(list_rows);
    }

    //calcolo totali
    let t: iTotals = defTotals;
    list.forEach((item) => {
      t.hours += item.Hours;
      t.overtime += item.OvertimeHours;
      t.nigthovertime += item.OvertimeNightHours;
      t.total += item.TotalHours;
    });

    t.days = Number((t.total / 8).toFixed(0));

    setTotals(t);
  }, [list]);

  useEffect(() => {
    setSelectedOrder(ordersList[0]);
    GetYearList();
  }, [ordersList]);

  useEffect(() => {
    const y: number = new Date().getFullYear();
    let item: SelectListItem = { Text: y.toString(), Value: y.toString() };
    setSelectedYear(item);
  }, [yearList]);

  useEffect(() => {
    const m: number = new Date().getMonth();
    let item: SelectListItem = monthList[m + 1];
    setSelectedMonth(item);
    ResetParams();
  }, [monthList]);

  useEffect(() => {
    GetReport();
  }, [params]);

  function GetOrdersList() {
    ISVC.getSelectItemList(SelectItemsListTypes.ORDERS, 0)
      .then((l) => {
        if (l.data) {
          let item: SelectListItem = { Text: "Tutte", Value: "0" };
          let items: SelectListItem[] = l.data;
          items.unshift(item);
          setOrdersList(items);
        }
      })
      .catch((e) => {
        context.setContext({
          ...context,
          showAlert: true /* showAttesa: false, */,
          messaggioAlert: "Errore di caricamento ORDERS: " + e,
          titoloAlert: "Errore",
        });
      })
      .finally(() => {});
  }

  function GetYearList() {
    ISVC.getSelectItemList(SelectItemsListTypes.ORDER_YEARS, 0)
      .then((l) => {
        if (l.data) {
          let item: SelectListItem = { Text: "Tutti", Value: "0" };
          let items: SelectListItem[] = l.data.slice();
          items.unshift(item);
          setYearList(items);
        }
      })
      .catch((e) => {
        context.setContext({
          ...context,
          showAlert: true /* showAttesa: false, */,
          messaggioAlert: "Errore di caricamento ANNI: " + e,
          titoloAlert: "Errore",
        });
      })
      .finally(() => {});
  }

  function MonthListInitialize() {
    let item: SelectListItem = { Text: "Tutti", Value: "0" };
    monthList.push(item);
    for (let i: number = 1; i <= 12; i++) {
      const v = i.toString();
      const t = MONTHS["it"][i - 1];
      item = { Text: t, Value: v };
      monthList.push(item);
    }
    setMonthList(monthList);
  }

  function GetReport() {
    if (params.orderID != -1) {
      SVC.GetRptOdresUsersWorkingHours(
        params.orderID,
        params.year,
        params.month
      )
        .then((l) => {
          if (l.data) {
            setList(l.data);
            console.log(l.data);
          }
        })
        .catch((e) => {
          context.setContext({
            ...context,
            showAlert: true /* showAttesa: false, */,
            messaggioAlert: "Errore di caricamento ore lavorate: " + e,
            titoloAlert: "Errore",
          });
        })
        .finally(() => {});
    }
  }

  const getColumnWidth = (index: number): string => {
    const w = columns[index].columnWidth;
    if (totalWidth > 0) {
      const retVal = (100 / totalWidth) * w;
      return retVal.toString() + "%";
    } else {
      return "auto";
    }
  };

  function changeOrderID(v: any, i: any) {
    const OrderID: number = Number(v.Value);
    setSelectedOrder(v);

    setParams({
      ...params,
      orderID: OrderID,
    });
  }

  function changeYear(v: any, i: any) {
    const y: number = Number(v.Value);
    setSelectedYear(v);

    let m: number = params.month;

    if (y == 0) {
      setSelectedMonth(v);
      m = 0;
    }
    setParams({
      ...params,
      year: y,
      month: m,
    });
  }
  function changeMonth(v: any, i: any) {
    const m: number = Number(v.Value);
    setSelectedMonth(v);

    setParams({
      ...params,
      month: m,
    });
  }

  function ResetParams() {
    setParams({
      orderID: 0,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    });
  }

  function onReset(e: MouseEvent<HTMLButtonElement>) {
    let item: SelectListItem = { Text: "Tutte", Value: "0" };
    setSelectedOrder(item);

    const year: number = new Date().getFullYear();
    item = { Text: year.toString(), Value: year.toString() };
    setSelectedYear(item);

    const m: number = new Date().getMonth();
    item = monthList[m + 1];
    setSelectedMonth(item);

    ResetParams();
  }

  return (
    <div className="Container">
      <TitleBar title="Ore lavorate commesse" />

      <div>
        <Form className="form-search-list">
          <Form.Row>
            <Col sm={12}>
              <h3>Ore lavorate commesse</h3>
            </Col>
            <Col sm={6}>
              <label>Commessa</label>
              <Select
                filterOption={selectFilter}
                value={selectedOrder}
                options={ordersList}
                isSearchable
                onChange={changeOrderID}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </Col>
            <Col sm={2}>
              <label>Anno</label>
              <Select
                filterOption={selectFilter}
                value={selectedYear}
                options={yearList}
                isSearchable
                onChange={changeYear}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </Col>
            <Col sm={2}>
              <label>Mese</label>
              <Select
                filterOption={selectFilter}
                value={selectedMonth}
                options={monthList}
                isSearchable
                onChange={changeMonth}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
                isDisabled={params.year == 0}
              />
            </Col>
            <Col md={2} className="pt-4">
              <Button
                variant={"link"}
                style={{ width: "auto" }}
                className="btn-sm mb-8 h-100 btn-context mr-3"
                onClick={onReset}
              >
                <Icon icon={refresh} size="16" className="mr-1" />
                Reset
              </Button>
              {list.length > 0 && rows.length > 0 ? (
                <>
                  <div title="Esporta in excel" className="d-inline">
                    <ExcelFile
                      filename={"OreLavorateCommesse"}
                      element={
                        <div
                          className="d-inline mr-1"
                          style={{ cursor: "pointer" }}
                        >
                          <Icon
                            icon={fileExcel}
                            className="text-success"
                            size={icon_size}
                          />
                        </div>
                      }
                    >
                      <ExcelSheet
                        data={list}
                        name={
                          params.year == 0
                            ? "Elenco completo"
                            : params.year.toString() +
                              (params.month == 0
                                ? ""
                                : " " + MONTHS["it"][params.month - 1])
                        }
                      >
                        {columns.map(function (item: ReportColumn, i) {
                          return (
                            <ExcelColumn
                              key={"xlsc_" + i.toString()}
                              label={item.columnName}
                              value={item.columnValue}
                            ></ExcelColumn>
                          );
                        })}
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  <div title="Esporta in csv" className="d-inline mr-1">
                    <CSVLink data={rows} filename={"OreLavorateCommesse.csv"}>
                      <Icon
                        icon={fileText2}
                        className="text-warning"
                        size={icon_size}
                      />
                    </CSVLink>
                  </div>
                  {/* <div title="Esporta in pdf" className="d-inline">
										<PDFDownloadLink
											document={<PdfDocument
												title="OreLavorateCommesse"
												columns={columns}
												rows={rows} />}
											fileName={"OreLavorateCommesse.pdf"}>
											{({ blob, url, loading, error }) => (loading ?
												<Icon icon={filePdf} className="text-danger" size={icon_size} />
												:
												<Icon icon={filePdf} className="text-danger" size={icon_size} />)}
										</PDFDownloadLink>
									</div> */}
                </>
              ) : (
                ""
              )}
            </Col>
          </Form.Row>
        </Form>
        <div className="p-3">
          <div className="form-row">
            <div className="col-md-12 m-0">
              <table className="table">
                <thead>
                  <tr>
                    {columns.map(function (item: ReportColumn, i) {
                      return (
                        <th
                          key={"th_" + i.toString()}
                          style={{
                            width: getColumnWidth(i),
                            textAlign: item.textAlign ? item.textAlign : "left",
                          }}
                        >
                          {item.columnName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {list
                    ? list.map(function (item, x) {
                        return (
                          <tr key={"tr_" + x.toString()}>
                            <td>{item.OrderCode}</td>
                            <td>{item.OrderName}</td>
                            <td>{item.CustomerName}</td>
                            <td>{item.OrderStartDate}</td>
                            <td>{item.UserFullName}</td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.Hours)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.OvertimeHours)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.OvertimeNightHours)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.TotalHours)}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>

            <div className="text-right w-100">
              <table
                className="table table-sm table-bordered float-right"
                style={{ width: "180px" }}
              >
                <tbody>
                  <tr>
                    <td className="bg-light">Ore ordinarie</td>
                    <td className="text-right">
                      {thousandSeparator(totals.hours)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Straordinari</td>
                    <td className="text-right">
                      {thousandSeparator(totals.overtime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Straordinari notturni</td>
                    <td className="text-right">
                      {thousandSeparator(totals.nigthovertime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Ore totali</td>
                    <td className="text-right">
                      {thousandSeparator(totals.total)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Giorni</td>
                    <td>{thousandSeparator(totals.days)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
