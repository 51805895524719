import React, { useContext, MouseEvent, useState, useEffect, ChangeEvent, FocusEvent } from "react";
import { Maincontext } from "../../maincontext";
import { Modal, Button, Popover } from "react-bootstrap";//Icone
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { MONTHS, FIRST_DAY_OF_WEEK, LABELS, WEEKDAYS_LONG, WEEKDAYS_SHORT, TODAY_LABEL, formatDate } from "../../../models/locale";
import { Form } from "react-bootstrap";
import Info from "../../common/Info";
import { ConfirmButton } from "../../common/ConfirmButton";
import { iconaBottoni } from "../../common/List";
import { QuickAssignmentParameters, AvailableUser, AvailableUsersParameters, UserAssignedOrder } from "../../../models/models";
import TimeSheetServices from "../../../api/TimeSheetServices";
import SubOrderStepProfileUserServices from "../../../api/SubOrderStepProfileUserServices";
import { FinestraDetails } from "../../common/List";

import Icon from "react-icons-kit";
import { wrench } from "react-icons-kit/icomoon/wrench";


export interface IQuickAssignmentProps {
	SubOrderStepProfileID: number;
	profileTypeID: number;
	activityStepName: string;
	profileTypeName: string;
	subOrderName: string;
	activityLineName: string;
	subOrderStartDate: Date;
	subOrderEndDate: Date;
	SubOrderStepProfileWorkingHours: number;
	SubOrderStepProfileAssignedgHours: number;
}

export default function QuickAssignment(props: IQuickAssignmentProps) {

	//const SVC = new SubOrderStepProfileUserServices();
	const TSVC = new TimeSheetServices();
	const SVC = new SubOrderStepProfileUserServices();
	const context = useContext(Maincontext);
	const language = "it";
	const dateFormat = "DD/MM/YYYY";

	const maxDays: number = 1000;
	const maxHours: number = 24;
	const maxOvertimeHours: number = 24;

	//const [startDate, setStartDate] = useState<Date>(new Date());
	//const [endDate, setEndDate] = useState<Date>(new Date());
	const [rangeStartDate, setRangeStartDate] = useState<Date>(new Date());
	const [rangeEndDate, setRangeEndDate] = useState<Date>(new Date());
	const [findEnabled, setFindEnabled] = useState<boolean>(false);
	const [showPopoverModal, setShowPopoverModal] = useState(false);
	const [noAvailableUserMessage, setNoAvailableUserMessage] = useState<string>("");
	const [availableUsers, setAvailableUsers] = useState<AvailableUser[]>([]);

	const defParameters: AvailableUsersParameters = {
		ProfileTypeID: props.profileTypeID,
		StartDate: new Date(),
		EndDate: new Date(),
		Days: 1,
		AllDay: false,
		StartDateMandatory: false,
		DayHours: 1,
		OvertimeMaxHours: 0,
		Monday: true,
		Tuesday: true,
		Wednesday: true,
		Thursday: true,
		Friday: true,
		Saturday: false,
		Sunday: false,
		Holidays: false
	}

	const [parameters, setParameters] = useState<AvailableUsersParameters>(defParameters);

	// useEffect(() => {

	// 	if (context.showFastAssignment) {


	// 	}

	// }, [context.showFastAssignment]);

	useEffect(() => {

		setParameters({
			...parameters, ProfileTypeID: props.profileTypeID
		});

	}, [props.profileTypeID]);

	useEffect(() => {
		const sDate: Date = new Date(props.subOrderStartDate);
		setRangeStartDate(sDate);
		parameters.StartDate = sDate;
	}, [props.subOrderStartDate]);

	useEffect(() => {
		const sDate: Date = new Date(props.subOrderEndDate);
		setRangeEndDate(sDate);
		parameters.EndDate = sDate;

	}, [props.subOrderEndDate]);

	function GetAvailableUsers() {

		TSVC.GetAvailableUsers(parameters).then((l) => {
			setAvailableUsers(l.data);
			if (l.data.length == 0) {
				setNoAvailableUserMessage("Nessuna risorsa disponibile");
			}
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true,
				messaggioAlert: "Errore caricamento risorse disponibili: " + e, titoloAlert: "Errore"
			});
		}).finally(() => {

		});
	}

	const isValid = (): boolean => {

		//verifica se le ore che si stanno assegnando
		//mon sono eccessive
		const assignableHours: number = props.SubOrderStepProfileWorkingHours - props.SubOrderStepProfileAssignedgHours;
		const assigningHours: number = parameters.DayHours * parameters.Days;

		if (assigningHours > assignableHours) {
			setNoAvailableUserMessage(`Le ore che si stanno assegnando (${assigningHours}) sono maggiori di quelle disponibili (${assignableHours})`);
			return false;
		}
		else {
			return true;
		}
	};

	function resetUsers() {
		//reset users list
		const users: AvailableUser[] = [];
		setAvailableUsers(users);
		setNoAvailableUserMessage("");
	}

	function setContext() {

		resetUsers();

	}

	function SetStartDate(day: Date) {
		if (day !== undefined && day != parameters.StartDate) {
			parameters.StartDate = day;
			setParameters({ ...parameters, StartDate: day });
			setContext();
		}
	}

	function SetEndDate(day: Date) {
		if (day !== undefined && day != parameters.EndDate) {
			parameters.EndDate = day;
			setParameters({ ...parameters, EndDate: day });
			setContext();
		}
	}

	const closeDialog = () => {

		resetUsers();

		//resetta i filtri
		setParameters({
			...parameters,
			Days: 1,
			AllDay: false,
			StartDateMandatory: false,
			DayHours: 1,
			OvertimeMaxHours: 0,
			Monday: true,
			Tuesday: true,
			Wednesday: true,
			Thursday: true,
			Friday: true,
			Saturday: false,
			Sunday: false,
			Holidays: false
		});

		context.setContext({ ...context, showFastAssignment: false });

	};

	function AssignToUser(userid: number) {

		const par: QuickAssignmentParameters = {
			UserID: userid,
			SubOrderStepProfileID: props.SubOrderStepProfileID,
			Parameters: parameters
		}

		SVC.QuickAssignment(par).then((d) => {

			if (d !== undefined && d.data !== "") {
				context.setContext({
					...context, showAlert: true,
					messaggioAlert: "Errore assegnazione: " + d.toString(), titoloAlert: "Errore"
				});
			}
			else {
				context.setContext({ ...context, refreshList: !context.refreshList });
				closeDialog();
			}
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true,
				messaggioAlert: "Errore assegnazione: " + e, titoloAlert: "Errore"
			});
		}).finally(() => {
		});
	}

	//eventi	
	const FindAvailableUsers = (e: MouseEvent<HTMLButtonElement>) => {

		const valid = isValid();

		if (valid == true) {
			GetAvailableUsers();
		}

	};

	function DaysChange(e: ChangeEvent<HTMLInputElement>) {
		let val = Number(e.target.value);
		if (val > maxDays) {
			val = maxDays;
		}
		else if (val == 0) {
			val = 1;
		}
		else if (val == undefined) {
			val = 1;
		}

		setParameters({
			...parameters, Days: val
		});

		setContext();
	}

	function DayHoursChange(e: ChangeEvent<HTMLInputElement>) {
		let val = Number(e.target.value);
		if (val > maxHours) {
			val = maxHours;
		}
		else if (val == 0) {
			val = 1;
		}
		else if (val == undefined) {
			val = 1;
		}

		parameters.DayHours = val;

		setParameters({
			...parameters, DayHours: val
		});

		setContext();
	}

	function AllDayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, AllDay: checked
		});
		setContext();
	}

	function StartDateMandatoryChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, StartDateMandatory: checked
		});
		setContext();
	}

	function OvertimeMaxHoursChange(e: ChangeEvent<HTMLInputElement>) {

		let val = Number(e.target.value);
		if (val > maxOvertimeHours) {
			val = maxOvertimeHours;
		}
		else if (val == undefined) {
			val = 0;
		}

		setParameters({
			...parameters, OvertimeMaxHours: val
		});
		setContext();
	}

	function MondayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Monday: checked
		});
		setContext();
	}

	function TuesdayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Tuesday: checked
		});
		setContext();
	}

	function WednesdayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Wednesday: checked
		});
		setContext();
	}

	function ThursdayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Thursday: checked
		});
		setContext();
	}

	function FridayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Friday: checked
		});
		setContext();
	}

	function SaturdayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Saturday: checked
		});
		setContext();
	}

	function SundayChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Sunday: checked
		});
		setContext();
	}

	function HolidaysChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setParameters({
			...parameters, Holidays: checked
		});
		setContext();
	}

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={"xl"}
				//className={hiddenDetail ? "d-none" : ""}
				show={context.showFastAssignment}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title className="fluid">

						<div className="form-row">
							<div className="col-12">
								<h3>Assegnazione rapida</h3>
							</div>
							<div className="col-7">
								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<td className="bg-light">Deliverable</td>
											<td className="p-1">{props.subOrderName}</td>
										</tr>
										<tr>
											<td className="bg-light">Linea di attività</td>
											<td className="p-1">{props.activityLineName}</td>
										</tr>
										<tr>
											<td className="bg-light">Fase di attività</td>
											<td className="p-1">{props.activityStepName}</td>
										</tr>
										<tr>
											<td className="bg-light">Profilo</td>
											<td className="p-1">{props.profileTypeName}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-5 text-rigth">
								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<th className="bg-light">Descrizione</th>
											<th className="text-right p-1 bg-light">Giornate</th>
											<th className="text-right p-1 bg-light">Ore</th>
										</tr>
										<tr>
											<td>
												Pianificate per il profilo
												<Info
													color="secondary"
													placement="auto"
													text="Totale pianificate per il profilo"
												/>
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileWorkingHours / 8}
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileWorkingHours}
											</td>
										</tr>
										<tr>
											<td>Già assegnate
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileAssignedgHours / 8}
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileAssignedgHours}
											</td>
										</tr>
										<tr>
											<td>Disponibili
											<Info
													color="secondary"
													placement="auto"
													text="Non ancora assegnate"
												/></td>
											<td className="text-right p-1">
												{(props.SubOrderStepProfileWorkingHours - props.SubOrderStepProfileAssignedgHours) / 8}
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileWorkingHours - props.SubOrderStepProfileAssignedgHours}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-row">
						<div className="form-group col text-left">
							<label>Dal giorno</label><br />
							<DayPickerInput
								dayPickerProps={{
									month: new Date(parameters.StartDate),
									todayButton: TODAY_LABEL[language],
									locale: language,
									months: MONTHS[language],
									weekdaysLong: WEEKDAYS_LONG[language],
									weekdaysShort: WEEKDAYS_SHORT[language],
									firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
									labels: LABELS[language],
									disabledDays: [{
										before: new Date(rangeStartDate),
										after: new Date(parameters.EndDate)
									}]
								}}
								placeholder={"Non impostata"}
								format={dateFormat}
								value={new Date(parameters.StartDate).toLocaleDateString()}
								onDayChange={day => SetStartDate(day)}
								inputProps={{
									readOnly: "readOnly"
								}}
								formatDate={day => formatDate(day)}
							/>
						</div>
						<div className="form-group col text-left">
							<label>Al giorno</label><br />
							<DayPickerInput
								dayPickerProps={{
									month: new Date(parameters.EndDate),
									todayButton: TODAY_LABEL[language],
									locale: language,
									months: MONTHS[language],
									weekdaysLong: WEEKDAYS_LONG[language],
									weekdaysShort: WEEKDAYS_SHORT[language],
									firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
									labels: LABELS[language],
									disabledDays: [{
										before: new Date(parameters.StartDate),
										after: new Date(rangeEndDate)
									}]
								}}
								placeholder={"Non impostata"}
								format={dateFormat}
								value={new Date(parameters.EndDate).toLocaleDateString()}
								onDayChange={day => SetEndDate(day)}
								inputProps={{
									readOnly: "readOnly"
								}}
								formatDate={day => formatDate(day)}
							/>
						</div>
						<div className="form-group col text-left">
							<label>Giornate</label>
							<Info placement="auto"
								text={"Numero totale delle giornate da assegnare (max " + maxDays.toString() + ")"}
							/><br />
							<input
								type="number"
								className="form-control"
								step={1}
								min={1}
								max={maxDays}
								onChange={DaysChange}
								value={parameters.Days} />
						</div>
						<div className="form-group col text-left pt-3 mt-2">
							<Form.Check
								id="chkAllDay"
								type="switch"
								label="Intera giornata"
								defaultChecked={parameters.AllDay}
								style={{ display: "inline" }}
								onChange={AllDayChange}
							/><Info placement="auto" text="Essegna l'intera giornata se disponibile" />
							<br />
							<Form.Check
								id="chkStartDateMandatory"
								type="switch"
								label="Data inizio"
								defaultChecked={parameters.StartDateMandatory}
								style={{ display: "inline" }}
								onChange={StartDateMandatoryChange}
							/><Info placement="auto" text="Data inizio assegnazione obbligatoriamente = dal giorno" />
						</div>
						<div className="form-group col text-left">
							<label>Ore al giorno</label>
							<Info placement="auto" text="Non saranno assegnate ore nelle giornate che non hanno sufficienti ore disponibili" /><br />
							<input
								type="number"
								className="form-control"
								step={1}
								min={1}
								max={maxHours}
								onChange={DayHoursChange}
								value={parameters.DayHours}
								readOnly={parameters.AllDay}
							/>
						</div>
						<div className="form-group col text-left">
							<label>Straordinari</label><Info placement="auto" text="Max ore al giorno, digitare zero se non sono previsti straordinari" /><br />
							<input
								type="number"
								className="form-control"
								step={1}
								min={1}
								max={maxOvertimeHours}
								onChange={OvertimeMaxHoursChange}
								value={parameters.OvertimeMaxHours}
								readOnly={parameters.AllDay}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col text-left">
							<Form.Check
								id="chkMonday"
								type="switch"
								label="Lunedì"
								defaultChecked={parameters.Monday}
								onChange={MondayChange}
							/>
						</div>
						<div className="form-group col text-left">
							<Form.Check
								id="chkTuesday"
								type="switch"
								label="Martedì"
								defaultChecked={parameters.Tuesday}
								onChange={TuesdayChange}
							/>
						</div>
						<div className="form-group col text-left">
							<Form.Check
								id="chkWednesday"
								type="switch"
								label="Mercoledì"
								defaultChecked={parameters.Wednesday}
								onChange={WednesdayChange}
							/>
						</div>
						<div className="form-group col text-left">
							<Form.Check
								id="chkThursday"
								type="switch"
								label="Giovedì"
								defaultChecked={parameters.Thursday}
								onChange={ThursdayChange}
							/>
						</div>
						<div className="form-group col text-left">
							<Form.Check
								id="chkFriday"
								type="switch"
								label="Venerdì"
								defaultChecked={parameters.Friday}
								onChange={FridayChange}
							/>
						</div>
						<div className="form-group col text-left">
							<Form.Check
								id="chkSaturday"
								type="switch"
								label="Sabato"
								defaultChecked={parameters.Saturday}
								onChange={SaturdayChange}
							/>
						</div>
						<div className="form-group col text-left">
							<Form.Check
								id="chkSunday"
								type="switch"
								label="Domenica"
								defaultChecked={parameters.Sunday}
								onChange={SundayChange}
							/>
						</div>
						<div className="form-group col text-left">
							<Form.Check
								id="chkHolidaysChange"
								type="switch"
								label="Festivi"
								defaultChecked={parameters.Holidays}
								onChange={HolidaysChange}
							/>
						</div>
					</div>
					<div className="form-row text-center">
						<div className="form-group col text-center">
							<Button
								id="btnFindAvalaibleUsers"
								variant="primary"
								size="sm"
								//disabled={!findEnabled}
								onClick={FindAvailableUsers}
							>Verifica risorse disponibili</Button>
						</div>
					</div>
					<div className="form-row tableFixHead">
						{availableUsers.length > 0 ?

							<table className="table table-sm table-list" >
								<thead>
									<tr>
										<th>
											Risorsa
									</th>
										<th>
											Ore ordinarie
									</th>
										<th>
											Straordinario
									</th>
										<th>
											Giornate
									</th>
										<th style={{ width: "120px" }}></th>
									</tr>
								</thead>
								<tbody>
									{availableUsers.map(
										function (item: AvailableUser, i: number) {
											return (
												<tr key={"qa_user_tr_" + item.UserID} className={(item.Days == 0 ? "text-muted" : "")}>
													<td>
														{item.UserFullName}
														<div className={"float-right"}>
															<FinestraDetails
																id={"quickdetails_" + i}
																placementRight={true}
																popoverWidth={450}
																icon={<Icon icon={wrench} size={18} />}> <AssignedOrdersDetail items={item.AssignedOrders} /> </FinestraDetails>
														</div>
													</td>
													<td>
														{item.Hours}
													</td>
													<td>
														{item.OvertimeHours}
													</td>
													<td>
														{item.Days}
													</td>
													<td className="text-right">
														{item.Days > 0 ?
															<ConfirmButton
																title="Assegna"
																//key={"ConfBtn" + item.UserID.toString()}
																icon={iconaBottoni("flash")}
																confirmMessage={"Assegnare l'attività a "
																	+ item.UserFullName + "?"}
																placement={"left"}
																confirmFunction={(event: React.MouseEvent<HTMLButtonElement>) => {
																	AssignToUser(item.UserID);
																}
																}
																toggleModal={() => { setShowPopoverModal(showState => !showState); }}
																className="m-0 p-0 btn-context"
																titleButton={true}
															/> : <div></div>
														}
													</td>
												</tr>
											);
										}
									)}

								</tbody>
							</table>
							:

							<div>{noAvailableUserMessage}</div>
						}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => closeDialog()}
					>
						Annulla
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}



interface AssignedOrdersDetailProps {
	items?: UserAssignedOrder[];
}
function AssignedOrdersDetail(props: AssignedOrdersDetailProps) {
	if (props.items && props.items.length > 0) {

		return (
			<div>
				<Popover.Title as="h3" style={{
					fontSize: "14px", margin: "8px", border: "none", borderRadius: "4px"
				}}>
					Attività assegnate alla risorsa
				</Popover.Title>
				<Popover.Content style={{ fontSize: "12px", minHeight: "100px" }}>
					<table className="w-100">
						<tr>
							<th>
								Cliente
							</th>
							<th>
								Commessa
							</th>
							<th>
								Ore
							</th>
						</tr>
						{props.items.map((e, i) => {
							return (
								<tr>
									<td>{e.CustomerName}</td>
									<td>{e.OrderName}</td>
									<td>{e.Hours}</td>
								</tr>
							)
						})}
					</table>
				</Popover.Content>
			</div>

		)
		//})
	} else {
		return (
			<div>
				<Popover.Title as="h3" style={{
					fontSize: "14px", margin: "8px", border: "none", borderRadius: "4px"
				}}>
					Attività assegnate alla risorsa
			</Popover.Title>
				<Popover.Content style={{ fontSize: "12px", minHeight: "100px" }}>
					<div>Nessuna attività assegnata</div>
				</Popover.Content>
			</div>
		)
	}


}
