import React, { useContext, useEffect, useState } from "react";
import TitleBar from "../common/TitleBar";
import { Maincontext, UserContext } from "../maincontext";
import "react-day-picker/lib/style.css";
import { ContentTypes, MenuList } from "../../models/models";
import UserDayAssignedWorkingHours from "./UserWidgets/UserDayAssignedWorkingHours";
import UserLoginsWidget from "./UserWidgets/UserLoginsWidget";
import { IsAuthorized, Log } from "../../core/Utilities";
import ActivityLinesUsersChart from "./charts/ActivityLinesUsersChart";
import CostCentersUsersChart from "./charts/CostCentersUsersChart";
import UserDeadlineSubOrdersWidget from "./UserWidgets/UserDeadlineSubOrdersWidget";
import UserTimesheetsWidget from "./UserWidgets/UserTimesheetsWidget";
import OrdersSummaryWidget from "./UserWidgets/OrdersSummaryWidget";

import { useAppInsightsContext, useTrackMetric  } from "@microsoft/applicationinsights-react-js";
import UserOrdersWidget from "./UserWidgets/UserOrdersWidget";
import UserSubOrdersWidget from "./UserWidgets/UserSubOrdersWidget";
import OrdersRemaingDaysWidget from "./UserWidgets/OrdersRemaingDaysWidget";

export default function Dashboard() {

	//#region AppInsights
    const appInsights = useAppInsightsContext();
    const trackComponent = useTrackMetric(appInsights, "Dashboard");
	//#endregion AppInsights

	const [showA, setShowA] = useState(true);
	const [showB, setShowB] = useState(true);

	const toggleShowA = () => setShowA(!showA);
	const toggleShowB = () => setShowB(!showB);

	const context = useContext(Maincontext);
	const userInfo = useContext(UserContext);
	const reports_authorized: boolean =
		(
			IsAuthorized(ContentTypes.REPORTS)
			||
			IsAuthorized(ContentTypes.COMMERCIAL_DATA)
			||
			IsAuthorized(ContentTypes.ORDERS)
		);

	useEffect(() => {
		//trackComponent();
		context.setContext({ ...context, selectedMenu: MenuList.DASHBOARD });
	}, []);

	return (
		<div>
			<TitleBar title="Dashboard" />
			{userInfo.Me && IsAuthorized(ContentTypes.REPORTS) ? (
				<div className="p-3">
					<div className="form-row">
						<div className="col-md-12 mb-4">
							<UserDayAssignedWorkingHours />
						</div>
						<div className="col-md-8">
							<UserDeadlineSubOrdersWidget />
							<OrdersRemaingDaysWidget />
							{/* <UserOrdersWidget />
							<UserSubOrdersWidget /> */}
						</div>
						<div className="col-md-2">
							<UserTimesheetsWidget />

						</div>
						<div className="col-md-2">
							<UserLoginsWidget list={userInfo.Me.UserLogins} />
						</div>						
						<div className="col-md-12">
							<OrdersSummaryWidget />
						</div>
					</div>
					{
						reports_authorized ?
							<div className="form-row">
								<div className="col-12 col-md-6">
									<CostCentersUsersChart />
								</div>
								<div className="col-12 col-md-6">
									<ActivityLinesUsersChart />
								</div></div>
							:
							<div></div>
					}
				</div>
			) :
				"caricamento dashboard"
			}
		</div>
	);
}