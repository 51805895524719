import React, { useContext, useEffect, useState } from 'react';
import ReportServices from '../../../api/ReportServices';
import OrderServices from '../../../api/OrderServices';
import { Maincontext } from '../../maincontext';

import { useParams } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import { SubOrder } from "../../../models/models";
import { OrderUsersWorkedMinutes, TimeSpanTypes, UserWorkedMinutes } from "../../../models/reports";
import { ChartDataset } from "../../../models/charts";

import { GetDatesArray, GetStringDatesArray, GetRandomColor, formatDateToStringDelimiter } from '../../../core/Utilities';
import { Col, Form } from 'react-bootstrap';
import TimeSpanSelect from '../../common/TimeSpanSelect';

export default function OrderUsersWorkedHours() {
	const SVC = new ReportServices();
	const SSVC = new OrderServices();
	const context = useContext(Maincontext);
	let params: any = useParams();
	const [reportData, setReportData] = useState<OrderUsersWorkedMinutes[]>([]);
	const [order, setOrder] = useState<SubOrder>();
	const [type, setType] = useState<TimeSpanTypes>(TimeSpanTypes.LAST_30_DAYS);

	const defState = {
		labels: [],
		datasets: []
	}

	const defOptions = {
		maintainAspectRatio: false,
		title: {
			display: true,
			text: '',
			fontSize: 20
		},
		legend: {
			display: true,
			position: 'right'
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}

	const [chartState, setChartState] = useState<any>(defState);
	const [chartOptions, setChartOptions] = useState<any>(defOptions);

	useEffect(() => {

		(document.querySelector(".menu-laterale") as HTMLElement).style.display = "none";
		(document.querySelector(".navbar-nav") as HTMLElement).style.display = "none";
		(document.querySelector(".principale") as HTMLElement).style.marginLeft = "0";
		GetOrder();
	}, []);

	useEffect(() => {
		GetList();
	}, [type]);


	useEffect(() => {

		if (reportData.length > 0) {
			FormatChartData();
		}

	}, [reportData]);

	function GetOrder() {
		var orderID: number = params.id;
		SSVC.getOrder(orderID).then((d) => {
			if (d.data) {
				chartOptions.title.text = d.data.OrderName + ": grafico minuti lavorati per risorsa";
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento dati: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function GetList() {		
		var orderID: number = params.id;
		SVC.GetRptUsersWorkedHoursByOrderID(orderID, type).then((d) => {
			if (d.data) {
				setReportData(d.data);
			}
			else {
				setChartState({
					...chartState, labels: [], datasets: []
				});
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento dati: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function FormatChartData() {

		//formatta i dati per valorizzare le lables (date sull'asse delle x)
		var startDate: Date = reportData[0].StartDate;
		var endDate: Date = reportData[0].EndDate;
		let labels: string[] = GetStringDatesArray(startDate, endDate);
		let datesList: Date[] = GetDatesArray(startDate, endDate);

		//imposta i dati del grafico per ciascuna risorsa
		let chartDatasets: ChartDataset[] = [];

		reportData.forEach((e: OrderUsersWorkedMinutes, i) => {

			chartDatasets.push({
				label: e.UserFullName,
				backgroundColor: "#fafafa",
				borderColor: GetRandomColor(),
				borderWidth: 2,
				data: SetChartDataset(datesList, e.WorkedMinutes)
			});
		});

		setChartState({
			...chartState, labels: labels, datasets: chartDatasets
		});
	}

	//imposta i dati per la singola curva, se la risorsa non ha 
	//ore lavorate per quella data mette zero
	function SetChartDataset(dateList: Date[], list: UserWorkedMinutes[]) {

		let values: any[] = [];

		dateList.forEach((d: Date, i) => {
			let sDate = formatDateToStringDelimiter(d, "-");
			let userWorkedMinutes = list.find(e => e.WorkingDate.toString() === sDate);
			values.push(
				userWorkedMinutes === undefined ? null : userWorkedMinutes.WorkedMinutes
			);
		});
		return values;
	}

	return (
		<>
			<Form className="form-search-list">
				<Form.Row>
					<Col md={3}>
						<TimeSpanSelect onChangeValue={(type: TimeSpanTypes) => setType(type)} />
					</Col>
				</Form.Row>
			</Form>
			<div className="p-2 border-0">
				<Line
					data={chartState}
					options={chartOptions}
					height={600}
				/>
			</div>
		</>
	)

}