import React, { useState, useEffect, useContext, useRef, ChangeEvent } from "react";
import { MenuList, SelectListItem, SelectItemsListTypes, UserMonthlyCost } from "../../models/models";
import UserServices from "../../api/UserServices";
import TitleBar from "../common/TitleBar";
import { Maincontext } from "../maincontext";
import { selectFilter, thousandSeparator } from "../../core/Utilities";

import { MONTHS } from "../../models/locale";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";

import Icon from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import { ic_edit } from "react-icons-kit/md/ic_edit";

export interface IUsersMonthlyCost {
}

export default function UsersMonthlyCost(props: IUsersMonthlyCost) {
	const context = useContext(Maincontext);
	const svc = new UserServices();
	const [usersMonthlyCostList, setUsersMonthlyCostList] = useState<UserMonthlyCost[]>([]);
	const [yearsList, setYearsList] = useState<SelectListItem[]>([]);
	const [monthList, setMonthList] = useState<SelectListItem[]>([]);

	const [selectedYear, setSelectedYear] = useState<SelectListItem | undefined>(undefined);
	const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();

	const [editingRow, setEditingRow] = useState<UserMonthlyCost | undefined>(undefined);
	const [monthlyCost, SetMonthlyCost] = useState<number>(0);
	const [currentUserID, SetCurrentUserID] = useState<number>(0);


	useEffect(() => {

		context.setContext({ ...context, selectedMenu: MenuList.USERS_MONTHLY_COST });
		YearListInitialize();

	}, []);

	useEffect(() => {
		MonthListInitialize();
	}, [yearsList]);

	useEffect(() => {

		if (selectedMonth != null && selectedYear != null) {
			GetUsersMonthlyCost();
		}

	}, [selectedMonth, selectedYear]);

	function MonthListInitialize() {

		let mlist: SelectListItem[] = [];
		let item: SelectListItem;
		for (let i: number = 1; i <= 12; i++) {
			const v = i.toString();
			const t = MONTHS["it"][i - 1];
			item = { Text: t, Value: v };
			mlist.push(item);
		}

		setMonthList(mlist);

		const d = new Date();
		const curMonth: number = d.getMonth();

		item = {
			Value: curMonth.toString(),
			Text: MONTHS["it"][curMonth - 1]
		}
		setSelectedMonth(item);
	}

	function YearListInitialize() {

		const year: number = 2020;
		const d = new Date();
		const curYear: number = d.getFullYear();
		let list: SelectListItem[] = [];

		for (let i = year; i <= curYear + 1; i++) {
			const item: SelectListItem = {
				Value: i.toString(),
				Text: i.toString()
			}
			list.push(item);
		}
		setYearsList(list);

		const item: SelectListItem = {
			Value: curYear.toString(),
			Text: curYear.toString()
		}
		setSelectedYear(item);
	}

	function GetUsersMonthlyCost() {


		var year: number = Number(selectedYear?.Value);
		var month: number = Number(selectedMonth?.Value);

		if (year != 0 && month != 0) {

			svc.GetUsersMonthlyCost(year, month).then((l) => {
				if (l.data) {
					setUsersMonthlyCostList(l.data);
				}
			}).catch((e) => {
				context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento cost list: " + e, titoloAlert: "Errore" });
			}).finally(() => {

			});
		}
	}

	function MonthlyCostUpdate(userMonthlyCost: UserMonthlyCost) {

		svc.UserMonthlyCostUpdate(userMonthlyCost).then((l) => {
			if (l.data != "") {
				context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore salvataggio cost list: " + l.data, titoloAlert: "Errore" });
			}
			else {
				setEditingRow(undefined);
				GetUsersMonthlyCost();
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore salvataggio cost list: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});

	}

	function setYear(v: any, i: any) {
		setSelectedYear(v);
	}

	function changeMonth(v: any, i: any) {
		setSelectedMonth(v);
	}

	function setMonthlyCost(e: ChangeEvent<HTMLInputElement>) {
		const cost: number = Number(e.target.value);
		SetMonthlyCost(cost);
	}

	function monthlyCostUpdate(userMonthlyCost: UserMonthlyCost) {
		userMonthlyCost.MonthlyCost = monthlyCost;
		MonthlyCostUpdate(userMonthlyCost);
	}

	return (
		<>
			<TitleBar title="Costo mensile delle risorse" />
			<Form className="form-search-list">
				<Form.Row>
					<Col md={3}>
						<label className="form-label">Anno</label>
						<Select
							filterOption={selectFilter}
							value={selectedYear}
							options={yearsList}
							isSearchable
							onChange={setYear}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col md={3}>
						<label className="form-label">Mese</label>
						<Select
							filterOption={selectFilter}
							value={selectedMonth}
							options={monthList}
							isSearchable
							onChange={changeMonth}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
				</Form.Row>
			</Form>
			<div className="p-3">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>
								Risorsa
							</th>
							<th className="text-center">
								Anno
							</th>
							<th className="text-center">
								Mese
							</th>
							<th className="text-right">
								Costo
							</th>
						</tr>
					</thead>
					<tbody>
						{
							usersMonthlyCostList ?
								usersMonthlyCostList.map(function (item: UserMonthlyCost) {
									return (
										<tr className="ts-resource-row"
											onMouseOver={() => SetCurrentUserID(item.UserID)}
											onMouseLeave={() => SetCurrentUserID(0)}
										>
											<td>{item.UserFullName}</td>
											<td className="text-center">{item.Year}</td>
											<td className="text-center">{item.TimeSheetMonthName}</td>
											<td className="text-right">
												{
													editingRow != undefined
														&& editingRow.UserID == item.UserID
														&& editingRow.Month == item.Month
														&& editingRow.Year == item.Year ?
														<>
															<button className="btn btn-link btn-sm p-0"
																title="Annulla"
																onClick={() => setEditingRow(undefined)}
															>
																<Icon icon={ic_close} size={24} />
															</button>
															<button className="btn btn-link btn-sm p-0 mr-2"
																title="Salva"
																onClick={() => monthlyCostUpdate(item)}
															>
																<Icon icon={ic_check} size={24} />
															</button>
															<input type="number"
																step="1"
																className="cost-input"
																defaultValue={item.MonthlyCost}
																onChange={setMonthlyCost} />
														</>
														:
														<>
															{currentUserID == item.UserID ?
																<button className="btn btn-link btn-sm p-0"
																	title="Modifica"
																	onClick={() => setEditingRow(item)}
																>
																	<Icon icon={ic_edit} size={24} /></button>
																: ''
															}
															<label className="cost-input">
																{thousandSeparator(item.MonthlyCost)}</label>
														</>

												}



											</td>
										</tr>
									);
								})
								: ''}
					</tbody>
				</table>
			</div>
		</>
	);
}
