import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { PermitType } from "../../models/models";
import PermitTypesServices from "../../api/PermitTypesServices";
import { Form } from "react-bootstrap";

export interface IPermitType {
}


export default function PermitTypeDetail(props: IPermitType) {
	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();
	const SVC = new PermitTypesServices();

	//ID dell'elemento
	let id = 0;
	if (context.objSelected) {
		id = context.objSelected.PermitTypeID as number;
	}

	//elemento predefinito
	const defPermitType: PermitType = {
		"PermitTypeID": 0,
		"PermitTypeDescription": "",
		"FullDay": true
	};

	//impostazione dello stato dell'elemento corrente utilizzando useState
	const [permitType, setPermitType] = useState<PermitType>(defPermitType);

	useEffect(() => {
		if (id) {
			//imposta l'elemento corrente
			GetItem();
		}
		else {
			//se è un nuovo elemento chiude il pannello loading 
			//e visualizza il pannnello di dettaglio
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		}
	}, []);

	//carica l'elemento corrente utilizzando i service
	function GetItem() {

		//context.setContext({ ...context, showAttesa: true });

		SVC.getPermitType(id).then((d) => {
			if (d.data) {
				//se restituisce il dato lo imposta nello stato
				setPermitType(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento tipologia di permesso: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			//appena caricati i dati chiude il pannello loading 
			//e visualizza il pannnello di dettaglio
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});

		context.objectName = context.objSelected.ProfileTypeName;
	}

	//validazione lato client
	const isValid = (): boolean => {
		return permitType.PermitTypeDescription !== "";
	};

	function setContext() {

		const valid = isValid();
		if (valid) {
			//aggiorna i dati nel context
			context.objSelected = permitType;
		}
		//se è valido abilita il tasto Salva
		context.setContext({ ...context, saveEnabled: valid });
	}

	//eseguita sul cambio valore del flag full day
	function setFullDay(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
		permitType.FullDay = enabled;
		setContext();
	}
	//eseguita sul cambio valore del campo input description
	function setDescription(e: ChangeEvent<HTMLInputElement>) {

		const descr: string = e.target.value;
		permitType.PermitTypeDescription = descr;
		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Tipologia di permesso</label>
						<input className="form-control" name="PermitTypeDescription"
							defaultValue={permitType.PermitTypeDescription}
							onChange={setDescription}
							ref={register({ required: true, maxLength: 50 })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<Form.Check
							id="PermitTypeFullDay"
							type="switch"
							label={"Intera Giornata"}
							checked={permitType.FullDay}
							onChange={setFullDay} />
					</div>
				</div>
			</form>
		</div>
	);
}