import React, { MouseEvent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Order } from "../../../../../models/reports";
import SubOrderReport from "./SubOrderReport";
import Icon from "react-icons-kit";
import { ic_expand_less } from "react-icons-kit/md/ic_expand_less";
import { ic_expand_more } from "react-icons-kit/md/ic_expand_more";
import { refresh } from "react-icons-kit/fa/refresh";
import { thousandSeparator, hoursToDays, minutesToHours } from "../../../../../core/Utilities";
import OrderWorkedPercentageTableRow from "./OrderWorkedPercentage";

export interface IOrderReport {
	data: Order,
	onResetEvent(): void
}

export default function OrderReport(props: IOrderReport) {
	//campi calcolati
	const [workedHours, setWorkedHours] = useState<string>("");
	const [workedDays, setWorkedDays] = useState<string>("");
	const [subOrdersWorkingDays, setSubOrdersWorkingDays] = useState<number>(0);
	const [stepsWorkingWorkingHours, setStepsWorkingHours] = useState<number>(0);
	const [profilesWorkingHours, setProfilesWorkingHours] = useState<number>(0);
	const [profilesAssignedWorkingHours, setProfilesAssignedWorkingHours] = useState<number>(0);
	const [collapsed, setCollapsed] = useState(false);

	useEffect(() => {

		SetComputedHours();

	}, [props.data]);

	async function SetComputedHours() {

		let minutes: number = 0;
		let soWorkingDays: number = 0;
		let stWorkingHours: number = 0;
		let prWorkingHours: number = 0;
		let asWorkingHours: number = 0;

		if (props.data) {
			if (props.data.SubOrders) {
				props.data.SubOrders.forEach(so => {
					if (so && so.SubOrderSteps) {
						soWorkingDays += so.WorkingDays ? so.WorkingDays : 0;
						so.SubOrderSteps.forEach(step => {
							if (step && step.SubOrderStepProfiles) {
								stWorkingHours += step.WorkingHours ? step.WorkingHours : 0;
								step.SubOrderStepProfiles.forEach(prof => {
									prWorkingHours += prof.WorkingHours ? prof.WorkingHours : 0;
									if (prof && prof.UserTimesheets) {
										prof.UserTimesheets.forEach(ts => {
											if (ts.UserID) {
												minutes += ts.Minutes;
												minutes += ts.OvertimeMinutes;
												minutes += ts.OvertimeNightMinutes;
											}
										});
									}

									if (prof && prof.UserAssignedHours) {
										prof.UserAssignedHours.forEach(ts => {
											if (ts.UserID) {
												asWorkingHours += ts.WorkingHours;
											}
										});
									}
								});
							}
						});
					}
				});
			}
		}
		setSubOrdersWorkingDays(soWorkingDays);
		setStepsWorkingHours(stWorkingHours);
		setProfilesWorkingHours(prWorkingHours);
		setProfilesAssignedWorkingHours(asWorkingHours);
		setWorkedHours(minutesToHours(minutes));
		setWorkedDays(hoursToDays(minutes / 60));
	}

	function onReset(e: MouseEvent<HTMLButtonElement>) {
		props.onResetEvent();
	}

	return (
		<div className="Container">
			{props.data ?
				<div>
					<div className="mt-3 d-flex w-100">
						<h3 className="d-inline mr-3">{props.data.OrderCode + " " + props.data.OrderName}</h3>
						<Button
							variant={"link"}
							style={{ width: "auto" }}
							className="btn-sm mb-8 h-100 btn-context mt-0"
							onClick={onReset}
						><Icon icon={refresh} size="16" className="mr-1" />
						Aggiorna
						</Button>
						<Button
							variant={"link"}
							style={{ width: "auto" }}
							className="btn-sm mb-8 h-100 btn-context mt-0 pl-3"
							onClick={() => setCollapsed(!collapsed)}
						>
							{collapsed ? "Chiudi dettagli" : "Apri dettagli"}
							<Icon style={{ marginLeft: "-2px" }} icon={collapsed ? ic_expand_less : ic_expand_more} size="18" className="" />
						</Button>
					</div>
					<div className="d-flex">
						<div className="w-20 pr-3">
							<table className="table table-sm">
								<tbody>
									<tr>
										<td>Cliente</td>
										<td>{props.data.CustomerName}</td>
									</tr>
									<tr>
										<td>Responsabile</td>
										<td>{props.data.FirstName
											+ " " +
											props.data.LastName}</td>
									</tr>
									<tr>
										<td className="nowrap">Data inizio</td>
										<td>{new Date(props.data.StartDate).toLocaleDateString()}</td>
									</tr>
									<tr>
										<td className="nowrap">Data fine</td>
										<td>{new Date(props.data.EndDate).toLocaleDateString()}</td>
									</tr>
								</tbody>
							</table>
							<table className="table table-sm">
								<thead>
									<tr>
										<th>Attività</th>
										<th className="text-right">Ore</th>
										<th className="text-right">Giorni</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="nowrap">Esportate al cliente</td>
										<td className="text-right">{thousandSeparator(props.data.OrderWorkingDays * 8)}</td>
										<td className="text-right">{thousandSeparator(props.data.OrderWorkingDays)}</td>
									</tr>
									<tr>
										<td className="nowrap">Pianificate</td>
										<td className="text-right">{thousandSeparator(props.data.WorkingDays * 8)}</td>
										<td className="text-right">{thousandSeparator(props.data.WorkingDays)}</td>
									</tr>
									<tr>
										<td className="nowrap">Pianificate deliverables</td>
										<td className="text-right">{thousandSeparator(subOrdersWorkingDays * 8)}</td>
										<td className="text-right">{thousandSeparator(subOrdersWorkingDays)}</td>
									</tr>
									<tr>
										<td className="nowrap">Pianificate fasi</td>
										<td className="text-right">{thousandSeparator(stepsWorkingWorkingHours)}</td>
										<td className="text-right">{hoursToDays(stepsWorkingWorkingHours)}</td>
									</tr>
									<tr>
										<td className="nowrap">Pianificate profili</td>
										<td className="text-right">{thousandSeparator(profilesWorkingHours)}</td>
										<td className="text-right">{hoursToDays(profilesWorkingHours)}</td>
									</tr>
									<tr>
										<td className="nowrap">Assegnate</td>
										<td className="text-right">{thousandSeparator(profilesAssignedWorkingHours)}</td>
										<td className="text-right">{hoursToDays(profilesAssignedWorkingHours)}</td>
									</tr>
									<tr>
										<td className="nowrap">Lavorate</td>
										<td className="text-right">{workedHours}</td>
										<td className="text-right">{workedDays}</td>
									</tr>
									<OrderWorkedPercentageTableRow
										OrderWorkedDays={parseFloat(workedDays)}
										OrderWorkingDays={props.data.WorkingDays}
									/>
								</tbody>
							</table>
							<table className="table table-sm">
								<thead>
									<tr>
										<th>Reperibilità</th>
										<th className="text-right">Giorni</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="nowrap">Preventivo</td>
										<td className="text-right">{props.data.AvailableDays}</td>
									</tr>
									{
										props.data.UsersAvailableDays ?
											props.data.UsersAvailableDays.map(function (u, i) {
												return <tr>
													<td className="nowrap pl-3">{u.FirstName + " " + u.LastName}</td>
													<td className="text-right">{u.AvailableDys}</td>
												</tr>
											}
											)
											:
											""
									}
									<tr>
										<td className="nowrap">Totale</td>
										<td className="text-right">{props.data.AvailableWorkedDays}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="w-80">
							{
								props.data.SubOrders ?
									props.data.SubOrders.map(function (item, i) {
										return <SubOrderReport data={item} collapsed={collapsed} />;
									}
									)
									:
									""
							}
						</div>
					</div>
				</div>
				:
				""
			}
		</div >
	);
}