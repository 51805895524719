import React, { useContext, MouseEvent, useState, useEffect, ChangeEvent, FocusEvent } from "react";
import { SubOrderStepProfile, SelectListItem, SelectItemsListTypes } from "../../../models/models";
import { Maincontext } from "../../maincontext";
import SelectItemServices from "../../../api/SelectItemServices";
import { selectFilter, getItemByValue } from "../../../core/Utilities";
import Select from "react-select";
import { Modal, Button, Popover, Alert } from "react-bootstrap";//Icone
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import SubOrderStepProfileServices from "../../../api/SubOrderStepProfileServices";
import Info from "../../common/Info";
import SaveButton from "../../common/SaveButton";

export interface ISubOrderStepProfileDetilProps {
	subOrderStepProfileID: number;
	subOrderStepWorkingHours: number;
	subOrderStepProfilesWorkingHours: number;
	subOrderStepID: number;
	subOrderName: string;
	activityLineID: number;
	activityLineName: string;
	activityStepName: string;
}

export default function SubOrderStepProfileDetail(props: ISubOrderStepProfileDetilProps) {
	const context = useContext(Maincontext);
	const SVC = new SubOrderStepProfileServices();
	const ISVC = new SelectItemServices();

	const subOrderStepProfileDef: SubOrderStepProfile = {
		SubOrderStepProfileID: 0,
		SubOrderStepID: 0,
		ProfileTypeID: 0,
		WorkingHours: props.subOrderStepProfilesWorkingHours,
		CreationDate: new Date(),
		ProfileTypeName: ""
	};

	const [subOrderStepProfile, setSubOrderStepProfile] = useState<SubOrderStepProfile>(subOrderStepProfileDef);
	const [profileTypeList, setProfileTypeList] = useState<SelectListItem[]>([]);
	const [selectedProfileType, setSelectedProfileType] = useState<SelectListItem | undefined>(undefined);
	const [showPopover, setShowPopover] = useState(false);
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
	const [hiddenDetail, setHiddenDetail] = useState<boolean>(true);
	const [maxHours, setMaxHours] = useState<number>(0);

	useEffect(() => {

		setContext();

	}, [subOrderStepProfile]);

	useEffect(() => {

		if (context.showSubOrderStepProfileDetail) {

			setHiddenDetail(true);
			setSaveEnabled(false);
			setAlertMessage("");
			setShowPopover(false);
			const max = props.subOrderStepWorkingHours - props.subOrderStepProfilesWorkingHours;
			setMaxHours(max);

			setSubOrderStepProfile({
				...subOrderStepProfile,
				SubOrderStepID: props.subOrderStepID,
				SubOrderStepProfileID: props.subOrderStepProfileID,
				WorkingHours: max
			});

			subOrderStepProfile.SubOrderStepID = props.subOrderStepID;
			subOrderStepProfile.SubOrderStepProfileID = props.subOrderStepProfileID;
			subOrderStepProfile.WorkingHours = max;

			if (props.subOrderStepProfileID === 0) {
				GetProfileTypesList();
			}
			else {
				GetItem();
			}

		}

	}, [context.showSubOrderStepProfileDetail]);


	function GetProfileTypesList() {

		ISVC.getSubOrderStepProfileTypeItemList(props.subOrderStepID).then((l) => {
			setHiddenDetail(true);
			if (l.data.length > 0) {
				setProfileTypeList(l.data);
				if (props.subOrderStepProfileID === 0) { //new item
					setSelectedProfileType(l.data[0]);
					const profileTypeID: number = Number(l.data[0].Value);
					setSubOrderStepProfile({ ...subOrderStepProfile, ProfileTypeID: profileTypeID });
					setHiddenDetail(false);
				}
				else {
					context.setContext({
						...context,
						showSubOrderStepProfileUserDetail: false,
						showAlert: true,
						messaggioAlert: "Nessun profilo è disponibile.",
						titoloAlert: "Attenzione!"
					});
				}
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento PROFILE_TYPES: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function GetItem() {
		SVC.getSubOrderStepProfile(props.subOrderStepProfileID).then((l) => {
			if (l.data) {
				setSubOrderStepProfile(l.data);
				subOrderStepProfile.SubOrderStepID = l.data.SubOrderStepID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento SubOrderStepProfile: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			setHiddenDetail(false);
		});
	}

	function Save(e: MouseEvent<HTMLButtonElement>) {
		setShowPopover(false);
		SVC.SubOrderStepProfileUpdate(subOrderStepProfile).then((d) => {
			if (d !== undefined && d.data !== "") {
				setAlertMessage(d.data);
			}
			else {
				closeDialog();
				context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
			}
		}).catch((e: React.SetStateAction<string>) => {
			setAlertMessage("Errore del server");
		}).finally(() => {

		});
	};

	//validazione del form
	const isValid = (): boolean => {
		return subOrderStepProfile.ProfileTypeID !== 0
			&& subOrderStepProfile.SubOrderStepID !== 0
			&& subOrderStepProfile.WorkingHours > 0;
	};

	function setContext() {

		const valid = isValid();
		setSaveEnabled(valid);
	}

	function setProfileTypeID(v: any, i: any) {
		const profileTypeID: number = Number(v.Value);
		setSelectedProfileType(v);
		subOrderStepProfile.ProfileTypeID = profileTypeID;
		//setContext();
	}

	function setWorkingHours(e: ChangeEvent<HTMLInputElement>) {
		let hours: number = Number(e.target.value);
		if (hours > maxHours) {
			hours = maxHours;
		}
		setSubOrderStepProfile({
			...subOrderStepProfile,
			WorkingHours: hours
		});
		//setContext();
	}

	function setWorkingDays(e: ChangeEvent<HTMLInputElement>) {
		let days: number = Number(e.target.value);
		if (days > maxHours / 8) {
			days = maxHours / 8;
		}
		const hours: number = days * 8;
		setSubOrderStepProfile({
			...subOrderStepProfile,
			WorkingHours: hours
		});
		//setContext();
	}
	//sul double click viene impostato il valore max consentito 
	//e inviato al contenitore con la funzione onChange
	function onDoubleClick(e: MouseEvent<HTMLInputElement>) {

		const hours: number = props.subOrderStepWorkingHours
			- props.subOrderStepProfilesWorkingHours;

		setSubOrderStepProfile({
			...subOrderStepProfile,
			WorkingHours: hours
		});

		//setContext();
	}

	function onFocus(e: FocusEvent<HTMLInputElement>) {
		e.target.select();
	}

	const closeDialog = () => {
		context.setContext({ ...context, showSubOrderStepProfileDetail: false });
		setSaveEnabled(false);
		setAlertMessage("");
		setShowPopover(false);
		context.showSubOrderStepProfileDetail = false;
	};


	const closePopover = () => {
		setShowPopover(false);
	};

	const popover = (
		<Popover id="popover-basic" className={showPopover ? "" : "d-none"}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				Salvare i dati?<br /><br />
				<Button variant="outline-secondary" className="btn-sm" onClick={closePopover}><Icon icon={ic_close} /></Button>
         		&nbsp;
          		<Button variant="outline-primary" className="btn-sm" onClick={Save}><Icon icon={ic_check} /></Button>
			</Popover.Content>
		</Popover>
	);

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={"lg"}
				className={hiddenDetail ? "d-none" : ""}
				show={context.showSubOrderStepProfileDetail}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title className="fluid">

						<div className="form-row">
							<div className="col-7">

								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<td className="bg-light">Deliverable</td>
											<td className="p-1">{props.subOrderName}</td>
										</tr>
										<tr>
											<td className="bg-light">Linea di attività</td>
											<td className="p-1">{props.activityLineName}</td>
										</tr>
										<tr>
											<td className="bg-light">Fase di attività</td>
											<td className="p-1">{props.activityStepName}</td>
										</tr>
										<tr>
											<td className="bg-light">Profilo</td>
											<td className="p-1">{props.subOrderStepProfileID === 0 ?
												"Nuovo profilo" : subOrderStepProfile.ProfileTypeName}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-5 text-rigth">
								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<th className="bg-light">Descrizione</th>
											<th className="text-right p-1 bg-light">Giornate</th>
											<th className="text-right p-1 bg-light">Ore</th>
										</tr>
										<tr>
											<td>Pianificate fase
											<Info
													color="secondary"
													placement="auto"
													text="Totale delle ore pianificate per la fase"
												/>
											</td>
											<td className="text-right p-1">
												{props.subOrderStepWorkingHours / 8}
											</td>
											<td className="text-right p-1">
												{props.subOrderStepWorkingHours}
											</td>
										</tr>
										<tr>
											<td>Pianificate profili
											<Info
													color="secondary"
													placement="auto"
													text="Pianificate per altri profili escluso quello corrente"
												/>
											</td>
											<td className="text-right p-1">
												{props.subOrderStepProfilesWorkingHours / 8}
											</td>
											<td className="text-right p-1">
												{props.subOrderStepProfilesWorkingHours}
											</td>
										</tr>
										<tr>
											<td>Disponibili
											<Info
													color="secondary"
													placement="auto"
													text="Non ancora pianificate per altri profili"
												/>
											</td>
											<td className="text-right p-1">
												{(props.subOrderStepWorkingHours
													- props.subOrderStepProfilesWorkingHours) / 8}
											</td>
											<td className="text-right p-1">
												{(props.subOrderStepWorkingHours
													- props.subOrderStepProfilesWorkingHours)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-row">
						<div className="form-group col">
							{props.subOrderStepProfileID === 0 ?
								<div>
									<label className="form-label">Profilo professionale</label>
									<Select
										filterOption={selectFilter}
										value={selectedProfileType}
										options={profileTypeList}
										isSearchable
										onChange={setProfileTypeID}
										getOptionLabel={c => c.Text}
										getOptionValue={c => c.Value}
									/>
								</div>
								:
								<h5>{subOrderStepProfile.ProfileTypeName}</h5>
							}
						</div>
					</div>
					<div className="form-row">

						<div className="form-group col-3">
							<label className="form-label">Giornate di lavoro</label>
							<Info
								placement={"right"}
								text={"Il numero massimo di giornate assegnabile è " + maxHours / 8}
							/>
							<input
								type="number"
								min="0"
								max={maxHours / 8}
								step="1"
								className="form-control"
								name="WorkingDays"
								onChange={setWorkingDays}
								onFocus={onFocus}
								onDoubleClick={onDoubleClick}
								value={subOrderStepProfile.WorkingHours / 8}
							/>
						</div>
						<div className="form-group col-3">
							<label className="form-label">Ore di lavoro</label>
							<Info
								placement={"right"}
								text={"Il numero massimo di ore assegnabile è "
									+ maxHours}
							/>
							<input type="number"
								min="0"
								max={maxHours}
								step="1"
								className="form-control"
								name="WorkingHours"
								onChange={setWorkingHours}
								onFocus={onFocus}
								onDoubleClick={onDoubleClick}
								value={subOrderStepProfile.WorkingHours}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Alert variant={alertMessage === "" ? undefined : "warning"}>
						{alertMessage}
					</Alert>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => closeDialog()}>
						Annulla
					</Button>
					<SaveButton
						disabled={!saveEnabled}
						popover={popover}
						popoverFunction={setShowPopover}
						saveFunction={Save}
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
}