import CommonServices from "./CommonServices";
import axios from "axios";
import { ValueForItem } from "../models/charts";

const SVC = new CommonServices();

export default class ChartsServices {

	//Users per centro di costo
	async GetCostCentersUsersCount() {
		return axios.get<ValueForItem[]>(`${await SVC.getApiBaseUri()}/api/Charts/GetCostCentersUsersCount`, { headers: await SVC.setAzureAuth() });
	}
	//Users per profilo
	async GetProfileTypesUsersCount() {
		return axios.get<ValueForItem[]>(`${await SVC.getApiBaseUri()}/api/Charts/GetProfileTypesUsersCount`, { headers: await SVC.setAzureAuth() });
	}
	//Users per linea di attività
	async GetActivityLinesUsersCount() {
		return axios.get<ValueForItem[]>(`${await SVC.getApiBaseUri()}/api/Charts/GetActivityLinesUsersCount`, { headers: await SVC.setAzureAuth() });
	}
}