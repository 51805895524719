import React, { useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import "./../../styles/signature.css";

export default function Test() {

	const [imageURL, setImageURL] = useState<string>("");
	const sigCanvas = useRef<SignaturePad>(null);

	const clear = () => {
		if (sigCanvas.current)
			sigCanvas.current.clear();
	}

	const save = () => {
		if (sigCanvas.current) {
			const imgUrl: string = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
			clear();
			setImageURL(imgUrl);
		}
	}

	return (
		<div className="sig-container">
			<h1>Esempio firma</h1>
			<SignaturePad
				ref={sigCanvas}
				penColor="red"
				canvasProps={{ className: 'sig-canvas' }}
			/>
			<button className="btn btn-secondary mr-2" onClick={clear}>CANCELLA</button>
			<button className="btn btn-primary ml-2" onClick={save}>SALVA</button>

			{imageURL != "" ? (
				<img
					src={imageURL}
					alt="my signature"
					style={{
						display: "block",
						margin: "32px auto",
						border: "1px solid black",
						width: "100%"
					}}
				/>
			) : null}
		</div>
	);
}