import React, { useContext, useEffect, useState } from "react";
import { hoursToDays } from "../../../../../core/Utilities";
import { ActivityByDescription, UserAssignedHour } from "../../../../../models/reports";
import SubOrderReport from "./SubOrderReport";

export interface IOrderActivityReportt {
	descriptionsData: ActivityByDescription[],
	usersData: UserAssignedHour[]
}

export default function OrderActivityReport(props: IOrderActivityReportt) {
	const [workedHours, setWorkedHours] = useState<number>(0);

	useEffect(() => {

		SetComputedHours();

	}, [props.usersData]);

	async function SetComputedHours(){

		let wHours: number = 0;

		if (props.usersData) {
			props.usersData.forEach(ud => {
				if (ud && ud.UserID) {
					wHours += ud.WorkingHours
				}
			})
		}
		setWorkedHours(wHours);
	}

	return (
		<div className="Container">
			<div className="form-row">
				<div className="col-3 pr-3">
					<table className="table table-sm">
						<thead>
							<tr>
								<th>Attività svolte</th>
								<th className="text-right">Ore</th>
								<th className="text-right">Giorni</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Totale attività svolte</td>
								<td className="text-right">{workedHours}</td>
								<td className="text-right">{hoursToDays(workedHours)}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="col-3 pr-3">
					<table className="table table-sm">
						<thead>
							<tr>
								<th>Attività per causale</th>
								<th className="text-right">Ore</th>
								<th className="text-right">Giorni</th>
							</tr>
						</thead>
						<tbody>
							{
								props.descriptionsData ?
									props.descriptionsData.map(function (item, i) {
										return <tr>
											<td>{item.ActivityDescriptionName}</td>
											<td className="text-right">{item.WorkingHours}</td>
											<td className="text-right">{hoursToDays(item.WorkingHours)}</td>
										</tr>
									}
									)
									:
									''
							}
						</tbody>
					</table>
				</div>
				<div className="col-3 pr-3">
					<table className="table table-sm">
						<thead>
							<tr>
								<th>Attività per risorsa</th>
								<th className="text-right">Ore</th>
								<th className="text-right">Giorni</th>
							</tr>
						</thead>
						<tbody>
							{
								props.usersData ?
									props.usersData.map(function (item, i) {
										return <tr>
											<td>{item.FirstName + ' ' + item.LastName}</td>
											<td className="text-right">{item.WorkingHours}</td>
											<td className="text-right">{hoursToDays(item.WorkingHours)}</td>
										</tr>
									}
									)
									:
									''
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
