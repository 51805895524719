import React, { createContext, useState, Dispatch, SetStateAction } from "react";
import { SelectedObjectTypes, User, MenuList } from "../models/models";

type Props = {
	children: React.ReactNode;
};

type Context = {
	showAlert: boolean;
	messaggioAlert: string;
	titoloAlert: string;
	showAttesa: boolean;
	token: string;
	showLogin: boolean;
	showDetail: boolean;
	hiddenDetail: boolean;
	saveEnabled: boolean;
	objSelected?: any;
	objTypeSelected?: SelectedObjectTypes;
	language: string;
	parentID: number;
	objectName: string;
	refreshList: boolean;
	selectedMenu: MenuList;
	panelSize?: "sm" | "lg" | "xl" | undefined;
	panelReadonly?: boolean;
	showSubOrderStepDetail: boolean;
	showSubOrderStepProfileDetail: boolean;
	showSubOrderStepProfileUserDetail: boolean;
	showOverTimeDetail: boolean;
	showUserDayTimeSheet: boolean;
	showFastAssignment: boolean;
	showUserPreferencestepDetail: boolean;
	setContext: Dispatch<SetStateAction<Context>>;
};

const initialContext: Context = {
	showAlert: false,
	messaggioAlert: "",
	titoloAlert: "",
	showAttesa: false,
	token: "",
	showLogin: false,
	showDetail: false,
	hiddenDetail: false,
	saveEnabled: false,
	language: "it-IT",
	parentID: 0,
	objectName: "",
	refreshList: false,
	selectedMenu: MenuList.UNDEFINED,
	panelSize: "lg",
	panelReadonly: false,
	showSubOrderStepDetail: false,
	showSubOrderStepProfileDetail: false,
	showSubOrderStepProfileUserDetail: false,
	showOverTimeDetail: false,
	showUserDayTimeSheet: false,
	showFastAssignment: false,
	showUserPreferencestepDetail: false,
	setContext: (): void => {
		throw new Error("setContext function must be overridden");
	},
};

export const Maincontext = createContext(initialContext);

export const MainProvider = ({ children }: Props): JSX.Element => {
	const [contextState, setContext] = useState<Context>(initialContext);
	return (
		<Maincontext.Provider value={{ ...contextState, setContext }}>
			{children}
		</Maincontext.Provider>
	);
};

type UserContext = {
	Me?: User;
	setContext: Dispatch<SetStateAction<UserContext>>;
};

const initialUserContext: UserContext = {
	Me: undefined,
	setContext: (): void => {
		throw new Error("setContext function must be overridden");
	}
};

export const UserContext = createContext(initialUserContext);

export const UserProvider = ({ children }: Props): JSX.Element => {
	const [userContextState, setContext] = useState<UserContext>(initialUserContext);
	return (
		<UserContext.Provider value={{ ...userContextState, setContext }}>
			{children}
		</UserContext.Provider>
	);
};