import React, { useEffect, useState } from "react";
import { hoursToDays, minutesToHours } from "../../../../../core/Utilities";
import { SubOrderStep } from "../../../../../models/reports";
import OrderWorkedPercentageTableRow from "./OrderWorkedPercentage";
import SubOrderStepProfileReport from "./SubOrderStepProfileReport";

export interface ISubOrderStepReport {
	data: SubOrderStep
}

export default function SubOrderStepReport(props: ISubOrderStepReport) {
	const [workedHours, setWorkedHours] = useState<string>("");
	const [workedDays, setWorkedDays] = useState<string>("");
	const [profilesWorkingHours, setProfilesWorkingHours] = useState<number>(0);
	const [stepAssignedWorkingHours, setStepAssignedWorkingHours] = useState<number>(0);

	useEffect(() => {

		SetComputedHours();

	}, [props.data]);

	async function SetComputedHours() {

		let minutes: number = 0;
		let prWorkingHours: number = 0;
		let asWorkingHours: number = 0;

		if (props.data) {
			if (props.data && props.data.SubOrderStepProfiles) {
				props.data.SubOrderStepProfiles.forEach(prof => {
					prWorkingHours += prof.WorkingHours ? prof.WorkingHours : 0;
					if (prof && prof.UserTimesheets) {
						prof.UserTimesheets.forEach(ts => {
							if (ts.UserID) {
								minutes += ts.Minutes;
								minutes += ts.OvertimeMinutes;
								minutes += ts.OvertimeNightMinutes;
							}
						})
					}

					if (prof && prof.UserAssignedHours) {
						prof.UserAssignedHours.forEach(ts => {
							if (ts.UserID) {
								asWorkingHours += ts.WorkingHours;
							}
						})
					}
				})
			}
		}
		setProfilesWorkingHours(prWorkingHours);
		setStepAssignedWorkingHours(asWorkingHours);
		setWorkedHours(minutesToHours(minutes));
		setWorkedDays(hoursToDays(minutes/ 60));
	}

	return (
		<div className="Container">
			{props.data && props.data.SubOrderStepID ?
				<div>
					<div className="mt-1 d-flex w-100">
						<div className="bg-light pl-1 mb-2 w-100">
							<label className="m-0 small">Fase</label>
							<h5 className="m-0">{props.data.ActivityStepName}</h5>
						</div>
					</div>

					<div className="w-100 d-flex">
						<div className="w-33 pr-3">
							<table className="table table-sm">
								<thead>
									<tr>
										<th>Attività</th>
										<th className="text-right">Ore</th>
										<th className="text-right">Giorni</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="nowrap">Pianificate</td>
										<td className="text-right">{props.data.WorkingHours}</td>
										<td className="text-right">{hoursToDays(props.data.WorkingHours)}</td>
									</tr>
									<tr>
										<td className="nowrap">Pianificate profili</td>
										<td className="text-right">{profilesWorkingHours}</td>
										<td className="text-right">{hoursToDays(profilesWorkingHours)}</td>
									</tr>
									<tr>
										<td className="nowrap">Assegnate</td>
										<td className="text-right">{stepAssignedWorkingHours}</td>
										<td className="text-right">{hoursToDays(stepAssignedWorkingHours)}</td>
									</tr>
									<tr>
										<td className="nowrap">Lavorate</td>
										<td className="text-right">{workedHours}</td>
										<td className="text-right">{workedDays}</td>
									</tr>
									<OrderWorkedPercentageTableRow 
										OrderWorkedDays={parseFloat(workedDays)} 
										OrderWorkingDays={parseFloat(hoursToDays(props.data.WorkingHours))}
									/>
								</tbody>
							</table>
						</div>
						<div className="w-66">
							{
							props.data.SubOrderStepProfiles?
							props.data.SubOrderStepProfiles.map(function (item, i) {
								return <SubOrderStepProfileReport data={item} />
							}
							)
							:
							''
							}

						</div>
					</div>
				</div>
				:
				''
			}
		</div>
	);
}