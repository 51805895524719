/*
	componente per la visualizzazione del SubOrder in modalità editing
	è composto da tre parti:
	SubOrderComponent, per la visualizzazione dei dati del SubOrder,
	implementa il tasto di editing e di ritorno alla lista dei SubOrder

	pannellino di riepilogo delle ore assegnate (tabella)

	componente SubOrderList, treeview con i tre livelli di assegnazione

	il componente legge il parametro id nella route (SubOrderID) e carica i dati
*/
import React, { useContext, useEffect, useState } from "react";
import SubOrderComponent from "./components/SubOrderComponent";
import { useParams } from "react-router-dom";
import { SubOrder } from "../../../models/models";
import SubOrderServices from "../../../api/SubOrderServices";
import { Maincontext } from "../../maincontext";
import TitleBar from "../../common/TitleBar";

import SubOrderStepList from "../SubOrderStep/SubOrderStepList";
import { hoursToDays } from "../../../core/Utilities";

export interface ISubOrderProps {

}

export default function SubOrderView(props: ISubOrderProps) {
	const context = useContext(Maincontext);
	let params: any = useParams();
	const subOrderID: number = Number(params.id);
	const SVC = new SubOrderServices();

	const subOrderDef: SubOrder = {
		SubOrderID: 0,
		OrderID: 0,
		UserID: 0,
		OrderUserID: 0,
		ActivityLineID: 0,
		OrderCode: "",
		OrderName: "",
		SubOrderName: "",
		SubOrderNote: "",
		WorkingDays: 0,
		CustomerName: "",
		Internal: false,
		ActivityLineName: "",
		Manager: "",
		StartDate: new Date(),
		EndDate: new Date(),
		OrderStartDate: new Date(),
		OrderEndDate: new Date(),
		CreationDate: new Date(),
		StepsWorkingHours: 0,
		ProfilesWorkingHours: 0,
		AssignedWorkingHours: 0
	};

	const [subOrder, setSubOrder] = useState<SubOrder>(subOrderDef);

	useEffect(() => {
		GetSubOrder();
	}, []);


	useEffect(() => {
		//sul salvataggio del subOrder scatta refreshList
		//lo utilizziamo per aggiornare i componenti
		GetSubOrder();
	}, [context.refreshList]);

	function GetSubOrder() {
		SVC.getSubOrder(subOrderID).then((d) => {
			if (d.data) {
				setSubOrder(d.data);
			}
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true,
				messaggioAlert: "Errore di caricamento SubOrder: " + e,
				titoloAlert: "Errore"
			});
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}

	function StepHoursClass() {
		let cl: string = "text-right p-1";
		if ((subOrder.WorkingDays * 8) > subOrder.StepsWorkingHours) {
			cl += " bg-warning";
		}
		return cl;
	}
	function ProfileHoursClass() {
		let cl: string = "text-right p-1";
		if ((subOrder.WorkingDays * 8) > subOrder.ProfilesWorkingHours) {
			cl += " bg-warning";
		}
		return cl;
	}
	function AssignedHoursClass() {
		let cl: string = "text-right p-1";
		if ((subOrder.WorkingDays * 8) > subOrder.AssignedWorkingHours) {
			cl += " bg-warning";
		}
		return cl;
	}

	return (
		<div>
			{/* bread crumb */}
			<TitleBar title={subOrder === undefined ? "" : subOrder.SubOrderName} links={[{ title: "Deliverables", to: "/suborders" }]} />
			<div className="p-2">
				<div className="form-row">
					<div className="form-group col-xl-6 col-lg-8 col-md-12">
						<SubOrderComponent subOrder={subOrder} />
					</div>
					<div className="form-group col-xl-2 col-lg-4">
						<div className="control-header">
							<table className="table  table-sm table-bordered">
								<tbody>
									<tr>
										<th className="bg-light">Descrizione</th>
										<th className="bg-light text-right">Giorni</th>
										<th className="bg-light text-right">Ore</th>
									</tr>
									<tr>
										<td>{subOrder.SubOrderName}</td>
										<td className="text-right p-1">{subOrder.WorkingDays}</td>
										<td className="text-right p-1">{subOrder.WorkingDays * 8}</td>
									</tr>
									<tr>
										<td>Fasi</td>
										<td className={StepHoursClass()}
										>{hoursToDays(subOrder.StepsWorkingHours)}</td>
										<td className={StepHoursClass()}
										>{subOrder.StepsWorkingHours}</td>
									</tr>
									<tr>
										<td>Profili</td>
										<td
											className={ProfileHoursClass()}>
											{hoursToDays(subOrder.ProfilesWorkingHours)}</td>
										<td
											className={ProfileHoursClass()}>
											{subOrder.ProfilesWorkingHours}</td>
									</tr>
									<tr>
										<td>Assegnate</td>
										<td
											className={AssignedHoursClass()}>
											{hoursToDays(subOrder.AssignedWorkingHours)}</td>
										<td
											className={AssignedHoursClass()}>
											{subOrder.AssignedWorkingHours}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="form-group col-4"></div>
				</div>
				<div className="form-row">
					<div className="form-group col-xl-8 col-md-12">
						<SubOrderStepList subOrder={subOrder} />
					</div>
					<div className="form-group col-4"></div>
				</div>
			</div>
		</div>
	);
}
