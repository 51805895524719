import React, { useContext, useState, useEffect, ChangeEvent } from "react";
import { useForm } from "react-hook-form"
import { UserContext, Maincontext } from "../maincontext";
import { MultiSelectItemsListTypes, SelectListItem } from "../../models/models";
import { MultiSelect } from "../common/MultiSelect";
import SelectItemServices from "../../api/SelectItemServices";

export interface IUserAuthorizationsList {
}

export default function UserAuthorizationsList(props: IUserAuthorizationsList) {
	const context = useContext(Maincontext);
	const userInfo = useContext(UserContext);
	const { handleSubmit } = useForm();
	const userID = context.objSelected.UserID as number;
	const ISVC = new SelectItemServices();

	const [authorizationsList, setAuthorizationsList] = useState<SelectListItem[] | []>([]);
	useEffect(() => {
		GetList();
	}, []);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		ISVC.getMlutiSelectItemList(MultiSelectItemsListTypes.USER_AUTHORIZATION_TYPES, userID).then((d) => {
			if (d.data) {
				setAuthorizationsList(d.data);
				context.parentID = userID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento USER_AUTHORIZATION_TYPES: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
		context.objectName = context.objSelected.FullName;
	}

	const isValid = (): boolean => {
		return true;
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = authorizationsList;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setChecked(e: ChangeEvent<HTMLInputElement>) {

		const checked: boolean = Boolean(e.target.checked);
		const profileTypeID = e.currentTarget.getAttribute("data-id");
		authorizationsList.forEach(function (item: SelectListItem) {

			if (item.Value === profileTypeID) {
				if (item.Disabled && !userInfo.Me?.SuperAdmin) {
					//alert('Non autorizzato');
					e.target.checked = !checked;
					e.target.disabled = true;
				}
				else {
					item.Selected = checked;
				}
			}
		});

		setContext();

	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<MultiSelect items={authorizationsList} onChange={setChecked} />
					</div>
				</div>
			</form>
		</div>
	);
}

