/*
	componente per i button che richiedono conferma
	il componente è a scelta obbligatoria,
	quando cliccato compare un pannello semitrasparente che disabilita tutti
	gli altri componenti nella pagina
*/
import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import { Placement } from "./../../../node_modules/react-bootstrap/Overlay";

export interface IConfirmButtonProps {
	key?: string;
	title?: string; //titolo del popover
	className?: string;
	confirmMessage?: string; //messaggio da visualizzare nella richiesta di conferma
	icon: any;//React.ComponentType<IconProp>;
	placement: Placement; //posizionamento del componente
	confirmFunction(e: MouseEvent<HTMLButtonElement>): void; //funzione invocata se conferma ok
	toggleModal(): void;
	titleButton?: boolean;
}

export function ConfirmButton(props: IConfirmButtonProps) {
	
	const [popoverVisible, setPopoverVisibility] = useState(false);

	const mainPopover = useRef(null);

	const triggerButton = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		setPopoverVisibility(false);		
	}, []);

	const closePopover = () => {
		if (triggerButton.current) {
			const b: any = triggerButton.current.children[0];
			b.click();
			document.body.click();
		}		
	};

	const showPopover = () => {		
		setPopoverVisibility(true);
		props.toggleModal();
	};
	
	const confirm = (e: MouseEvent<HTMLButtonElement>) => {
		props.confirmFunction(e);
		closePopover();
	};

	const popover = (			
		<Popover id="popover-basic" className="front-position" ref={mainPopover}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				{props.confirmMessage === undefined ? "COnferma" : props.confirmMessage}<br /><br />
				<Button variant="outline-secondary" className="btn-sm" onClick={closePopover} title="Annulla"><Icon icon={ic_close} /></Button>
				<Button variant="outline-primary" className="btn-sm ml-2" onClick={confirm} title="Esegui"><Icon icon={ic_check} /></Button>
			</Popover.Content>
		</Popover>
	);

	return (		
		<span ref={triggerButton}>
			<OverlayTrigger trigger="click" placement={props.placement} overlay={popover} >
				<Button
					key={props.key}
					title={props.title}
					variant="link"
					className={props.className}
					onClick={() => showPopover()}
				>
					{props.icon}
					{props.titleButton && props.title ? " " + props.title : ""}
				</Button>
			</OverlayTrigger>
		</span>
	);
}