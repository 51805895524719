import axios from "axios";
import { authProvider } from "../authProvider";

//metodi comuni a tutti i servizi
export default class CommonServices {

	async setAzureAuth() {
		//return { "Authorization": "bearer " + authProvider.getAccountInfo()?.jwtIdToken };
		const token = sessionStorage.getItem("msal.idtoken") || (await authProvider.getIdToken()).idToken.rawIdToken;
		return { "Authorization": "bearer " + token };
	}

	async getApiBaseUri() {

		//base_uri scritta nel codice
		return "https://ts20-web-api.azurewebsites.net";

		//return "https://localhost:44304";
		// if ((window as any).base_uri === undefined) {
		// 	const base_uri = ((await (axios.get("/config.json"))).data).base_uri;
		// 	(window as any).base_uri = base_uri;
		// 	return base_uri;	
		// } else {
		// 	return (window as any).base_uri;
		// }
	}

}