import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext, UserContext } from "../maincontext";
import Lista from "../common/List";
import OrderServices from "../../api/OrderServices";
import { Order, columnDetail, SelectedObjectTypes, MenuList, ContentTypes, DataColumnTypes } from "../../models/models";
import OrderDetail from "./OrderDetail";
import OrderSubOrdersList from "./OrderSubOrdersList";
import { IsAuthorized } from "../../core/Utilities";

import TitleBar from "../common/TitleBar";
import SubOrderDetail from "./SubOrder/SubOrderDetail";

export interface IOrderListProps {
	expired?: boolean
}

export default function OrdersList(props: IOrderListProps) {
	const context = useContext(Maincontext);
	const userInfo = useContext(UserContext);
	const SVC = new OrderServices();
	const [list, setList] = useState<Order[]>([]);
	const orders_authorized: boolean = IsAuthorized(ContentTypes.ORDERS);
	const rpt_authorized: boolean = IsAuthorized(ContentTypes.REPORTS);

	//Dichiarazione della colonna di dettaglio che determina se una colonna viene visualizzata e in che posizione se non viene passato viene fatto il rendering dell"oggetto
	const columnDetails: columnDetail[] = [
		{ name: "Internal", text: "Interna", size: 80, position: 1 },
		{ name: "OrderCode", text: "Codice", size: 80, position: 2 },
		{ name: "SalesForceID", text: "Sales Force", size: 96, position: 3 },
		{ name: "OrderName", text: "Commessa", position: 4 },
		{ name: "CustomerName", text: "Cliente", position: 5 },
		{ name: "Manager", text: "Responsabile", size: 112, position: 6 },
		{ name: "SubOrdersCount", text: "Deliverables", size: 104, position: 7, type: DataColumnTypes.NUMBER },
		{ name: "WorkingDays", text: "Giornate", size: 96, position: 8, type: DataColumnTypes.NUMBER },
		{ name: "StartDate", text: "Data inizio", size: 96, position: 9, type: DataColumnTypes.DATE },
		{ name: "EndDate", text: "Data fine", size: 96, position: 10, type: DataColumnTypes.DATE },
	];

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList, props.expired]);


	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getOrders(props.expired).then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Orders: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: props.expired === true ? MenuList.EXPIRED_ORDERS : MenuList.ORDERS, });
		});
	}

	function onDelete(e: MouseEvent<HTMLButtonElement>) {
		const Order: Order = context.objSelected;

		SVC.OrderDelete(Order).then((d) => {
			if (d.data !== "") {
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione commessa";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	function isAddDeliverableHidden(o: Order) {
		return o.UserID != userInfo.Me!.UserID;
	}

	function isDeleteOrderHidden(o: Order) {
		return !orders_authorized;
	}


	return (
		<div>
			<TitleBar title={props.expired === true ? "Commesse scadute" : "Commesse attive"} />
			<Lista obj={list} details={[
				{
					detail: <OrderDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.ORDER,
					panelSize: "lg"
				},
				{
					detail: <OrderSubOrdersList />,
					button: "list",
					titoloDettaglio: "Deliverables",
					panelReadonly: true,
					objTypeSelected: SelectedObjectTypes.ORDER,
					panelSize: "lg"
				},
				{
					detail: <SubOrderDetail new={true} />,
					button: "add",
					titoloDettaglio: "Aggiungi deliverable",
					objTypeSelected: SelectedObjectTypes.ORDER,
					panelSize: "lg",
					isHidden: isAddDeliverableHidden
				},
				{
					detail: <OrderDetail />,
					button: "delete",
					titoloDettaglio: "Elimina commessa",
					objTypeSelected: SelectedObjectTypes.ORDER,
					isFunction: true,
					onClick: onDelete,
					confirm: true,
					confirmMessage: "Eliminare la Commessa?",
					isHidden: isDeleteOrderHidden
				},
				{
					detail: <OrderDetail />,
					button: "list",
					titoloDettaglio: "Visualizza report",
					objTypeSelected: SelectedObjectTypes.ORDER,
					isFunction: true,
					onClick: (e: MouseEvent<HTMLButtonElement>) => {
						const Order: Order = context.objSelected;
						window.open(`/reports/order/${Order.OrderID}?toolbar=no`);
					}
				},
				{
					detail: <SubOrderDetail />,
					button: "chart",
					titoloDettaglio: "Grafico incrementale",
					objTypeSelected: SelectedObjectTypes.ORDER,
					isFunction: true,
					onClick: (e: MouseEvent<HTMLButtonElement>) => {
						const Order: Order = context.objSelected;
						window.open(`/reports/OrderDailyWorkedHours/${Order.OrderID}?toolbar=no`);
					}
				},
				{
					detail: <SubOrderDetail />,
					button: "chart",
					titoloDettaglio: "Grafico minuti per risorsa",
					objTypeSelected: SelectedObjectTypes.ORDER,
					isFunction: true,
					onClick: (e: MouseEvent<HTMLButtonElement>) => {
						const Order: Order = context.objSelected;
						window.open(`/reports/OrderUsersWorkedHours/${Order.OrderID}`);
					}
				}
			]}
				columnDetails={columnDetails}
				addButton={orders_authorized}
				addElement={<OrderDetail />}
				addType={SelectedObjectTypes.ORDER}
				addPanelSize="lg"
				detailPosition={3}
			/>
		</div>
	);
}

