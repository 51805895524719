import { locale } from "moment";
import React, { MouseEvent, useContext, useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_swap_horiz } from 'react-icons-kit/md/ic_swap_horiz'
import { ic_done } from "react-icons-kit/md/ic_done";
import { ic_thumb_up } from "react-icons-kit/md/ic_thumb_up";
import { ic_thumb_down } from "react-icons-kit/md/ic_thumb_down";
import { ic_create } from "react-icons-kit/md/ic_create";
import { ic_undo } from "react-icons-kit/md/ic_undo";
import { ic_redo } from "react-icons-kit/md/ic_redo";
import { ic_gavel } from "react-icons-kit/md/ic_gavel";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import { useParams } from "react-router-dom";
import TimeSheetServices from "../../api/TimeSheetServices";
import { formatDateToString, Log, minutesToHours } from "../../core/Utilities";
import { MONTHS } from "../../models/locale";
import { DayTimeSheetTypes, HistoricalTimeSheetMonthState, MenuList, MonthStates, UserTimeSheetMonth } from "../../models/models";
import TitleBar from "../common/TitleBar";
import { Maincontext } from "../maincontext";
import DayTimeSheetDetail from "./YearlyTimesheet/DayTimeSheetDetail";
import { parseCommandLine } from "typescript";

export default function MonthTimeSheet() {

	const context = useContext(Maincontext);
	const SVC = new TimeSheetServices();
	const toDay: string = new Date().toDateString();
	let params: any = useParams();

	const [userMonthTimeSheet, setUserMonthTimeSheet] = useState<UserTimeSheetMonth | undefined>();
	const [HistoricalTSMonthState, setHistoricalTSMonthState] = useState<HistoricalTimeSheetMonthState[]>();

	const [clickedDay, setClickedDay] = useState<Date>(new Date());
	const [clickedDayReadOnly, setClickedDayReadOnly] = useState<boolean>(false);
	const [timesheetMonthComplete, setTimesheetMonthComplete] = useState<boolean>(false);
	const [year, setYear] = useState<number>(Number(params.year));
	const [month, setMont] = useState<number>(Number(params.month));
	const [title, setTitle] = useState<string>("");
	const [operationType, setOperationType] = useState<MonthStates>();
	const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
	const [userID, setUserID] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// (document.querySelector(".menu-laterale") as HTMLElement).style.display = "none";
	// (document.querySelector(".navbar-nav") as HTMLElement).style.display = "none";	
	// (document.querySelector(".principale") as HTMLElement).style.marginLeft = "0";	

	useEffect(() => {
		context.setContext({ ...context, selectedMenu: MenuList.TIMESHEET });
		if (params.id) {
			CheckIsAuthorized();
		}
	}, []);

	useEffect(() => {
		if (month > 0 && year > 0) {
			setTitle(MONTHS["it"][month - 1] + " " + year.toString());
			GetUserTimeSheetMonth();
		}
		else if (isAuthorized && params.id) {
			GetUserTimeSheetMonth();
		}
	}, [month, year, context.refreshList, isAuthorized]);

	// useEffect(() => {
	// 	if (isAuthorized) {
	// 		GetUserTimeSheetMonth();
	// 	}
	// }, [isAuthorized]);

	const dateClass = (type: number): string => {

		switch (type) {
			case DayTimeSheetTypes.EMPTY:
				return "empty"
			case DayTimeSheetTypes.TOO_FEW:
				return "too-few";
			case DayTimeSheetTypes.EXACT:
				return "exact";
			case DayTimeSheetTypes.TOO_MANY:
				return "too-many";
			default:
				return "";
		}
	}

	function CheckIsAuthorized() {
		//verifica se l'utente ha il permesso
		//e se il ts è di almeno due mesi prima
		SVC.TimeSheetMonthAuthorized(params.id).then((d) => {
			setIsAuthorized(d.data);
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di verifica autorizzazione: " + e, titoloAlert: "Errore" });
			setIsAuthorized(false);
		}).finally(() => {

		});
	}

	function GetUserTimeSheetMonth() {
		if (!isLoading) {
			setIsLoading(true);
			if (params.id) {

				SVC.GetMonthTimesheetFromID(params.id).then((d) => {
					setUserMonthTimeSheet(d.data);
					if (d.data.TimeSheetMonthID && d.data.TimeSheetMonthID !== 0) {
						setTitle(d.data.UserFullName + " - " + MONTHS["it"][d.data.Month - 1] + " " + d.data.Year.toString());
						setUserID(d.data.UserID);
						setYear(d.data.Year);
						setMont(d.data.Month);
						GetHistoricalMonthState(d.data.TimeSheetMonthID);
					}
					setIsLoading(false);
				}).catch((e) => {
					setIsLoading(false);
					context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento TimeSheet: " + e, titoloAlert: "Errore" });
				}).finally(() => {

				});

			}
			else {
				SVC.GetUserMonthTimesheet(year, month).then((d) => {
					setUserMonthTimeSheet(d.data);
					if (d.data.TimeSheetMonthID && d.data.TimeSheetMonthID !== 0) {
						GetHistoricalMonthState(d.data.TimeSheetMonthID);
					}
					setIsLoading(false);
				}).catch((e) => {
					setIsLoading(false);
					context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento TimeSheet: " + e, titoloAlert: "Errore" });
				}).finally(() => {

				});
			}
		}
	}

	function GetHistoricalMonthState(TimeSheetMonthID: number) {
		SVC.getHistoricalMonthState(TimeSheetMonthID).then((result) => {
			setHistoricalTSMonthState(result.data);
		});
	}

	function onDayClick(day: Date) {

		setClickedDay(day);
		setClickedDayReadOnly(false);
		if (
			userMonthTimeSheet!.TimeSheetMonthStateID === MonthStates.RICHIESTA_APPROVAZIONE
			||
			userMonthTimeSheet!.TimeSheetMonthStateID === MonthStates.APPROVATO
		) {
			setClickedDayReadOnly(true);
		}
		else {
			setClickedDayReadOnly(false);
		}
		context.setContext({ ...context, showUserDayTimeSheet: true });
	}

	function ChangeMonthState(e: MouseEvent<HTMLButtonElement>) {

		const timeSheetMonthID: number = Number(e.currentTarget.getAttribute("data-id"));
		const note: string = ""; //TODO
		const state: MonthStates = Number(e.currentTarget.getAttribute("data-state"));

		if (userID == 0) {
			SVC.TimeSheetMonthStateChange(timeSheetMonthID, note, state).then((d) => {

				if (d.data == "") {
					GetUserTimeSheetMonth();
				}
				else {
					context.setContext({
						...context,
						showAlert: true,
						messaggioAlert: "Errore salvataggio: " + d.data,
						titoloAlert: "Attenzione!"
					});
				}
			}).catch((e) => {
				context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di salvataggio: " + e, titoloAlert: "Errore" });
			}).finally(() => {
				document.body.click();
			});
		}
		else {
			SVC.TimeSheetMonthSendToApprover(timeSheetMonthID, note, state, userID).then((d) => {
				if (d.data == "") {
					GetUserTimeSheetMonth();
				}
				else {
					context.setContext({
						...context,
						showAlert: true,
						messaggioAlert: "Errore salvataggio: " + d.data,
						titoloAlert: "Attenzione!"
					});
				}
			}).catch((e) => {
				context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di salvataggio: " + e, titoloAlert: "Errore" });
			}).finally(() => {
				document.body.click();
			});
		}
	}

	const setHistoricalTsMonthStateIcon = (currentMonthStateID: MonthStates, historicalMonthStateID: MonthStates) => {
		switch (currentMonthStateID) {
			case MonthStates.COMPILAZIONE:
				if (historicalMonthStateID === MonthStates.COMPILAZIONE)
					return ic_create;
				else return ic_done;
			case MonthStates.RICHIESTA_APPROVAZIONE:
				if (historicalMonthStateID === MonthStates.RICHIESTA_APPROVAZIONE)
					return ic_gavel;
				else return ic_done;
			case MonthStates.RITIRATO:
				if (historicalMonthStateID === MonthStates.RITIRATO)
					return ic_create;
				else return ic_done;
			case MonthStates.RIFIUTATO:
				return ic_thumb_down;
			case MonthStates.APPROVATO:
				return ic_thumb_up;
			default:
				return ic_done;
		}
	};

	function sendApprover() {
		const userID: number = params.id ? Number(params.id) : 0;
		SVC.IsTimesheetMonthComplete(year, month, userID).then((d) => {
			if (d.data) {
				setTimesheetMonthComplete(true);
				setOperationType(MonthStates.RICHIESTA_APPROVAZIONE)
			}
			else {
				setTimesheetMonthComplete(false);
				setOperationType(MonthStates.RICHIESTA_APPROVAZIONE)
			}
		})

	}
	return (
		<>
			<div className={isAuthorized ? "" : "d-none"}>
				{params.id ?
					<TitleBar links={[{ title: "Timesheet in corso", to: "/workingtimesheets" }]} title={title} />
					:
					<TitleBar links={[{ title: "Archivio timesheet", to: "/timesheet" }]} title={title} />
				}

				<div className="Container p-3">
					<div className="row">
						<div className="col-10">
							<h2>{title}</h2>
							Stato: {
								userMonthTimeSheet && userMonthTimeSheet.TimeSheetMonthStateDescription ?
									userMonthTimeSheet.TimeSheetMonthStateDescription
									: "Da compilare"}
							{userMonthTimeSheet && userMonthTimeSheet.TimeSheetMonthStateID ?
								<OverlayTrigger
									key={`ot_${month}`}
									rootClose
									trigger="click"
									placement="bottom"
									// onExiting={() => { if (historyBoxRef.current) historyBoxRef.current.scrollTop = 0; }}
									onEntering={() => { setOperationType(undefined); }}
									onEntered={() => {
										let el = document.querySelector(".history");
										if (el) el.scrollTop = el.scrollHeight;
									}}
									overlay={
										<Popover key={`ga_${month}`} id={`ga_${month}`} style={{
											maxWidth: "100%"
										}}>
											<Popover.Title as="h3" style={{
												fontSize: "14px", margin: "8px", border: "none", borderRadius: "4px"
											}}>
												Gestione approvazione
											</Popover.Title>
											<Popover.Content style={{ fontSize: "12px", minHeight: "100px" }}>
												{
													HistoricalTSMonthState
														? HistoricalTSMonthState.length > 0
															? <div>
																<div
																	className="history"
																	style={{
																		maxHeight: "250px", paddingRight: "10px", overflow: "auto"
																	}}>
																	{
																		HistoricalTSMonthState.map((tsMonthState) => {
																			return (
																				<div key={`hmsb-${tsMonthState.HistoricalTimeSheetMonthStateID}`} className="d-flex align-items-center mb-3">
																					<div key={`hmsib-${tsMonthState.HistoricalTimeSheetMonthStateID}`} className="pr-1">
																						<Icon key={`hmsi-${tsMonthState.HistoricalTimeSheetMonthStateID}`}
																							icon={
																								setHistoricalTsMonthStateIcon(
																									userMonthTimeSheet.TimeSheetMonthStateID,
																									tsMonthState.TimeSheetMonthStateID
																								)
																							}
																							size={24}
																						/>
																					</div>
																					<div>
																						{formatDateToString(tsMonthState.CreationDate)}
																						&nbsp;
																						<b className="pl-1">{tsMonthState.TimeSheetMonthStateDescription}</b>
																						{tsMonthState.TimeSheetMonthStateID === MonthStates.RIFIUTATO && (
																							<>
																								<br />
																								da <strong>{tsMonthState.ApproverFullName}</strong>
																								<br />
																								<i>{tsMonthState.Note}</i>
																							</>
																						)}
																						{tsMonthState.TimeSheetMonthStateID === MonthStates.RICHIESTA_APPROVAZIONE && (
																							<>
																								<br />
																								Approvatore <b>{tsMonthState.ApproverFullName}</b>
																							</>
																						)}
																					</div>
																				</div>
																			);
																		})
																	}
																</div>
																<hr></hr>
																<div className="d-flex align-items-center mt-3">
																	{userMonthTimeSheet.TimeSheetMonthStateID === MonthStates.RICHIESTA_APPROVAZIONE && (
																		<>
																			<button onClick={() => setOperationType(MonthStates.RITIRATO)} className="btn btn-primary w-100">
																				<Icon icon={ic_redo} size={24} /> Ritira dall'approvazione
																			</button>
																		</>
																	)}
																	{
																		(
																			userMonthTimeSheet.TimeSheetMonthStateID === MonthStates.COMPILAZIONE ||
																			userMonthTimeSheet.TimeSheetMonthStateID === MonthStates.RITIRATO ||
																			userMonthTimeSheet.TimeSheetMonthStateID === MonthStates.RIFIUTATO
																		) && (
																			<>
																				<button onClick={() => sendApprover()} className="btn btn-primary w-100">
																					<Icon icon={ic_undo} size={24} /> Invia all'approvazione
																				</button>
																			</>
																		)}
																</div>
																<div className={`bg-light mt-3 p-2 rounded ${!operationType ? "d-none" : ""}`}>
																	<div className="pb-2">
																		<span className={timesheetMonthComplete ? "d-none" : ""}><b>Attenzione</b><br />
																			il timesheet non sembra compilato correttamente.
																		</span><br />
																		<b>Confermi {operationType === MonthStates.RICHIESTA_APPROVAZIONE ?
																			"l'invio all'approvazione" :
																			"il ritiro dall'approvazione"}?
																		</b>
																	</div>
																	<Button
																		variant="outline-secondary"
																		className="btn-sm"
																		onClick={() => setOperationType(undefined)}
																	>
																		<Icon icon={ic_close} />
																	</Button>
																	<Button
																		variant="outline-primary"
																		className="btn-sm ml-2"
																		data-id={userMonthTimeSheet.TimeSheetMonthID}
																		data-state={operationType}
																		onClick={ChangeMonthState}
																	>
																		<Icon icon={ic_check} />
																	</Button>
																</div>
															</div>
															: <div>Nessun dato presente.</div>
														: <div>Caricamento dati...</div>
												}
											</Popover.Content>
										</Popover>
									}>
									<button className="btn btn-light btn-sm ml-3" >
										<Icon icon={ic_swap_horiz} size="24" className="mr-1" />Gestisci approvazione</button>
								</OverlayTrigger>
								: ""
							}
						</div>
					</div>
					<table className="table table-sm table-bordered table-timesheet">
						<tbody>
							<tr>
								<td className="name pt-3" style={{ "border": "none" }}>Descrizione</td>
								{userMonthTimeSheet && userMonthTimeSheet.WorkingDates ?
									userMonthTimeSheet.WorkingDates.map(function (item, i) {
										return (
											<td className={item.IsHolidayOrWeekEnd ? "holiday" : "border-0"}>
												<div
													className={dateClass(item.DayTimeSheetType)}
													onClick={() => onDayClick(new Date(item.WorkingDate))}>
													{new Date(item.WorkingDate).getDate()}
												</div>
											</td>);
									})
									:
									""
								}
								<td className="total">Tot.</td>
							</tr>
							{userMonthTimeSheet && userMonthTimeSheet.Orders ?
								userMonthTimeSheet.Orders.map(function (item, i) {
									return (<tr>
										<td className="name" title={item.CustomerCode + ' ' + item.CustomerName}>
											<span>Commessa</span><br />
											{item.OrderName}
										</td>

										{item.WorkedDates ?
											item.WorkedDates.map(function (wd, i) {
												return (
													<td className={
														new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
													}>
														{
															wd.Value == 0 ? ""
																:
																minutesToHours(wd.Value)
														}
													</td>
												);
											})
											:
											''
										}
										<td className="total">
											{minutesToHours(item.TotalMinutes)}
										</td>
									</tr>
									);
								})
								:
								''
							}

							{userMonthTimeSheet && userMonthTimeSheet.Permits ?
								userMonthTimeSheet.Permits.map(function (item, i) {
									return (<tr>
										<td className="name">
											<span>Permesso</span><br />{item.PermitTypeDescription}
										</td>

										{item.WorkedDates ?
											item.WorkedDates.map(function (wd, i) {
												return (
													<td className={
														new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
													}>
														{
															wd.Value == 0 ? ""
																:
																minutesToHours(wd.Value)
														}
													</td>
												);
											})
											:
											''
										}
										<td className="total">
											{minutesToHours(item.TotalMinutes)}
										</td>
									</tr>
									);
								})
								:
								''
							}

							{userMonthTimeSheet && userMonthTimeSheet.FullDayPermits ?
								userMonthTimeSheet.FullDayPermits.map(function (item, i) {
									return (<tr>
										<td className="name">
											<span>Assenze</span><br />{item.PermitTypeDescription}
										</td>

										{item.WorkedDates ?
											item.WorkedDates.map(function (wd, i) {
												return (
													<td className={
														new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
													}>
														{
															wd.Value == 0 ? ""
																:
																"x"
														}
													</td>
												);
											})
											:
											''
										}
										<td className="total">
											{item.TotalDays}
										</td>
									</tr>
									);
								})
								:
								''
							}

							{userMonthTimeSheet && userMonthTimeSheet.Availables ?
								userMonthTimeSheet.Availables.map(function (item, i) {
									return (<tr>
										<td className="name">
											<span>Reperibile</span><br />{item.OrderName}
										</td>

										{item.WorkedDates ?
											item.WorkedDates.map(function (wd, i) {
												return (
													<td className={
														new Date(wd.WorkingDate).toDateString() == toDay ? "value today" : "value"
													}>
														{
															wd.Value == 0 ? ""
																:
																"x"
														}
													</td>
												);
											})
											:
											''
										}
										<td className="total">
											{item.TotalDays}
										</td>
									</tr>
									);
								})
								:
								''
							}

						</tbody>
					</table>

					<div className="text-right">
						<table className="table table-sm table-bordered float-right" style={{ "width": "180px" }}>
							<tbody>
								<tr>
									<td className="bg-light">Ore ordinarie</td>
									<td className="text-right">{minutesToHours(
										userMonthTimeSheet && userMonthTimeSheet.Minutes ?
											userMonthTimeSheet.Minutes : 0)}</td>
								</tr>
								<tr>
									<td className="bg-light">Straordinari</td>
									<td className="text-right">{minutesToHours(
										userMonthTimeSheet && userMonthTimeSheet.OvertimeMinutes ?
											userMonthTimeSheet.OvertimeMinutes : 0)}</td>
								</tr>
								<tr>
									<td className="bg-light">Straordinari notturni</td>
									<td className="text-right">{minutesToHours(
										userMonthTimeSheet && userMonthTimeSheet.OvertimeNightMinutes ?
											userMonthTimeSheet.OvertimeNightMinutes : 0)}</td>
								</tr>
								<tr>
									<td className="bg-light">Permessi</td>
									<td className="text-right">{minutesToHours(
										userMonthTimeSheet && userMonthTimeSheet.PermitMinutes ?
											userMonthTimeSheet.PermitMinutes : 0)}</td>
								</tr>
								<tr>
									<td className="bg-light">Giorni di assenza</td>
									<td>{
										userMonthTimeSheet && userMonthTimeSheet.FullDayPermitsCount ?
											userMonthTimeSheet.FullDayPermitsCount : 0}</td>
								</tr>
								<tr>
									<td className="bg-light">Giorni di reperibilità</td>
									<td>{
										userMonthTimeSheet && userMonthTimeSheet.AvailablesCount ?
											userMonthTimeSheet.AvailablesCount : 0}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<DayTimeSheetDetail userID={userID} timeSheetDate={clickedDay} readonly={clickedDayReadOnly} />
			</div>
			<div className={isAuthorized ? "d-none" : ""}>
				<TitleBar links={[{ title: "Timesheet in corso", to: "/workingtimesheets" }]} title="Utente non autorizzato" />
				<div>
					<p style={{ padding: "40px", fontWeight: "bold" }}>
						Utente non autorizzato
					</p>
				</div>
			</div>
		</>
	);
}