import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import ActivityLinesServices from "../../api/ActivityLinesServices";
import { ActivityLine, columnDetail, SelectedObjectTypes, MenuList, DataColumnTypes } from "../../models/models";
import ActivityLineDetail from "./ActivityLineDetail";
import TitleBar from "../common/TitleBar";
import ActivityLineProfilesList from "./ActivityLineProfilesList";

export interface IActivityLinesList {
}

export default function ActivityLinesList(props: IActivityLinesList) {
	const context = useContext(Maincontext);
	const SVC = new ActivityLinesServices();

	//lista linee attività, aggiornata con setList da useState
	const [list, setList] = useState<ActivityLine[]>([]);

	//Colonne da visualizzare nella lista
	const columnDetails: columnDetail[] = [
		{ name: "ActivityLineName", text: "Linea attività", position: 1 },
		{ name: "Enabled", text: "Abilitato", position: 2, size: 160 },
		{ name: "CreationDate", text: "Data Creazione", position: 3, type: DataColumnTypes.DATE },
	];

	//caricamento iniziale della lista
	/*     useEffect(() => {
			GetList();
		}, []); */

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList]);

	//carica i dati dal servizio e aggiorna lo stato della lista
	function GetList() {

		//context.setContext({ ...context, showAttesa: true });
		SVC.getActivityLinesList().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Activity Lines List: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			//chiude il loading panel e imposta la voce selezionata nel menu della sidebar
			context.setContext({ ...context, /*showAttesa: false,*/ selectedMenu: MenuList.ACTIVITY_LINES, });
		});
	}

	//funzione per l'eliminaizone di una voce dalla lista
	//nel caso il servizio restituisce una stringa, viene visulaizzata
	//in un alert
	function onDelete(e: MouseEvent<HTMLButtonElement>) {

		const activityLine: ActivityLine = context.objSelected;

		SVC.ActivityLineDelete(activityLine).then((d) => {

			if (d.data !== "") {
				//visualizzazione del messaggio se restituito dal servizio
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione linea attività";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			//chiusura loading panel e aggiornamento della lista
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			{/* bread crumb */}
			<TitleBar title="Linee attività" />
			{/* impostazione della lista */}
			<Lista obj={list} details={[
				//tasti
				{
					// tasto edit
					detail: <ActivityLineDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.ACTIVITY_LINE
				},
				{
					detail: <ActivityLineProfilesList />, button: "profile",
					titoloDettaglio: "Profili",
					panelSize: "lg",
					objTypeSelected: SelectedObjectTypes.ACTIVITY_LINE_PROFILE_TYPE
				},
				{
					//tasto elimina, di tipo funzione (esegue una funzione e non apre un dettaglio)
					//richiede conferma
					detail: <ActivityLineDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.ACTIVITY_LINE,
					isFunction: true, 	//di tipo funzione (esegue una funzione e non apre un dettaglio)
					confirm: true,		//richiede conferma
					confirmMessage: "Eliminare la linea di attività?",
					onClick: onDelete	//funzione da eseguire al click del tasto
				}
			]}
			//impostazione generale della lista
			columnDetails={columnDetails} 		//colonne da visualizzare
			addButton={true}					//tasto Aggiungi visualizzato
			addElement={<ActivityLineDetail />}	//elemento da utilizzare per il tasto Aggiungi
			addType={SelectedObjectTypes.ACTIVITY_LINE} //tipo di oggetto gestito
			addPanelSize={undefined}	//dimensioni del pannello di aggiunta elemento
			detailPosition={1}
			/>
		</div>
	);
}