import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import ProfileTypeServices from "../../api/ProfileTypeServices";
import { ProfileType, columnDetail, SelectedObjectTypes, MenuList, DataColumnTypes } from "../../models/models";
import ProfileTypeDetail from "./ProfileTypeDetail";
import TitleBar from "../common/TitleBar";

export interface IProfileTypesList {
}

export default function ProfileTypesList(props: IProfileTypesList) {
	const context = useContext(Maincontext);
	const SVC = new ProfileTypeServices();

	//lista di profili, aggiornata con setList da useState
	const [list, setList] = useState<ProfileType[]>([]);

	//Colonne da visualizzare nella lista
	const columnDetails: columnDetail[] = [
		{ name: "ProfileTypeName", text: "Profilo", position: 1 },
		{ name: "Enabled", text: "Abilitato", position: 2, size: 160 },
		{ name: "CreationDate", text: "Data Creazione", position: 3, type: DataColumnTypes.DATE },
	];

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList]);

	//carica i dati dal servizio e aggiorna lo stato della lista
	function GetList() {

		//context.setContext({ ...context, showAttesa: true });
		SVC.getProfileTypesList().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Profile Types List: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			//chiude il loading panel e imposta la voce selezionata nel menu della sidebar
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: MenuList.PROFILE_TYPES, });
		});
	}

	//funzione per l'eliminaizone di una voce dalla lista
	//nel caso il servizio restituisce una stringa, viene visulaizzata
	//in un alert
	function onDelete(e: MouseEvent<HTMLButtonElement>) {

		const profileType: ProfileType = context.objSelected;

		SVC.ProfileTypeDelete(profileType).then((d) => {

			if (d.data !== "") {
				//visualizzazione del messaggio se restituito dal servizio
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione Profilo";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			//chiusura loading panel e aggiornamento della lista
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			{/* bread crumb */}
			<TitleBar title="Profili professionali" />
			{/* impostazione della lista */}
			<Lista obj={list} details={[
				//tasti
				{
					// tasto edit
					detail: <ProfileTypeDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.PROFILE_TYPE
				},
				{
					//tasto elimina, di tipo funzione (esegue una funzione e non apre un dettaglio)
					//richiede conferma
					detail: <ProfileTypeDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.PROFILE_TYPE,
					isFunction: true, 	//di tipo funzione (esegue una funzione e non apre un dettaglio)
					confirm: true,		//richiede conferma
					confirmMessage: "Eliminare il profilo?",
					onClick: onDelete	//funzione da eseguire al click del tasto
				}
			]}
			//impostazione generale della lista
			columnDetails={columnDetails} 		//colonne da visualizzare
			addButton={true}					//tasto Aggiungi visualizzato
			addElement={<ProfileTypeDetail />}	//elemento da utilizzare per il tasto Aggiungi
			addType={SelectedObjectTypes.PROFILE_TYPE} //tipo di oggetto gestito
			addPanelSize={undefined}	//dimensioni del pannello di aggiunta elemento
			detailPosition={1}
			/>
		</div>
	);
}

