import React, { useState, useEffect, useContext, useRef, MouseEvent } from "react";
import { MenuList, TimeSheetApprovingMonth, MonthStates, HistoricalTimeSheetMonthState } from "../../models/models";
import TimesheetServices from "../../api/TimeSheetServices";
import TitleBar from "../common/TitleBar";
import { Maincontext } from "../maincontext";
import { minutesToHours, formatDateToString } from "../../core/Utilities";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_history } from "react-icons-kit/md/ic_history";
import { ic_gavel } from "react-icons-kit/md/ic_gavel";
import { ic_done } from "react-icons-kit/md/ic_done";
import { ic_thumb_up } from "react-icons-kit/md/ic_thumb_up";
import { ic_thumb_down } from "react-icons-kit/md/ic_thumb_down";
import { ic_create } from "react-icons-kit/md/ic_create";
import { ic_undo } from "react-icons-kit/md/ic_undo";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import "../../styles/approving-timesheet.css";

export interface IApprovingTimesheets {
}

export default function ApprovingTimesheets(props: IApprovingTimesheets) {
	const context = useContext(Maincontext);
	const svc = new TimesheetServices();
	const [timeSheetApprovingMonths, setTimeSheetApprovingMonths] = useState<TimeSheetApprovingMonth[]>([]);
	const [HistoricalTSMonthState, setHistoricalTSMonthState] = useState<HistoricalTimeSheetMonthState[]>();

	const textAreaNotesRef = useRef<HTMLTextAreaElement>(null);

	const [operationType, setOperationType] = useState<MonthStates>();

	useEffect(() => {
		context.setContext({ ...context, selectedMenu: MenuList.APPROVAL_REQUEST });
		GetTimeSheetApprovingMonths();
	}, []);

	function GetTimeSheetApprovingMonths() {
		svc.GetTimeSheetApprovingMonths().then((d) => {
			setTimeSheetApprovingMonths(d.data);
		});
	}

	const getHistoricalTimesheetMonthState = (timeSheetMonthID: number) => {
		setHistoricalTSMonthState(undefined);
		svc.getHistoricalMonthState(timeSheetMonthID).then((result) => {
			setHistoricalTSMonthState(result.data);
			let el = document.querySelector(".history");
			if (el) el.scrollTop = el.scrollHeight;
		});
	};

	const setHistoricalTsMonthStateIcon = (currentMonthStateID: MonthStates, historicalMonthStateID: MonthStates) => {
		switch (currentMonthStateID) {
			case MonthStates.COMPILAZIONE:
				if (historicalMonthStateID === MonthStates.COMPILAZIONE) return ic_create;
				else return ic_done;
			case MonthStates.RICHIESTA_APPROVAZIONE:
				if (historicalMonthStateID === MonthStates.RICHIESTA_APPROVAZIONE) return ic_gavel;
				else return ic_done;
			case MonthStates.RITIRATO:
				if (historicalMonthStateID === MonthStates.RITIRATO) return ic_create;
				else return ic_done;
			case MonthStates.RIFIUTATO:
				return ic_thumb_down;
			case MonthStates.APPROVATO:
				return ic_thumb_up;
			default:
				return ic_done;
		}
	};

	function ChangeMonthState(timeSheetMonthID: number, state: MonthStates, note: string | undefined) {

		svc.TimeSheetMonthStateChange(timeSheetMonthID, note ? note : "", state).then((d) => {

			if (d.data == "") {
				GetTimeSheetApprovingMonths();
			}
			else {
				context.setContext({
					...context,
					showAlert: true,
					messaggioAlert: "Errore salvataggio: " + d.data,
					titoloAlert: "Attenzione!"
				});
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di salvataggio: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	return (
		<>
			<TitleBar title="Richieste approvazione" />
			<div className="p-3">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>
								Risorsa
							</th>
							<th className="text-center">
								Anno
							</th>
							<th className="text-center">
								Mese
							</th>
							<th className="text-center">
								Ore ord.
							</th>
							<th className="text-center">
								Ore str.
							</th>
							<th className="text-center">
								Str. nott.
							</th>
							<th className="text-center">
								Permessi
							</th>
							<th className="text-center">
								Tot. ore
							</th>
							<th className="text-center">
								Assenze
							</th>
						</tr>
					</thead>
					<tbody>
						{timeSheetApprovingMonths.map(function (item: TimeSheetApprovingMonth) {
							return (
								<tr className="ts-resource-row">
									<td>
										<div className="d-inline-flex justify-content-between w-100 align-items-center">
											<div className="fullname" title={`${item.UserFullName}`}>{item.UserFullName}</div>
											<div className="ts-approving-tools d-inline-flex">
												<OverlayTrigger
													rootClose
													trigger="click"
													placement="auto-start"
													onEntering={() => { setOperationType(undefined); getHistoricalTimesheetMonthState(item.TimeSheetMonthID); }}
													overlay={
														<Popover id={`ol_${item.TimeSheetMonthID}`} style={{ maxWidth: "400px", minWidth: "280px" }}>
															<Popover.Title as="h3" style={{
																fontSize: "14px", margin: "8px",
																border: "none", borderRadius: "4px"
															}}>
																<strong>{item.UserFullName}</strong>
																<br />
																Approvazione {item.TimeSheetMonthName} {item.TimeSheetYear.toString()}
															</Popover.Title>
															<Popover.Content style={{ fontSize: "12px", minHeight: "100px", maxWidth: "400px" }}>
																{
																	HistoricalTSMonthState
																		? HistoricalTSMonthState.length > 0
																			? <div>
																				<div className="history" style={{ maxHeight: "200px", paddingRight: "10px", overflow: "auto" }}>
																					{
																						HistoricalTSMonthState.map((tsMonthState) => {
																							return (
																								<div key={`histMonthStateBox-${tsMonthState.HistoricalTimeSheetMonthStateID}`} className="d-flex align-items-center mb-3">
																									<div key={`histMonthStateIconBox-${tsMonthState.HistoricalTimeSheetMonthStateID}`} className="pr-1">
																										<Icon key={`histMonthStateIcon-${tsMonthState.HistoricalTimeSheetMonthStateID}`}
																											icon={
																												setHistoricalTsMonthStateIcon(
																													MonthStates.RICHIESTA_APPROVAZIONE,
																													tsMonthState.TimeSheetMonthStateID
																												)
																											}
																											size={24}
																										/>
																									</div>
																									<div>
																										{formatDateToString(tsMonthState.CreationDate)}
																										&nbsp;
																										<b className="pl-1">{tsMonthState.TimeSheetMonthStateDescription}</b>
																										{tsMonthState.TimeSheetMonthStateID === MonthStates.RIFIUTATO && (
																											<>
																												<br />
																												<i>{tsMonthState.Note}</i>
																											</>
																										)}
																										{tsMonthState.TimeSheetMonthStateID === MonthStates.RICHIESTA_APPROVAZIONE && (
																											<>
																												<br />
																												Approvatore <b>{tsMonthState.ApproverFullName}</b>
																											</>
																										)}
																									</div>
																								</div>
																							);
																						})
																					}
																				</div>
																				<hr></hr>
																				<div className="mt-3">
																					<textarea
																						className="form-control"
																						style={{ resize: "none" }}
																						name="note"
																						id={`note_${item.UserID}`}
																						rows={3}
																						placeholder="Note..."
																						ref={textAreaNotesRef}
																					>
																					</textarea>
																					<div className="d-flex align-items-center justify-content-around mt-3 mb-2">
																						<button
																							disabled={operationType && operationType !== MonthStates.RIFIUTATO}
																							className="btn btn-danger mr-3 w-100"
																							onClick={() => setOperationType(MonthStates.RIFIUTATO)}
																						>
																							<Icon icon={ic_undo} size={24} /> Rifiuta
																						</button>
																						<button
																							disabled={operationType && operationType !== MonthStates.APPROVATO}
																							className="btn btn-info w-100"
																							onClick={() => setOperationType(MonthStates.APPROVATO)}
																						>
																							<Icon icon={ic_gavel} size={24} /> Approva
																						</button>
																					</div>
																					<div className={`bg-light p-2 rounded ${!operationType ? "d-none" : ""}`}>
																						<div className="pb-2">
																							<b>Confermi {operationType === MonthStates.RIFIUTATO ? "il rifiuto" : "l'approvazione"}?</b>
																						</div>
																						<Button
																							variant="outline-secondary"
																							className="btn-sm"
																							onClick={() => setOperationType(undefined)}
																						>
																							<Icon icon={ic_close} />
																						</Button>
																						<Button
																							variant="outline-primary"
																							className="btn-sm ml-2"
																							onClick={
																								() => operationType && ChangeMonthState(
																									item.TimeSheetMonthID,
																									operationType,
																									textAreaNotesRef.current?.value
																								)
																							}
																						>
																							<Icon icon={ic_check} />
																						</Button>
																					</div>
																				</div>
																			</div>
																			: <div>Nessun dato presente.</div>
																		: <div>Caricamento dati...</div>
																}
															</Popover.Content>
														</Popover>
													}
												>
													<button className="btn btn-link btn-sm p-0" title="Approva/Rifiuta...">
														<Icon icon={ic_gavel} size={24} />
													</button>
												</OverlayTrigger>
												<a className="btn btn-link btn-sm p-0" title="Riepilogo attività svolte"
													href={`/reports/monthtimesheet/${item.TimeSheetMonthID.toString()}`}
												>
													<Icon icon={ic_history} size={24} />
												</a>
											</div>
										</div>
									</td>
									<td className="text-center">{item.TimeSheetYear}</td>
									<td className="text-center">{item.TimeSheetMonthName}</td>
									<td className="text-center">{minutesToHours(item.Minutes)}</td>
									<td className="text-center">{minutesToHours(item.OvertimeMinutes)}</td>
									<td className="text-center">{minutesToHours(item.OvertimeNightMinutes)}</td>
									<td className="text-center">{minutesToHours(item.Permits)}</td>
									<td className="text-center">{minutesToHours(item.TotalMinutes)}</td>
									<td className="text-center">{item.Absences}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
}