import React, { useState, useEffect, useContext, useRef, ChangeEvent } from "react";
import { MenuList, SelectListItem, SelectItemsListTypes, UserMonthlyCost } from "../../models/models";
import ReportServices from "../../api/ReportServices";
import SelectItemServices from "../../api/SelectItemServices";
import TitleBar from "../common/TitleBar";
import { Maincontext } from "../maincontext";
import { ListToArray, selectFilter, thousandSeparator, thousandSeparatorDecimal } from "../../core/Utilities";

//esportazione report
import PdfDocument from '../common/pdf/PdfDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";

import { MONTHS } from "../../models/locale";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";

// icone
import Icon from 'react-icons-kit';
import { filePdf } from 'react-icons-kit/icomoon/filePdf';
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel';
import { fileText2 } from 'react-icons-kit/icomoon/fileText2';

import { CustomerUserMonthlyCost, ReportColumn } from "../../models/reports";

export interface ICustomersMonthlyCost {
}

export default function CustomersMonthlyCost(props: ICustomersMonthlyCost) {

	const context = useContext(Maincontext);
	const svc = new ReportServices();
	const isvc = new SelectItemServices();

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	const icon_size: number = 22;

	const report_tile: string = "CostoMensile";

	const columns: ReportColumn[] = [
		{ columnName: 'Cliente', columnValue: 'CustomerName', columnWidth: 4 },
		{ columnName: 'Risorsa', columnValue: 'UserFullName', columnWidth: 2 },
		{ columnName: 'Ore totali', columnValue: 'WorkedHours', columnWidth: 1, textAlign: 'right' },
	]

	const [rows, setRows] = useState<any[][]>([]);

	const [customerUsersCostList, setCustomerUsersCostList] = useState<CustomerUserMonthlyCost[]>([]);
	const [yearsList, setYearsList] = useState<SelectListItem[]>([]);
	const [monthList, setMonthList] = useState<SelectListItem[]>([]);
	const [customerList, setCustomerList] = useState<SelectListItem[]>([]);

	const [selectedYear, setSelectedYear] = useState<SelectListItem>();
	const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();
	const [selectedCustomer, setSelectedCustomer] = useState<SelectListItem>();

	const [totalHours, SetTotalHours] = useState<number>(0);

	const d = new Date();
	const curYear: number = d.getFullYear();
	const curMonth: number = d.getMonth();


	const [sheeName, setSheetName] = useState<string>("New sheet");

	useEffect(() => {
		context.setContext({ ...context, selectedMenu: MenuList.COMMERCIAL_CUSTOMERS_MONTHLY_COST });
		MonthListInitialize();
		YearListInitialize();
		GetCustomerList(curYear, curMonth);
		GetRptMonthlyCustomerUsersCost(curYear, curMonth, 0);
	}, []);


	useEffect(() => {

		const total: number = customerUsersCostList.reduce((total, current) => total + current.WorkedHours, 0);
		SetTotalHours(total);

		//esportazione in pdf commentata perchè va in errore quando si modifica un filtro
		// let r: any[][] = [];

		// let cloned = customerUsersCostList.map(({ 
		// 										CustomerName, 
		// 										UserFullName, 
		// 										HourlyCost, 
		// 										WorkedHours,
		// 										UserTotalCost 
		// 									}) => ({
		// 										CustomerName, 
		// 										UserFullName, 
		// 										HourlyCost, 
		// 										WorkedHours,
		// 										UserTotalCost 
		// 									}));

		// r = ListToArray(cloned);
		// setRows(r);

	}, [customerUsersCostList]);

	function YearListInitialize() {

		const year: number = 2020;
		let list: SelectListItem[] = [];

		for (let i = year; i <= curYear + 1; i++) {
			const item: SelectListItem = {
				Value: i.toString(),
				Text: i.toString()
			}
			list.push(item);
		}
		setYearsList(list);

		const item: SelectListItem = {
			Value: curYear.toString(),
			Text: curYear.toString()
		}
		setSelectedYear(item);
	}

	function MonthListInitialize() {

		let mlist: SelectListItem[] = [];
		let item: SelectListItem;

		for (let i: number = 1; i <= 12; i++) {
			const v = i.toString();
			const t = MONTHS["it"][i - 1];
			item = { Text: t, Value: v };
			mlist.push(item);
		}

		setMonthList(mlist);

		item = {
			Value: curMonth.toString(),
			Text: MONTHS["it"][curMonth - 1]
		}
		setSelectedMonth(item);
	}

	function GetCustomerList(year: number, month: number) {

		if (year && month) {

			isvc.getMonthlyUserCostCustomerItems(year, month).then((l) => {

				if (l.data) {

					let item: SelectListItem = {
						Value: "0",
						Text: "Tutti"
					}
					
					l.data.unshift(item);

					setCustomerList(l.data);
					setSelectedCustomer(item);
				}
			}).catch((e) => {
				context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento cost list: " + e, titoloAlert: "Errore" });
			}).finally(() => {

			});
		}

	}

	function GetRptMonthlyCustomerUsersCost(year: number, month: number, customerID: number) {

		const sName: string = MONTHS["it"][month - 1] + " " + year.toString();
		setSheetName(sName);

		svc.GetRptUserMonthlyCustomerUsersCost(customerID, year, month).then((l) => {

			if (l.data) {
				setCustomerUsersCostList(l.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento cost list: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function setYear(v: any, i: any) {
		setSelectedYear(v);

		let item: SelectListItem = {
			Value: "1",
			Text: MONTHS["it"][0]
		}
		setSelectedMonth(item);
		item = {
			Value: "0",
			Text: "Tutti"
		}
		setSelectedCustomer(item);
		GetCustomerList(Number(v.Value), 1);
		GetRptMonthlyCustomerUsersCost(Number(v.Value), 1, 0);
	}

	function changeMonth(v: any, i: any) {
		setSelectedMonth(v);
		GetCustomerList(Number(selectedYear!.Value), Number(v.Value));
		GetRptMonthlyCustomerUsersCost(Number(selectedYear!.Value), Number(v.Value), 0);
	}

	function changeCustomer(v: any, i: any) {
		setSelectedCustomer(v);
		GetRptMonthlyCustomerUsersCost(Number(selectedYear!.Value), Number(selectedMonth!.Value), Number(v.Value));
	}

	return (
		<>
			<TitleBar title="Ore lavorate per cliente" />
			<Form className="form-search-list">
				<Form.Row>
					<Col md={2}>
						<label className="form-label">Anno</label>
						<Select
							filterOption={selectFilter}
							value={selectedYear}
							options={yearsList}
							isSearchable
							onChange={setYear}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col md={2}>
						<label className="form-label">Mese</label>
						<Select
							filterOption={selectFilter}
							value={selectedMonth}
							options={monthList}
							isSearchable
							onChange={changeMonth}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col md={3}>
						<label className="form-label">Cliente</label>
						<Select
							filterOption={selectFilter}
							value={selectedCustomer}
							options={customerList}
							isSearchable
							onChange={changeCustomer}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col md={1}>
						{customerUsersCostList.length > 0 ?
							<div className="label-padding">
								<div title="Esporta in excel" className="d-inline">
									<ExcelFile filename={report_tile} element={
										<div className="d-inline mr-1" style={{ "cursor": "pointer" }}>
											<Icon icon={fileExcel} className="text-success" size={icon_size} />
										</div>}
									>
										<ExcelSheet
											data={customerUsersCostList}
											name={sheeName}>
											{columns.map(function (item: ReportColumn, i) {
												return <ExcelColumn
													key={"xlsc_" + i.toString()}
													label={item.columnName}
													value={item.columnValue}
												>
												</ExcelColumn>
											}
											)}
										</ExcelSheet>
									</ExcelFile>
								</div>
								<div title="Esporta in csv" className="d-inline mr-1">
									<CSVLink
										data={customerUsersCostList}
										filename={report_tile + ".csv"}>
										<Icon icon={fileText2} className="text-warning" size={icon_size} />
									</CSVLink>
								</div>
								{/* {rows.length > 0?
								<div title="Esporta in pdf" className="d-inline">
									<PDFDownloadLink
										document={<PdfDocument
										title={report_tile}
										columns={columns}
										rows={rows} />}
										fileName={report_tile + ".pdf"}>
										{({ blob, url, loading, error }) => (loading ?
											<Icon icon={filePdf} className="text-danger" size={icon_size} />
											:
											<Icon icon={filePdf} className="text-danger" size={icon_size} />)}
									</PDFDownloadLink>
								</div>
								:
								''
								} */}
							</div>
							:
							''
						}
					</Col>
					<Col md={4} className="text-right pr-3">
						<label className="form-label">Ore totali</label>
						<div className="bordered">
							{thousandSeparator(totalHours)}
						</div>
					</Col>
				</Form.Row>
			</Form>
			<div className="p-3">
				<table className="table table-sm">
					<thead>
						<tr>
							<th style={{ "width": "400px" }}>
								Causale
							</th>
							<th>
								Risorsa
							</th>
							<th className="text-right">
								Numero ore
							</th>
						</tr>
					</thead>
					<tbody>
						{
							customerUsersCostList ?
								customerUsersCostList.map(function (item: CustomerUserMonthlyCost) {
									return (
										<tr key={"tr_" + item.CustomerID.toString() + item.UserID.toString()} className="ts-resource-row">
											<td>{item.CustomerName}</td>
											<td>{item.UserFullName}</td>
											<td className="text-right pr-2">{thousandSeparator(item.WorkedHours)}</td>
										</tr>
									);
								})
								: ''}
					</tbody>
				</table>
			</div >
		</>
	);
}
