import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { UserContractType, SelectListItem, SelectItemsListTypes } from "../../models/models";
import UserServices from "../../api/UserServices";
import SelectItemServices from "../../api/SelectItemServices";
import { Table, Button } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add"
import { ic_edit } from "react-icons-kit/md/ic_edit"
import { ic_check } from "react-icons-kit/md/ic_check"
import { ic_close } from "react-icons-kit/md/ic_close"
import { ic_delete } from "react-icons-kit/md/ic_delete"
import Select from "react-select";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { getItemByValue } from "../../core/Utilities";

import "react-day-picker/lib/style.css";
import { MONTHS, FIRST_DAY_OF_WEEK, LABELS, WEEKDAYS_LONG, WEEKDAYS_SHORT, TODAY_LABEL } from "../../models/locale";



export interface IUserContractsList {
}

export default function UserContractsList(props: IUserContractsList) {
	const context = useContext(Maincontext);
	const { handleSubmit } = useForm();
	const userID = context.objSelected.UserID as number;
	const language = "it";
	const dateFormat = "DD/MM/YYYY";
	const SVC = new UserServices();
	const ISVC = new SelectItemServices();
	let count: number = 0;

	/* const defItem: UserContractType = {
		ContractTypeID: 0,
		StartDate: new Date,
		EndDate: undefined,
		ContractTypeName: "",
		edit: false,
		isNew: false
	}; */

	const [userContractsList, setUserContractsList] = useState<UserContractType[]>([]);
	const [contractTypesList, setContractTypesList] = useState<SelectListItem[]>([]);
	const [hideClass, setHideClass] = useState<string>("d-none");
	const [selectedContractType, setSelectedContractType] = useState<SelectListItem | undefined>(undefined);

	useEffect(() => {

		GetList();

		context.parentID = userID;
		context.objectName = context.objSelected.FullName;

	}, []);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getUserContractTypesList(userID).then((d) => {
			if (d.data) {
				setUserContractsList(d.data);
			}
			GetContractTypesList();
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: ContractTypes List" + e, titoloAlert: "Errore" });
		}).finally(() => {
			//context.setContext({ ...context, showAttesa: false, });
		});
	}

	function GetContractTypesList() {
		//context.setContext({ ...context, showAttesa: true });
		ISVC.getSelectItemList(SelectItemsListTypes.CONTRACT_TYPES, 0).then((d) => {
			if (d.data) {
				setContractTypesList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: CONTRACT_TYPES" + e, titoloAlert: "Errore" });
		}).finally(() => {
			setHideClass("");
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}


	const getContractTypeName = (id: number): string => {

		const contrctTypeName: string | undefined = contractTypesList.find(obj => obj.Value === id.toString())?.Text;
		return contrctTypeName!;
	};

	//verifica se esiste un item new

	function refreshList() {

		//aggiorna la lista con i dati dell'item corrente
		let userContractsListNew: UserContractType[] = [];

		userContractsList.forEach((e: UserContractType, i) => {

			userContractsListNew.push(e);
		});

		setUserContractsList(userContractsListNew);
	}



	function addItem() {

		if (!newExists()) {
			setSelectedContractType(contractTypesList[0]);
			const userContractNew: UserContractType = {
				ContractTypeID: Number(contractTypesList[0].Value),
				StartDate: new Date,
				EndDate: undefined,
				ContractTypeName: contractTypesList[0].Text,
				edit: true,
				isNew: true
			};

			let userContractsListNew: UserContractType[] = [];
			userContractsList.forEach((e: UserContractType, i) => {
				e.edit = false;
				userContractsListNew.push(e);
			});

			userContractsListNew.push(userContractNew);
			setUserContractsList(userContractsListNew);
			context.setContext({ ...context, saveEnabled: false });
		}
	}

	//verifica se esiste un item new
	const newExists = (): boolean => {
		let esito: boolean = false;
		userContractsList.forEach((e: UserContractType, i) => {
			if (e.isNew) {
				esito = true;
			};
		});
		return esito;
	};

	//validazione del form DA FARE!!!
	const isValid = (): boolean => {
		return true;
	};
	const isItemValid = (item: UserContractType): boolean => {

		if (item.ContractTypeID === 0 || item.StartDate === undefined) { return false; }
		else {
			return true;
		}
	};

	function setContractTypeID(v: any, i: any) {

		const contractTypeID: number = Number(v.Value);
		setSelectedContractType(v);

		let editingItem = userContractsList.find(e => e.edit === true);
		if (editingItem !== undefined) {
			editingItem.ContractTypeID = contractTypeID;
			editingItem.ContractTypeName = getContractTypeName(contractTypeID);
			refreshList();
		}
	}

	function setStartDate(day: Date) {
		if (day !== undefined) {
			//currentItem.StartDate = day;
			let editingItem = userContractsList.find(e => e.edit === true);
			if (editingItem !== undefined) {
				editingItem.StartDate = day;
				refreshList();
			}
		}
	}

	function setEndDate(day: Date) {

		let editingItem = userContractsList.find(e => e.edit === true);
		if (editingItem !== undefined) {
			editingItem.EndDate = day;
			refreshList();
		}
	}

	function setItemEditing(index?: number) {

		let userContractsListNew: UserContractType[] = [];
		userContractsList.forEach((e: UserContractType, i) => {
			if (i === index) {
				e.edit = true;
				const contractTypeID = e.ContractTypeID;
				const selected: SelectListItem | undefined = getItemByValue(contractTypesList, contractTypeID);
				setSelectedContractType(selected);
			}
			else {
				e.edit = false;
			}
			userContractsListNew.push(e);
		});

		setUserContractsList(userContractsListNew);
		context.setContext({ ...context, saveEnabled: false });
	}

	function setItemDeleted(index?: number) {

		let userContractsListNew: UserContractType[] = [];
		userContractsList.forEach((e: UserContractType, i) => {
			if (i !== index) {
				userContractsListNew.push(e);
			}
		});

		setUserContractsList(userContractsListNew);
		context.objSelected = userContractsListNew;
		context.setContext({ ...context, saveEnabled: isValid() });
	}

	function setItemCancelEditing() {

		let userContractsListNew: UserContractType[] = [];
		userContractsList.forEach((e: UserContractType, i) => {
			e.edit = false;
			if (!e.isNew) {
				userContractsListNew.push(e);
			}
		});

		setUserContractsList(userContractsListNew);
		context.setContext({ ...context, saveEnabled: isValid() });
	}

	function editItem(e: MouseEvent<HTMLButtonElement>) {
		const index = Number(e.currentTarget.getAttribute("data-id"));
		setItemEditing(index);
	}

	function deleteItem(e: MouseEvent<HTMLButtonElement>) {
		const index = Number(e.currentTarget.getAttribute("data-id"));
		setItemDeleted(index);
	}

	function saveItem(e: MouseEvent<HTMLButtonElement>) {

		let editingItem = userContractsList.find(e => e.edit === true);
		if (editingItem !== undefined) {

			const valid: boolean = isItemValid(editingItem);
			if (!valid) {
				return;
			}

			let userContractsListNew: UserContractType[] = [];

			userContractsList.forEach((e: UserContractType, i) => {
				e.isNew = false;
				e.edit = false;
				userContractsListNew.push(e);
			});

			setUserContractsList(userContractsListNew);

			context.objSelected = userContractsListNew;
			context.setContext({ ...context, saveEnabled: isValid() });

		}

	}

	function cancelEditItem(e: MouseEvent<HTMLButtonElement>) {
		setItemCancelEditing();
	}


	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<Button variant={"outline-primary"}
							onClick={addItem}
							className={"btn-sm mb-2 " + hideClass}>
							<Icon icon={ic_add} size="16" />Aggiungi</Button>
						<Table striped size="sm">
							<thead>
								<tr>
									<th>Tipologia di contratto</th>
									<th>Data inizio</th>
									<th>Data fine</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									userContractsList.map(function (item: UserContractType, i: number) {
										count += 1;
										if (!item.edit)
											return <tr key={"clist_tr_" + count}>
												<td key={"ctname_td" + count}>
													{item.ContractTypeName}
												</td>
												<td key={"StartDate_td" + count}>
													{new Date(item.StartDate).toLocaleDateString()}
												</td>
												<td key={"EndDate_td" + count}>
													{item.EndDate === undefined ? "" : new Date(item.EndDate).toLocaleDateString()}
												</td>
												<td className={"text-right"} key={"ctbtn_td" + count}>
													<Button variant={"link"}
														key={"btn_edit" + count}
														className={"btn-sm " + hideClass}
														data-id={i}
														onClick={editItem}>
														<Icon icon={ic_edit} size="16" /></Button>
													<Button variant={"link"}
														key={"btn_delete" + count}
														className={"btn-sm ml-2 " + hideClass}
														data-id={i}
														onClick={deleteItem}>
														<Icon icon={ic_delete} size="16" /></Button>
												</td>
											</tr>;
										else
											return <tr>
												<td>
													<Select
														value={selectedContractType}
														options={contractTypesList}
														isClearable={false}
														onChange={setContractTypeID}
														getOptionLabel={c => c.Text}
														getOptionValue={c => c.Value}
													/>
												</td>
												<td>
													<DayPickerInput
														dayPickerProps={{
															month: new Date(item.StartDate),
															todayButton: TODAY_LABEL[language],
															locale: language,
															months: MONTHS[language],
															weekdaysLong: WEEKDAYS_LONG[language],
															weekdaysShort: WEEKDAYS_SHORT[language],
															firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
															labels: LABELS[language]
														}}
														placeholder={"Non impostata"}
														format={dateFormat}
														value={new Date(item.StartDate).toLocaleDateString()}
														onDayChange={day => setStartDate(day)}
														inputProps={{
															readOnly: "readOnly"
														}}

													/>

												</td>
												<td>

													<DayPickerInput

														dayPickerProps={{
															month: new Date(item.EndDate === undefined ? item.StartDate : item.EndDate),
															todayButton: TODAY_LABEL[language],
															locale: language,
															months: MONTHS[language],
															weekdaysLong: WEEKDAYS_LONG[language],
															weekdaysShort: WEEKDAYS_SHORT[language],
															firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
															labels: LABELS[language]
														}}
														format={dateFormat}
														placeholder={"Non impostata"}
														value={item.EndDate === undefined ? undefined : new Date(item.EndDate).toLocaleDateString()}
														onDayChange={day => setEndDate(day)}
														inputProps={{
															readonly: "readOnly"
														}}
													/>
												</td>
												<td className={"text-right"}>

													<Button variant={"link"} className="btn-sm"
														data-id={i}
														onClick={cancelEditItem}>
														<Icon icon={ic_close} size="16" /></Button>
													<Button variant={"link"} className="btn-sm ml-2"
														data-id={i}
														onClick={saveItem}>
														<Icon icon={ic_check} size="16" /></Button>
												</td>
											</tr>;
									})
								}
							</tbody>
						</Table>

					</div>
				</div>
			</form>
		</div >
	);
}

