import React, { ChangeEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { SubOrderUser, SubOrderUsers, WorkingDate } from '../../../models/reports';
import ReportServices from '../../../api/ReportServices';
import { Maincontext } from '../../maincontext';
import { MenuList, SelectListItem } from '../../../models/models';
import { useParams } from "react-router-dom";

import { PDFDownloadLink } from '@react-pdf/renderer';

import moment from 'moment';
import UserMonthAvailableHours from '../../common/UserMonthAvailableHours';
import { Button, Col, Row } from 'react-bootstrap';
import { MONTHS } from '../../../models/locale';
import Icon from 'react-icons-kit';
import { filePdf } from 'react-icons-kit/icomoon/filePdf';
import { refresh } from "react-icons-kit/fa/refresh";
import PdfReportSubOrderUsers from '../../common/pdf/PdfReportSubOrderUsers';
import { IUserAvailableHours } from '../../common/UserAvailableHours';
import { GetMonthNumberOfDays } from '../../../core/Utilities';


export default function SubOrdersUsers() {
	const icon_size: number = 22;
	let params: any = useParams();
	const SubOrderId: number = Number(params.id);
	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const [reportDetailUsers, setReportDetailUsers] = useState<SubOrderUser[] | undefined>(undefined);
	const [reportDetailBase, setReportDetailBase] = useState<SubOrderUsers | undefined>(undefined);

	const [selectedMonth, setSelectedMonth] = useState<number>(11);
	const [selectedYear, setSelectedYear] = useState<number>(2019);

	const [arrayYears, setArrayYears] = useState<number[]>([2019]);
	const [arrayMonths, setArrayMonths] = useState<SelectListItem[]>([{ Value: "1", Text: "Gennaio" }]);

	const [selectedProfile, setSelectedProfile] = useState<number>(0);

	useEffect(() => {
		var urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has("toolbar") && urlParams.get("toolbar") === "no") {
			(document.querySelector(".menu-laterale") as HTMLElement).style.display = "none";
			(document.querySelector(".navbar-nav") as HTMLElement).style.display = "none";
			(document.querySelector(".principale") as HTMLElement).style.marginLeft = "0";
		}
		GetList();
		context.setContext({
			...context,
			selectedMenu: MenuList.RPT_SUBORDERUSERS
		});
	}, []);

	useEffect(() => {

		if (reportDetailBase?.Users) {

			SetUsersDisplayDates();
		}

	}, [selectedYear, selectedMonth, selectedProfile, reportDetailBase]);


	useEffect(() => {

		
		if (reportDetailBase) {
			const sDate = new Date(reportDetailBase.StartDate);
			const eDate = new Date(reportDetailBase.EndDate);
			let arrYears: number[] = [];
			const sYear: number = sDate.getFullYear();
			const eYear: number = eDate.getFullYear();

			for (let y: number = sYear; y <= eYear; y++) {
				arrYears.push(y);
			}
			setArrayYears(arrYears);
			let arrMonths = GetMonths(sDate, eDate, sYear)
			setArrayMonths(arrMonths);

			setSelectedMonth(sDate.getMonth());
			setSelectedYear(sYear);
			setSelectedProfile(0);
		}

	}, [reportDetailBase]);

	function GetList() {
		context.setContext({ ...context, showAttesa: true });
		SVC.GetSubOrderUsers(SubOrderId).then((d) => {
			if (d.data) {
				setReportDetailBase(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento dati: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	async function SetUsersDisplayDates() {

		if (reportDetailBase?.Users) {

			let base: SubOrderUser[] = JSON.parse(JSON.stringify(reportDetailBase?.Users));

			//filtro per profilo
			if (selectedProfile != 0) {
				base = base.filter(k => k.ProfileTypes.some(
					e => e.ProfileTypeID === selectedProfile
				)
				);
			}

			//filtro per mese e anno
			base.forEach(function (v, i) {
				if (v.WorkingDates) {
					v.WorkingDates = v.WorkingDates.filter(function (element, index) {
						if (element.WorkingDate) {
							let dateSel = moment(element.WorkingDate);
							return (dateSel && dateSel.month() == selectedMonth && dateSel.year() == selectedYear)
						}
					})
				}
			})

			const d = GetMonthNumberOfDays(selectedYear, selectedMonth);
			base.forEach(u => {
				let dDates: IUserAvailableHours[] = [];
				for (let i = 1; i <= d; i++) {
					const avHours: IUserAvailableHours = GetUserAvailablesHours(i, u.WorkingDates);
					dDates.push(avHours);
				}
				u.DisplayDates = dDates;
			});

			setReportDetailUsers(base);
		}
	}

	//IMPOSTA I VALORI PER VISUALIZZARE LE DATE NEL COMPONENTE
	//E NEL PDF
	function SetUsersDisplayDates_OLD(users: SubOrderUser[]): SubOrderUser[] {

		const d = GetMonthNumberOfDays(selectedYear, selectedMonth);
		users.forEach(u => {
			let dDates: IUserAvailableHours[] = [];
			for (let i = 1; i <= d; i++) {
				const avHours: IUserAvailableHours = GetUserAvailablesHours(i, u.WorkingDates);
				dDates.push(avHours);
			}
			u.DisplayDates = dDates;
		});

		return users;
	}

	function GetUserAvailablesHours(monthDay: number, workingdates: WorkingDate[]): IUserAvailableHours {

		let retVal: IUserAvailableHours = {
			Disabled: true,
			MonthDay: monthDay,
			IsWorkingDay: false,
			IsHolidayOrWeekEnd: false,
			AvailableHours: 0
		};

		let avHours: WorkingDate | undefined = undefined;
		if (workingdates) {
			avHours = workingdates.find(e => new Date(e.WorkingDate).getDate() === monthDay);
		}

		if (avHours) {
			retVal.Disabled = false;
			retVal.IsWorkingDay = avHours.IsWorkingDay;
			retVal.IsHolidayOrWeekEnd = avHours.IsHolidayOrWeekEnd;
			retVal.AvailableHours = avHours.AvailableHours;
		}

		return retVal;
	}

	function GetMonths(start: Date, end: Date, year: number): SelectListItem[] {

		let monthIndex: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
		let months: SelectListItem[] = [];
		let date: Date;

		const sYear: number = start.getFullYear();
		const eYear: number = end.getFullYear();
		const sMonth: number = start.getMonth();
		const eMonth: number = end.getMonth();

		for (let i of monthIndex) {

			if (
				(sYear == eYear && i >= sMonth && i <= eMonth)
				||
				(sYear < eYear
					&&
					(
						(sYear == year && i >= sMonth)
						||
						(eYear == year && i <= eMonth)
						||
						(year > sYear && year < eYear)
					)
				)
			) {
				months.push({ Value: i.toString(), Text: MONTHS["it"][i] })
			}
		}

		setSelectedMonth(Number(months[0].Value));
		return months;

	}

	function onYearChange(e: ChangeEvent<HTMLSelectElement>) {

		const y: number = Number(e.target.value);

		setSelectedYear(y);

		if (reportDetailBase) {
			const sDate = new Date(reportDetailBase.StartDate);
			const eDate = new Date(reportDetailBase.EndDate);
			let arrMonths = GetMonths(sDate, eDate, y);
			setArrayMonths(arrMonths);
		}
	}

	function onReset(e: MouseEvent<HTMLButtonElement>) {
		GetList();
	}

	return (
		<div className="Container">
			<div className="p-3">
				<Row>
					<Col sm={12}><h3>{reportDetailBase ? reportDetailBase.SubOrderName : "-"}</h3>
						<h5>Ore disponibili per risorsa</h5></Col>
					<Col sm={2}>
						<table className="table table-sm">
							<tbody>
								<tr>
									<td>Responsabile</td>
									<td>{reportDetailBase ?
										reportDetailBase.SubOrderManagers[0].FirstName
										+ " " + reportDetailBase.SubOrderManagers[0].LastName
										: ""}</td>
								</tr>
								<tr>
									<td className="nowrap">Data inizio</td>
									<td>{reportDetailBase ?
										new Date(reportDetailBase.StartDate).toLocaleDateString()
										: ""}</td>
								</tr>
								<tr>
									<td className="nowrap">Data fine</td>
									<td>{reportDetailBase ?
										new Date(reportDetailBase.EndDate).toLocaleDateString()
										: ""}</td>		</tr>
							</tbody>
						</table>

					</Col>
					<Col sm={2}>
						<label>Anno</label>
						<select className={"form-control"} onChange={onYearChange}>
							{arrayYears.map(function (i) {
								return <option value={i}>{i}</option>
							})}
						</select>
					</Col>
					<Col sm={2}>
						<label>Mese</label>
						<select className={"form-control"}
							onChange={(e) => { setSelectedMonth(parseInt(e.currentTarget.value)) }}>
							{arrayMonths.map(function (i) {
								return <option selected={Number(i.Value) == selectedMonth} value={i.Value}>{i.Text}</option>
							})}
						</select>
					</Col>
					<Col sm={2}>
						<label>Profilo</label>
						<select className={"form-control"}
							onChange={(e) => { setSelectedProfile(parseInt(e.currentTarget.value)) }}>
							<option value={0}>Tutti...</option>
							{reportDetailBase?.ProfileTypes.map(function (i) {
								return <option value={i.ProfileTypeID}>{i.ProfileTypeName}</option>
							})}
						</select>
					</Col>
					<Col sm={2}>
						<Button
							variant={"link"}
							style={{ width: "auto", top: "32px" }}
							className="btn-sm btn-context position-relative"
							onClick={onReset}
						>
							<Icon icon={refresh} size="16" className="mr-1" />
							Aggiorna
						</Button>
						{/* {reportDetailUsers ?
							<div title="Esporta in pdf"
								className="d-inline position-relative ml-2"
								style={{ width: "auto", top: "34px" }}>
								<PDFDownloadLink
									document={
										<PdfReportSubOrderUsers
											title={reportDetailBase!.SubOrderName}
											data={reportDetailUsers}
											year={selectedYear}
											month={selectedMonth}
										/>
									}
									fileName={reportDetailBase!.SubOrderName + ".pdf"}>
									{({ blob, url, loading, error }) => (loading ?
										<Icon icon={filePdf} className="text-muted" size={icon_size} />
										:
										<Icon icon={filePdf} className="text-danger" size={icon_size} />)}
								</PDFDownloadLink>
							</div>
							:
							''
						} */}
					</Col>
				</Row>
				{
					reportDetailUsers ?
						reportDetailUsers.map((e) => {
							return <UserMonthAvailableHours
								item={e}
							/>
						})
						:
						''
				}
			</div>
		</div>
	);

}