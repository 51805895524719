import { UserNotificationType } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class UserNotificationServices {

	//Toast notifications
	async GetToastUserNotifications() {
		return axios.get<UserNotificationType[] | undefined>(`${await SVC.getApiBaseUri()}/api/UserNotifications/GetToastUserNotifications`, { headers: await SVC.setAzureAuth() });
	}

	//Menu notifications
	async GetMenuUserNotifications() {
		return axios.get<UserNotificationType[] | undefined>(`${await SVC.getApiBaseUri()}/api/UserNotifications/GetMenuUserNotifications`, { headers: await SVC.setAzureAuth() });
	}
	
	//Notification menu read!
	async SetUserNotificationMenuRead(userNotificationID: number) {
		const params = {
			userNotificationID: userNotificationID
		};
		return axios.get<string>(`${await SVC.getApiBaseUri()}/api/UserNotifications/SetUserNotificationMenuRead`, { params, headers: await SVC.setAzureAuth() });
	}
	//Notification toast read!
	async SetUserNotificationToastRead(userNotificationID: number) {
		const params = {
			userNotificationID: userNotificationID
		};
		return axios.get<string>(`${await SVC.getApiBaseUri()}/api/UserNotifications/SetUserNotificationToastRead`, { params, headers: await SVC.setAzureAuth() });
	}
}