import React, { useState, useEffect, useContext, CSSProperties, MouseEvent, useRef } from "react";
import { MenuList, MonthStates, UserTimeSheetYearMonth } from "../../../models/models";
import TimesheetServices from "../../../api/TimeSheetServices";
import { Maincontext } from "../../maincontext";
import YearNavigator from "./Components/YearNavigator";
import TitleBar from "../../common/TitleBar";
import { minutesToHours } from "../../../core/Utilities";
import { MONTHS } from "../../../models/locale";
import MonthStatusLed from "./Components/MonthStatusLed";
import { Link } from "react-router-dom"; //libreria di routing
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";

export interface ITimesheetYearProps {
}

export default function TimesheetYear(props: ITimesheetYearProps) {

	const [timeSheetYear, setTimeSheetYear] = useState<UserTimeSheetYearMonth[]>();
	const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
	const TSService = new TimesheetServices();
	const context = useContext(Maincontext);

	useEffect(() => {
		context.setContext({ ...context, selectedMenu: MenuList.ARCHIVIO_TIMESHEET });
	}, []);

	useEffect(() => {
		GetList();
	}, [currentYear]);

	const GetList = () => {

		if (currentYear >= 2020 && currentYear <= 2099) {
			TSService.GetUserYearTimesheet(currentYear).then((d) => {
				setTimeSheetYear(d.data);
			});
		} else {
			setTimeSheetYear([]);
		}
	};

	function OnYearChange(Year: string) {
		setCurrentYear(Number(Year));
	};

	return (
		<>
			<TitleBar title="Archivio timesheet" />
			<div className="form-search-list text-center">
				<YearNavigator CurrentYear={currentYear.toString()} OnYearChange={OnYearChange} />
			</div>
			<div className="p-3">
				<table className="table table-list">
					<thead>
						<tr>
							<th className="p-2"></th>
							<th className="p-2">Mese</th>
							<th className="p-2">Stato</th>
							<th className="p-2">Ore ordinarie</th>
							<th className="p-2">Straordinari</th>
							<th className="p-2">Notturni</th>
							<th className="p-2">Permessi</th>
							<th className="p-2">Giorni di assenza</th>
						</tr>
					</thead>
					<tbody>
						{
							timeSheetYear ?
								timeSheetYear.map((month: UserTimeSheetYearMonth) => {
									return (
										<tr>
											<td className="p-2"><MonthStatusLed Size="lg" MonthState={month.MonthTimesheetTypeID} /></td>
											<td className="p-2">
												<div className="d-flex justify-content-between align-items-center">
													<div>{MONTHS["it"][month.MonthID]}</div>
													<div>												
														<Link title={`Compila ${MONTHS["it"][month.MonthID]}`} to={`/monthtimesheet/${currentYear}/${month.MonthID + 1}`}>
															<Icon className="btn btn-link btn-sm p-0 btn-menu-context" icon={ic_edit} size={24} />
														</Link>
													</div>
												</div>
											</td>
											<td className="p-2">{month.MonthTimesheetTypeDescription}</td>
											<td className="p-2">{minutesToHours(month.Minutes)}</td>
											<td className="p-2">{minutesToHours(month.OvertimeMinutes)}</td>
											<td className="p-2">{minutesToHours(month.OvertimeNightMinutes)}</td>
											<td className="p-2">{minutesToHours(month.Permits)}</td>
											<td className="p-2">{month.FullDayPermits}</td>
										</tr>
									);
								})
								:
								""
						}
					</tbody>
				</table>
			</div>
		</>
	);
}