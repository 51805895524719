import React, { useContext, MouseEvent, useState, useEffect, ChangeEvent, FocusEvent } from "react";
import { OverTimeStates, SubOrderStep, SelectListItem, OverTime, SelectItemsListTypes } from "../../models/models";
import { Maincontext } from "../maincontext";
import SelectItemServices from "../../api/SelectItemServices";
import { selectFilter } from "../../core/Utilities";
import Select from "react-select";
import { Modal, Button, Popover, Alert } from "react-bootstrap";//Icone
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import HoursMinutes from "../common/HoursMinutes";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, MONTHS, FIRST_DAY_OF_WEEK, LABELS, WEEKDAYS_LONG, WEEKDAYS_SHORT, TODAY_LABEL } from "../../models/locale";
import OverTimeServices from "../../api/OverTimeServices";
import SaveButton from "../common/SaveButton";
export interface IOverTimeDetailProps {
}

export default function OverTimeDetail(props: IOverTimeDetailProps) {

	const context = useContext(Maincontext);
	const SVC = new OverTimeServices();
	const ISVC = new SelectItemServices();

	const language = "it";
	const dateFormat = "DD/MM/YYYY";

	const overTimeDef: OverTime = {
		OverTimeID: 0,
		OverTimeStateID: OverTimeStates.WAITING,
		OrderID: 0,
		UserID: 0,
		ApproverID: 0,
		OverTimeDate: new Date(),
		OverTimeMinutes: 0,
		OverTimeNightMinutes: 0,
		UserNote: '',
		ApproverNote: '',
		UserFullName: '',
		ApproverFullName: '',
		Minutes: 0,
		OrderName: ''
	};

	const [overTime, setOverTime] = useState<OverTime>(overTimeDef);
	const [orderList, setOrderList] = useState<SelectListItem[]>([]);
	const [selectedOrder, setSelectedOrder] = useState<SelectListItem | undefined>(undefined);
	const [showPopover, setShowPopover] = useState(false);
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

	useEffect(() => {
		getOrdersList();

	}, []);


	useEffect(() => {

		setContext();

	}, [overTime]);


	function getOrdersList() {

		ISVC.getSelectItemList(SelectItemsListTypes.OVERTIME_ORDERS, 0).then((l) => {

			if (l.data.length > 0) {
				setOrderList(l.data);
			}
			else {
				context.setContext({
					...context,
					showOverTimeDetail: false,
					showAlert: true,
					messaggioAlert: "Nessuna commessa disponibile.",
					titoloAlert: "Attenzione!"
				});
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento commesse: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function Save(e: MouseEvent<HTMLButtonElement>) {

		setShowPopover(false);

		SVC.overTimeInsert(overTime).then((d) => {
			if (d !== undefined && d.data !== "") {
				setAlertMessage(d.data);
			}
			else {
				context.setContext({ ...context, refreshList: !context.refreshList });
				closeDialog();
			}
		}).catch((e: React.SetStateAction<string>) => {
			setAlertMessage("Errore del server: " + e.toString());
		}).finally(() => {
		});
	};

	//validazione del form
	const isValid = (): boolean => {

		return overTime.OrderID !== 0
			&&
			(overTime.OverTimeMinutes > 0 ||
				overTime.OverTimeNightMinutes > 0);
	};

	function setContext() {
		const valid = isValid();
		setSaveEnabled(valid);
	}

	function setOrderID(v: any, i: any) {
		const orderID: number = Number(v.Value);
		setSelectedOrder(v);
		overTime.OrderID = orderID;
		setOverTime({
			...overTime,
			OrderID: orderID
		});
	}

	function setOverTimeDate(day: Date) {
		if (day !== undefined) {
			overTime.OverTimeDate = day;
			setOverTime({
				...overTime,
				OverTimeDate: day
			});
		}
	}

	function setOverTimeMinutes(minutes: number) {

		setOverTime({
			...overTime,
			OverTimeMinutes: minutes
		});
	}

	function setOverTimeNightMinutes(minutes: number) {

		setOverTime({
			...overTime,
			OverTimeNightMinutes: minutes
		});
	}
	function setNote(e: ChangeEvent<HTMLInputElement>) {
		const note: string = e.target.value;
		setOverTime({
			...overTime,
			UserNote: note
		});
	}

	function onFocus(e: FocusEvent<HTMLInputElement>) {
		e.target.select();
	}

	const closeDialog = () => {

		setSaveEnabled(false);
		setAlertMessage("");
		setShowPopover(false);
		setOverTime(
			overTimeDef
		);
		setSelectedOrder(undefined);

		context.setContext({ ...context, showOverTimeDetail: false });

	};

	const closePopover = () => {
		setShowPopover(false);
	};

	const popover = (
		<Popover id="popover-basic" className={showPopover ? "" : "d-none"}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				Inviare la richiesta?<br /><br />
				<Button variant="outline-secondary" className="btn-sm" onClick={closePopover}><Icon icon={ic_close} /></Button>
          		&nbsp;
				<Button variant="outline-primary" className="btn-sm" onClick={Save}><Icon icon={ic_check} /></Button>
			</Popover.Content>
		</Popover>
	);

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={"lg"}
				show={context.showOverTimeDetail}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title className="fluid">
						<h3>Richiesta autorizzazione straordinari</h3>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-row">
						<div className="form-group col-12">
							<div>
								<label className="form-label">Commessa</label>
								<Select
									filterOption={selectFilter}
									value={selectedOrder}
									options={orderList}
									isSearchable
									onChange={setOrderID}
									getOptionLabel={c => c.Text}
									getOptionValue={c => c.Value}
								/>
							</div>
						</div>

						<div className="form-group col-4">
							<label className="form-label">Data</label>
							<DayPickerInput
								dayPickerProps={{
									//className: "calendar-up",
									month: new Date(overTime.OverTimeDate),
									todayButton: TODAY_LABEL[language],
									locale: language,
									months: MONTHS[language],
									weekdaysLong: WEEKDAYS_LONG[language],
									weekdaysShort: WEEKDAYS_SHORT[language],
									firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
									labels: LABELS[language]
								}}
								placeholder={"Non impostata"}
								format={dateFormat}
								value={new Date(overTime.OverTimeDate)}
								onDayChange={day => setOverTimeDate(day)}
								inputProps={{
									readonly: "readonly"
								}}
								formatDate={day => formatDate(day)}
							/>
						</div>
						<div className="form-group col-2"></div>
						<div className="form-group col-2">
							<label>Diurno</label>
							<HoursMinutes
								Minutes={overTime.OverTimeMinutes}
								onChange={(v: number) => {
									setOverTimeMinutes(v);
								}} />
						</div>
						<div className="form-group col-4">
							<label>Notturno</label>
							<HoursMinutes
								Minutes={overTime.OverTimeNightMinutes}
								onChange={(v: number) => {
									setOverTimeNightMinutes(v);
								}} />

						</div>
						<div className="form-group col-12">
							<label className="form-label">Note</label>
							<input
								className="form-control" name="OrderCode"
								maxLength={100}
								placeholder="Note..."
								onChange={setNote}
							/>

						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Alert variant={alertMessage === "" ? undefined : "warning"}>
						{alertMessage}
					</Alert>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => closeDialog()}>
						Annulla
					</Button>
					<SaveButton
						disabled={!saveEnabled}
						popover={popover}
						popoverFunction={setShowPopover}
						saveFunction={Save}
					/>
					{/* <div style={{ "position": "relative" }}>
						<Button variant="primary" size="sm" onClick={() => setShowPopover(true)} disabled={!saveEnabled}>Salva</Button>
						{popover}
					</div> */}
				</Modal.Footer>
			</Modal>
		</div>
	);
}