import { IUserAvailableHours } from "../components/common/UserAvailableHours";

//colonne per tabelle nei report
export interface ReportColumn {
  columnName: string;
  columnValue: string;
  columnWidth: number;
  textAlign?: "center" | "left" | "right";
}

//utenti per centro di costo
export interface RptCostCentersUsers {
  CostCenterName: string;
  CostCenterManagerFullName: string;
  UserFullName: string;
  Percentage: number;
}

//report commessa ===============================================
export interface Order {
  OrderID: number;
  OrderCode: string;
  OrderName: string;
  StartDate: Date;
  EndDate: Date;
  WorkingDays: number;
  OrderWorkingDays: number;
  AvailableDays: number;
  AvailableWorkedDays: number;
  UsersAvailableDays: UserAvailableDays[];
  UserID: number;
  FirstName: string;
  LastName: string;
  CustomerID: number;
  CustomerName: string;
  SubOrders: SubOrder[];
}
export interface UserAvailableDays extends User {
  AvailableDys: number;
}
export interface Customer {
  CustomerID: number;
  CustomerCode: string;
  CustomerName: string;
}
export interface SubOrder {
  SubOrderID: number;
  SubOrderName: string;
  StartDate: Date;
  EndDate: Date;
  WorkingDays: number;
  UserID: number;
  FirstName: string;
  LastName: string;
  ActivityLineID: number;
  ActivityLineName: string;
  SubOrderSteps: SubOrderStep[];
  ActivityByDescriptions: ActivityByDescription[];
  ActivityByUsers: UserAssignedHour[];
}
export interface ActivityLine {
  ActivityLineID: number;
  ActivityLineName: string;
}
export interface SubOrderStep {
  SubOrderStepID: number;
  WorkingHours: number;
  ActivityStepID: number;
  ActivityStepName: string;
  SubOrderStepProfiles: SubOrderStepProfile[];
}

export interface ActivityStep {
  ActivityStepID: number;
  ActivityStepName: string;
}

export interface SubOrderStepProfile {
  SubOrderStepProfileID: number;
  WorkingHours: number;
  ProfileTypeID: number;
  ProfileTypeName: string;
  UserTimesheets: UserTimesheet[];
  UserAssignedHours: UserAssignedHour[];
}
export interface ProfileType {
  ProfileTypeID: number;
  ProfileTypeName: string;
}
export interface User {
  UserID: number;
  FirstName: string;
  LastName: string;
}
export interface UserTimesheet extends User {
  Minutes: number;
  OvertimeMinutes: number;
  OvertimeNightMinutes: number;
}

export interface UserAssignedHour extends User {
  WorkingHours: number;
}

export interface ActivityByDescription extends User {
  ActivityDescriptionID: number;
  ActivityDescriptionName: string;
  WorkingHours: number;
}

//report risorse per il deliverable ===============================================
export interface SubOrderUsers {
  SubOrderID: number;
  SubOrderName: string;
  StartDate: Date;
  EndDate: Date;
  SubOrderManagers: User[];
  ProfileTypes: ProfileType[];
  Users: SubOrderUser[];
}
export interface ProfileType {
  ProfileTypeID: number;
  ProfileTypeName: string;
}
export interface SubOrderUser extends User {
  ProfileTypes: ProfileType[];
  WorkingDates: WorkingDate[];
  DisplayDates: IUserAvailableHours[];
}
export interface WorkingDate {
  WorkingDate: Date;
  IsWorkingDay: boolean;
  IsHolidayOrWeekEnd: boolean;
  AvailableHours: number;
}

//impegno risorse per centro di costo
export interface CostCenterUser {
  UserID: number;
  FirstName: string;
  LastName: string;
  Dates: UserWorkingDate[];
}

export interface UserWorkingDate {
  WorkingDate: Date;
  IsWorkingDay: boolean;
  IsHolidayOrWeekEnd: boolean;
  WorkingHours: number;
  AssiGnedWorkingHours: number;
  AvailableHours: number;
  WorkedHours: number;
}

//ore lavorative commessa

export interface CustomerOdresUsersWorkingHours {
  CustomerName: string;
  OrderName: string;
  OrderCode: string;
  OrderStartDate: string;
  UserFullName: string;
  Hours: number;
  OvertimeHours: number;
  OvertimeNightHours: number;
  TotalHours: number;
}

//costo mensile per cliente
export interface CustomerUserMonthlyCost {
  Internal: boolean;
  CustomerID: number;
  CustomerName: string;
  UserID: number;
  UserFullName: string;
  HourlyCost: number;
  WorkedHours: number;
  UserTotalCost: number;
}
export interface DailyWorkingWorkedHoursItem {
  WorkingDate: Date;
  WorkingHours: number;
  WorkedHours: number;
}
export interface OrderUsersWorkedMinutes {
  StartDate: Date;
  EndDate: Date;
  UserID: number;
  UserFullName: string;
  WorkedMinutes: UserWorkedMinutes[];
}
export interface UserWorkedMinutes {
  WorkingDate: Date;
  WorkedMinutes: number;
}

export interface UserWOrderSummaryItem {
  CustomerCode: string;
  CustomerName: string;
  OrderID: number;
  OrderName: string;
  StartDate: Date;
  EndDate: Date;
  WorkingDays: number;
  AvailableDays: number;
  TotalWorkedMinutes: number;
  UserWorkedMinutes: number;
}
export interface UserWSubOrderSummaryItem {
  SubOrderID: number;
  SubOrderName: string;
  StartDate: Date;
  EndDate: Date;
  WorkingDays: number;
  TotalWorkedMinutes: number;
  UserWorkedMinutes: number;
}

export interface TechnicalIntervention {
  UserID: number;
  OrderID: number;
  Year: number;
  Month: number;
  SupportIncluded: boolean;
}

//ore mese lavorate per attività
export interface SubOrdersMonthlyWorkedHoursItem {
  UserID: number;
  SubOrderID: number;
  UserFullName: string;
  CustomerName: string;
  SalesForceID: string;
  OrderName: string;
  SubOrderName: string;
  SubOrderWorkingHours: number;
  UserMonthWorkedHours: number;
  UserTotalWorkedHours: number;
  SubOrderTotalWorkedHours: number;
  SubOrderRemainingHours: number;
}

export interface IOrderTimesheet {
  CustomerName: string;
  OrderName: string;
  OrderID: number;
  OrderCode: string;
  UserFullName: string;
  TimeSheetDate: Date;
  Minutes: number;
  Hours: number;
  OvertimeMinutes: number;
  OvertimeHours: number;
  OvertimeNightMinutes: number;
  OvertimeNightHours: number;
  Support: boolean;
  UserNote: string;
}

export enum TimeSpanTypes {
  LAST_30_DAYS = 0,
  LAST_90_DAYS = 1,
  CUR_MONTH = 2,
  CUR_YEAR = 3,
  ALL = 4,
}
