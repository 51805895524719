import React from "react";
import { hoursToDays, minutesToHours } from "../../../../../core/Utilities";
import { UserTimesheet } from "../../../../../models/reports";

export interface IUserTimesheetReport {
	data: UserTimesheet
}
export default function UserTimesheetReport(props: IUserTimesheetReport) {
	return (
		<div className="Container">
			{props.data && props.data.UserID ?
				<div>
					<h6 className="bg-light ml-1">{props.data.FirstName
						+ ' '
						+ props.data.LastName}</h6>
					<div className="form-row">
						<div className="col">
							<table className="table table-sm">
								<thead>
									<tr>
										<th>Attività effettuate</th>
										<th className="text-right">Ore</th>
										<th className="text-right">Giorni</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Ordinarie</td>
										<td className="text-right">{minutesToHours(props.data.Minutes)}</td>
										<td className="text-right">{hoursToDays(props.data.Minutes / 60)}</td>
									</tr>
									<tr>
										<td>Straordinari</td>
										<td className="text-right">{minutesToHours(props.data.OvertimeMinutes)}</td>
										<td className="text-right">{hoursToDays(props.data.OvertimeMinutes / 8)}</td>
									</tr>
									<tr>
										<td>Straordinari notturni</td>
										<td className="text-right">{minutesToHours(props.data.OvertimeNightMinutes)}</td>
										<td className="text-right">{hoursToDays(props.data.OvertimeNightMinutes /8)}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				:
				''
			}
		</div>
	);
}