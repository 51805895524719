import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import CostCenterServices from "../../api/CostCenterServices";
import { CostCenter, columnDetail, SelectedObjectTypes, MenuList, DataColumnTypes } from "../../models/models";
import CostCenterDetail from "./CostCenterDetail";
import TitleBar from "../common/TitleBar";

export interface IAppProps {
}

export default function CostCentersList(props: IAppProps) {
	const context = useContext(Maincontext);
	const SVC = new CostCenterServices();
	const [list, setList] = useState<CostCenter[]>([]);

	//Dichiarazione della colonna di dettaglio che determina se una colonna viene visualizzata e in che posizione se non viene passato viene fatto il rendering dell'oggetto
	const columnDetails: columnDetail[] = [
		{ name: "CostCenterName", text: "Centro di costo", position: 1 },
		{ name: "Manager", text: "Responsabile", position: 2 },
		{ name: "Enabled", text: "Abilitato", position: 3, /*size: 100*/ },
		{ name: "CreationDate", text: "Data creazione", position: 4, type: DataColumnTypes.DATE },
	];

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList]);


	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getCostCentersList().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Cost Centers List: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: MenuList.COST_CENTERS, });
		});
	}

	function onDelete(e: MouseEvent<HTMLButtonElement>) {
		const costCenter: CostCenter = context.objSelected;

		SVC.CostCenterDelete(costCenter).then((d) => {
			if (d.data !== "") {
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione Centro di Costo";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			<TitleBar title="Centri di costo" />
			<Lista obj={list} details={[
				{
					detail: <CostCenterDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.COST_CENTER
				},
				{
					detail: <CostCenterDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.COST_CENTER,
					isFunction: true,
					onClick: onDelete,
					confirm: true,
					confirmMessage: "Eliminare il centro di costo?"
				}
			]}
			columnDetails={columnDetails}
			addButton={true}
			addElement={<CostCenterDetail />}
			addType={SelectedObjectTypes.COST_CENTER}
			addPanelSize={undefined}
			detailPosition={1}
			/>
		</div>
	);
}

