import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import CustomerServices from "../../api/CustomerServices";
import { Customer, columnDetail, SelectedObjectTypes, MenuList } from "../../models/models";
import CustomerDetail from "./CustomerDetail";
import TitleBar from "../common/TitleBar";
import UserAuthorizationsList from "./UserAuthorizationsList";
import CustomerUsersList from "./CustomerUsersList";

export interface IAppProps {
}

export default function CustomersList(props: IAppProps) {
	const context = useContext(Maincontext);
	const SVC = new CustomerServices();
	const [list, setList] = useState<Customer[]>([]);

	//Dichiarazione della colonna di dettaglio che determina se una colonna viene visualizzata e in che posizione se non viene passato viene fatto il rendering dell'oggetto
	const columnDetails: columnDetail[] = [
		{ name: "Internal", text: "Interno", position: 1 },
		{ name: "CustomerCode", text: "Codice", position: 2 },
		{ name: "CustomerName", text: "Cliente", position: 3 },
		{ name: "NationName", text: "Paese", position: 4 },
		{ name: "RegionName", text: "Regione", position: 5 },
		{ name: "ProvinceCode", text: "Provincia", position: 6 },
		{ name: "CountryName", text: "Comune", position: 7 },
		{ name: "VatNumber", text: "Partita IVA/C.F.", position: 8 },
		{ name: "Enabled", text: "Abilitato", position: 9, /*size: 100*/ },
	];

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList]);


	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getCustomers().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Customers: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: MenuList.CUSTOMERS, });
		});
	}

	function onDelete(e: MouseEvent<HTMLButtonElement>) {
		const customer: Customer = context.objSelected;

		SVC.CustomerDelete(customer).then((d) => {
			if (d.data !== "") {
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione cliente";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			<TitleBar title="Clienti" />
			<Lista 
				obj={list} 
				details={[
					{
						detail: <CustomerDetail />,
						button: "edit",
						titoloDettaglio: "Modifica",
						objTypeSelected: SelectedObjectTypes.CUSTOMER
					},
					{
						detail: <CustomerDetail />,
						button: "delete",
						titoloDettaglio: "Elimina",
						objTypeSelected: SelectedObjectTypes.CUSTOMER,
						isFunction: true,
						onClick: onDelete,
						confirm: true,
						confirmMessage: "Eliminare il cliente?"
					},
					{
						detail: <CustomerUsersList />, button: "users",
						titoloDettaglio: "Risorse assegnate",
						objTypeSelected: SelectedObjectTypes.COSTOMER_USERS
					}
				]}
				columnDetails={columnDetails}
				addButton={true}
				addElement={<CustomerDetail />}
				addType={SelectedObjectTypes.CUSTOMER}
				detailPosition={3}
			/>
		</div>
	);
}