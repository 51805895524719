import React, { useContext, useEffect, useState } from 'react';
import { CostCenterUser, ReportColumn, RptCostCentersUsers } from '../../../models/reports';
import ReportServices from '../../../api/ReportServices';
import { Maincontext } from '../../maincontext';
import { MenuList, SelectItemsListTypes, SelectListItem } from '../../../models/models';
import TitleBar from '../../common/TitleBar';
import Select from "react-select";
import SelectItemServices from '../../../api/SelectItemServices';
import { selectFilter } from '../../../core/Utilities';
import YearNavigator from '../YearlyTimesheet/Components/YearNavigator';
import { Col, ListGroupItem, Row } from 'react-bootstrap';
import { MONTHS } from '../../../models/locale';
import MonthCostCenterUserActivities from './UsersActivities/MonthCostCenterUsersActivities';

export default function CostCentersUsersActivities() {

	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const ISVC = new SelectItemServices();

	const [costCenterID, setCostCenterID] = useState<number>(0);
	const [year, setYear] = useState<number>(new Date().getFullYear()); 	//di default l'anno corrente
	const [month, setMonth] = useState<number>(new Date().getMonth() + 1); 	//di default il mese corrente
	const [monthList, setMonthList] = useState<SelectListItem[]>([]);
	const [costCenterList, setCostCenterList] = useState<SelectListItem[]>([]);
	const [selectedCostCenter, setSelectedCostCenter] = useState<SelectListItem>();
	const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();

	useEffect(() => {
		
		GetCostCenterList();
		MonthListInitialize();
		context.setContext({
			...context,
			selectedMenu: MenuList.RPT_COSTCENTERUSERS
		});
	}, []);

	function MonthListInitialize() {

		for (let i: number = 1; i <= 12; i++) {
			const v = i.toString();
			const t = MONTHS["it"][i - 1];
			let item: SelectListItem = { Text: t, Value: v };
			monthList.push(item);
		}
		setSelectedMonth(monthList[new Date().getMonth()]);
	}

	function GetCostCenterList() {
		ISVC.getSelectItemList(SelectItemsListTypes.COST_CENTERS_MANAGED, 0).then((l) => {
			if (l.data) {
				setCostCenterList(l.data);
			}
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true, /* showAttesa: false, */
				messaggioAlert: "Errore di caricamento centri di costo: " + e, titoloAlert: "Errore"
			});
		}).finally(() => {
		});
	}

	function changeCostCenterID(v: any, i: any) {
		const id: number = Number(v.Value);
		setSelectedCostCenter(v);
		setCostCenterID(Number(v.Value));
	}

	function OnYearChange(Year: string) {
		setSelectedMonth(monthList[0]);
		setYear(Number(Year));
	};

	function changeMonth(v: any, i: any) {
		const id: number = Number(v.Value);
		setSelectedMonth(v);
		setMonth(Number(v.Value));
	}

	return (
		<div className="Container">
			<TitleBar title="Orari risorse" />
			<div className="p-3">
				<Row>
					<Col sm={12}><h3>Orari risorse per centro di costo</h3></Col>
					<Col sm={4}>
						<label>Centro di costo</label>
						<Select
							filterOption={selectFilter}
							value={selectedCostCenter}
							options={costCenterList}
							isSearchable
							onChange={changeCostCenterID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col sm={2}>
						<label>Anno</label>
						<YearNavigator CurrentYear={year.toString()} OnYearChange={OnYearChange} />
					</Col>
					<Col sm={2}>
						<label>Mese</label>
						<Select
							filterOption={selectFilter}
							value={selectedMonth}
							options={monthList}
							isSearchable
							onChange={changeMonth}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
				</Row>
				<MonthCostCenterUserActivities costCenterID={costCenterID} year={year} month={month} />				
			</div>
		</div>
	);
}