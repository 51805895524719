import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import Select from "react-select";
import { ActivityStep, SelectItemsListTypes, SelectListItem } from "../../models/models";
import ActivityStepServices from "../../api/ActivityStepServices";
import SelectItemServices from "../../api/SelectItemServices";
import { Form } from "react-bootstrap";
import { getIndexSelectedById, selectFilter } from "../../core/Utilities";

export interface IActivityStepDetail {

}

export default function ActivityStepDetail(props: IActivityStepDetail) {

	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();

	const SVC = new ActivityStepServices();
	const ISVC = new SelectItemServices();
	let id = 0;
	if (context.objSelected) {
		id = context.objSelected.ActivityStepID as number;
	}

	const defActivityStep: ActivityStep = {
		"ActivityStepID": 0,
		"ActivityLineID": 0,
		"ActivityStepName": "",
		"ActivityLineName": "",
		"CreationDate": new Date(),
		"Enabled": true
	};

	const [ActivityStep, setActivityStep] = useState<ActivityStep>(defActivityStep);
	const [activityLineList, setLineList] = useState<SelectListItem[] | []>([]);
	const [selectedLine, setSelectedLine] = useState<SelectListItem | undefined>(undefined);

	useEffect(() => {
		GetActivityLinesList();
	}, []);

	useEffect(() => {
		if (id !== 0){
			setSelectedLine(activityLineList[getIndexSelectedById(activityLineList, context.objSelected!.ActivityLineID)])
		}		
	}, [activityLineList]);

	function GetItem() {
		SVC.getActivityStep(id).then((d) => {

			if (d.data) {
				setActivityStep(d.data);
				context.objectName = context.objSelected.ActivityStepName;
			}
		}).catch((e) => {
			context.setContext({ ...context, /* showAlert: true, */ messaggioAlert: "Errore di caricamento Activity Step: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}

	function GetActivityLinesList() {
		ISVC.getSelectItemList(SelectItemsListTypes.ACTIVITY_LINES, ActivityStep.ActivityLineID).then((l) => {
			if (l.data) {
				setLineList(l.data);
				setSelectedLine(l.data[0]);
				if (id) {
					GetItem();
				}
				else {
					const activityLineID: number = Number(l.data[0].Value);
					setActivityStep({ ...ActivityStep, ActivityLineID: activityLineID });
					context.setContext({ ...context, showAttesa: false, hiddenDetail: false });
				}
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Activity Lines: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	//validazione del form
	const isValid = (): boolean => {
		return ActivityStep.ActivityStepName !== "" && ActivityStep.ActivityLineID !== 0;
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = ActivityStep;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setEnabled(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
		ActivityStep.Enabled = enabled;
		setContext();
	}

	function setName(e: ChangeEvent<HTMLInputElement>) {

		const name: string = e.target.value;
		ActivityStep.ActivityStepName = name;
		setContext();

	}

	function setActivityLineID(v: any, i: any) {
		const activityLineID: number = Number(v.Value);
		setSelectedLine(v);
		ActivityStep.ActivityLineID = activityLineID;
		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Linea attività</label>
						<Select
							filterOption={selectFilter}
							value={selectedLine}
							options={activityLineList}
							isSearchable
							onChange={setActivityLineID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Fase attività</label>
						<input className="form-control" name="ActivityStepName"
							onChange={setName}
							defaultValue={ActivityStep?.ActivityStepName} ref={register({ required: true, maxLength: 100 })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<Form.Check
							id="chkEnabled"
							type="switch"
							label={"Abilitato"}
							checked={ActivityStep.Enabled}
							onChange={setEnabled} />
					</div>
				</div>
			</form>
		</div >
	);
}
