/*
	pannello di dettaglio del SubOrder
 */

import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext, UserContext } from "../../maincontext";
import Select from "react-select";
import { SubOrder, SelectItemsListTypes, SelectListItem, SelectedObjectTypes, Order } from "../../../models/models";
import OrderServices from "../../../api/OrderServices";
import SubOrderServices from "../../../api/SubOrderServices";
import SelectItemServices from "../../../api/SelectItemServices";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { selectFilter, getItemByValue, getIndexSelectedById } from "../../../core/Utilities";
import "react-day-picker/lib/style.css";
import { formatDate, MONTHS, FIRST_DAY_OF_WEEK, LABELS, WEEKDAYS_LONG, WEEKDAYS_SHORT, TODAY_LABEL } from "../../../models/locale";
import Info from "../../common/Info";

export interface ISubOrderDetailProps {
	new?: boolean
}
export default function SubOrderDetail(props: ISubOrderDetailProps) {
	const context = useContext(Maincontext);
	const userInfo = useContext(UserContext);
	context.objTypeSelected = SelectedObjectTypes.SUBORDER;
	const { register, handleSubmit } = useForm();
	const language = "it";
	const dateFormat = "DD/MM/YYYY";
	const SVC = new SubOrderServices();
	const ISVC = new SelectItemServices();
	const OSVC = new OrderServices();

	let id = 0;

	const defSubOrder: SubOrder = {
		SubOrderID: 0,
		OrderID: 0,
		UserID: 0,
		OrderUserID: 0,
		ActivityLineID: 0,
		SubOrderName: "",
		SubOrderNote: "",
		WorkingDays: 0,
		OrderCode: "",
		OrderName: "",
		CustomerName: "",
		Internal: false,
		ActivityLineName: "",
		Manager: "",
		StartDate: new Date(),
		EndDate: new Date(),
		OrderStartDate: new Date(),
		OrderEndDate: new Date(),
		CreationDate: new Date(),
		StepsWorkingHours: 0,
		ProfilesWorkingHours: 0,
		AssignedWorkingHours: 0
	};

	const [SubOrder, setSubOrder] = useState<SubOrder>(defSubOrder);
	const [userList, setUserList] = useState<SelectListItem[]>([]);
	const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(undefined);
	const [activityLineList, setLineList] = useState<SelectListItem[] | []>([]);
	const [selectedLine, setSelectedLine] = useState<SelectListItem | undefined>(undefined);

	const [rangeStartDate, setRangeStartDate] = useState<Date>(new Date());
	const [rangeEndDate, setRangeEndDate] = useState<Date>(new Date());
	const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
	const [maxWorkingDays, setMaxWorkingDays] = useState<number>(0);
	const [order, setOrder] = useState<Order>();

	useEffect(() => {

		if (context.objSelected) {
			if (props.new) {
				const ord: Order = context.objSelected;

				id = context.objSelected.OrderID as number;
				SubOrder.StartDate = ord.StartDate;
				SubOrder.EndDate = ord.EndDate;

				const maxWDays: number =
					ord.WorkingDays
					- ord.SubOrdersAssignedWorkingDays;

				SubOrder.WorkingDays = maxWDays;
				setSubOrder({ ...SubOrder, WorkingDays: maxWDays });
				setRangeStartDate(ord.StartDate);
				setRangeEndDate(context.objSelected.EndDate);
				setMaxWorkingDays(maxWDays);
				setOrder(ord);
			}
			else {
				id = context.objSelected.SubOrderID as number;
			}
		}
	}, [props.new]);

	useEffect(() => {
		GetUserList();
	}, []);

	useEffect(() => {
		if (SubOrder) {
			setSelectedLine(activityLineList[getIndexSelectedById(activityLineList, SubOrder.ActivityLineID)])
			setSelectedUser(userList[getIndexSelectedById(userList, SubOrder.UserID)])
		}
	}, [SubOrder]);

	function GetItem() {
		SVC.getSubOrderData(id).then((d) => {
			if (d.data) {
				setSubOrder(d.data);
				getOrder(d.data);
				const order: Order = context.objSelected;
				setMaxWorkingDays(
					order.WorkingDays
					- order.SubOrdersAssignedWorkingDays
					- d.data.WorkingDays);

				context.objectName = context.objSelected.OrderName + " | " + context.objSelected.SubOrderName;

				if (d.data.OrderStartDate) {
					setRangeStartDate(d.data.OrderStartDate);
					setRangeEndDate(d.data.OrderEndDate);
				}

				setIsReadOnly(d.data.OrderUserID != userInfo.Me!.UserID);
			}
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true,
				messaggioAlert: "Errore di caricamento SubOrder: " + e,
				titoloAlert: "Errore"
			});
		}).finally(() => {
			context.setContext({ ...context, hiddenDetail: false });
		});
	}

	function getOrder(so: SubOrder) {
		OSVC.getOrder(so.OrderID).then((d) => {
			setOrder(d.data);
			setMaxWorkingDays(
				d.data.WorkingDays
				- d.data.SubOrdersAssignedWorkingDays
				+ so.WorkingDays);
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true,
				messaggioAlert: "Errore di caricamento Order: " + e,
				titoloAlert: "Errore"
			});
		}).finally(() => {

		});

	}

	function GetUserList() {
		ISVC.getSelectItemList(SelectItemsListTypes.USERS, SubOrder.UserID).then((l) => {
			if (l.data) {
				setSelectedUser(l.data[0]);
				setUserList(l.data);
				const userID: number = Number(l.data[0].Value);
				SubOrder.UserID = userID;
			}
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true, /* showAttesa: false, */
				messaggioAlert: "Errore di caricamento USERS: " + e, titoloAlert: "Errore"
			});
		}).finally(() => {
			GetActivityLinesList();
		});
	}


	function GetActivityLinesList() {
		ISVC.getSelectItemList(SelectItemsListTypes.ACTIVITY_LINES, SubOrder.ActivityLineID).then((l) => {
			if (l.data) {
				setLineList(l.data);
				setSelectedLine(l.data[0]);
				const activityLineID: number = Number(l.data[0].Value);
				SubOrder.ActivityLineID = activityLineID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento ACTIVITY_LINES: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			if (id) {
				if (props.new) {
					setSubOrder({ ...SubOrder, OrderID: id });
					context.setContext({
						...context,
						objTypeSelected: SelectedObjectTypes.SUBORDER,
						/* showAttesa: false, */
						hiddenDetail: false, objectName: ""
					});
				}
				else {
					GetItem();
				}
			}
			else {
				context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
			}
		});
	}

	//validazione del form
	const isValid = (): boolean => {
		return SubOrder.SubOrderName !== "" && SubOrder.OrderID !== 0 && SubOrder.UserID !== 0;
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = SubOrder;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setName(e: ChangeEvent<HTMLInputElement>) {

		const name: string = e.target.value;
		SubOrder.SubOrderName = name;
		setContext();
	}

	function setNote(e: ChangeEvent<HTMLTextAreaElement>) {
		const note: string = e.target.value;
		SubOrder.SubOrderNote = note;
		setContext();
	}

	function setUserID(v: any, i: any) {
		const userID: number = Number(v.Value);
		setSelectedUser(v);
		SubOrder.UserID = userID;
		setContext();
	}

	function setActivityLineID(v: any, i: any) {
		const activityLineID: number = Number(v.Value);
		setSelectedLine(v);
		SubOrder.ActivityLineID = activityLineID;
		setContext();
	}

	function setWorkingDays(e: ChangeEvent<HTMLInputElement>) {

		let days: number = Number(e.target.value);

		if (days > maxWorkingDays) {
			days = maxWorkingDays
		}

		SubOrder.WorkingDays = days;
		setContext();
	}

	function setStartDate(day: Date) {
		if (day !== undefined) {
			SubOrder.StartDate = day;
			//setSubOrder({ ...SubOrder, StartDate: day });
			setContext();
		}
	}

	function setEndDate(day: Date) {

		if (day !== undefined) {
			SubOrder.EndDate = day;
			//setSubOrder({ ...SubOrder, EndDate: day });
			setContext();
		}
	}

	// function formatDate(date?: Date): string{
	// 	return date? new Date(date).toLocaleDateString(): new Date().toLocaleTimeString();
	// }

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>

				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Nome deliverable</label>
						<input className="form-control" name="SubOrderName"
							onChange={setName}
							readOnly={isReadOnly}
							defaultValue={SubOrder.SubOrderName} ref={register({ required: true, maxLength: 100 })} />
					</div>
					<div className="form-group col">
						<label className="form-label">Responsabile</label>
						<Select
							filterOption={selectFilter}
							value={selectedUser}
							options={userList}
							isSearchable
							onChange={setUserID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
							isDisabled={isReadOnly}
						/>
					</div>
					<div className="form-group col">
						<label className="form-label">Linea attività</label>
						<Select
							filterOption={selectFilter}
							value={selectedLine}
							options={activityLineList}
							isSearchable
							onChange={setActivityLineID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
							isDisabled={isReadOnly}
						/>
					</div>

				</div>

				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Data inizio</label>
						<DayPickerInput
							dayPickerProps={{
								month: new Date(SubOrder.StartDate),
								todayButton: TODAY_LABEL[language],
								locale: language,
								months: MONTHS[language],
								weekdaysLong: WEEKDAYS_LONG[language],
								weekdaysShort: WEEKDAYS_SHORT[language],
								firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
								labels: LABELS[language],
								disabledDays: [{
									before: new Date(rangeStartDate),
									after: new Date(SubOrder.EndDate)
								}]
							}}
							placeholder={"Non impostata"}
							format={dateFormat}
							value={new Date(SubOrder.StartDate).toLocaleDateString()}
							onDayChange={day => setStartDate(day)}
							inputProps={{
								readOnly: "readonly"
							}}
							formatDate={day => formatDate(day)}
						/>
					</div>
					<div className="form-group col">
						<label className="form-label">Data fine</label>
						<DayPickerInput
							dayPickerProps={{
								month: new Date(SubOrder.EndDate),
								todayButton: TODAY_LABEL[language],
								locale: language,
								months: MONTHS[language],
								weekdaysLong: WEEKDAYS_LONG[language],
								weekdaysShort: WEEKDAYS_SHORT[language],
								firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
								labels: LABELS[language],
								disabledDays: [{
									before: new Date(SubOrder.StartDate),
									after: new Date(rangeEndDate)
								}]
							}}
							placeholder={"Non impostata"}
							format={dateFormat}
							value={new Date(SubOrder.EndDate).toLocaleDateString()}
							onDayChange={day => setEndDate(day)}
							inputProps={{
								readOnly: "readonly"
							}}
							formatDate={day => formatDate(day)}
						/>
					</div>
					<div className="form-group col">
						<label className="form-label">Giornate di lavoro
						<Info
								placement={"right"}
								text={"Il numero massimo di giornate assegnabile è " + maxWorkingDays}
							/>
						</label>
						<input
							type="number"
							min="0"
							max={maxWorkingDays}
							step="1"
							className="form-control"
							name="WorkingDays"
							onChange={setWorkingDays}
							value={SubOrder.WorkingDays}
							ref={register({ required: true })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Note</label>
						<textarea className="form-control" name="SubOrderNote"
							onChange={setNote}
							defaultValue={SubOrder.SubOrderNote} />
					</div>
				</div>

			</form>
		</div>
	);
}
