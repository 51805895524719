//componente per multi check boxe
//

import React, { ChangeEvent, useContext } from "react";
import { SelectListItem } from "../../models/models";
import { Col, Container, Form, Row } from "react-bootstrap";
import { UserContext } from "../maincontext";

export interface IMultiSelectProps {
	items: SelectListItem[];	//elenco degli items da visualizzare
	onChange(e: ChangeEvent<HTMLInputElement>): void; //funzione per la gesione del change
	columns?: number;
}
export function MultiSelect(props: IMultiSelectProps) {
	
	const userInfo = useContext(UserContext);

	let col = "col-12"
	if (props.columns){
		switch (props.columns){
			case 2:
				col = "col-6"
				break;
			case 3:
				col = "col-4"
				break;

			case 4:
				col = "col-3"
				break;
			case 6:
				col = "col-2"
				break;
			default:
				col = "col-12"
				break;
		}
	} 
	return (
		<Container>
					<Form.Group controlId="ProfileTypes" className="row">
						{props.items.map(function (item) {
							return <Form.Check
								key={"chk" + item.Value}
								id={"chk" + item.Value}
								data-id={item.Value}
								type="switch"
								label={item.Text}
								defaultChecked={item.Selected}
								disabled={item.Disabled && !userInfo.Me?.SuperAdmin}
								onChange={props.onChange}
								className= {col} />;
						})}
					</Form.Group>
		</Container>
	);
}