import * as React from "react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
export interface IUserAvailableHours {
	Disabled: boolean;
	MonthDay: number;
	IsWorkingDay: boolean;
	IsHolidayOrWeekEnd: boolean;
	AvailableHours: number;
}

export default function UserAvailableHours(props: IUserAvailableHours) {
	const bgColors = ["#1565C0", "#1976D2", "#1E88E5", "#2196F3", "#42A5F5", "#64B5F6", "#90CAF9", "#B0D0FB", "#BFDFFF"];
	const [labelClassName, setLabelClassName] = useState<string>("");
	const [dayClassName, setDayClassName] = useState<string>("");

	useEffect(() => {

		let cName: string = "";
		if (props.Disabled) {
			cName="text-muted";
		}
		else if (!props.IsWorkingDay) {
			cName = "line-through";
		}

		if (props.IsHolidayOrWeekEnd) {
			cName += " text-danger";
		}

		cName += " m-1";

		setLabelClassName(cName);

	}, [props.Disabled, props.IsWorkingDay]);

	return (
		<div className={"text-center d-flex flex-column mr-1"} style={{ width: "36px" }}>
			<span className={labelClassName}>{props.MonthDay}</span>
			<div
				className={"btn text-white"}
				style={{ "backgroundColor": (props.Disabled ? "#eeeeee" : bgColors[props.AvailableHours]) }}>
				{props.Disabled ? "-" : props.AvailableHours}
			</div>
		</div>
	);
}
