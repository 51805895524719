import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import PermitTypesServices from "../../api/PermitTypesServices";
import { PermitType, columnDetail, SelectedObjectTypes, MenuList } from "../../models/models";
import PermitTypeDetail from "./PermitTypeDetail";
import TitleBar from "../common/TitleBar";

export interface IPermitTypesList {
}

export default function PermitTypesList(props: IPermitTypesList) {
	const context = useContext(Maincontext);
	const SVC = new PermitTypesServices();

	//lista tipi permessi, aggiornata con setList da useState
	const [list, setList] = useState<PermitType[]>([]);

	//Colonne da visualizzare nella lista
	const columnDetails: columnDetail[] = [
		{ name: "PermitTypeDescription", text: "Tipologia di permesso", position: 1 },
		{ name: "FullDay", text: "Intera giornata", position: 2 }
	];

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList]);

	//carica i dati dal servizio e aggiorna lo stato della lista
	function GetList() {

		//context.setContext({ ...context, showAttesa: true });
		SVC.getPermitTypesList().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento tipologie di permessi: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			//chiude il loading panel e imposta la voce selezionata nel menu della sidebar
			context.setContext({ ...context, /*showAttesa: false,*/ selectedMenu: MenuList.PERMIT_TYPES });
		});
	}

	//funzione per l'eliminazione di una voce dalla lista
	//nel caso il servizio restituisce una stringa, viene visualizzata
	//in un alert
	function onDelete(e: MouseEvent<HTMLButtonElement>) {

		const permitType: PermitType = context.objSelected;

		SVC.PermitTypeDelete(permitType).then((d) => {

			if (d.data !== "") {
				//visualizzazione del messaggio se restituito dal servizio
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione tipologia di permesso";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			//chiusura loading panel e aggiornamento della lista
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			{/* bread crumb */}
			<TitleBar title="Tipologie di permessi" />
			{/* impostazione della lista */}
			<Lista obj={list} details={[
				//tasti
				{
					// tasto edit
					detail: <PermitTypeDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.PERMIT_TYPE
				},
				{
					//tasto elimina, di tipo funzione (esegue una funzione e non apre un dettaglio)
					//richiede conferma
					detail: <PermitTypeDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.PERMIT_TYPE,
					isFunction: true, 	//di tipo funzione (esegue una funzione e non apre un dettaglio)
					confirm: true,		//richiede conferma
					confirmMessage: "Eliminare la tipologia di permesso?",
					onClick: onDelete	//funzione da eseguire al click del tasto
				}
			]}
			//impostazione generale della lista
			columnDetails={columnDetails} 		//colonne da visualizzare
			addButton={true}					//tasto Aggiungi visualizzato
			addElement={<PermitTypeDetail />}	//elemento da utilizzare per il tasto Aggiungi
			addType={SelectedObjectTypes.PERMIT_TYPE} //tipo di oggetto gestito
			addPanelSize={undefined}	//dimensioni del pannello di aggiunta elemento
			detailPosition={1}
			/>
		</div>
	);
}