import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { WorkingHoursType } from "../../models/models";
import WorkingHoursTypeServices from "../../api/WorkingHoursTypeServices";
import { Form } from "react-bootstrap";
import HoursMinutes from "../common/HoursMinutes";

export interface IWorkingHoursTypeDetail {
}
export default function WorkingHoursTypeDetail(props: IWorkingHoursTypeDetail) {

	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();
	const SVC = new WorkingHoursTypeServices();

	let id = 0;

	if (context.objSelected) {
		id = context.objSelected.WorkingHoursTypeID as number;
	}

	const defWorkingHoursType: WorkingHoursType = {
		"WorkingHoursTypeID": 0,
		"WorkingHoursTypeName": "",
		"Sunday": 0,
		"Monday": 0,
		"Tuesday": 0,
		"Wednesday": 0,
		"Thursday": 0,
		"Friday": 0,
		"Saturday": 0,
		"Holiday": 0,
		"CreationDate": new Date()
	};

	const [workingHoursType, setWorkingHoursType] = useState<WorkingHoursType>(defWorkingHoursType);

	useEffect(() => {
		if (id) {
			GetItem();
		}
		else {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		}
	}, []);

	function GetItem() {
		SVC.getWorkingHoursType(id).then((d) => {
			if (d.data) {				
				setWorkingHoursType(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento WorkingHoursTypes: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
		context.objectName = context.objSelected.WorkingHoursType;
	}

	//validazione del form
	const isValid = (): boolean => {
		return workingHoursType.WorkingHoursTypeName !== "";
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = workingHoursType;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setName(e: ChangeEvent<HTMLInputElement>) {
		const name: string = e.target.value;
		workingHoursType.WorkingHoursTypeName = name;
		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label d-block">Orario di lavoro</label>
						<input className="form-control" name="WorkingHoursTypeName"
							defaultValue={workingHoursType.WorkingHoursTypeName}
							ref={register({ required: true, maxLength: 50 })} onChange={setName} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Lunedì</label>
						<HoursMinutes Minutes={workingHoursType.Monday} onChange={(v: number) => {
							workingHoursType.Monday = v;
							setContext();
						}} />
					</div>
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Martedì</label>
						<HoursMinutes Minutes={workingHoursType.Tuesday} onChange={(v: number) => {
							workingHoursType.Tuesday = v;
							setContext();
						}} />
					</div>
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Mercoledì</label>
						<HoursMinutes Minutes={workingHoursType.Wednesday} onChange={(v: number) => {
							workingHoursType.Wednesday = v;
							setContext();
						}} />
					</div>
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Giovedì</label>
						<HoursMinutes Minutes={workingHoursType.Thursday} onChange={(v: number) => {
							workingHoursType.Thursday = v;
							setContext();
						}} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Venerdì</label>
						<HoursMinutes Minutes={workingHoursType.Friday} onChange={(v: number) => {
							workingHoursType.Friday = v;
							setContext();
						}} />
					</div>
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Sabato</label>
						<HoursMinutes Minutes={workingHoursType.Saturday} onChange={(v: number) => {
							workingHoursType.Saturday = v;
							setContext();
						}} />
					</div>
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Domenica</label>
						<HoursMinutes Minutes={workingHoursType.Sunday} onChange={(v: number) => {
							workingHoursType.Sunday = v;
							setContext();
						}} />
					</div>
					<div className="form-group col-lg-3">
						<label className="form-label d-block">Festivi</label>
						<HoursMinutes Minutes={workingHoursType.Holiday} onChange={(v: number) => {
							workingHoursType.Holiday = v;
							setContext();
						}} />
					</div>
				</div>
			</form>
		</div>
	);
}