/*
	componente per l'impostazione del numero di ore già assegnate a una risorsa
	viene utilizzato nel componente WeekTable
	il componente cambia colore in base alle ore assegnate:
	verde se non ci sono ore già assengate
	arangio se assegnate parzialmente
	rosso se tutte le 8 ore sono state assegnate
	è possibile impostare un size "sm" | "lg" | "xl" 
	se il size non è impostato viene utilizzato quello di default lg
*/
import React, { useState, useEffect, CSSProperties } from 'react';

export interface IReadOnlyCell {
	key?: string;
	value: number;
	size?: "sm" | "lg" | "xl" | undefined;
	fixedBgColor?: boolean;
	maxValue: number;
}

export function ReadOnlyCell(props: IReadOnlyCell) {
	const [size, setSize] = useState<number>(32);
	const [fontSize, setFontSize] = useState<number>(12);
	const [] = useState<number>(12);
	const [bgColor, setBgColor] = useState<string>("");
	useEffect(() => {

		switch (props.size) {
			case "sm":
				setSize(28);
				setFontSize(10);
				/* setPadding(1); */
				break;

			case "lg":
				setSize(36);
				setFontSize(12);
				/* setPadding(3); */
				break;

			case "xl":
				setSize(52);
				setFontSize(14);
				/* setPadding(8); */
				break;

			default:
				setSize(36);
				setFontSize(12);
				/* setPadding(3); */
				break;
		}

		//let bgColor: string = "";
		if (props.fixedBgColor){
			setBgColor("Gray");
		}
		else{
		switch (props.value) {
			case 0:
				setBgColor("MediumSeaGreen");
				break;

			case props.maxValue:
				setBgColor("Crimson");
				break;

			default:
				setBgColor("orange");
				break;
		}
	}

	}, [props.value, props.size]);

	const mystyle: CSSProperties = {
		width: size + "px",
		height: size + "px",
		textAlign: "center",
		lineHeight: size + "px",
		fontSize: fontSize +"pt",
		/* paddingTop: padding, */
		color: "white",
		backgroundColor: bgColor
	};
	return (
		<div key={props.key} style={mystyle}>
			{props.value}
		</div>
	);
}