import React, { useContext, useState, useEffect } from "react";
import { UserContext, Maincontext } from "../../maincontext";
import { userTie as ic_userTie } from "react-icons-kit/icomoon/userTie";
import { wrench as ic_wrench } from "react-icons-kit/icomoon/wrench";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import Icon from "react-icons-kit";
import { Table, Button } from "react-bootstrap";
import OrderServices from "../../../api/OrderServices";
import { Order } from "../../../models/models";

export interface IUserOrdersWidget {
	UserID?: number
}

export default function UserOrdersWidget(props: IUserOrdersWidget) {
	const context = useContext(Maincontext);
	const UserInfo = useContext(UserContext);
	const SVC = new OrderServices();
	const [list, setList] = useState<Order[]>([]);

	useEffect(() => {
		GetList();
	}, []);

	function GetList() {
		SVC.getUserActiveOrders(props.UserID).then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento commesse: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	return (

		<div className="widget border-0" >
			<h6 className={list.length > 0 ? "title" : "title"}>
				<Icon icon={ic_wrench} className="mr-1" size={20} />Commesse attive</h6>
			{list.length > 0 ?
				<Table size="sm">
					<thead>
						<tr>
							<th>Cliente</th>
							<th>Commessa</th>
							<th>Data inizio</th>
							<th>Data fine</th>
						</tr>
					</thead>
					<tbody>
						{list.map(function (item) {
							return <tr key={"Ord_tr_" + item.OrderID}>
								<td key={"Ord_custname_td_" + item.OrderID}>
									{item.CustomerName}
								</td>
								<td key={"Ord_name_td_" + item.OrderID}>
									{item.OrderName}
								</td>
								<td className="date-width" key={"Ord_strtdate_td_" + item.OrderID}>
									{new Date(item.StartDate).toLocaleDateString()}
								</td>
								<td className="date-width" key={"Ord_endtdate_td_" + item.OrderID}>
									{new Date(item.EndDate).toLocaleDateString()}
								</td>
							</tr>;
						}
						)
						}
					</tbody>
				</Table>
				: <span>Nessuna commessa attiva assegnata</span>
			}
		</div>
	);
}

