import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import Select from "react-select";
import { Customer, SelectItemsListTypes, SelectListItem } from "../../models/models";
import CustomerServices from "../../api/CustomerServices";
import SelectItemServices from "../../api/SelectItemServices";
import { Form } from "react-bootstrap";
import { getIndexSelectedById, selectFilter } from "../../core/Utilities";



export default function CustomerDetail() {
	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();
	const SVC = new CustomerServices();
	const ISVC = new SelectItemServices();

	const defCustomer: Customer = {
		CustomerID: 0,
		Internal: false,
		NationID: 0,
		CountryID: 0,
		CountryName: "",
		Address: "",
		ZipCode: "",
		ProvinceCode: "",   //readonly
		RegionName: "",   //readonly
		CustomerName: "",   //max 100
		CustomerCode: "",
		VatNumber: "",      //max 16
		Enabled: true,
		CreationDate: new Date()     //readonly
	};

	const [customer, setCustomer] = useState<Customer>(defCustomer);
	const [countryList, setCountryList] = useState<SelectListItem[] | []>([]);
	const [provincesList, setProvinceList] = useState<SelectListItem[] | []>([]);
	const [nationsList, setNationList] = useState<SelectListItem[] | []>([]);
	const [selectedOptionCountry, setSelectedOptionCountry] = useState<SelectListItem | undefined>(undefined);
	const [selectedOptionProvince, setSelectedOptionProvince] = useState<SelectListItem | undefined>(undefined);
	const [selectedOptionNation, setSelectedOptionNation] = useState<SelectListItem | undefined>(undefined);

	let id = 0;
	if (context.objSelected) {
		id = context.objSelected.CustomerID as number;
	}

	useEffect(() => {
		
		GetNationsList();	

	}, []);



	useEffect(() => {

		if (customer.NationID == 0) {
			customer.CountryName = '';
		}

		setSelected();
		
	}, [customer]);

	useEffect(() => {

		if (id == 0) {
			setSelected();
		}		
		
	}, [provincesList]);


	function GetItem(cstomerID: number) {
		SVC.getCustomer(cstomerID).then((d) => {
			if (d.data) {
				setCustomer(d.data);

				context.objectName = context.objSelected.CustomerName;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Customer: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function GetNationsList() {
		ISVC.getSelectItemList(SelectItemsListTypes.NATIONS, 0).then((l) => {
			if (l.data) {
				setNationList(l.data);
				GetProvincesList(0);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Nazioni: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}

	function GetProvincesList(nationID: number) {

		ISVC.getSelectItemList(SelectItemsListTypes.PROVINCES, nationID).then((l) => {
			if (l.data) {
				setProvinceList(l.data);
				if (id != 0) {
					GetItem(id);
				}
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Provinces List: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function GetCountryList(proviceCode: string) {

		ISVC.getCountriesItemList(proviceCode).then((l) => {
			if (l.data) {
				setCountryList(l.data);
				const countryID = customer.CountryID;
				if (l.data.some(e => e.Value == countryID.toString())) {
					//esiste l'item
					const item: SelectListItem = l.data[getIndexSelectedById(l.data, countryID)];
					setSelectedOptionCountry(item);
				}
				else {
					//non esiste l'item
					const item: SelectListItem = l.data[0];
					setSelectedOptionCountry(item);
					customer.CountryID = Number(item.Value);	
					context.saveEnabled = isValid();									
				}
			}			
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Country Items: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, hiddenDetail: false });
		});
	}

	function setSelected(){

				if (id !== 0) {
					//modifica cliente

					const nationID = customer.NationID;
					const n_item: SelectListItem = nationsList[getIndexSelectedById(nationsList, nationID)];
					setSelectedOptionNation(n_item);

					const ProvinceCode = customer.ProvinceCode;
					const p_item: SelectListItem = provincesList[getIndexSelectedById(provincesList, ProvinceCode)];
					setSelectedOptionProvince(p_item);
					GetCountryList(ProvinceCode);
				}
				else if (provincesList.length > 0) {
					//nuovo cliente
					const nationID = 0;
					const n_item: SelectListItem = nationsList[getIndexSelectedById(nationsList, nationID)];
					setSelectedOptionNation(n_item);

					const p_item: SelectListItem = provincesList[0];
					setSelectedOptionProvince(p_item);
					GetCountryList(p_item.Value);
				}
	}

	//validazione del form
	const isValid = (): boolean => {
		return customer.CustomerName !== "" && customer.CustomerCode !== "";
	};

	function setContext() {
		
		const valid = isValid();
		if (valid) {
			context.objSelected = customer;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setEnabled(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
		customer.Enabled = enabled;
		setContext();
	}

	function setInternal(e: ChangeEvent<HTMLInputElement>) {
		const internal = Boolean(e.target.checked);
		customer.Internal = internal;
		setContext();
	}
	function setName(e: ChangeEvent<HTMLInputElement>) {

		const name: string = e.target.value;
		customer.CustomerName = name;
		setContext();

	}
	function setCode(e: ChangeEvent<HTMLInputElement>) {

		const name: string = e.target.value;
		customer.CustomerCode = name;
		setContext();

	}
	function setCountryName(e: ChangeEvent<HTMLInputElement>) {

		const countryName: string = e.target.value;
		customer.CountryName = countryName;
		setContext();

	}

	function setAddress(e: ChangeEvent<HTMLInputElement>) {

		const address: string = e.target.value;
		customer.Address = address;
		setContext();

	}

	function setZipCode(e: ChangeEvent<HTMLInputElement>) {

		const zipcode: string = e.target.value;
		customer.ZipCode = zipcode;
		setContext();

	}

	function setVatNumber(e: ChangeEvent<HTMLInputElement>) {

		const vat: string = e.target.value;
		customer.VatNumber = vat;
		setContext();

	}

	function setCountryID(v: any, i: any) {

		const countryID: number = Number(v.Value);
		setSelectedOptionCountry(v);
		customer.CountryID = countryID;
		setContext();

	}

	function setNationID(v: any, i: any) {

		const nationID: number = Number(v.Value);
		setSelectedOptionNation(v);
		customer.NationID = nationID;
		setContext();
	}

	function setProvinceID(v: any, i: any) {
		const ProvinceCode: string = v.Value;
		setSelectedOptionProvince(v);
		customer.ProvinceCode = ProvinceCode;
		GetCountryList(ProvinceCode);
		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<Form.Check
							id="chkEnabled"
							type="switch"
							label={"Interno"}
							checked={customer.Internal}
							onChange={setInternal} />
					</div>
					<div className="form-group col">
						<Form.Check
							id="chkEnabled"
							type="switch"
							label={"Abilitato"}
							checked={customer.Enabled}
							onChange={setEnabled} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-9">
						<label className="form-label">Cliente</label>
						<input className="form-control" name="CustomerName"
							onChange={setName}
							defaultValue={customer?.CustomerName} ref={register({ required: true, maxLength: 100 })} />
					</div>
					<div className="form-group col-3">
						<label className="form-label">Codice</label>
						<input className="form-control" name="CustomerCode"
							onChange={setCode}
							defaultValue={customer?.CustomerCode} ref={register({ required: true, maxLength: 50 })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Partita IVA/C.F.</label>
						<input className="form-control" name="VatNumber"
							onChange={setVatNumber}
							defaultValue={customer?.VatNumber} ref={register({ required: true, maxLength: 16 })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Stato</label>
						<Select
							filterOption={selectFilter}
							value={selectedOptionNation}
							options={nationsList}
							isSearchable
							onChange={setNationID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</div>
					<div className="form-group col">
						
						<label className="form-label">Provincia</label>
						<Select
							filterOption={selectFilter}
							value={customer.NationID == 0? selectedOptionProvince: undefined}
							options={customer.NationID == 0? provincesList: []}
							isSearchable
							onChange={setProvinceID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
							isDisabled={customer.NationID !== 0}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Comune</label>
						{customer.NationID == 0 ?
							<Select
								filterOption={selectFilter}
								value={selectedOptionCountry}
								options={countryList}
								isSearchable
								onChange={setCountryID}
								getOptionLabel={c => c.Text}
								getOptionValue={c => c.Value}
							/>
							:
							<input className="form-control"
								name="CountryName"
								onChange={setCountryName}
								defaultValue={customer?.CountryName}
								ref={register({ required: false, maxLength: 100 })} />
						}
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-9">
						<label className="form-label">Indirizzo</label>
						<input className="form-control"
							name="Address"
							onChange={setAddress}
							defaultValue={customer?.Address}
							ref={register({ required: false, maxLength: 100 })} />

					</div>
					<div className="form-group col-3">
						<label className="form-label">CAP</label>
						<input className="form-control"
							name="Address"
							onChange={setZipCode}
							defaultValue={customer?.ZipCode}
							ref={register({ required: false, maxLength: 6 })} />

					</div>
				</div>
			</form>
		</div >
	);
}
