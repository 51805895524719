import React, { useContext, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import { Modal, Button } from "react-bootstrap";


export default function SystemAlert() {
	const context = useContext(Maincontext);

	const onHideAlert = (e: MouseEvent<HTMLButtonElement>): void => {
		HideAlert();
	};

	const HideAlert = (): void => {
		context.setContext({ ...context, showAlert: false, titoloAlert: "", messaggioAlert: "" });
	};

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={context.showAlert}
				onHide={() => HideAlert}
			>
				<Modal.Header closeButton>
					<Modal.Title>{context.titoloAlert}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{context.messaggioAlert}</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						size="sm"
						onClick={onHideAlert}>
						Chiudi
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
