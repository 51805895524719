import { ActivityStep } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class ActivityStepServices {

	//elenco completo 
	async getActivityStepsList() {
		return axios.get<ActivityStep[]>(`${await SVC.getApiBaseUri()}/api/ActivitySteps`, { headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getActivityStep(id: number) {
		const params = {
			id: id
		};
		return axios.get<ActivityStep>(`${await SVC.getApiBaseUri()}/api/ActivitySteps`, { params, headers: await SVC.setAzureAuth() });
	}

	//update singolo elemento
	async ActivityStepUpdate(ActivityStep: ActivityStep) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/ActivitySteps`, ActivityStep, { headers: await SVC.setAzureAuth() });
	}

	async ActivityStepDelete(ActivityStep: ActivityStep) {

		const params = {
			id: ActivityStep.ActivityStepID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/ActivitySteps`,
			{ params, headers: await SVC.setAzureAuth() });
	}
}