//componente per la gestione delle ore lavorate in un giorno
import React, {
  useContext,
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
  useRef,
} from "react";
import { Maincontext, UserContext } from "../../maincontext";
import {
  UserDayTimeSheet,
  SelectListItem,
  PermitType,
  TimeSheet,
  TimesheetTypes,
  SelectItemsListTypes,
  OverTime,
} from "../../../models/models";
import TimeSheetServices from "../../../api/TimeSheetServices";
import {
  Popover,
  Button,
  Modal,
  Alert,
  Form,
  OverlayTrigger,
} from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import HoursMinutes from "../../common/HoursMinutes";
import Select, { ActionMeta } from "react-select";
import { selectFilter, minutesToHours } from "../../../core/Utilities";
import SelectItemServices from "../../../api/SelectItemServices";
import PermitTypesServices from "../../../api/PermitTypesServices";
import SubOrderStepProfileServices from "../../../api/SubOrderStepProfileServices";
import SaveButton from "../../common/SaveButton";

export interface IDayTimeSheetDetail {
  timeSheetDate: Date;
  readonly?: boolean | undefined;
  userID: number;
}

export default function DayTimeSheetDetail(props: IDayTimeSheetDetail) {
  const context = useContext(Maincontext);
  const userInfo = useContext(UserContext);

  const [hiddenDetail, setHiddenDetail] = useState<boolean>(true);

  const [extraActivityList, setExtraActivityList] = useState<SelectListItem[]>(
    []
  );
  const [activityDescriptions, setActivityDescriptions] = useState<
    SelectListItem[]
  >([]);
  const SVC = new TimeSheetServices();
  const ISVC = new SelectItemServices();
  const PSVC = new PermitTypesServices();
  const SOSVC = new SubOrderStepProfileServices();
  const [showPopover, setShowPopover] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [saveEnabled, setSaveEnabled] = useState<boolean>(true);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const [exetendedList, setExtendedList] = useState<boolean>(false);
  const [openedMenu, setOpenedMenu] = useState<boolean | undefined>(undefined);
  const [permitTypesList, setPermitTypesList] = useState<PermitType[]>([]);
  //riepilogo
  const [totalMinutes, setTotalMinutes] = useState<number>(0);
  const [totalOvertimeMinutes, setTotalOvertimeMinutes] = useState<number>(0);
  const [totalPermitMinutes, setTotalPermitMinutes] = useState<number>(0);
  const [totalNoOvertimeMinutes, setTotalNoOvertimeMinutes] =
    useState<number>(0);
  const [mainTotalMinutes, setMainTotalMinutes] = useState<number>(0);
  const [activityExtraSelected, setActivityExtraSelected] = useState(0);

  const UserDayTimeSheetDayDef: UserDayTimeSheet = {
    UserID: props.userID,
    TimeSheetDate: props.timeSheetDate,
    TimeSheets: [],
    AuthorizedOverTimes: [],
  };

  const [userDayTimeSheet, setUserDayTimeSheet] = useState<UserDayTimeSheet>(
    UserDayTimeSheetDayDef
  );

  useEffect(() => {
    if (context.showUserDayTimeSheet == true) {
      setUserDayTimeSheet(UserDayTimeSheetDayDef);
      GetPermitTypesList();
      //GetExtraActivityItems();
      GetActivityDescriptions();
      setActivityExtraSelected(0);
      GetDayTimesheet();
      setSaveEnabled(true);
    }
  }, [context.showUserDayTimeSheet]);

  useEffect(() => {
    if (exetendedList) {
      GetExtraActivityItems();
    }
  }, [exetendedList]);

  useEffect(() => {
    SummaryRefresh();
  }, [userDayTimeSheet]);

  useEffect(() => {
    GetExtraActivityItems();
  }, [activityExtraSelected]);

  function GetDayTimesheet() {
    SVC.getUserDayTimeSheet(props.timeSheetDate, props.userID)
      .then((d) => {
        console.log(d.data);
        setUserDayTimeSheet(d.data);
      })
      .catch((e) => {
        context.setContext({
          ...context,
          showAlert: true,
          messaggioAlert: "Errore di caricamento TimeSheet del giorno: " + e,
          titoloAlert: "Errore",
        });
      })
      .finally(() => {
        setHiddenDetail(false);
      });
  }

  function GetExtraActivityItems() {
    switch (activityExtraSelected) {
      case 0:
        ISVC.getExtraActivityItems(props.timeSheetDate)
          .then((d) => {
            let itemList: SelectListItem[] = d.data;
            let count: number = 0;
            itemList.forEach(function (item) {
              if (item.Selected) {
                count++;
              }
            });
            //se non è richiesta la lista completa ed esiste almeno un elemento selected
            //filtra la lista

            if (
              userInfo.Me!.RecentActivitiesList &&
              !exetendedList &&
              count > 0
            ) {
              itemList = d.data.filter((x) => x.Selected == true);
              const item: SelectListItem = {
                Text: "Elenco completo...",
                Value: "-1",
              };
              itemList.push(item);
            }
            setExtraActivityList(itemList);
          })
          .catch((e) => {
            context.setContext({
              ...context,
              showAlert: true,
              messaggioAlert:
                "Errore di caricamento attività sulla commessa: " + e,
              titoloAlert: "Errore",
            });
          })
          .finally(() => {});
        break;
      case 1:
        ISVC.getActiveOrdersItems(props.timeSheetDate)
          .then((d) => {
            setExtraActivityList(d.data);
          })
          .catch((e) => {
            context.setContext({
              ...context,
              showAlert: true,
              messaggioAlert: "Errore di caricamento Extra Attività: " + e,
              titoloAlert: "Errore",
            });
          })
          .finally(() => {});
        break;
      case 2:
        ISVC.getSelectItemList(SelectItemsListTypes.ORDERS_WITH_AVAILABLE, 0)
          .then((d) => {
            setExtraActivityList(d.data);
          })
          .catch((e) => {
            context.setContext({
              ...context,
              showAlert: true,
              messaggioAlert: "Errore di caricamento reperibilità: " + e,
              titoloAlert: "Errore",
            });
          })
          .finally(() => {});
        break;
    }
    if (activityExtraSelected) {
    } else {
    }
  }

  function GetPermitTypesList() {
    PSVC.getPermitTypesList()
      .then((d) => {
        setPermitTypesList(d.data);
      })
      .catch((e) => {
        context.setContext({
          ...context,
          showAlert: true,
          messaggioAlert: "Errore di caricamento tipoloia di permessi: " + e,
          titoloAlert: "Errore",
        });
      })
      .finally(() => {
        setHiddenDetail(false);
      });
  }

  function GetActivityDescriptions() {
    ISVC.getSelectItemList(SelectItemsListTypes.ACTIVITY_DESCRIPTIONS, 0)
      .then((d) => {
        setActivityDescriptions(d.data);
      })
      .catch((e) => {
        context.setContext({
          ...context,
          showAlert: true,
          messaggioAlert: "Errore di caricamento descrizioni attività: " + e,
          titoloAlert: "Errore",
        });
      })
      .finally(() => {});
  }

  function Save(e: MouseEvent<HTMLButtonElement>) {
    setShowPopover(false);
    //prepara l'elenco per il salvataggio dei dati
    //se esiste un permesso FullDay elimina tutto il resto
    let permitTypeID: number = 0;
    for (var x = 0; x < userDayTimeSheet.TimeSheets.length; x++) {
      if (
        userDayTimeSheet.TimeSheets[x].TimeSheetType ==
        TimesheetTypes.FULL_DAY_PERMIT
      ) {
        permitTypeID = userDayTimeSheet.TimeSheets[x].PermitTypeID!;
      }
    }
    if (permitTypeID != 0) {
      //elimina tutti i dati diversi da permesso intera giornata
      for (var x = 0; x < userDayTimeSheet.TimeSheets.length; x++) {
        let i: number = userDayTimeSheet.TimeSheets.length;
        while (i--) {
          if (
            userDayTimeSheet.TimeSheets[i].TimeSheetType !==
            TimesheetTypes.FULL_DAY_PERMIT
          ) {
            userDayTimeSheet.TimeSheets.splice(i, 1);
          }
        }
      }
    } else {
      //elimina tutti gli elementi senza minutaggio
      let i: number = userDayTimeSheet.TimeSheets.length;
      while (i--) {
        if (
          userDayTimeSheet.TimeSheets[i].Minutes == 0 &&
          userDayTimeSheet.TimeSheets[i].OvertimeMinutes == 0 &&
          userDayTimeSheet.TimeSheets[i].OvertimeNightMinutes == 0 &&
          userDayTimeSheet.TimeSheets[i].Available == false
        ) {
          userDayTimeSheet.TimeSheets.splice(i, 1);
        }
      }
    }

    //save procedures
    SVC.UserDayTimeSheetUpdate(userDayTimeSheet, props.userID)
      .then((d) => {
        if (d !== undefined && d.data !== "") {
          setAlertMessage(d.data);
        } else {
          context.setContext({ ...context, refreshList: !context.refreshList });
          closeDialog();
        }
      })
      .catch((e: React.SetStateAction<string>) => {
        setAlertMessage("Errore del server: " + e.toString());
      })
      .finally(() => {});
  }

  //restituisce le descrizioni per le attività non pianificate
  //o per le attività dirette sulla commessa
  function ActivityDescriptionItems(OrderActivity: boolean): SelectListItem[] {
    let items: SelectListItem[] = [];
    activityDescriptions.forEach((element) => {
      if (element.Selected == OrderActivity) {
        items.push(element);
      }
    });
    return items;
  }

  const closeDialog = () => {
    setHiddenDetail(true);
    setSaveEnabled(false);
    setAlertMessage("");
    setShowPopover(false);
    setExtendedList(false);
    setOpenedMenu(undefined);
    context.setContext({ ...context, showUserDayTimeSheet: false });
  };

  function addActivityDescription(event: ChangeEvent<HTMLSelectElement>) {
    const activityDescriptionID: number = Number(event.currentTarget.value);
    const i: number = Number(event.currentTarget.getAttribute("data-id"));
    if (activityDescriptionID == -1) {
      delete userDayTimeSheet.TimeSheets.filter(function (element) {
        return element.TimeSheetType == TimesheetTypes.EXTRA;
      })[i].ActivityDescriptionID;
    } else {
      userDayTimeSheet.TimeSheets.filter(function (element) {
        return element.TimeSheetType == TimesheetTypes.EXTRA;
      })[i].ActivityDescriptionID = activityDescriptionID;
    }
  }

  function setUserNoteAssegnate(event: ChangeEvent<HTMLTextAreaElement>) {
    const userNote: string = event.currentTarget.value;
    const i: number = Number(event.currentTarget.getAttribute("data-id"));
    userDayTimeSheet.TimeSheets.filter(function (element) {
      return element.TimeSheetType == TimesheetTypes.ASSIGNED;
    })[i].UserNote = userNote;
  }

  function setSupport(checked: boolean, id: number, type: TimesheetTypes) {
    console.log("ID", id);

    userDayTimeSheet.TimeSheets.filter(function (element) {
      return element.TimeSheetType === type;
    })[id].Support = checked;

    setUserDayTimeSheet({
      ...userDayTimeSheet,
      TimeSheets: userDayTimeSheet.TimeSheets,
    });
  }

  function setUserNote(event: ChangeEvent<HTMLTextAreaElement>) {
    const userNote: string = event.currentTarget.value;
    const i: number = Number(event.currentTarget.getAttribute("data-id"));
    userDayTimeSheet.TimeSheets.filter(function (element) {
      return element.TimeSheetType == TimesheetTypes.EXTRA;
    })[i].UserNote = userNote;
  }

  function onSelectMenuClosed() {}

  function addExtraActivity(v: any, i: any) {
    const subOrderStepProfileID: number = Number(v.Value);
    if (subOrderStepProfileID == -1) {
      setExtendedList(true);
    } else {
      const description: string = v.Text;
      let timeSheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
      let available = activityExtraSelected == 2;

      //se già esiste non lo aggiunge
      // for (var x = 0; x < timeSheets.length; x++) {
      // 	if (timeSheets[x].SubOrderStepProfileID === subOrderStepProfileID && timeSheets[x].Available == available) {
      // 		return;
      // 	}
      // };

      setOpenedMenu(false);
      SOSVC.GetOrderIDFromSubOrderStepProfileID(subOrderStepProfileID)
        .then((d) => {
          const orderID: number = d.data;
          const ts: TimeSheet = {
            TimeSheetID: 0,
            TimeSheetDate: props.timeSheetDate,
            SubOrderStepProfileID: subOrderStepProfileID,
            OrderID: orderID,
            Description: description,
            Minutes: 0,
            OvertimeMinutes: 0,
            OvertimeNightMinutes: 0,
            AssignedHours: 0,
            TimeSheetType: TimesheetTypes.EXTRA,
            ActivityLineID: activityExtraSelected ? 0 : 1,
            Available: available,
            Support: false,
            UserNote: "",
          };

          timeSheets.push(ts);

          setUserDayTimeSheet({
            ...userDayTimeSheet,
            TimeSheets: timeSheets,
          });
        })
        .catch((e) => {
          context.setContext({
            ...context,
            showAlert: true,
            messaggioAlert: "Errore di caricamento commessa: " + e,
            titoloAlert: "Errore",
          });
        })
        .finally(() => {});
    }
  }

  //aggiorna i totali nell tabella di riepilogo
  function SummaryRefresh() {
    let tMinutes: number = 0;
    let tOvertimeMinutes: number = 0;
    let tPermitMinutes: number = 0;
    let tNoOvertimeMinutes: number = 0;
    let tMainTotalMinutes: number = 0;

    let workingHoursList: TimeSheet[] = userDayTimeSheet.TimeSheets.filter(
      function (element) {
        return element.TimeSheetType == TimesheetTypes.ASSIGNED;
      }
    );

    let extraWorkingHoursList: TimeSheet[] = userDayTimeSheet.TimeSheets.filter(
      function (element) {
        return element.TimeSheetType == TimesheetTypes.EXTRA;
      }
    );

    let permits: TimeSheet[] = userDayTimeSheet.TimeSheets.filter(function (
      element
    ) {
      return element.TimeSheetType == TimesheetTypes.PERMIT;
    });

    let fullDayPrmits: TimeSheet[] = userDayTimeSheet.TimeSheets.filter(
      function (element) {
        return element.TimeSheetType == TimesheetTypes.FULL_DAY_PERMIT;
      }
    );

    //se esiste un permesso di tipo full day
    //vengono coperte le 8 ore di lavoro
    //e gli input sono disabilitati
    if (fullDayPrmits.length != 0) {
      tPermitMinutes = 480;
      tMainTotalMinutes = 480;
      tNoOvertimeMinutes = 480;
      setInputDisabled(true);
    } else {
      setInputDisabled(false);

      for (var x = 0; x < workingHoursList.length; x++) {
        tMinutes += workingHoursList[x].Minutes;
        tOvertimeMinutes += workingHoursList[x].OvertimeMinutes;
        tOvertimeMinutes += workingHoursList[x].OvertimeNightMinutes;
      }

      for (var x = 0; x < extraWorkingHoursList.length; x++) {
        tMinutes += extraWorkingHoursList[x].Minutes;
        tOvertimeMinutes += extraWorkingHoursList[x].OvertimeMinutes;
        tOvertimeMinutes += extraWorkingHoursList[x].OvertimeNightMinutes;
      }

      for (var x = 0; x < permits.length; x++) {
        tMinutes += permits[x].Minutes;
      }

      tNoOvertimeMinutes = tPermitMinutes + tMinutes;
      tMainTotalMinutes = tPermitMinutes + tMinutes + tOvertimeMinutes;
    }

    setTotalMinutes(tMinutes);
    setTotalOvertimeMinutes(tOvertimeMinutes);
    setTotalNoOvertimeMinutes(tNoOvertimeMinutes);
    setTotalPermitMinutes(tPermitMinutes);
    setMainTotalMinutes(tMainTotalMinutes);

    //setSaveEnabled(tMainTotalMinutes > 0);
  }

  const isFullDayPermitChecked = (permitTypeID: number): boolean => {
    let exists: boolean = false;

    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    for (var x = 0; x < timesheets.length; x++) {
      if (timesheets[x].PermitTypeID === permitTypeID) {
        exists = true;
      }
    }
    return exists;
  };

  //EVENTI

  //modifica minuti lavorati in attività assegnata o extra
  function setMinutes(minutes: number, index: number, type: TimesheetTypes) {
    console.log(minutes, index, type);

    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    let extra: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.EXTRA
    );
    let assigned: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.ASSIGNED
    );
    let permits: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.PERMIT
    );
    let fullday_permits: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.FULL_DAY_PERMIT
    );

    console.log("timesheets", timesheets);
    console.log("extra", extra);
    console.log("assigned", assigned);
    console.log("permits", permits);
    console.log("fullday_permits", fullday_permits);

    //modifica il valore
    switch (type) {
      case TimesheetTypes.ASSIGNED: {
        assigned[index].Minutes = minutes;
        break;
      }
      case TimesheetTypes.EXTRA: {
        extra[index].Minutes = minutes;
        break;
      }
      case TimesheetTypes.PERMIT: {
        permits[index].Minutes = minutes;
        break;
      }
      case TimesheetTypes.FULL_DAY_PERMIT: {
        fullday_permits[index].Minutes = minutes;
        break;
      }
    }

    timesheets = [];
    timesheets = assigned.concat(extra).concat(fullday_permits).concat(permits);

    setUserDayTimeSheet({
      ...userDayTimeSheet,
      TimeSheets: timesheets,
    });
  }

  function setOvertimeMinutes(
    minutes: number,
    index: number,
    type: TimesheetTypes
  ) {
    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    let extra: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.EXTRA
    );
    let assigned: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.ASSIGNED
    );
    let permits: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.PERMIT
    );
    let fullday_permits: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.FULL_DAY_PERMIT
    );

    //timesheets = timesheets.filter(x => x.TimeSheetType==TimesheetTypes.EXTRA);

    //modifica il valore

    switch (type) {
      case TimesheetTypes.ASSIGNED: {
        assigned[index].OvertimeMinutes = minutes;
        break;
      }
      case TimesheetTypes.EXTRA: {
        extra[index].OvertimeMinutes = minutes;
        break;
      }
      case TimesheetTypes.PERMIT: {
        permits[index].OvertimeMinutes = minutes;
        break;
      }
      case TimesheetTypes.FULL_DAY_PERMIT: {
        fullday_permits[index].OvertimeMinutes = minutes;
        break;
      }
    }

    timesheets = [];
    timesheets = assigned.concat(extra).concat(fullday_permits).concat(permits);

    setUserDayTimeSheet({
      ...userDayTimeSheet,
      TimeSheets: timesheets,
    });
  }

  function setOvertimeNightMinutes(
    minutes: number,
    index: number,
    type: TimesheetTypes
  ) {
    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    let extra: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.EXTRA
    );
    let assigned: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.ASSIGNED
    );
    let permits: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.PERMIT
    );
    let fullday_permits: TimeSheet[] = timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.FULL_DAY_PERMIT
    );

    //timesheets = timesheets.filter(x => x.TimeSheetType==TimesheetTypes.EXTRA);

    //modifica il valore

    switch (type) {
      case TimesheetTypes.ASSIGNED: {
        assigned[index].OvertimeNightMinutes = minutes;
        break;
      }
      case TimesheetTypes.EXTRA: {
        extra[index].OvertimeNightMinutes = minutes;
        break;
      }
      case TimesheetTypes.PERMIT: {
        permits[index].OvertimeNightMinutes = minutes;
        break;
      }
      case TimesheetTypes.FULL_DAY_PERMIT: {
        fullday_permits[index].OvertimeNightMinutes = minutes;
        break;
      }
    }

    timesheets = [];
    timesheets = assigned.concat(extra).concat(fullday_permits).concat(permits);

    setUserDayTimeSheet({
      ...userDayTimeSheet,
      TimeSheets: timesheets,
    });
  }

  //modifica minuti dei permessi non FullDay
  function setPermitMinutes(minutes: number, permitTypeID: number) {
    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    let exists: boolean = false;

    //modifica il valore
    for (var x = 0; x < timesheets.length; x++) {
      if (timesheets[x].PermitTypeID === permitTypeID) {
        timesheets[x].Minutes = minutes;
        exists = true;
      }
    }

    if (!exists) {
      const ts: TimeSheet = {
        TimeSheetID: 0,
        TimeSheetDate: props.timeSheetDate,
        PermitTypeID: permitTypeID,
        Description: "",
        Minutes: minutes,
        OvertimeMinutes: 0,
        OvertimeNightMinutes: 0,
        AssignedHours: 0,
        TimeSheetType: TimesheetTypes.PERMIT,
        ActivityLineID: 0,
        Available: false,
        Support: false,
        UserNote: "",
      };
      timesheets.push(ts);
    }

    setUserDayTimeSheet({
      ...userDayTimeSheet,
      TimeSheets: timesheets,
    });
  }

  const getPermitMinutes = (permitTypeID: number): number => {
    let minutes: number = 0;

    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    for (var x = 0; x < timesheets.length; x++) {
      if (timesheets[x].PermitTypeID === permitTypeID) {
        minutes = timesheets[x].Minutes;
      }
    }
    return minutes;
  };

  const overtimeAuthorized = (orderID: number | undefined): boolean => {
    let auth: boolean = false;

    if (orderID && userDayTimeSheet.AuthorizedOverTimes.length > 0) {
      userDayTimeSheet.AuthorizedOverTimes.forEach(function (item: OverTime) {
        if (item.OrderID === orderID && item.OverTimeMinutes > 0) {
          auth = true;
        }
      });
    }
    return auth;
  };

  const overtimeNightAuthorized = (orderID: number | undefined): boolean => {
    let auth: boolean = false;

    if (orderID && userDayTimeSheet.AuthorizedOverTimes.length > 0) {
      userDayTimeSheet.AuthorizedOverTimes.forEach(function (item: OverTime) {
        if (item.OrderID === orderID && item.OverTimeNightMinutes > 0) {
          auth = true;
        }
      });
    }
    return auth;
  };

  function setFullDayPermit(e: ChangeEvent<HTMLInputElement>) {
    const enabled: boolean = Boolean(e.target.checked);
    const permitTypeID: number = Number(e.target.id);
    let exists: boolean = false;
    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    //modifica il valore

    if (enabled) {
      for (var x = 0; x < timesheets.length; x++) {
        if (timesheets[x].PermitTypeID === permitTypeID) {
          exists = true;
        } else if (
          timesheets[x].TimeSheetType == TimesheetTypes.FULL_DAY_PERMIT
        ) {
          timesheets.splice(x, 1);
        }
      }
      if (!exists) {
        const ts: TimeSheet = {
          TimeSheetID: 0,
          TimeSheetDate: props.timeSheetDate,
          PermitTypeID: permitTypeID,
          Description: "",
          Minutes: 0,
          OvertimeMinutes: 0,
          OvertimeNightMinutes: 0,
          AssignedHours: 0,
          TimeSheetType: TimesheetTypes.FULL_DAY_PERMIT,
          ActivityLineID: 0,
          Available: false,
          Support: false,
          UserNote: "",
        };
        timesheets.push(ts);
      }
    } else {
      for (var x = 0; x < timesheets.length; x++) {
        if (timesheets[x].PermitTypeID === permitTypeID) {
          timesheets.splice(x, 1);
        }
      }
    }

    setUserDayTimeSheet({
      ...userDayTimeSheet,
      TimeSheets: timesheets,
    });
  }

  //modifica
  function ExtraActivityDelete(index: number) {
    let timesheets: TimeSheet[] = userDayTimeSheet.TimeSheets;
    //non considero eventuali item di tipo assegnato
    index += timesheets.filter(
      (x) => x.TimeSheetType == TimesheetTypes.ASSIGNED
    ).length;
    //elimina l'elemento se esiste
    timesheets.splice(index, 1);

    setUserDayTimeSheet({
      ...userDayTimeSheet,
      TimeSheets: timesheets,
    });
  }

  function getBgColor() {
    let bcColor: string = "green";
    if (totalNoOvertimeMinutes < 480) {
      bcColor = "orange";
    } else if (totalNoOvertimeMinutes > 480) {
      bcColor = "red";
    }

    return bcColor;
  }

  const closePopover = () => {
    setShowPopover(false);
  };

  const popover = (
    <Popover
      id="popover-basic"
      className={showPopover ? "" : "d-none"}
      style={{ zIndex: 9999 }}
    >
      <Popover.Title as="h3">Conferma</Popover.Title>
      <Popover.Content>
        Salvare i dati?
        <br />
        <br />
        <Button
          variant="outline-secondary"
          className="btn-sm"
          onClick={closePopover}
        >
          <Icon icon={ic_close} />
        </Button>
        &nbsp;
        <Button variant="outline-primary" className="btn-sm" onClick={Save}>
          <Icon icon={ic_check} />
        </Button>
      </Popover.Content>
    </Popover>
  );

  return (
    <div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size={"xl"}
        className={hiddenDetail ? "d-none" : ""}
        show={context.showUserDayTimeSheet}
        backdrop={"static"}
      >
        <Modal.Header>
          <Modal.Title className="fluid">
            {props.timeSheetDate.toLocaleDateString()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-row">
            <div className="form-group col">
              {/* tabella lavorazioni su attività assegnate */}
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="bg-light">Attività assegnate</th>
                    <th className="bg-light">Descrizione</th>
                    <th className="text-center bg-light">Ore</th>
                    <th className="text-center bg-light">Ord.</th>
                    <th className="text-center bg-light">Straord.</th>
                    <th className="text-center bg-light">Str. nott.</th>
                  </tr>
                </thead>
                <tbody>
                  {userDayTimeSheet.TimeSheets.filter(function (element) {
                    return element.TimeSheetType == TimesheetTypes.ASSIGNED;
                  }).map(function (item: TimeSheet, index: number) {
                    return (
                      <tr key={"tr_as_" + item.TimeSheetID}>
                        <td>
                          {item.Description}
                          <Form.Check
                            id={`support-chk-${TimesheetTypes.ASSIGNED}-${index}`}
                            type="switch"
                            label={`Affiancamento ${index}`}
                            checked={item.Support}
                            onChange={(event) =>
                              setSupport(
                                event.target.checked,
                                index,
                                TimesheetTypes.ASSIGNED
                              )
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            defaultValue={item.UserNote}
                            data-id={index}
                            maxLength={200}
                            style={{
                              width: "100%",
                              fontSize: "10pt",
                              borderColor: "#ced4da",
                            }}
                            onChange={setUserNoteAssegnate}
                          />
                        </td>
                        <td className="input-minute text-center">
                          {item.AssignedHours}
                        </td>
                        <td className="input-minute">
                          <HoursMinutes
                            Minutes={item.Minutes}
                            onChange={(v: number) => {
                              setMinutes(v, index, item.TimeSheetType);
                            }}
                            disabled={inputDisabled || props.readonly}
                          />
                        </td>
                        <td className="input-minute">
                          <HoursMinutes
                            Minutes={item.OvertimeMinutes}
                            onChange={(v: number) => {
                              setOvertimeMinutes(v, index, item.TimeSheetType);
                            }}
                            disabled={
                              inputDisabled ||
                              props.readonly ||
                              !overtimeAuthorized(item.OrderID)
                            }
                          />
                        </td>
                        <td className="input-minute">
                          <HoursMinutes
                            Minutes={item.OvertimeNightMinutes}
                            onChange={(v: number) => {
                              setOvertimeNightMinutes(
                                v,
                                index,
                                item.TimeSheetType
                              );
                            }}
                            disabled={
                              inputDisabled ||
                              props.readonly ||
                              !overtimeNightAuthorized(item.OrderID)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label className="form-label label-sm">
                <input
                  type="radio"
                  name="rdoActivityDescription"
                  checked={activityExtraSelected === 0}
                  value="0"
                  onClick={() => setActivityExtraSelected(0)}
                  disabled={inputDisabled || props.readonly}
                  className="mr-1"
                />
                Elenco attività non pianificate
              </label>
              <label className="form-label label-sm ml-3">
                <input
                  type="radio"
                  name="rdoActivityDescription"
                  value="1"
                  checked={activityExtraSelected === 1}
                  onClick={() => setActivityExtraSelected(1)}
                  disabled={inputDisabled || props.readonly}
                  className="mr-1"
                />
                Elenco commesse per riunioni, call, ecc.
              </label>
              {userInfo.Me?.Available ? (
                <label className="form-label label-sm ml-3">
                  <input
                    type="radio"
                    name="rdoActivityDescription"
                    value="2"
                    checked={activityExtraSelected === 2}
                    onClick={() => setActivityExtraSelected(2)}
                    disabled={inputDisabled || props.readonly}
                    className="mr-1"
                  />
                  Reperibilità
                </label>
              ) : (
                ""
              )}
              <Select
                filterOption={selectFilter}
                options={extraActivityList}
                isSearchable
                onChange={addExtraActivity}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
                isDisabled={inputDisabled || props.readonly}
                placeholder={"Seleziona..."}
                value={null}
                disabled={inputDisabled || props.readonly}
                onBlur={() => setOpenedMenu(false)}
                onFocus={() => setOpenedMenu(true)}
                menuIsOpen={openedMenu}
              />
              {userDayTimeSheet.TimeSheets.filter(function (element) {
                return element.TimeSheetType == TimesheetTypes.EXTRA;
              }).length > 0 ? (
                <table className="table table-sm table-bordered mt-3">
                  <thead>
                    <tr>
                      <th className="bg-light">Altre attività svolte</th>
                      <th className="text-center bg-light">Causale</th>
                      <th className="text-center bg-light">Descrizione</th>
                      <th className="text-center bg-light">Ord.</th>
                      <th className="text-center bg-light">Straord.</th>
                      <th className="text-center bg-light">Str. nott.</th>
                      <th className="input-minute border border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userDayTimeSheet.TimeSheets.filter(function (element) {
                      return element.TimeSheetType == TimesheetTypes.EXTRA;
                    }).map(function (item: TimeSheet, index: number) {
                      return (
                        <tr key={"tr_ts_extra_" + item.TimeSheetID}>
                          <td>{item.Description}</td>
                          <td>
                            {item.Available ? (
                              <span>Reperibile</span>
                            ) : (
                              <>
                                <select
                                  className={"form-control"}
                                  // autoFocus={openedMenu != undefined && openedMenu == false}
                                  style={{
                                    padding: 0,
                                    height: "auto",
                                    fontSize: "13px",
                                    minWidth: "180px",
                                    marginBottom: "8px",
                                  }}
                                  onChange={addActivityDescription}
                                  data-id={index}
                                >
                                  <option value={-1}>
                                    Selezionare la causale...
                                  </option>
                                  {ActivityDescriptionItems(
                                    item.ActivityLineID == 0
                                  ).map(function (i: SelectListItem) {
                                    return (
                                      <option
                                        value={i.Value}
                                        selected={
                                          i.Value ==
                                          (item.ActivityDescriptionID
                                            ? item.ActivityDescriptionID.toString()
                                            : "")
                                        }
                                      >
                                        {i.Text}
                                      </option>
                                    );
                                  })}
                                </select>

                                <Form.Check
                                  id={`support-chk-${TimesheetTypes.EXTRA}-${index}`}
                                  type="switch"
                                  label="Affiancamento"
                                  checked={item.Support}
                                  onChange={(event) =>
                                    setSupport(
                                      event.target.checked,
                                      index,
                                      TimesheetTypes.EXTRA
                                    )
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td>
                            <textarea
                              defaultValue={item.UserNote}
                              data-id={index}
                              data-type={TimesheetTypes.EXTRA}
                              maxLength={200}
                              style={{
                                width: "100%",
                                fontSize: "10pt",
                                borderColor: "#ced4da",
                              }}
                              onChange={setUserNote}
                            />
                          </td>
                          <td className="input-minute">
                            {!item.Available && (
                              <HoursMinutes
                                Minutes={item.Minutes}
                                onChange={(v: number) => {
                                  setMinutes(v, index, item.TimeSheetType);
                                }}
                                disabled={inputDisabled || props.readonly}
                              />
                            )}
                          </td>
                          <td className="input-minute">
                            {!item.Available && (
                              <HoursMinutes
                                Minutes={item.OvertimeMinutes}
                                onChange={(v: number) => {
                                  setOvertimeMinutes(
                                    v,
                                    index,
                                    item.TimeSheetType
                                  );
                                }}
                                disabled={
                                  inputDisabled ||
                                  props.readonly ||
                                  !overtimeAuthorized(item.OrderID)
                                }
                              />
                            )}
                          </td>
                          <td className="input-minute">
                            {!item.Available && (
                              <HoursMinutes
                                Minutes={item.OvertimeNightMinutes}
                                onChange={(v: number) => {
                                  setOvertimeNightMinutes(
                                    v,
                                    index,
                                    item.TimeSheetType
                                  );
                                }}
                                disabled={
                                  inputDisabled ||
                                  props.readonly ||
                                  !overtimeNightAuthorized(item.OrderID)
                                }
                              />
                            )}
                          </td>
                          <td className="input-minute border border-0">
                            <button
                              autoFocus={
                                openedMenu != undefined && openedMenu == false
                              }
                              className="btn btn-sm btn-link m-0 p-0"
                              onClick={() => ExtraActivityDelete(index)}
                              disabled={inputDisabled || props.readonly}
                            >
                              <Icon icon={ic_delete} size="20" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Assenza intera giornata</th>
                  </tr>
                </thead>
                <tbody>
                  {permitTypesList.map(function (item: PermitType) {
                    if (item.FullDay)
                      return (
                        <tr key={"prmt_tr" + item.PermitTypeID}>
                          <td>
                            <Form.Check
                              id={item.PermitTypeID.toString()}
                              type="switch"
                              label={item.PermitTypeDescription}
                              disabled={props.readonly}
                              onChange={setFullDayPermit}
                              checked={isFullDayPermitChecked(
                                item.PermitTypeID
                              )}
                            />
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
            <div className="form-group col">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Assenze</th>
                    <th className="text-center input-minute">Ore</th>
                  </tr>
                </thead>
                <tbody>
                  {permitTypesList.map(function (item: PermitType) {
                    if (!item.FullDay)
                      return (
                        <tr key={"prmt_2_tr" + item.PermitTypeID}>
                          <td>{item.PermitTypeDescription}</td>
                          <td className="input-minute">
                            <HoursMinutes
                              Minutes={getPermitMinutes(item.PermitTypeID)}
                              onChange={(v: number) => {
                                setPermitMinutes(v, item.PermitTypeID!);
                              }}
                              disabled={inputDisabled || props.readonly}
                            />
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
            <div className="form-group col">
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="text-center bg-light" colSpan={2}>
                      Riepilogo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Totale ore ordinarie</td>
                    <td className="text-right">
                      {minutesToHours(totalMinutes)}
                    </td>
                  </tr>
                  <tr>
                    <td>Totale ore straordinarie</td>
                    <td className="text-right">
                      {minutesToHours(totalOvertimeMinutes)}
                    </td>
                  </tr>
                  <tr>
                    <td>Totale ore assenza</td>
                    <td className="text-right">
                      {minutesToHours(totalPermitMinutes)}
                    </td>
                  </tr>
                  <tr>
                    <td>Esclusi straordinari</td>
                    <td className="text-right">
                      {minutesToHours(totalNoOvertimeMinutes)}
                    </td>
                  </tr>
                  <tr>
                    <td>Totale ore</td>
                    <td className="text-right">
                      {minutesToHours(mainTotalMinutes)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Alert variant={alertMessage === "" ? undefined : "warning"}>
            {alertMessage}
          </Alert>
          <Button variant="secondary" size="sm" onClick={() => closeDialog()}>
            {props.readonly ? "Chiudi" : "Annulla"}
          </Button>

          <SaveButton
            hidden={props.readonly}
            disabled={!saveEnabled}
            popover={popover}
            popoverFunction={setShowPopover}
            saveFunction={Save}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}
