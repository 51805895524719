import React, { useContext, useEffect, useState } from "react";
import { Maincontext, UserContext } from "../../maincontext";
import { ic_drag_handle } from 'react-icons-kit/md/ic_drag_handle'
import Icon from "react-icons-kit";
import { Table } from "react-bootstrap";
import { UserActivityLine } from "../../../models/models";
import UserServices from "../../../api/UserServices";

export interface IUserActivityLinesWidget {
	list: UserActivityLine[]
}

export default function UserActivityLinesWidget(props: IUserActivityLinesWidget) {
	const SVC = new UserServices();
	const UserInfo = useContext(UserContext);
	const context = useContext(Maincontext);

	return (
		<div className="widget p-2 border-0">
			<h6 className={"title"}>
				{/* <Icon icon={ic_drag_handle} className="mr-1" size={20} /> */}
				<span style={{ verticalAlign: "middle" }}>Linee di attività</span>
			</h6>
			{
				props.list.map(function (item) {
					return <div key={"div_" + item.ActivityLineID}>
						{item.ActivityLineName}
					</div>
				}
				)
			}
		</div>
	);
}

