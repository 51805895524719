import React, { useState, useEffect, useContext, useRef, ChangeEvent } from "react";
import { MenuList, TimeSheetApprovedMonth, TimeSheetMonthActivity, MonthStates, HistoricalTimeSheetMonthState, SelectListItem, SelectItemsListTypes, ContentTypes } from "../../models/models";
import TimesheetServices from "../../api/TimeSheetServices";
import TitleBar from "../common/TitleBar";
import { Maincontext } from "../maincontext";
import { minutesToHours, formatDateToString, selectFilter, IsAuthorized } from "../../core/Utilities";
import { OverlayTrigger, Popover, Button, Form, Col } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_history } from "react-icons-kit/md/ic_history";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import "../../styles/approving-timesheet.css";
import Select from "react-select";
import SelectItemServices from "../../api/SelectItemServices";
import { MONTHS } from "../../models/locale";

export interface IWorkingTimesheets {
}

export default function WorkingTimesheets(props: IWorkingTimesheets) {
	const context = useContext(Maincontext);
	const svc = new TimesheetServices();
	const ISVC = new SelectItemServices();

	const [timeSheetApprovedMonths, setTimeSheetApprovedMonths] = useState<TimeSheetApprovedMonth[]>([]);
	const [filteredTimeSheetApprovedMonths, setFilteredTimeSheetApprovedMonths] = useState<TimeSheetApprovedMonth[]>([]);

	const editing_authorized: boolean =
		(
			IsAuthorized(ContentTypes.USERS)
		);

	const [yearsList, setYearsList] = useState<SelectListItem[] | []>([]);
	const [monthList, setMonthList] = useState<SelectListItem[]>([]);
	const [userList, setUserList] = useState<SelectListItem[] | []>([]);

	const [selectedYear, setSelectedYear] = useState<SelectListItem | undefined>(undefined);
	const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();
	const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(undefined);

	const [HistoricalTSMonthState, setHistoricalTSMonthState] = useState<HistoricalTimeSheetMonthState[]>();
	const textAreaNotesRef = useRef<HTMLTextAreaElement>(null);
	const [operationType, setOperationType] = useState<MonthStates>();

	const [lastYear, setLastYear] = useState<number>();
	const [lastMonth, setLastMonth] = useState<number>();

	const [note, setNote] = useState<string>("");
	const [searchValue, setSearchValue] = useState<string>("");

	useEffect(() => {

		context.setContext({ ...context, selectedMenu: MenuList.WORKING_TIMESHEETS });

		let newDate = new Date();
		newDate = new Date(newDate.setMonth(newDate.getMonth() - 1));
		setLastYear(newDate.getFullYear());
		setLastMonth(newDate.getMonth() + 1);
		GetApproverList();

	}, []);

	useEffect(() => {

		if (selectedUser != undefined) {
			const approverID: number = selectedUser ? Number(selectedUser.Value) : 0;
			GetTimeSheetApprovedMonths(approverID);
		}

	}, [selectedUser]);

	useEffect(() => {

		setSearchValue("");
		setFilteredTimeSheetApprovedMonths(timeSheetApprovedMonths);

	}, [timeSheetApprovedMonths]);

	useEffect(() => {

		Search();

	}, [searchValue]);

	function Search() {

		if (searchValue == "") {
			setFilteredTimeSheetApprovedMonths(timeSheetApprovedMonths);
		}
		else {
			const s: string = searchValue.toUpperCase();
			const filtered: TimeSheetApprovedMonth[] =
				timeSheetApprovedMonths.filter(x => x.UserFullName.toUpperCase().includes(s));
			setFilteredTimeSheetApprovedMonths(filtered);
		}
	}

	function GetApproverList() {

		ISVC.getWorkingTimesheetsApproverItems().then((l) => {
			if (l.data) {
				const item: SelectListItem = {
					Value: "0",
					Text: "Tutti"
				}
				l.data.unshift(item);
				setUserList(l.data);
				setSelectedUser(l.data[0]);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Users: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function GetTimeSheetApprovedMonths(approverID: number) {

		svc.GetTimeSheetApprovedMonths(0, 0, approverID, false).then((d) => {
			if (d.data == null) {
				setTimeSheetApprovedMonths([]);
			}
			else {
				setTimeSheetApprovedMonths(d.data);
			}
		});
	}

	function setUserID(v: any, i: any) {
		setSelectedUser(v);
	}

	function onSearchValueChange(e: ChangeEvent<HTMLInputElement>) {
		const val: string = e.target.value;
		setSearchValue(val);
	}

	return (
		<>
			<TitleBar title="Timesheet in corso" />
			<Form className="form-search-list">
				<Form.Row>
					<Col md={3}>
						<label className="form-label">Approvatore</label>
						<Select
							filterOption={selectFilter}
							value={selectedUser}
							options={userList}
							isSearchable
							onChange={setUserID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</Col>
					<Col md={3}>
						<label className="form-label">Risorsa</label>
						<input type="text"
							onChange={onSearchValueChange}
							className="form-control"
							placeholder="Cerca..."
							value={searchValue} />
					</Col>
				</Form.Row>
			</Form>
			<div className="p-3">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>
								Risorsa
							</th>
							<th>
								Approvatore
							</th>
							<th className="text-center">
								Anno
							</th>
							<th className="text-center">
								Mese
							</th>
							<th>
								Stato
							</th>
							<th className="text-center">
								Ore ord.
							</th>
							<th className="text-center">
								Ore str.
							</th>
							<th className="text-center">
								Str. nott.
							</th>
							<th className="text-center">
								Permessi
							</th>
							<th className="text-center">
								Tot. ore
							</th>
							<th className="text-center">
								Assenze
							</th>
						</tr>
					</thead>
					<tbody>
						{filteredTimeSheetApprovedMonths.map(function (item: TimeSheetApprovedMonth) {
							return (
								<tr className="ts-resource-row">
									<td>
										<div className="d-inline-flex justify-content-between w-100 align-items-center">
											<div className="fullname" title={`${item.UserFullName}`}>{item.UserFullName}</div>
											<div className="ts-approving-tools d-inline-flex">
												{item.Editable && editing_authorized ?
													<a className="btn btn-link btn-sm p-0" title="Modifica..."
														href={`/monthtimesheet/${item.TimeSheetMonthID.toString()}`}
													>
														<Icon icon={ic_edit} size={24} />
													</a>
													:
													<a className="btn btn-link btn-sm p-0" title="Dettaglio mese"
														href={`/reports/monthtimesheet/${item.TimeSheetMonthID.toString()}`}
													>
														<Icon icon={ic_date_range} size={24} />
													</a>

												}

												<OverlayTrigger
													rootClose
													trigger="click"
													placement="auto-start"
													overlay={
														<Popover id={`ol_${item.TimeSheetMonthID}`} style={{ maxWidth: "400px" }}>
															<Popover.Title as="h3" style={{
																fontSize: "14px", margin: "8px",
																border: "none", borderRadius: "4px"
															}}>
																<strong>{item.UserFullName}</strong>
																<br />
																Riepilogo attività {item.TimeSheetMonthName} {item.TimeSheetYear.toString()}
															</Popover.Title>
															<Popover.Content style={{ minHeight: "100px", width: "400px" }}>
																<div className="container">
																	<div className="row pb-2">
																		{item.Activities != null && item.Activities.map(function (a: TimeSheetMonthActivity) {
																			return (
																				<>
																					<div className="col-10 p-1 pt-0">{a.Description}</div>
																					<div className="col-2 p-1 pt-0 text-right">
																						{minutesToHours(a.Minutes)}
																					</div>
																				</>
																			);
																		})
																		}
																	</div>
																</div>
															</Popover.Content>
														</Popover>
													}
												>
													<button className="btn btn-link btn-sm p-0" title="Riepilogo attività svolte">
														<Icon icon={ic_history} size={24} />
													</button>
												</OverlayTrigger>
											</div>
										</div>

									</td>
									<td>{item.ApproverFullName}</td>
									<td className="text-center">{item.TimeSheetYear}</td>
									<td className="text-center">{item.TimeSheetMonthName}</td>
									<td>{item.TimeSheetMonthStateDescription}</td>
									<td className="text-center">{minutesToHours(item.Minutes)}</td>
									<td className="text-center">{minutesToHours(item.OvertimeMinutes)}</td>
									<td className="text-center">{minutesToHours(item.OvertimeNightMinutes)}</td>
									<td className="text-center">{minutesToHours(item.Permits)}</td>
									<td className="text-center">{minutesToHours(item.TotalMinutes)}</td>
									<td className="text-center">{item.Absences}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
}
