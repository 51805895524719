import React, { useContext, MouseEvent, useState, useEffect, CSSProperties } from "react";
import { Maincontext } from "../../maincontext";
import { SubOrder, SubOrderStepsTreeView } from "../../../models/models";
import { Icon } from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import SuborderStepDetail, { ISubOrderStepDetailProps } from "./SubOrderStepDetail";
import SuborderStepProfileDetail, { ISubOrderStepProfileDetilProps } from "../SubOrderStepProfile/SubOrderStepProfileDetail";
import SuborderStepProfileUserDetail, { ISubOrderStepProfileUserDetilProps } from "../SubOrderStepProfileUser/SubOrderStepProfileUserDetail";
import QuickAssignment, { IQuickAssignmentProps } from "../SubOrderStepProfileUser/QuickAssignment";

import "../../../styles/treeview.css";

import SubOrderStepServices from "../../../api/SubOrderStepServices";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { iconaBottoni } from "../../common/List";
import { ConfirmButton } from "../../common/ConfirmButton";
import SubOrderStepProfileServices from "../../../api/SubOrderStepProfileServices";
import SubOrderStepProfileUserServices from "../../../api/SubOrderStepProfileUserServices";

export interface ISubOrderStepListProps {
	subOrder: SubOrder;
}

export default function SubOrderStepList(props: ISubOrderStepListProps) {

	const context = useContext(Maincontext);
	const svc = new SubOrderStepServices();
	let svcp = new SubOrderStepProfileServices();
	let svcpu = new SubOrderStepProfileUserServices();

	const [showPopoverModal, setShowPopoverModal] = useState(false);

	const [tBodies, setTBodies] = useState<JSX.Element[]>([]);
	const [treeViewData, setTreeViewData] = useState<SubOrderStepsTreeView[]>([]);
	const [displayCountInfo, setDisplayCountInfo] = useState<CSSProperties>({ display: "inline" });

	const [subOrder, setSubOrder] = useState<SubOrder>(props.subOrder);

	//properties per la configurazione dei dettagli
	const [subOrderStepProps, setSubOrderStepProps] = useState<ISubOrderStepDetailProps>({
		subOrderID: subOrder.SubOrderID,
		subOrderStepID: 0,
		activityLineID: subOrder.ActivityLineID,
		subOrderName: subOrder.SubOrderName,
		activityLineName: subOrder.ActivityLineName,
		subOrderWorkingHours: subOrder.WorkingDays * 8,
		subOrderStepsWorkingHours: subOrder.StepsWorkingHours
	});

	const [profileDetailProps, setProfileDetailProps] = useState<ISubOrderStepProfileDetilProps>({
		subOrderStepProfileID: 0,
		subOrderStepID: 0,
		subOrderStepWorkingHours: 0,
		subOrderStepProfilesWorkingHours: 0,
		activityStepName: "",
		subOrderName: subOrder.SubOrderName,
		activityLineName: subOrder.ActivityLineName,
		activityLineID: subOrder.ActivityLineID
	});

	const [userDetailProps, setUserDetailProps] = useState<ISubOrderStepProfileUserDetilProps>({
		SubOrderStepProfileID: 0,
		UserID: 0,
		profileTypeID: 0,
		activityStepName: "",
		profileTypeName: "",
		userFullName: "",
		subOrderName: subOrder.SubOrderName,
		activityLineName: subOrder.ActivityLineName,
		SubOrderStepProfileAssignedgHours: 0,
		SubOrderStepProfileWorkingHours: 0
	});

	const [quickAssignmentProps, setQuickAssignmentProps] = useState<IQuickAssignmentProps>({
		SubOrderStepProfileID: 0,
		subOrderName: "",
		profileTypeID: 0,
		activityStepName: "",
		profileTypeName: "",
		activityLineName: subOrder.ActivityLineName,
		subOrderStartDate: new Date(),
		subOrderEndDate: new Date(),
		SubOrderStepProfileWorkingHours: 0,
		SubOrderStepProfileAssignedgHours: 0
	});

	useEffect(() => {

		context.setContext({
			...context,
			showSubOrderStepDetail: false,
			showSubOrderStepProfileDetail: false,
			showSubOrderStepProfileUserDetail: false
		});

		setSubOrder(props.subOrder);
		setSubOrderStepProps(
			{
				...subOrderStepProps,
				subOrderStepID: 0,
				activityLineID: props.subOrder.ActivityLineID,
				subOrderID: props.subOrder.SubOrderID
			});

		setQuickAssignmentProps(
			{
				...quickAssignmentProps,
				subOrderName: props.subOrder.SubOrderName,
				activityLineName: props.subOrder.ActivityLineName,
				subOrderStartDate: props.subOrder.StartDate,
				subOrderEndDate: props.subOrder.EndDate
			});

		if (subOrder.SubOrderID !== 0) {
			getSubOrderStepsTreeView();
		}

	}, [props.subOrder]);

	useEffect(() => {

		renderLevelsTreeView();

	}, [treeViewData, displayCountInfo]);

	function getSubOrderStepsTreeView() {
		svc.getSubOrderStepsTreeView(subOrder.SubOrderID).then((d) => {
			setTreeViewData(d.data);
		});
	}

	function renderLevelsTreeView() {

		let nodesLevels: JSX.Element[] = [];

		// render node levels
		treeViewData.forEach(level1 => {

			// Step (Level 1)
			let profilesWorkingHours: number = 0;
			level1.StepProfiles.forEach(level2 => {
				profilesWorkingHours += level2.WorkingHours;
			});

			nodesLevels.push(renderLevel1(level1, profilesWorkingHours));
			level1.StepProfiles.forEach(level2 => {

				// StepProfile (Level 2)
				nodesLevels.push(renderLevel2(
					level2,
					level1.ActivityStepName,
					level1.WorkingHours,
					profilesWorkingHours));
				let assignedHours: number = 0;
				level2.StepProfileUsers.forEach(level3 => {
					assignedHours += level3.WorkingHours;
				});
				level2.StepProfileUsers.forEach(level3 => {

					// StepProfileUser (Level 3)
					nodesLevels.push(renderLevel3(level3,
						level2.SubOrderStepProfileID,
						assignedHours,
						level2.WorkingHours,
						level1.ActivityStepName,
						level2.ProfileTypeName));

				});
			});
		});

		setTBodies(nodesLevels);

		// se dopo l'eliminazione di un nodo figlio il nodo genitore non ha altri nodi figlio
		// si rimuove la classe expanded
		setTimeout(() => {

			let elLev1 = document.querySelector(".level1.expanded");
			let elLev2 = document.querySelector(".level2.expanded");

			if (!elLev2 && elLev1 && elLev1.nextElementSibling && elLev1.nextElementSibling.className !== "level2") {
				elLev1.className = elLev1.className.replace(/expanded/ig, "");
			}

			if (elLev2 && elLev2.nextElementSibling && elLev2.nextElementSibling.className !== "level3") {
				elLev2.className = elLev2.className.replace(/expanded/ig, "");
			}


		}, 10);

	}

	//STEPS FUNCTIONS =======================================================
	function addNewStep(e: MouseEvent<HTMLButtonElement>) {

		setSubOrderStepProps(
			{
				...subOrderStepProps,
				subOrderStepID: 0,
				activityLineID: props.subOrder.ActivityLineID,
				subOrderID: props.subOrder.SubOrderID,
				subOrderName: props.subOrder.SubOrderName,
				activityLineName: props.subOrder.ActivityLineName
			});

		context.setContext({
			...context,
			showSubOrderStepProfileDetail: false,
			showSubOrderStepProfileUserDetail: false,
			showSubOrderStepDetail: true,
			saveEnabled: false,
			showAlert: false,
			messaggioAlert: ""
		});
	}

	function editStep(subOrderStepID: number) {

		setSubOrderStepProps(
			{
				...subOrderStepProps,
				subOrderStepID: subOrderStepID,
				activityLineID: subOrder.ActivityLineID,
				subOrderID: subOrder.SubOrderID,
				activityLineName: props.subOrder.ActivityLineName
			});

		context.setContext({
			...context,
			showSubOrderStepProfileDetail: false,
			showSubOrderStepProfileUserDetail: false,
			showSubOrderStepDetail: true,
			saveEnabled: false,
			showAlert: false,
			messaggioAlert: ""
		});
	}

	function deleteStep(subOrderStepID: number) {

		svc.SubOrderStepDelete(subOrderStepID).then((d) => {

			if (d.data !== "") {
				//visualizzazione del messaggio se restituito dal servizio
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione fase deliverable";
				context.showAlert = true;
			}
			else {
				context.messaggioAlert = "";
				context.titoloAlert = "";
				context.showAlert = false;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server: " + e.toString();
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			//chiusura loading panel e aggiornamento della lista
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}


	//PROFILES FUNCTIONS =======================================================
	function addProfile(subOrderStepID: number,
		activityStepName: string,
		subOrderStepWorkingHours: number,
		subOrderStepProfilesWorkingHours: number) {

		setProfileDetailProps({
			...profileDetailProps,
			subOrderStepID: subOrderStepID,
			subOrderStepProfileID: 0,
			activityStepName: activityStepName,
			subOrderName: subOrder.SubOrderName,
			activityLineName: subOrder.ActivityLineName,
			subOrderStepWorkingHours: subOrderStepWorkingHours,
			subOrderStepProfilesWorkingHours: subOrderStepProfilesWorkingHours
		});

		context.setContext({
			...context,
			showSubOrderStepProfileDetail: true,
			showSubOrderStepProfileUserDetail: false,
			showSubOrderStepDetail: false,
			saveEnabled: false,
			showAlert: false,
			messaggioAlert: ""
		});
	}

	function editProfile(suborderstepid: number,
		suborderstepprofileid: number,
		activityStepName: string,
		subOrderStepWorkingHours: number,
		subOrderStepProfilesWorkingHours: number) {

		setProfileDetailProps({
			...profileDetailProps,
			subOrderStepID: suborderstepid,
			subOrderStepProfileID: suborderstepprofileid,
			activityStepName: activityStepName,
			subOrderName: subOrder.SubOrderName,
			activityLineName: subOrder.ActivityLineName,
			subOrderStepWorkingHours: subOrderStepWorkingHours,
			subOrderStepProfilesWorkingHours: subOrderStepProfilesWorkingHours
		});

		context.setContext({
			...context,
			showSubOrderStepProfileDetail: true,
			showSubOrderStepProfileUserDetail: false,
			showSubOrderStepDetail: false,
			saveEnabled: false,
			showAlert: false,
			messaggioAlert: ""
		});
	}

	function deleteProfile(subOrderStepProfileID: number) {
		//context.setContext({ ...context, showAttesa: true });

		svcp.SubOrderStepProfileDelete(subOrderStepProfileID).then((d) => {

			if (d.data !== "") {
				//visualizzazione del messaggio se restituito dal servizio
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione profilo";
				context.showAlert = true;
			}
			else {
				context.messaggioAlert = "";
				context.titoloAlert = "Eliminazione profilo";
				context.showAlert = false;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			//chiusura loading panel e aggiornamento della lista
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	//USERS FUNCTIONS =======================================================


	function fastAssignment(
		subOrderStepProfileID: number,
		profiletypeid: number,
		activityStepName: string,
		profileTypeName: string,
		workingHours: number,
		assignedWorkingHours: number
	) {

		setQuickAssignmentProps({
			...quickAssignmentProps,
			SubOrderStepProfileID: subOrderStepProfileID,
			profileTypeID: profiletypeid,
			profileTypeName: profileTypeName,
			activityStepName: activityStepName,
			SubOrderStepProfileWorkingHours: workingHours,
			SubOrderStepProfileAssignedgHours: assignedWorkingHours
		});

		context.setContext({
			...context,
			showSubOrderStepProfileDetail: false,
			showSubOrderStepProfileUserDetail: false,
			showSubOrderStepDetail: false,
			showFastAssignment: true,
			saveEnabled: false,
			showAlert: false,
			messaggioAlert: ""
		});
	}

	function addUser(
		subOrderStepProfileID: number,
		AssignedWorkingHours: number,
		subOrderStepProfileWorkingHours: number,
		profiletypeid: number,
		activityStepName: string,
		profileTypeName: string) {

		setUserDetailProps({
			...userDetailProps,
			SubOrderStepProfileID: subOrderStepProfileID,
			SubOrderStepProfileAssignedgHours: AssignedWorkingHours,
			SubOrderStepProfileWorkingHours: subOrderStepProfileWorkingHours,
			UserID: 0,
			profileTypeID: profiletypeid,
			profileTypeName: profileTypeName,
			activityStepName: activityStepName,
			userFullName: ""
		});


		context.setContext({
			...context,
			showSubOrderStepProfileDetail: false,
			showSubOrderStepProfileUserDetail: true,
			showSubOrderStepDetail: false,
			saveEnabled: false,
			showAlert: false,
			messaggioAlert: ""
		});
	}

	function editUser(
		subOrderStepProfileID: number,
		AssignedWorkingHours: number,
		subOrderStepProfileWorkingHours: number,
		userID: number,
		userFullName: string,
		activityStepName: string,
		profileTypeName: string) {

		setUserDetailProps({
			...userDetailProps,
			SubOrderStepProfileID: subOrderStepProfileID,
			SubOrderStepProfileAssignedgHours: AssignedWorkingHours,
			SubOrderStepProfileWorkingHours: subOrderStepProfileWorkingHours,
			UserID: userID,
			profileTypeName: profileTypeName,
			activityStepName: activityStepName,
			userFullName: userFullName
		});

		context.setContext({
			...context,
			showSubOrderStepProfileDetail: false,
			showSubOrderStepProfileUserDetail: true,
			showSubOrderStepDetail: false,
			saveEnabled: false,
			showAlert: false,
			messaggioAlert: ""
		});
	}

	function deleteUser(subOrderStepProfileID: number, userID: number) {

		svcpu.SubOrderStepProfileUserDelete(subOrderStepProfileID, userID).then((d) => {

			if (d.data !== "") {
				//visualizzazione del messaggio se restituito dal servizio
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione risorsa";
				context.showAlert = true;
			}
			else {
				context.messaggioAlert = "";
				context.titoloAlert = "Eliminazione risorsa";
				context.showAlert = false;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server: " + e.toString();
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			//chiusura loading panel e aggiornamento della lista
			context.setContext({ ...context, refreshList: !context.refreshList });
		});
	}

	//TREE VIEW FUNCTIONS =======================================================

	type Step = {
		ActivityStepID: number,
		ActivityStepName: string,
		SubOrderStepID: number,
		WorkingHours: number,
		StepProfiles: StepProfile[]
	}

	type StepProfile = {
		ProfileTypeID: number,
		ProfileTypeName: string,
		SubOrderStepID: number,
		SubOrderStepProfileID: number,
		WorkingHours: number,
		StepProfileUsers: StepProfileUser[]
	}

	type StepProfileUser = {
		UserID: number,
		UserFullName: string,
		WorkingHours: number
	}

	enum LevelContext {
		Profile = 1,
		User = 2
	}

	const sumWorkingHours = (levelData: any, level?: LevelContext): number => {

		let resSum: number = 0;

		if ((levelData as Step).StepProfiles) {
			if (level === LevelContext.Profile) {
				(levelData as Step).StepProfiles.forEach(stepProfile => {
					resSum += stepProfile.WorkingHours;
				});
			} else {
				(levelData as Step).StepProfiles.forEach(stepProfile => {
					stepProfile.StepProfileUsers.forEach(stepProfileUser => {
						resSum += stepProfileUser.WorkingHours;
					});
				});
			}
		} else {
			(levelData as StepProfile).StepProfileUsers.forEach(stepProfileUser => {
				resSum += stepProfileUser.WorkingHours;
			});
		}

		return resSum;

	};

	const countResources = (levelData: any, levelContext?: LevelContext): number => {

		let resCount: number = 0;

		if ((levelData as Step).StepProfiles) {
			if (levelContext === LevelContext.User) {
				(levelData as Step).StepProfiles.forEach(stepProfile => {
					resCount += stepProfile.StepProfileUsers.length;
				});
			} else {
				resCount = (levelData as Step).StepProfiles.length;
			}
		}

		if ((levelData as StepProfile).StepProfileUsers) {
			(levelData as StepProfile).StepProfileUsers.forEach(stepProfileUser => {
				resCount += 1;
			});
		}

		return resCount;

	};

	const renderLevel1 = (stepData: Step, profilesWorkingHours: number) => {
		return (
			<tbody className="level1" key={"level1_" + stepData.ActivityStepName}>
				<tr>
					<td>
						{stepData.StepProfiles.length > 0
							? (
								<>
									<span className="plus" onClick={e => toggleClass(e.currentTarget)}>
										{stepData.ActivityStepName}
									</span>
									<span style={displayCountInfo}>
										{countResources(stepData)}
									</span>
									<span style={displayCountInfo}>
										{countResources(stepData, LevelContext.User)}
									</span>
								</>
							)
							: (
								<>
									<span>
										{stepData.ActivityStepName}
									</span>
									<span style={displayCountInfo}>
										{countResources(stepData)}
									</span>
									<span style={displayCountInfo}>
										{countResources(stepData, LevelContext.User)}
									</span>
								</>
							)
						}
					</td>
					<td className="text-left">
						{stepData.WorkingHours > sumWorkingHours(stepData, LevelContext.User)
							?
							(
								<>
									<span className="text-warning">
										{sumWorkingHours(stepData, LevelContext.User)}
									</span>
									<span>
										&nbsp;({sumWorkingHours(stepData, LevelContext.User) - stepData.WorkingHours})
									</span>
								</>
							)
							:
							(
								<span className="text-success">{stepData.WorkingHours}</span>
							)
						}
					</td>
					<td className="text-left">{stepData.WorkingHours}</td>
					<td className="text-right">
						<Button title={"Aggiungi profilo"} variant="link" className="m-0 ml-1 p-0"
							onClick={() => {
								addProfile(
									stepData.SubOrderStepID,
									stepData.ActivityStepName,
									stepData.WorkingHours,
									profilesWorkingHours);
							}}>
							{iconaBottoni("plususer")}
						</Button>
						<Button title={"Modifica fase " + stepData.ActivityStepName} variant="link" className="m-0 ml-1 p-0"
							key={"stepedit" + stepData.SubOrderStepID.toString()}
							onClick={() => {
								editStep(stepData.SubOrderStepID);
							}}
						>{iconaBottoni("edit")}</Button>
						<ConfirmButton title={"Elimina fase " + stepData.ActivityStepName}
							//key={"stepcnf" + stepData.SubOrderStepID.toString()}
							icon={iconaBottoni("delete")}
							confirmMessage={"Eliminare la fase " + stepData.ActivityStepName + "?"}
							placement={"left"}
							className={"m-0-ml-0 p-0"}
							confirmFunction={(event: React.MouseEvent<HTMLButtonElement>) => {
								deleteStep(stepData.SubOrderStepID);
							}}
							toggleModal={() => { setShowPopoverModal(showState => !showState); }}
						/>
					</td>
				</tr>
			</tbody>
		);
	};

	const renderLevel2 = (
		profileData: StepProfile,
		activityStepName: string,
		subOrderStepWorkingHours: number,
		subOrderStepProfilesWorkingHours: number) => {
		return (
			<tbody className="level2" key={"level2_" + activityStepName + "_" + profileData.ProfileTypeName}>
				<tr>
					<td>
						{profileData.StepProfileUsers.length > 0 ?
							(
								<>
									<span className="plus" onClick={e => toggleClass(e.currentTarget)}>
										{profileData.ProfileTypeName}
									</span>
									<span style={displayCountInfo}>
										{countResources(profileData)}
									</span>
								</>
							) :
							(
								<>
									<span>{profileData.ProfileTypeName}</span>
									<span style={displayCountInfo}>
										{countResources(profileData)}
									</span>
								</>
							)}
					</td>
					<td className="text-left">
						{profileData.WorkingHours > sumWorkingHours(profileData) ?
							(
								<>
									<span className="text-warning">{sumWorkingHours(profileData)}</span>
									<span> ({sumWorkingHours(profileData) - profileData.WorkingHours})</span>
								</>
							) :
							(<span className="text-success">{profileData.WorkingHours}</span>)}
					</td>
					<td className="text-left">{profileData.WorkingHours}</td>
					<td className="text-right">
						<Button variant="link" className="m-0 ml-1 p-0"
							title="Assegnazione rapida"
							onClick={() => {
								fastAssignment(
									profileData.SubOrderStepProfileID,
									profileData.ProfileTypeID,
									activityStepName,
									profileData.ProfileTypeName,
									profileData.WorkingHours,
									sumWorkingHours(profileData)
								);
							}}
						>{iconaBottoni("flash")}</Button>
						<Button title={"Assegnazione per data"}
							variant="link" className="m-0 ml-1 p-0"
							onClick={() => {
								addUser(
									profileData.SubOrderStepProfileID,
									sumWorkingHours(profileData),
									profileData.WorkingHours,
									profileData.ProfileTypeID,
									activityStepName,
									profileData.ProfileTypeName);
							}}
						>{iconaBottoni("calendar")}</Button>
						<Button title={"Modifica profilo " + profileData.ProfileTypeName}
							variant="link" className="m-0 ml-1 p-0"
							onClick={() => {
								editProfile(profileData.SubOrderStepID,
									profileData.SubOrderStepProfileID,
									activityStepName,
									subOrderStepWorkingHours,
									subOrderStepProfilesWorkingHours - profileData.WorkingHours
								);
							}}
						>{iconaBottoni("edit")}</Button>
						<ConfirmButton title={"Elimina profilo " + profileData.ProfileTypeName}
							//key={"delprof" + profileData.SubOrderStepProfileID.toString()}
							icon={iconaBottoni("delete")}
							confirmMessage={"Eliminare il profilo " + profileData.ProfileTypeName + "?"}
							placement={"left"}
							className={"m-0-ml-0 p-0"}
							confirmFunction={(event: React.MouseEvent<HTMLButtonElement>) => {
								deleteProfile(profileData.SubOrderStepProfileID);
							}}
							toggleModal={() => { setShowPopoverModal(showState => !showState); }}
						/>
					</td>
				</tr>
			</tbody>
		);
	};

	const renderLevel3 = (userData: StepProfileUser,
		subOrderStepProfileID: number,
		AssignedWorkingHours: number,
		subOrderStepProfileWorkingHours: number,
		activityStepName: string,
		profileTypeName: string) => {
		return (
			<tbody className="level3" key={"level3_" + activityStepName + "_" + profileTypeName + "_" + userData.UserID}>
				<tr>
					<td><span>{userData.UserFullName}</span></td>
					<td className="text-left">{userData.WorkingHours}</td>
					<td className="text-left"></td>
					<td className="text-right">
						<Button variant="link" className="m-0 ml-1 p-0"
							title="Assegnazione per data"
							onClick={() => {
								editUser(subOrderStepProfileID,
									AssignedWorkingHours,
									subOrderStepProfileWorkingHours,
									userData.UserID,
									userData.UserFullName,
									activityStepName,
									profileTypeName
								);
							}
							}
						>{iconaBottoni("edit")}</Button>
						<ConfirmButton title={"Elimina risorsa " + userData.UserFullName}
							//key={"deluser" + userData.UserID.toString()}
							icon={iconaBottoni("delete")}
							confirmMessage={"Eliminare l'assegnazione alla risorsa " + userData.UserFullName + "?"}
							placement={"left"}
							className={"m-0-ml-0 p-0"}
							confirmFunction={(event: React.MouseEvent<HTMLButtonElement>) => {
								deleteUser(subOrderStepProfileID, userData.UserID);
							}}
							toggleModal={() => { setShowPopoverModal(showState => !showState); }}
						/>
					</td>
				</tr>
			</tbody>
		);
	};

	const toggleClass = (target: EventTarget & HTMLSpanElement) => {

		const parentEl: HTMLTableSectionElement = target.parentNode?.parentNode?.parentElement as HTMLTableSectionElement;

		if (parentEl.className.match(/level1/i)) {
			document.querySelectorAll(".level1").forEach(el => {
				if (el !== parentEl) {
					el.className = "level1";
				}
			});
			document.querySelectorAll(".level2").forEach(el => {
				el.className = "level2";
			});
		}

		if (parentEl.className.match(/level2/i)) {
			document.querySelectorAll(".level2").forEach(el => {
				if (el !== parentEl) el.className = "level2";
			});
		}

		parentEl.className =
			parentEl.className.match(/expanded/i)
				? parentEl.className.replace(/expanded/ig, "")
				: `${parentEl.className} expanded`;

	};

	const toggleInfoEstese = (e: any) => {
		if (e.currentTarget.checked) {
			setDisplayCountInfo({ display: "inline" });
		} else {
			setDisplayCountInfo({ display: "none" });
		}
		e.currentTarget.blur();
	};

	return (
		<div>
			<div className="control-header" style={{ whiteSpace: "nowrap" }}>
				<label><strong>Fasi del deliverable</strong></label>
				<span className="divider" />
				<button onClick={addNewStep} className={"bg-btn"}>
					<Icon icon={ic_add} size={16} />Aggiungi
				</button>
				<span className="divider" />
				<Form.Check
					id={"chkShowCounters"}
					type="switch"
					label={"Mostra contatori"}
					defaultChecked={true}
					onChange={(e: any) => toggleInfoEstese(e)}
				/>
			</div>
			<Table className="table-treeview">
				<thead>
					<tr>
						<th><i className="far fa-flag"></i> Fase attività / <i className="far fa-address-card"></i> Profili / <i className="far fa-user"></i> Risorse</th>
						<th className="text-left"><i className="far fa-calendar-alt"></i> Ore assegnate</th>
						<th className="text-left"><i className="far fa-calendar-alt"></i> Ore pianificate</th>
						<th></th>
					</tr>
				</thead>
				{tBodies}
				<tfoot>
					<tr>
						<td><i className="far fa-flag"></i> Fase attività / <i className="far fa-address-card"></i> Profili / <i className="far fa-user"></i> Risorse</td>
						<td className="text-left"><i className="far fa-calendar-alt"></i> Ore assegnate</td>
						<td className="text-left"><i className="far fa-calendar-alt"></i> Ore pianificate</td>
						<td></td>
					</tr>
				</tfoot>
			</Table>
			<SuborderStepDetail
				subOrderID={subOrderStepProps.subOrderID}
				subOrderStepID={subOrderStepProps.subOrderStepID}
				activityLineID={subOrderStepProps.activityLineID}
				subOrderName={subOrder.SubOrderName}
				activityLineName={subOrderStepProps.activityLineName}
				subOrderWorkingHours={subOrder.WorkingDays * 8}
				subOrderStepsWorkingHours={subOrder.StepsWorkingHours}
			/>
			<SuborderStepProfileDetail
				subOrderStepID={profileDetailProps.subOrderStepID}
				subOrderStepWorkingHours={profileDetailProps.subOrderStepWorkingHours}
				subOrderStepProfilesWorkingHours={profileDetailProps.subOrderStepProfilesWorkingHours}
				subOrderStepProfileID={profileDetailProps.subOrderStepProfileID}
				activityStepName={profileDetailProps.activityStepName}
				subOrderName={subOrder.SubOrderName}
				activityLineName={subOrder.ActivityLineName}
				activityLineID={subOrder.ActivityLineID}
			/>
			<SuborderStepProfileUserDetail
				SubOrderStepProfileID={userDetailProps.SubOrderStepProfileID}
				UserID={userDetailProps.UserID}
				profileTypeID={userDetailProps.profileTypeID}
				profileTypeName={userDetailProps.profileTypeName}
				activityStepName={userDetailProps.activityStepName}
				userFullName={userDetailProps.userFullName}
				subOrderName={subOrder.SubOrderName}
				activityLineName={subOrder.ActivityLineName}
				SubOrderStepProfileAssignedgHours={userDetailProps.SubOrderStepProfileAssignedgHours}
				SubOrderStepProfileWorkingHours={userDetailProps.SubOrderStepProfileWorkingHours}
			/>
			<QuickAssignment
				SubOrderStepProfileID={quickAssignmentProps.SubOrderStepProfileID}
				profileTypeID={quickAssignmentProps.profileTypeID}
				profileTypeName={quickAssignmentProps.profileTypeName}
				activityStepName={quickAssignmentProps.activityStepName}
				subOrderName={quickAssignmentProps.subOrderName}
				activityLineName={quickAssignmentProps.activityLineName}
				subOrderStartDate={quickAssignmentProps.subOrderStartDate}
				subOrderEndDate={quickAssignmentProps.subOrderEndDate}
				SubOrderStepProfileWorkingHours={quickAssignmentProps.SubOrderStepProfileWorkingHours}
				SubOrderStepProfileAssignedgHours={quickAssignmentProps.SubOrderStepProfileAssignedgHours}
			/>
			<Modal className="modal-popover" show={showPopoverModal}></Modal>
		</div>
	);
}