import React, { useEffect, useState } from "react";
import { SubOrderUser, WorkingDate } from "../../models/reports";
import UserAvailableHours, { IUserAvailableHours } from "../common/UserAvailableHours";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { GetMonthNumberOfDays } from "../../core/Utilities";
import Icon from "react-icons-kit";
import { ic_local_mall } from "react-icons-kit/md/ic_local_mall";

export interface IAppProps {
	item: SubOrderUser;
	// year: number;
	// month: number;
}

export default function UserMonthAvailableHours(props: IAppProps) {

	return (
		<div key={`${props.item.UserID}`}
			className="ts-month-row d-flex align-items-start mt-0 p-0"	
			title={`${props.item.FirstName} ${props.item.LastName}`} 		
		>
			<div className="d-flex pt-3">
				{/* div nome risorsa */}
				<div className="d-inline-flex my-1 pl-1 mr-1 justify-content-end">
					<div style={{
						width: "220px", 
						whiteSpace: "nowrap", 
						textOverflow: "ellipsis", 
						overflow: "hidden",
						textAlign: "left",
						cursor: "default",
						backgroundColor: "#f3f3f3",
						borderRadius: "5px",
						padding: "8px",
						marginTop: "10px"
					}}>
						{props.item.FirstName} {props.item.LastName}
					</div>					
					{/* div overlay profili */}
					<div className="ts-month-tools d-inline-flex">
						<OverlayTrigger
							key={`ot_${props.item.UserID}`}
							rootClose
							trigger="click"
							placement="right"
							overlay={
								<Popover key={`ga_${props.item.UserID}`} id={`ga_${props.item.UserID}`} style={{
									maxWidth: "100%"
								}}>
									<Popover.Title as="h3" style={{
										fontSize: "14px", margin: "8px", border: "none", borderRadius: "4px"
									}}>
										Profili della risorsa
									</Popover.Title>
									<Popover.Content style={{ fontSize: "12px", minHeight: "100px" }}>
										{
											props.item.ProfileTypes ? props.item.ProfileTypes.map(function (el, i) {
												return <div key={`${props.item.UserID}_${el.ProfileTypeID}`}>{el.ProfileTypeName}</div>;
											}) : ""
										}
									</Popover.Content>
								</Popover>
							}>
							<button className="btn btn-link btn-sm p-0" title="Profili risorsa...">
								<Icon icon={ic_local_mall} size={24} />
							</button>
						</OverlayTrigger>						
					</div>
				</div>
			</div>
			{/* div giorni mese */}
			<div className="d-flex align-items-center flex-wrap">
				{
					props.item.DisplayDates.map(
						function (d, i) {
							return <UserAvailableHours
								IsWorkingDay={d.IsWorkingDay}
								IsHolidayOrWeekEnd={d.IsHolidayOrWeekEnd}
								MonthDay={d.MonthDay}
								AvailableHours={d.AvailableHours}
								Disabled={d.Disabled} 
								key={`${props.item.UserID}_${i}`}
							/>;
						}
					)
				}
			</div>
		</div>
		/*
		<Row className={"mb-1"}>
			<Col sm={2}>
				<b>{props.item.FirstName} {props.item.LastName}</b>
				{
					props.item.ProfileTypes ? props.item.ProfileTypes.map(function (el, i) {
						return <div>{el.ProfileTypeName}</div>;
					}) : ""
				}
			</Col>
			<Col sm={10} className="d-flex">
				{days.map(function (d, i) {
					return <UserAvailableHours
						IsWorkingDay={d.IsWorkingDay}
						MonthDay={d.MonthDay}
						AvailableHours={d.AvailableHours}
						Disabled={d.Disabled} />;
				}
				)
				}
			</Col>
		</Row> 
		*/
	);
}
