import React, { useContext, useEffect, useState } from "react";
import { Order } from "../../../../models/reports";
import ReportServices from "../../../../api/ReportServices";
import { Maincontext } from "../../../maincontext";
import OrderReport from "./components/OrderReport";
import SelectItemServices from "../../../../api/SelectItemServices";
import { selectFilter } from "../../../../core/Utilities";
import { MenuList, SelectItemsListTypes, SelectListItem } from "../../../../models/models";
import Select from "react-select";
import TitleBar from "../../../common/TitleBar";

export default function OrdersReport() {

	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const ISVC = new SelectItemServices();
	const [order, setOrder] = useState<Order>();
	const [ordersList, setOrdersList] = useState<SelectListItem[]>([]);
	const [selectedOrder, setSelectedOrder] = useState<SelectListItem>();

	useEffect(() => {
		GetOrdersList();
		context.setContext({
			...context,
			selectedMenu: MenuList.RPT_ORDERS
		})
	}, []);


	function GetOrdersList() {
		ISVC.getSelectItemList(SelectItemsListTypes.ORDERS, 0).then((l) => {
			if (l.data) {
				setOrdersList(l.data);
			}
		}).catch((e) => {
			context.setContext({
				...context, showAlert: true, /* showAttesa: false, */
				messaggioAlert: "Errore di caricamento ORDERS: " + e, titoloAlert: "Errore"
			});
		}).finally(() => {
		});
	}

	function GetOrder(OrderID: number) {
		//context.setContext({ ...context, showAttesa: true });
		SVC.GetOrder(OrderID).then((d) => {
			if (d.data) {
				setOrder(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento commessa: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	function changeOrderID(v: any, i: any) {
		const OrderID: number = Number(v.Value);
		GetOrder(OrderID);
		setSelectedOrder(v);
	}

	function Refresh() {
		if (selectedOrder) {
			const OrderID: number = Number(selectedOrder.Value);
			GetOrder(OrderID);
		}
	}

	return (
		<div className="Container">
			<TitleBar title="Report commessa" />
			<div className="form-group col-4 mt-2">
				<label>Selezionare la commessa</label>
				<Select
					filterOption={selectFilter}
					value={selectedOrder}
					options={ordersList}
					isSearchable
					onChange={changeOrderID}
					getOptionLabel={c => c.Text}
					getOptionValue={c => c.Value}
				/>
			</div>
			<div className="form-group col">
				{order ?
					<OrderReport onResetEvent={() => Refresh()} data={order} />
					:
					''}
			</div>
		</div>
	);
}