import React, { useContext, useState, useEffect } from "react";
import { Maincontext } from "../maincontext";
import SubOrderServices from "../../api/SubOrderServices";
import { SubOrder} from "../../models/models";
import { Table } from "react-bootstrap";

export interface IOrderSubOrdersListProps {
}

export default function OrderSubOrdersList() {
	const context = useContext(Maincontext);
	const SVC = new SubOrderServices();
	const [list, setList] = useState<SubOrder[]>([]);
	const orderID = context.objSelected.OrderID as number;

	useEffect(() => {
		GetList();
	}, []);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getSubOrdersByOrderID(orderID).then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: " SubOrders: " + e, titoloAlert: "Errore" });
		}).finally(() => {

			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false, objectName: "" });
		});
	}

	return (
		<div>
			<Table striped size="sm">
				<thead>
					<tr>
						<th>Deliverable</th>
						<th>Responsabile</th>
						<th>Giornate</th>
						<th>Data inizio</th>
						<th>Data fine</th>
					</tr>
				</thead>
				<tbody>
					{
						list.map(function (item: SubOrder) {
							return <tr key={"so_list_tr_" + item.SubOrderID}>
								<td>
									{item.SubOrderName}
								</td>
								<td>
									{item.Manager}
								</td>
								<td>
									{item.WorkingDays}
								</td>
								<td>
									{new Date(item.StartDate).toLocaleDateString()}
								</td>
								<td>
									{new Date(item.EndDate).toLocaleDateString()}
								</td>
							</tr>;
						}
						)
					}
				</tbody>
			</Table>
		</div>
	);
}

