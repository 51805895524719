import React, { useContext, MouseEvent } from "react";
import { Button} from "react-bootstrap";
import { UserContext } from "../maincontext";

export interface ISaveButton {
	popover: React.ReactNode;
	disabled: boolean;
	hidden?: boolean;
	popoverFunction: (value: React.SetStateAction<boolean>) => void;
	saveFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function SaveButton(props: ISaveButton) {

	const userContext = useContext(UserContext);

	function onClickEvent(e: MouseEvent<HTMLButtonElement>) {
		if(userContext.Me!.ConfirmButton){
			props.popoverFunction(true);
		}
		else{
			props.saveFunction(e);
		}
	}

	return (
		<div style={{ "position": "relative" }}
		className={props.hidden ? "d-none" : ""}>
			<Button
				variant="primary"
				size="sm"
				onClick={onClickEvent}
				disabled={props.disabled}>Salva
			</Button>
			{props.popover}
		</div>
	);

}