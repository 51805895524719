import React, { useContext, MouseEvent, useState, useEffect, ChangeEvent, FocusEvent } from "react";
import { SubOrderStepProfileUser, SelectListItem, AssignedWorkingHours, DateWorkingHours } from "../../../models/models";
import { Maincontext } from "../../maincontext";
import SelectItemServices from "../../../api/SelectItemServices";
import { selectFilter } from "../../../core/Utilities";
import Select from "react-select";
import { Modal, Button, Popover, Alert, Form, InputGroup } from "react-bootstrap";//Icone
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import SubOrderStepProfileUserServices from "../../../api/SubOrderStepProfileUserServices";
import { WeekTable } from "../../common/WeekRange/WeekTable";
import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";
import { MONTHS, FIRST_DAY_OF_WEEK, LABELS, WEEKDAYS_LONG, WEEKDAYS_SHORT, TODAY_LABEL } from "../../../models/locale";
import Info from "../../common/Info";

export interface ISubOrderStepProfileUserDetilProps {
	SubOrderStepProfileID: number;
	UserID: number;
	profileTypeID: number;
	activityStepName: string;
	profileTypeName: string;
	userFullName: string;
	subOrderName: string;
	activityLineName: string;
	SubOrderStepProfileWorkingHours: number;
	SubOrderStepProfileAssignedgHours: number;
}

export default function SubOrderStepProfileUserDetail(props: ISubOrderStepProfileUserDetilProps) {
	const context = useContext(Maincontext);
	const SVC = new SubOrderStepProfileUserServices();
	const ISVC = new SelectItemServices();
	const language = "it";
	const dateFormat = "DD/MM/YYYY";

	const SubOrderStepProfileUserDef: SubOrderStepProfileUser = {
		UserID: props.UserID,
		UserFullName: props.userFullName,
		SubOrderStartDate: new Date(),
		SubOrderEndDate: new Date(),
		AssignedWorkingHoursList: []
	};

	const [SubOrderStepProfileUser, setSubOrderStepProfileUser] = useState<SubOrderStepProfileUser>(SubOrderStepProfileUserDef);
	const [userList, setUserList] = useState<SelectListItem[]>([]);
	const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(undefined);
	const [showPopover, setShowPopover] = useState(false);
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
	const [hiddenDetail, setHiddenDetail] = useState<boolean>(true);
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [rangeStartDate, setRangeStartDate] = useState<Date>(new Date());
	const [rangeEndDate, setRangeEndDate] = useState<Date>(new Date());
	const [saturdayIncluded, setSaturdayIncluded] = useState<boolean>(false);
	const [sundayIncluded, setSundayIncluded] = useState<boolean>(false);
	const [holidayIncluded, setHolidayIncluded] = useState<boolean>(false);
	const [totalHours, setTotalHours] = useState<number>(0);
	const [dayHours, setDayHours] = useState<number>(0);
	const [profileAssignedHours, setProfileAssignedHours] = useState<number>(props.SubOrderStepProfileAssignedgHours); //ore assegnate tranne quelle correnti
	const [totalassigningHours, setTotalAssigningHours] = useState<number>(0); //ore in corso di assegnamento
	const [totalAssignablegHours, setTotalAssigningableHours] = useState<number>(0); //ore in corso di assegnamento
	const [totalOvertimeHours, setTotalOvertimeHours] = useState<number>(0); //totale straordinari che si stanno assegnando
	//const [readonly, setReadonly] = useState<boolean>(false);

	useEffect(() => {

		if (context.showSubOrderStepProfileUserDetail == true) {

			setHiddenDetail(true);
			setSaveEnabled(false);
			setAlertMessage("");
			setShowPopover(false);
			setSaturdayIncluded(false);
			setSundayIncluded(false);
			setHolidayIncluded(false);
			setDayHours(0);

			setProfileAssignedHours(props.SubOrderStepProfileAssignedgHours);
			setTotalAssigningHours(0);

			if (props.UserID !== 0) {
				GetItem(props.UserID);
			}
			else {
				SubOrderStepProfileUser.AssignedWorkingHoursList = [];
				if (props.profileTypeID > 0) {
					GetUsersList();
				}
			}
		}

	}, [context.showSubOrderStepProfileUserDetail]);

	function GetUsersList() {
		setHiddenDetail(true);
		ISVC.getSubordStepProfiletUserItems(props.SubOrderStepProfileID).then((l) => {
			if (l.data.length > 0) {
				setUserList(l.data);
				setHiddenDetail(false);
			}
			else {
				context.setContext({
					...context,
					showSubOrderStepProfileUserDetail: false,
					showAlert: true,
					messaggioAlert: "Nessuna risorsa disponibile per il profilo richiesto.",
					titoloAlert: "Attenzione!"
				});
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Risorse: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function GetItem(userID: number) {
		SVC.getSubOrderStepProfileUser(props.SubOrderStepProfileID, userID).then((l) => {

			if (l.data) {

				setSubOrderStepProfileUser(l.data);
				const item: SelectListItem = { Text: l.data.UserFullName, Value: l.data.UserID.toString() };
				//se contiene almeno una data imposto la data inizio nel tabel week
				if (l.data.AssignedWorkingHoursList.length > 0) {
					const sDate: Date = new Date(l.data.AssignedWorkingHoursList[0].WorkingHoursDate);
					setStartDate(sDate);
					const today = new Date();

					setRangeStartDate(sDate);
					const eDate: Date = new Date(l.data.AssignedWorkingHoursList[l.data.AssignedWorkingHoursList.length - 1].WorkingHoursDate);
					setEndDate(eDate);
					setRangeEndDate(eDate);

					let totalAssigning = 0;
					let overtime = 0;
					let tot_overtime = 0;

					l.data.AssignedWorkingHoursList.forEach((item: AssignedWorkingHours, i) => {

						if (item.AssignedWorkingHours > 0) {
							totalAssigning += item.AssignedWorkingHours;
						};

						if (
							item.AssignedWorkingHours > 0
							&&
							(item.AssignedWorkingHours + item.WorkingHours) > item.UserWorkingHours
						) {
							overtime = (item.AssignedWorkingHours + item.WorkingHours) - item.UserWorkingHours;
							tot_overtime += overtime;
						}

					});

					setTotalAssigningHours(totalAssigning);
					setTotalOvertimeHours(tot_overtime);
					setProfileAssignedHours(props.SubOrderStepProfileAssignedgHours - totalAssigning);
				}
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: SubOrderStepProfileUser" + e, titoloAlert: "Errore" });
		}).finally(() => {

			setHiddenDetail(false);
		});
	}

	function setAssegnableHours() {

		let hours: number = 0;
		SubOrderStepProfileUser.AssignedWorkingHoursList.forEach(function (e: AssignedWorkingHours, i) {
			const currDate: Date = new Date(e.WorkingHoursDate);
			if (
				currDate >= startDate
				&& currDate <= endDate
				&& (!e.IsHoliday || holidayIncluded)
				&& (!e.IsSaturday || saturdayIncluded)
				&& (!e.IsSunday || sundayIncluded)
			) {
				if (dayHours == 0) {
					hours += (8 - e.AssignedWorkingHours);
				}
				else {
					if ((8 - e.AssignedWorkingHours) >= dayHours) {
						hours += dayHours;
					}
				}
			}
		});

		const AssignableHours: number = props.SubOrderStepProfileWorkingHours - profileAssignedHours;

		if (hours > AssignableHours) {
			hours = AssignableHours;
		}
		setTotalAssigningableHours(hours);
	}

	function Save(e: MouseEvent<HTMLButtonElement>) {

		const workingHoursList: DateWorkingHours[] = getWorkingHours();
		SVC.SubOrderStepProfileUserUpdate(props.SubOrderStepProfileID, SubOrderStepProfileUser.UserID, workingHoursList).then((d) => {
			if (d !== undefined && d.data !== "") {
				setAlertMessage(d.data);
			}
			else {
				context.setContext({ ...context, refreshList: !context.refreshList });
				closeDialog();
			}
		}).catch((e: React.SetStateAction<string>) => {
			setAlertMessage("Errore del server");
		}).finally(() => {

		});
	};

	//restituisce l'elenco delle date con ore assegnate
	const getWorkingHours = (): DateWorkingHours[] => {

		let workingHoursList: DateWorkingHours[] = [];
		SubOrderStepProfileUser.AssignedWorkingHoursList.forEach((item: AssignedWorkingHours, i) => {
			if (item.AssignedWorkingHours > 0) {
				workingHoursList.push({
					WorkingHours: item.AssignedWorkingHours,
					WorkingHoursDate: new Date(item.WorkingHoursDate)
				});

			};
		});

		return workingHoursList;
	};


	//validazione del form
	const isValid = (): boolean => {
		return SubOrderStepProfileUser.UserID !== 0
			&& props.SubOrderStepProfileID !== 0;
	};

	function setContext() {
		const valid = isValid();
		setSaveEnabled(valid);
	}

	function setUserID(v: any, i: any) {
		const userID: number = Number(v.Value);
		setSelectedUser(v);
		setSubOrderStepProfileUser({ ...SubOrderStepProfileUser, UserID: userID });
		GetItem(userID);
	}

	function onDayHoursChange(currentDate: Date, hours: number) {

		const data: string = currentDate.toLocaleDateString();
		const newList: AssignedWorkingHours[] = SubOrderStepProfileUser.AssignedWorkingHoursList.slice();
		let tot: number = 0;
		let overtime: number = 0;
		let tot_overtime: number = 0;
		newList.forEach((item: AssignedWorkingHours, i) => {

			if (new Date(item.WorkingHoursDate).toLocaleDateString() === data) {
				item.AssignedWorkingHours = hours;
			};
			tot += item.AssignedWorkingHours;

			if (
				item.AssignedWorkingHours > 0
				&&
				(item.AssignedWorkingHours + item.WorkingHours) > item.UserWorkingHours
			) {
				overtime = (item.AssignedWorkingHours + item.WorkingHours) - item.UserWorkingHours;
				tot_overtime += overtime;
			}

		});

		setTotalAssigningHours(tot);
		setTotalOvertimeHours(tot_overtime);
		setSubOrderStepProfileUser({ ...SubOrderStepProfileUser, AssignedWorkingHoursList: newList });
		setContext();
	};

	function onFocus(e: FocusEvent<HTMLInputElement>) {
		e.target.select();
	}

	const closeDialog = () => {
		setSaveEnabled(false);
		setAlertMessage("");
		setShowPopover(false);
		setHiddenDetail(true);
		setSaturdayIncluded(false);
		setSundayIncluded(false);
		setHolidayIncluded(false);
		setDayHours(0);
		context.setContext({ ...context, showSubOrderStepProfileUserDetail: false });
		setSubOrderStepProfileUser(SubOrderStepProfileUserDef);
		setSelectedUser(undefined);
	};

	const closePopover = () => {
		setShowPopover(false);
	};

	const popover = (
		<Popover id="popover-basic" className={showPopover ? "" : "d-none"}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				Salvare i dati?<br /><br />
				<Button variant="outline-secondary" className="btn-sm" onClick={closePopover}><Icon icon={ic_close} /></Button>
         		&nbsp;
          		<Button variant="outline-primary" className="btn-sm" onClick={Save}><Icon icon={ic_check} /></Button>
			</Popover.Content>
		</Popover>
	);

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={"xl"}
				className={hiddenDetail ? "d-none" : ""}
				show={context.showSubOrderStepProfileUserDetail}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title className="fluid">
						<div className="form-row">
							<div className="col-12">
								<h2>Assegnazione per data</h2>
							</div>
							<div className="col-8">

								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<td className="bg-light">Deliverable</td>
											<td className="p-1">{props.subOrderName}</td>
										</tr>
										<tr>
											<td className="bg-light">Linea di attività</td>
											<td className="p-1">{props.activityLineName}</td>
										</tr>
										<tr>
											<td className="bg-light">Fase di attività</td>
											<td className="p-1">{props.activityStepName}</td>
										</tr>
										<tr>
											<td className="bg-light">Profilo</td>
											<td className="p-1">{props.profileTypeName}</td>
										</tr>
										<tr>
											<td className="bg-light">Risorsa</td>
											<td className="p-1">{props.UserID === 0 ?
												"Nuova risorsa" : props.userFullName}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-4 text-rigth">
								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<th className="bg-light">Descrizione</th>
											<th className="text-right p-1 bg-light">Giornate</th>
											<th className="text-right p-1 bg-light">Ore</th>
										</tr>
										<tr>
											<td>
												Pianificate per il profilo
												<Info
													color="secondary"
													placement="auto"
													text="Totale pianificate per il profilo"
												/>
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileWorkingHours / 8}
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileWorkingHours}
											</td>
										</tr>
										<tr>
											<td>Già assegnate
											<Info
													color="secondary"
													placement="auto"
													text="Assegnate ad altre risorse"
												/>
											</td>
											<td className="text-right p-1">
												{profileAssignedHours / 8}
											</td>
											<td className="text-right p-1">
												{profileAssignedHours}
											</td>
										</tr>
										<tr>
											<td>Da assegnare
											<Info
													color="secondary"
													placement="auto"
													text="Non ancora assegnate ad altre risorse"
												/></td>
											<td className="text-right p-1">
												{(props.SubOrderStepProfileWorkingHours - profileAssignedHours) / 8}
											</td>
											<td className="text-right p-1">
												{props.SubOrderStepProfileWorkingHours - profileAssignedHours}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ height: "410px" }}>
					<div className="form-row">
						<div className="form-group col">
							<label className="form-label">Risorsa umana</label>
							{props.userFullName == "" ?
								<Select
									filterOption={selectFilter}
									value={selectedUser}
									options={userList}
									isSearchable
									onChange={setUserID}
									getOptionLabel={c => c.Text}
									getOptionValue={c => c.Value}
									isDisabled={props.UserID !== 0}
								/>
								:
								<h5>{props.userFullName}</h5>
							}

						</div>
					</div>

					{SubOrderStepProfileUser.UserID !== 0 ?
						(
							<div className="form-row">
								<div className="form-group col text-center p-0">
									<div className={
										(selectedUser == undefined && props.userFullName == "") ? "d-none" : ""}>
										<WeekTable
											range={14}
											startDate={startDate}
											onDayHoursChange={onDayHoursChange}
											daysList={SubOrderStepProfileUser.AssignedWorkingHoursList}
										/>
										<div className="text-right">
											<table
												className="table table-sm table-bordered table-total-hours mt-3"
											>
												<thead>
													<tr>
														<th></th>
														<th>Giornate</th>
														<th>Ore</th>
														<th>Straordinari</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className="bg-light">
															Totale assegnazione
														</td>
														<td
															className={
																totalassigningHours != (props.SubOrderStepProfileWorkingHours - profileAssignedHours) ? "bg-warning" : ""}>
															{totalassigningHours / 8}
														</td>
														<td
															className={
																totalassigningHours != (props.SubOrderStepProfileWorkingHours - profileAssignedHours) ? "bg-warning" : ""}>
															{totalassigningHours}
														</td>
														<td
															className={
																totalOvertimeHours > 0 ? "bg-warning" : ""}>
															{totalOvertimeHours}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>)
						:
						(<p>Selezionare la risorsa</p>)
					}
				</Modal.Body>
				<Modal.Footer>
					<Alert variant={alertMessage === "" ? undefined : "warning"}>
						{alertMessage}
					</Alert>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => closeDialog()}>
						Annulla
					</Button>

					<div style={{ "position": "relative" }}>
						<Button variant="primary" size="sm" onClick={() => setShowPopover(true)} disabled={!saveEnabled}>Salva</Button>
						{popover}
					</div>
				</Modal.Footer>
			</Modal >
		</div >
	);
}