/*
	pannello di dettaglio USER
 */

import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import UserServices from "../../api/UserServices";
import { User } from "../../models/models";
import TitleBar from "../common/TitleBar";
import { Maincontext } from "../maincontext";
import UserCostCentersWidget from "./UserWidgets/UserCostCentersWidget";
import UserProfilesWidget from "./UserWidgets/UserProfilesWidget";
import UserActivityLinesWidget from "./UserWidgets/UserActivityLinesWidget";
import Icon from "react-icons-kit";
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_person_pin } from 'react-icons-kit/md/ic_person_pin';
import { ic_date_range } from 'react-icons-kit/md/ic_date_range';
import UserLoginsWidget from "./UserWidgets/UserLoginsWidget";
import UserAuthorizationsWidget from "./UserWidgets/UserAuthorizationsWidget";
import UserDayAssignedWorkingHours from "./UserWidgets/UserDayAssignedWorkingHours";
import UserOrdersWidget from "./UserWidgets/UserOrdersWidget";
import UserSubOrdersWidget from "./UserWidgets/UserSubOrdersWidget";
import { Log } from "../../core/Utilities";

export interface IUserView {
}

export default function UserView(props: IUserView) {

	const context = useContext(Maincontext);
	const SVC = new UserServices();
	let params: any = useParams();
	const userID: number = Number(params.id);


	const defUser: User = {
		UserID: 0,
		FirstName: "",
		LastName: "",
		Language: "",
		CreationDate: new Date(),
		Enabled: true,
		SuperAdmin: false,
		EmailAddress: "",
		ApproverFullName: "",
		ProfilePhoto: "",
		IsApprover: false,
		FullName: "",
		Initials: "",
		UserAuthorizationTypes: [],
		UserProfileTypes: [],
		UserContractTypes: [],
		UserCostCenters: [],
		WorkingHoursTypeID: 0,
		UserActivityLines: [],
		UserLogins: [],
		WorkingHoursTypeName: "",
		Available: false,
		DefaultApprover: true,
		ConfirmButton: true,
		RecentActivitiesList: true,
		TimesheetDuplicated: true
	};

	const [user, setUser] = useState<User>(defUser);

	useEffect(() => {
		GetUser();
	}, []);


	function GetUser() {
		SVC.getUser(userID).then((d) => {
			if (d.data) {
				setUser(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento risorsa" + e, titoloAlert: "Errore" });
		}).finally(() => {
		});

	}

	return (
		<div>
			<TitleBar title={user.FullName} links={[{ title: "Risorse umane", to: "/users" }]} />

			<div className="p-3">
				<div className="form-row">
					<div className="col-md-4 mb-4">
						<div className="profile-photo profile-photo-big" style={{ backgroundImage: `url(${user.ProfilePhoto})` }}>
							{user.ProfilePhoto == "photo not found" ? user.Initials : ""}
						</div>
						<div>
							<h2>{user.FullName}</h2>
							<p className="mb-0"><Icon icon={ic_email} className="mr-1" size={20} ></Icon>{user.EmailAddress}</p>
							<p className="mb-0" title="Approvatore"><Icon icon={ic_person_pin} className="mr-1" size={20} ></Icon>{user.ApproverFullName}</p>
							<p className="mb-0" title="Orario di lavoro"><Icon icon={ic_date_range} className="mr-1" size={20} ></Icon>{user.WorkingHoursTypeName}</p>
						</div>
					</div>
					<div className="col-md-2">
						<UserCostCentersWidget list={user.UserCostCenters} />
					</div>
					<div className="col-md-2">
						<UserProfilesWidget list={user.UserProfileTypes} />
						<UserActivityLinesWidget list={user.UserActivityLines} />
					</div>
					<div className="col-md-2">
						<UserAuthorizationsWidget list={user.UserAuthorizationTypes} />
					</div>
					<div className="col-md-2">
						<UserLoginsWidget list={user.UserLogins} />
					</div>
					<div className="col-md-12 mb-4">
						<hr></hr>
						<UserDayAssignedWorkingHours UserID={userID} />
					</div>
					<div className="col-md-6 mb-4">
						<UserOrdersWidget UserID={userID} />
					</div>
					<div className="col-md-6 mb-4">
						<UserSubOrdersWidget UserID={userID} />
					</div>
				</div>
			</div>
		</div>
	);
}
