import * as React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import OverTimeServices from '../../api/OverTimeServices';
import { MenuList, OverTime } from '../../models/models';
import TitleBar from '../common/TitleBar';
import { Maincontext, UserContext } from '../maincontext';
import { Icon } from 'react-icons-kit'
import { circle } from 'react-icons-kit/fa/circle';
import OverTimeDetail from './OverTimeDetail';
import { ic_more_vert } from "react-icons-kit/md/ic_more_vert";
import { ic_add } from "react-icons-kit/md/ic_add";
import { refresh } from "react-icons-kit/fa/refresh";
import { minutesToHours } from '../../core/Utilities';

export interface IOvertimeListProps {

}

export default function OvertimeList(props: IOvertimeListProps): JSX.Element {
	const context = useContext(Maincontext);
	const userContext = useContext(UserContext);


	const OSVC = new OverTimeServices();

	const [nota, setNota] = useState("");
	const [overtimesList, setOvertimesList] = useState<OverTime[]>([]);
	const [completeList, setCompleteList] = useState<boolean>(false);
	// WAITING = 0,
	// APPROVED = 1,
	// REFUSED = 2
	const approvatingArray = ["Attesa", "Approvato", "Rifiutato"];
	const colorstateArray = ["orange", "green", "red"];

	useEffect(() => {
		context.setContext({ ...context, selectedMenu: MenuList.OVERTIME });
	}, []);

	useEffect(() => {
		GetList();
	}, [context.refreshList]);

	useEffect(() => {
		GetList();
	}, [completeList]);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		OSVC.getOverTimes(completeList).then((d) => {
			if (d.data) {
				setOvertimesList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: Straordinari" + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function AddRequest() {
		context.setContext({
			...context,
			showOverTimeDetail: true
		});
	}
	function approveOvertime(id: number, approve: boolean) {
		if (approve) {
			//alert (nota + " - " + 1);
			OSVC.overTimeStateChange(id, 1, nota).then(() => {
				GetList();
			});
		} else {
			//alert (nota + " - " + 2);
			OSVC.overTimeStateChange(id, 2, nota).then(() => {
				GetList();
			});
		}
	}

	function CompleteListToggle() {
		setCompleteList(!completeList);
	}

	return (
		<div>
			<TitleBar title="Straordinari" />
			<Form className="form-search-list">
				<Form.Row>
					<Col md={4}>
						<h3>Richieste autorizzazione straordinari</h3>
					</Col>
					<Col md={4}>
						<Button variant={"link"} onClick={() => { GetList(); }} className="btn-sm ml-2 h-100 btn-context" style={{ width: "auto" }}>
							<Icon icon={refresh} size="16" />Aggiorna</Button>
						<Button variant={"link"} onClick={() => { AddRequest(); }} className="btn-sm ml-2 h-100 btn-context" style={{ width: "auto" }}>
							<Icon icon={ic_add} size="16" />Nuova richiesta</Button>
					</Col>
					<Col md={4}>
						<Form.Check type="radio" label="Ultimi 30 giorni"
							onClick={() => CompleteListToggle()}
							checked={!completeList} />
						<Form.Check type="radio" label="Elenco completo"
							onClick={() => CompleteListToggle()}
							checked={completeList} />
					</Col>
				</Form.Row>
			</Form>
			<OverTimeDetail />
			<div>
				<Table className="overtime-table" hover={true}>
					<thead>
						<tr>
							<th></th>
							<th>Stato</th>
							<th>Risorsa</th>
							<th>Commessa</th>
							<th className={"text-center"}>Data</th>
							<th className={"text-center"}>Diurno</th>
							<th className={"text-center"}>Notturno</th>
							<th>Note risorsa</th>
							<th>Approvatore</th>
							<th>Note approvatore</th>
						</tr>
					</thead>
					<tbody>
						{overtimesList.map((e, i) => {
							return (
								<tr className="overtime-table-tr">
									<td>
										<Icon size={'15px'} icon={circle} style={{ color: colorstateArray[e.OverTimeStateID] }} />
									</td>
									<td>
										{approvatingArray[e.OverTimeStateID]}
									</td>
									<td>
										{e.UserFullName}
										<div className="overtime-tools d-inline-flex float-right">
											{userContext.Me!.UserID == e.ApproverID && e.OverTimeStateID == 0 ?
												<OverlayTrigger
													rootClose
													trigger="click"
													placement="right"
													overlay={
														<Popover key={`ga_${i}`} id={`ga_${i}`} style={{
															maxWidth: "100%"
														}}>
															<Popover.Content style={{ fontSize: "12px", minHeight: "100px" }}>
																<Form.Group>
																	<Form.Label>Note</Form.Label>
																	<Form.Control as="textarea" rows={3} defaultValue="" onChange={(e) => { setNota(e.currentTarget.value); }} />
																</Form.Group>
																<button className="btn btn-primary w-100 mb-1" onClick={() => approveOvertime(e.OverTimeID, true)}>Approva</button>
																<button className="btn btn-secondary w-100" onClick={() => approveOvertime(e.OverTimeID, false)}>Rifiuta</button>
															</Popover.Content>
														</Popover>
													}>
													<button className="btn btn-link btn-sm p-0" title="Approvazione straordinario" onClick={() => setNota("")}>
														<Icon icon={ic_more_vert} size={24} />
													</button>
												</OverlayTrigger>
												: <div></div>
											}
										</div>
									</td>
									<td>
										{e.OrderName}
									</td>
									<td className={"text-center"}>
										{new Date(e.OverTimeDate).toLocaleDateString()}
									</td>
									<td className={"text-center"}>
										{minutesToHours(e.OverTimeMinutes)}
									</td>
									<td className={"text-center"}>
										{minutesToHours(e.OverTimeNightMinutes)}
									</td>
									<td>
										{e.UserNote}
									</td>
									<td>
										{e.ApproverFullName}
									</td>
									<td>
										{e.ApproverNote}
									</td>
								</tr>
							)
						})
						}
					</tbody>
				</Table>
			</div>
		</div>
	);
}
