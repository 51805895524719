import React, { useEffect, useContext, useState, MouseEvent } from "react";
import Lista from "../common/List";
import UserServices from "../../api/UserServices";
import { Maincontext } from "../maincontext";
import { columnDetail, SelectedObjectTypes, MenuList, UserList, DataColumnTypes, User } from "../../models/models";
import TitleBar from "../common/TitleBar";
import UserProfilesList from "./UserProfilesList";
import UserAuthorizationsList from "./UserAuthorizationsList";
import UserCostCentersList from "./UserCostCentersList";
import UserContractsList from "./UserContractsList";
import UserDetail from "./UserDetail";

import { useAppInsightsContext, useTrackMetric  } from "@microsoft/applicationinsights-react-js";

export default function UsersList() {
	
	//#region AppInsights
    const appInsights = useAppInsightsContext();
    const trackComponent = useTrackMetric(appInsights, "Risorse umane");
	//#endregion AppInsights

	const SVC = new UserServices();
	const [list, setList] = useState<UserList[] | []>([]);
	const context = useContext(Maincontext);
	const [completeList, setCompleteList] = useState<boolean>(false);

	//Dichiarazione della colonna di dettaglio che determina se una colonna viene visualizzata e in che posizione se non viene passato viene fatto il rendering dell'oggetto
	const columnDetails: columnDetail[] = [
		{ name: "Initials", text: "", position: 1, size: 24, type: DataColumnTypes.CIRCLE },
		{ name: "FirstName", text: "Nome", position: 2 },
		{ name: "LastName", text: "Cognome", position: 3 },
		{ name: "EmailAddress", text: "Email", position: 4 },
		{ name: "WorkingHoursTypeName", text: "Orario di lavoro", position: 5 },
		{ name: "ApproverFullName", text: "Approvatore", position: 6 },
		{ name: "Available", text: "Reperibile", position: 7 },
		{ name: "Enabled", text: "Abilitato", position: 8, size: 112 },
		{ name: "SuperAdmin", text: "Admin", position: 9, size: 112 }
	];
	
	// useEffect(() => {
	// 	trackComponent();
	// }, []);

	useEffect(() => {
		GetList();
	}, [context.refreshList]);

	useEffect(() => {
		GetList();
	}, [completeList]);

	function GetList() {		
		SVC.getUsersList(completeList).then((d) => {
			setList(d.data);
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: Users List" + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: MenuList.USERS, });
		});
	}

	function onShowUser(e: MouseEvent<HTMLButtonElement>) {

		const user: User = context.objSelected;
		window.location.href = "/user/" + user.UserID.toString();

	}


	return (
		<div>
			<TitleBar title="Risorse umane" />
			<Lista obj={list}
				details={[
					{
						detail: <UserDetail />,
						button: "contact",
						titoloDettaglio: "Scheda risorsa",
						isFunction: true,
						confirm: false,
						objTypeSelected: SelectedObjectTypes.USER,
						onClick: onShowUser
					},
					{
						detail: <UserDetail />,
						button: "edit",
						titoloDettaglio: "Modifica",
						objTypeSelected: SelectedObjectTypes.USER,
					},
					{
						detail: <UserAuthorizationsList />, button: "permission",
						titoloDettaglio: "Permessi",
						objTypeSelected: SelectedObjectTypes.USER_AUTHORIZATION_TYPE
					},
					{
						detail: <UserProfilesList />, button: "profile",
						titoloDettaglio: "Profili",
						panelSize: "lg",
						objTypeSelected: SelectedObjectTypes.USER_PROFILE_TYPE
					},
					{
						detail: <UserCostCentersList />, button: "costcenter",
						titoloDettaglio: "Centri di costo",
						objTypeSelected: SelectedObjectTypes.USER_COST_CENTER
					},
					{
						detail: <UserContractsList />,
						panelSize: "xl",
						button: "contract",
						titoloDettaglio: "Contratti",
						objTypeSelected: SelectedObjectTypes.USER_CONTRACT_TYPE
					}]}
				columnDetails={columnDetails}
				detailPosition={3}
				completeListButton={true}
				setCompleteList={setCompleteList}
			/>
		</div>
	);
}

