import axios from "axios";
import { SelectItemsListTypes, SelectListItem, MultiSelectItemsListTypes } from "../models/models";
import CommonServices from "./CommonServices";

const SVC = new CommonServices();

export default class SelectItemServices {

	//liste di SelectItemList per popolare i componenti SELECT
	async getSelectItemList(type: SelectItemsListTypes, id: number) {
		const params = {
			type: type,
			id: id
		};			
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems`, { params, headers: await SVC.setAzureAuth() });
	}

	async getCountriesItemList(provincecode: string) {
		const params = {
			provincecode: provincecode
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/countriesitems`, { params, headers: await SVC.setAzureAuth() });
	}

	async getActivityStepsItemList(activitylineid: number) {
		const params = {
			activitylineid: activitylineid
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/activitystepsitems`, { params, headers: await SVC.setAzureAuth() });
	}

	//restituisce le fasi di attività non ancora impostate per un suborder 
	async getSubOrderActivityStepsItemList(suborderid: number) {
		const params = {
			suborderid: suborderid
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/suborderactivitystepsitems`, { params, headers: await SVC.setAzureAuth() });
	}

	//restituisce i profili per una attività non ancora impostati per quella fase 
	async getSubOrderStepProfileTypeItemList(suborderstepid: number) {
		const params = {
			suborderstepid: suborderstepid
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/suborderstepprofiltypeitems`, { params, headers: await SVC.setAzureAuth() });
	}

	async getProfileTypesByActivityLineID(activiytyLineID: number, id: number) {
		const params = {
			ActiviytyLineID: activiytyLineID,
			id: id
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/profiletypesbyactivitylineid`, { params, headers: await SVC.setAzureAuth() });
	}

	async getUserByProfileItems(profiletypeid: number, id: number) {
		const params = {
			profiletypeid: profiletypeid,
			id: id
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/usersbyprofile`, { params, headers: await SVC.setAzureAuth() });
	}

	async getSubordStepProfiletUserItems(suborderstepprofileid: number) {
		const params = {
			suborderstepprofileid: suborderstepprofileid
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/suborderstepprofileusers`, { params, headers: await SVC.setAzureAuth() });
	}

	async getMlutiSelectItemList(type: MultiSelectItemsListTypes, id: number) {
		const params = {
			type: type,
			id: id
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/multiselect`, { params, headers: await SVC.setAzureAuth() });
	}

	async getExtraActivityItems(timeSheetDate: Date) {
		const params = {
			TimeSheetDate: timeSheetDate
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/extraactivityitems`, { params, headers: await SVC.setAzureAuth() });
	}

	async getApproverItems(year: number, month: number) {
		const params = {
			Year: year,
			Month: month
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/approveritems`, { params, headers: await SVC.setAzureAuth() });
	}

	async getWorkingTimesheetsApproverItems() {
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/workingtimesheetsapproveritems`, { headers: await SVC.setAzureAuth() });
	}

	async getActiveOrdersItems(timeSheetDate: Date) {
		const params = {
			TimeSheetDate: timeSheetDate
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/activeordersitems`, { params, headers: await SVC.setAzureAuth() });
	}
	
	async getMonthlyCostCustomerItems(year: number, month: number) {
		const params = {
			year: year,
			month: month
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/monthlycostcustomeritems`, { params, headers: await SVC.setAzureAuth() });
	}
	
	
	async getMonthlyUserCostCustomerItems(year: number, month: number) {
		const params = {
			year: year,
			month: month
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/monthlyusercostcustomeritems`, { params, headers: await SVC.setAzureAuth() });
	}

	
	async getCustomersWorkedItems(year: number, month: number) {
		const params = {
			Year: year,
			Month: month
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/getCustomersWorkedItems`, { params, headers: await SVC.setAzureAuth() });
	}	

	async getOrdersWorkedItems(customerID: number, year: number, month: number) {
		const params = {
			CustomerID: customerID,
			Year: year,
			Month: month
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/getOrdersWorkedItems`, { params, headers: await SVC.setAzureAuth() });
	}

	async getUsersWorkedItems(orderID: number, year: number, month: number) {
		const params = {
			OrderID: orderID,
			Year: year,
			Month: month
		};
		return axios.get<SelectListItem[]>(`${await SVC.getApiBaseUri()}/api/selectitems/getUsersWorkedItems`, { params, headers: await SVC.setAzureAuth() });
	}
	
}