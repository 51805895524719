import CommonServices from "./CommonServices";
import axios from "axios";
import {
  RptCostCentersUsers,
  Order,
  SubOrderUsers,
  CostCenterUser,
  CustomerOdresUsersWorkingHours,
  CustomerUserMonthlyCost,
  DailyWorkingWorkedHoursItem,
  TimeSpanTypes,
  OrderUsersWorkedMinutes,
  UserWOrderSummaryItem,
  UserWSubOrderSummaryItem,
  TechnicalIntervention,
  SubOrdersMonthlyWorkedHoursItem,
  IOrderTimesheet,
} from "../models/reports";
import { IOrderTimesheetSearhParams } from "../models/models";

const SVC = new CommonServices();
export default class ReportServices {
  //Users per centro di costo
  async GetCostCentersUser() {
    return axios.get<RptCostCentersUsers[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetCostCentersUser`,
      { headers: await SVC.setAzureAuth() }
    );
  }

  //Order report
  async GetOrder(OrderID: number) {
    const params = {
      id: OrderID,
    };
    return axios.get<Order>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetOrder`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  //Order report
  async GetSubOrderUsers(SubOrderID: number) {
    const params = {
      id: SubOrderID,
    };
    return axios.get<SubOrderUsers>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetSubOrderUsers`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  //user costcenter report
  async GetRptCostCenterUsers(
    costCenterID: number,
    year: number,
    month: number
  ) {
    const params = {
      costCenterID: costCenterID,
      year: year,
      month: month,
    };
    return axios.get<CostCenterUser[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptCostCenterUsers`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  //customers orders user workinghours report
  async GetRptOdresUsersWorkingHours(
    orderID: number,
    year: number,
    month: number
  ) {
    const params = {
      orderID: orderID,
      year: year,
      month: month,
    };
    return axios.get<CustomerOdresUsersWorkingHours[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptOdresUsersWorkingHours`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  async GetRptUsersOrdersWorkingHours(
    userID: number,
    year: number,
    month: number
  ) {
    const params = {
      userID: userID,
      year: year,
      month: month,
    };
    return axios.get<CustomerOdresUsersWorkingHours[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptUsersOrdersWorkingHours`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  //customers orders user workinghours report
  async GetRptMonthlyCustomerUsersCost(
    customerID: number,
    year: number,
    month: number
  ) {
    const params = {
      customerID: customerID,
      year: year,
      month: month,
    };
    return axios.get<CustomerUserMonthlyCost[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptMonthlyCustomerUsersCost`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  //customers orders user workinghours report
  async GetRptUserMonthlyCustomerUsersCost(
    customerID: number,
    year: number,
    month: number
  ) {
    const params = {
      customerID: customerID,
      year: year,
      month: month,
    };
    return axios.get<CustomerUserMonthlyCost[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptUserMonthlyCustomerUsersCost`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  async GetRptSubOrdersMonthlyWorkedHours(year: number, month: number) {
    const params = {
      year: year,
      month: month,
    };
    return axios.get<SubOrdersMonthlyWorkedHoursItem[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptSubOrdersMonthlyWorkedHours`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  // order totale incrementale ore lavorate per giorno
  async GetRptOrderDailyWorkedHours(orderID: number, type: TimeSpanTypes) {
    const params = {
      OrderID: orderID,
      Type: type,
    };
    return axios.get<DailyWorkingWorkedHoursItem[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptOrderDailyWorkedHours`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  // suborder totale incrementale ore lavorate per giorno
  async GetRptSubOrderDailyWorkedHours(
    subOrderID: number,
    type: TimeSpanTypes
  ) {
    const params = {
      SubOrderID: subOrderID,
      Type: type,
    };
    return axios.get<DailyWorkingWorkedHoursItem[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptSubOrderDailyWorkedHours`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  // grafico lavorazioni risorse per commessa
  async GetRptUsersWorkedHoursByOrderID(orderID: number, type: TimeSpanTypes) {
    const params = {
      OrderID: orderID,
      Type: type,
    };
    return axios.get<OrderUsersWorkedMinutes[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptUsersWorkedHoursByOrderID`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  // elenco lavorazioni risorse per commessa
  async GetRptOrderSummary() {
    return axios.get<UserWOrderSummaryItem[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptOrderSummary`,
      { headers: await SVC.setAzureAuth() }
    );
  }
  // elenco lavorazioni risorse per deliverable
  async GetRptSubOrderSummary(orderID: number) {
    const params = {
      OrderID: orderID,
    };
    return axios.get<UserWSubOrderSummaryItem[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetRptSubOrderSummary`,
      { params, headers: await SVC.setAzureAuth() }
    );
  }

  async GetTimesheetsByOrderAndDate(filter: IOrderTimesheetSearhParams) {
    console.log("filter", filter);
    return axios.post<IOrderTimesheet[]>(
      `${await SVC.getApiBaseUri()}/api/Reports/GetTimesheetsByOrderAndDate`,
      filter,
      { headers: await SVC.setAzureAuth() }
    );
  }

  async GetTechnicalIntervention(data: TechnicalIntervention) {
    axios({
      url: `${await SVC.getApiBaseUri()}/api/Download/GetTechnicalIntervention`,
      method: "POST",
      data: data,
      responseType: "blob",
      headers: await SVC.setAzureAuth(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Rapportino.docx");
      document.body.appendChild(link);
      link.click();
    });
  }
}
