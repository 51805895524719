import * as React from "react";
import { useEffect, useState } from "react";
import { minutesToHours, hoursToMinutes } from "../../core/Utilities";
import { InputGroup } from "react-bootstrap";

export interface IHoursMinutes {
	onChange(v: number): void;
	Minutes: number; //valore in minuti
	id?: number;
	disabled?: boolean;
}

export default function HoursMinutes(props: IHoursMinutes) {
	const [error, setError] = useState<string>("");
	const [hoursValue, setHoursValue] = useState<number>(0);
	const [minutesValue, setMinutesValue] = useState<number>(0);
	const [minutesDisabled, setMinutesDisabled] = useState<boolean>(false);


	useEffect(() => {
		//se il valore iniziale è 1440 (24 ore) disabilito l'input per i minuti
		setMinutesDisabled(props.Minutes == 1440);
		const hours = Math.trunc(props.Minutes / 60);
		const minutes = props.Minutes - (hours * 60);
		setHoursValue(hours);
		setMinutesValue(minutes);

	}, [props.Minutes]);


	function handleHours(event: React.ChangeEvent<HTMLInputElement>): void {
		let hours: number = Number(event.currentTarget.value);
		if (hours > 24) {
			hours = 0;
		}
		setHoursValue(hours);
		props.onChange(hours * 60 + minutesValue);
		setMinutesDisabled(hours == 24);
	}

	function handleMinutes(event: React.ChangeEvent<HTMLInputElement>): void {
		let minutes: number = Number(event.currentTarget.value);
		if (minutes > 59) {
			minutes = 0;
		}
		setMinutesValue(minutes);
		props.onChange(minutes + (hoursValue * 60));
	}

	function handleFocus(event: React.FocusEvent<HTMLInputElement>): void {
		event.currentTarget.select();
	}

	return (
		<React.Fragment>
			{props.disabled ? <span className="text-right">{minutesToHours(props.Minutes)}</span> :
				<InputGroup>
					<input
						title="Ore"
						className="hours-input"
						type="number"
						max={24}
						min={0}
						onChange={handleHours}
						value={hoursValue}
						onFocus={handleFocus}
					/>
					<input
						title="Minuti"
						className="hours-input minutes"
						type="number"
						max={59}
						min={0}
						onChange={handleMinutes}
						disabled={minutesDisabled}
						value={minutesValue}
						onFocus={handleFocus}
					/>
				</InputGroup>
			}
		</React.Fragment>
	);
}
