/*
	barra laterale sinistra con il menu
	composto da link diretti e sub menu
	in fondo ci sono le icone di short cut
 */

import React, { useContext } from "react";
import { Link } from "react-router-dom"; //libreria di routing
import { Accordion } from "react-bootstrap";
import { Maincontext, UserContext } from "../maincontext";
//Icone
import { Icon } from "react-icons-kit";
import { ic_dashboard } from "react-icons-kit/md/ic_dashboard";
import { ic_people } from "react-icons-kit/md/ic_people";
import { ic_euro_symbol } from "react-icons-kit/md/ic_euro_symbol";
import { ic_insert_drive_file } from "react-icons-kit/md/ic_insert_drive_file";
import { ic_local_mall } from "react-icons-kit/md/ic_local_mall";
import { ic_folder } from "react-icons-kit/md/ic_folder";
import { ic_folder_open } from "react-icons-kit/md/ic_folder_open";
import { ic_child_friendly } from "react-icons-kit/md/ic_child_friendly";
import { barChart as ic_barChart } from "react-icons-kit/fa/barChart";
import { userTie as ic_userTie } from "react-icons-kit/icomoon/userTie";
import { listUl as ic_listUl } from "react-icons-kit/fa/listUl";
import { wrench as ic_wrench } from "react-icons-kit/icomoon/wrench";
import { caretDown as ic_caretDown } from "react-icons-kit/fa/caretDown";
import { ic_drag_handle } from "react-icons-kit/md/ic_drag_handle";
import { ic_format_align_justify } from "react-icons-kit/md/ic_format_align_justify";
import { ic_redo } from "react-icons-kit/md/ic_redo";
import { calendarCheckO as ic_calendarCheckO } from "react-icons-kit/fa/calendarCheckO";
import { ic_av_timer } from "react-icons-kit/md/ic_av_timer";
import { archive } from "react-icons-kit/fa/archive";

import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import { MenuList, ContentTypes } from "../../models/models";
import { IsAuthorized } from "../../core/Utilities";
//Icone fine

export interface IMenuLateraleProps {}

export default function SidebarMenu(props: IMenuLateraleProps) {
  const context = useContext(Maincontext);
  const customers_authorized: boolean = IsAuthorized(ContentTypes.CUSTOMERS);
  const orders_authorized: boolean = true; // IsAuthorized(ContentTypes.ORDERS);
  const support_registries_authorized: boolean = IsAuthorized(
    ContentTypes.SUPPORT_REGISTRIES
  );
  const timesheet_authorized: boolean = IsAuthorized(ContentTypes.TIMESHEETS);
  const users_authorized: boolean = IsAuthorized(ContentTypes.USERS);
  const reports_authorized: boolean = IsAuthorized(ContentTypes.REPORTS);
  const economic_data_authorized: boolean = IsAuthorized(
    ContentTypes.ECONOMIC_DATA
  );
  const commercial_data_authorized: boolean = IsAuthorized(
    ContentTypes.COMMERCIAL_DATA
  );
  const userContext = useContext(UserContext);
  const is_approver: boolean = userContext.Me
    ? userContext.Me.IsApprover
    : false;

  return (
    <div className="menu-laterale">
      <ul>
        <Link to="/">
          <li
            className={
              context.selectedMenu === MenuList.DASHBOARD ? "active" : ""
            }
          >
            <Icon icon={ic_dashboard} size={16} className="mr-3" />
            Dashboard
          </li>
        </Link>
        {userContext.Me?.IsApprover && (
          <Link to="/approvazioni">
            <li
              className={
                context.selectedMenu === MenuList.APPROVAL_REQUEST
                  ? "active"
                  : ""
              }
            >
              <Icon icon={ic_redo} size={16} className="mr-3" />
              Richieste approvazione
            </li>
          </Link>
        )}
        {users_authorized ? (
          <Link to="/users">
            <li
              className={
                context.selectedMenu === MenuList.USERS ? "active" : ""
              }
            >
              <Icon icon={ic_people} size={16} className="mr-3" />
              Risorse umane
            </li>
          </Link>
        ) : (
          ""
        )}
        {customers_authorized ? (
          <Link to="/customers">
            <li
              className={
                context.selectedMenu === MenuList.CUSTOMERS ? "active" : ""
              }
            >
              <Icon icon={ic_userTie} size={16} className="mr-3" />
              Clienti
            </li>
          </Link>
        ) : (
          ""
        )}
        <Accordion>
          <li>
            <Accordion.Toggle as={Link} to="#" eventKey="0">
              <Icon icon={ic_wrench} size={16} className="mr-3" />
              Commesse{" "}
              <Icon icon={ic_caretDown} size={16} className="float-right" />
            </Accordion.Toggle>
          </li>
          <Accordion.Collapse eventKey="0">
            <ul className="ml-3">
              {orders_authorized ? (
                <Link to="/orders">
                  <li
                    className={
                      context.selectedMenu === MenuList.ORDERS ? "active" : ""
                    }
                  >
                    <Icon icon={ic_folder_open} size={16} className="mr-3" />
                    Commesse Attive
                  </li>
                </Link>
              ) : (
                ""
              )}
              {orders_authorized ? (
                <Link to="/expiredorders">
                  <li
                    className={
                      context.selectedMenu === MenuList.EXPIRED_ORDERS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_folder} size={16} className="mr-3" />
                    Commesse Scadute
                  </li>
                </Link>
              ) : (
                ""
              )}
              <Link to="/suborders">
                <li
                  className={
                    context.selectedMenu === MenuList.SUBORDERS ? "active" : ""
                  }
                >
                  <Icon icon={ic_folder_open} size={16} className="mr-3" />
                  Deliverables Attivi
                </li>
              </Link>
              <Link to="/expiredsuborders">
                <li
                  className={
                    context.selectedMenu === MenuList.EXPIRED_SUBORDERS
                      ? "active"
                      : ""
                  }
                >
                  <Icon icon={ic_folder} size={16} className="mr-3" />
                  Deliverables Scaduti
                </li>
              </Link>
            </ul>
          </Accordion.Collapse>
          {economic_data_authorized ? (
            <li>
              <Accordion.Toggle as={Link} to="#" eventKey="5">
                <Icon icon={ic_euro_symbol} size={16} className="mr-3" />
                Dati economici
                <Icon icon={ic_caretDown} size={16} className="float-right" />
              </Accordion.Toggle>
            </li>
          ) : (
            ""
          )}
          {economic_data_authorized ? (
            <Accordion.Collapse eventKey="5">
              <ul className="ml-3">
                <Link to="/usersmonthlycost">
                  <li
                    className={
                      context.selectedMenu === MenuList.USERS_MONTHLY_COST
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_people} className="mr-3" />
                    Costo mensile risorse
                  </li>
                </Link>
                <Link to="/customersusersmonthlycost">
                  <li
                    className={
                      context.selectedMenu === MenuList.CUSTOMERS_MONTHLY_COST
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_userTie} className="mr-3" />
                    Costo mensile clienti
                  </li>
                </Link>
              </ul>
            </Accordion.Collapse>
          ) : (
            ""
          )}
          {commercial_data_authorized ? (
            <li>
              <Accordion.Toggle as={Link} to="#" eventKey="6">
                <Icon icon={ic_euro_symbol} size={16} className="mr-3" />
                Dati commerciali
                <Icon icon={ic_caretDown} size={16} className="float-right" />
              </Accordion.Toggle>
            </li>
          ) : (
            ""
          )}
          {commercial_data_authorized ? (
            <Accordion.Collapse eventKey="6">
              <ul className="ml-3">
                <Link to="/customersmonthlycost">
                  <li
                    className={
                      context.selectedMenu ===
                      MenuList.COMMERCIAL_CUSTOMERS_MONTHLY_COST
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_people} className="mr-3" />
                    Ore lavorate per cliente
                  </li>
                </Link>
                {/* <Link to="/subordersmonthlyworkedhours" >
									<li className={context.selectedMenu === MenuList.SUBORDERS_MONTHLY_WORKED_HOURS? "active" : ""}>
										<Icon icon={ic_people} className="mr-3" />Ore lavorate per attività
									</li>
								</Link> */}
              </ul>
            </Accordion.Collapse>
          ) : (
            ""
          )}
          {reports_authorized ? (
            <li>
              <Accordion.Toggle as={Link} to="#" eventKey="2">
                <Icon icon={ic_barChart} size={16} className="mr-3" />
                Reports
                <Icon icon={ic_caretDown} size={16} className="float-right" />
              </Accordion.Toggle>
            </li>
          ) : (
            ""
          )}
          {reports_authorized ? (
            <Accordion.Collapse eventKey="2">
              <ul className="ml-3">
                <Link to="/approvedtimesheets">
                  <li
                    className={
                      context.selectedMenu === MenuList.APPROVED_TIMESHEETS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_calendarCheckO} className="mr-3" />
                    Timesheet approvati
                  </li>
                </Link>
                <Link to="/workingtimesheets">
                  <li
                    className={
                      context.selectedMenu === MenuList.WORKING_TIMESHEETS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_calendarCheckO} className="mr-3" />
                    Timesheet in corso
                  </li>
                </Link>
                <Link to="/costcentersusers">
                  <li
                    className={
                      context.selectedMenu === MenuList.RPT_COSTCENTERSUSERS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_euro_symbol} className="mr-3" />
                    Risorse centri di costo
                  </li>
                </Link>
                <Link to="/reports/costcentersusersactivities">
                  <li
                    className={
                      context.selectedMenu === MenuList.RPT_COSTCENTERUSERS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_people} className="mr-3" />
                    Orari risorse umane
                  </li>
                </Link>
                <Link to="/ordersreport">
                  <li
                    className={
                      context.selectedMenu === MenuList.RPT_ORDERS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_wrench} className="mr-3" />
                    Commesse
                  </li>
                </Link>
                <Link to="/ordersworkinghoursreport">
                  <li
                    className={
                      context.selectedMenu === MenuList.RPT_ORDERSWORKINGHOURS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_wrench} className="mr-3" />
                    Ore lavorate commesse
                  </li>
                </Link>
                <Link to="/usersworkinghoursreport">
                  <li
                    className={
                      context.selectedMenu === MenuList.RPT_USERSWORKINGHOURS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_wrench} className="mr-3" />
                    Ore lavorate risorse
                  </li>
                </Link>
                <Link to="/ordersusersworkinghoursreport">
                  <li
                    className={
                      context.selectedMenu === MenuList.RPT_ORDERUSERSWORKINGHOURS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_wrench} className="mr-3" />
                    Ore risorse commessa
                  </li>
                </Link>
              </ul>
            </Accordion.Collapse>
          ) : (
            ""
          )}
          {support_registries_authorized ? (
            <li>
              <Accordion.Toggle as={Link} to="#" eventKey="1">
                <Icon icon={ic_listUl} size={16} className="mr-3" />
                Anagrafiche di supporto
                <Icon icon={ic_caretDown} size={16} className="float-right" />
              </Accordion.Toggle>
            </li>
          ) : (
            ""
          )}
          {support_registries_authorized ? (
            <Accordion.Collapse eventKey="1">
              <ul className="ml-3">
                <Link to="/costcenters">
                  <li
                    className={
                      context.selectedMenu === MenuList.COST_CENTERS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_euro_symbol} className="mr-3" />
                    Centri di costo
                  </li>
                </Link>
                <Link to="/profiles">
                  <li
                    className={
                      context.selectedMenu === MenuList.PROFILE_TYPES
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_local_mall} className="mr-3" />
                    Profili professionali
                  </li>
                </Link>
                <Link to="/contracttypes">
                  <li
                    className={
                      context.selectedMenu === MenuList.CONTRACT_TYPES
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_insert_drive_file} className="mr-3" />
                    Tipologie di contratto
                  </li>
                </Link>
                <Link to="/workinghourstypes">
                  <li
                    className={
                      context.selectedMenu === MenuList.WORKING_HOURS_TYPES
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_date_range} className="mr-3" />
                    Orari di lavoro
                  </li>
                </Link>
                <Link to="/activitylines">
                  <li
                    className={
                      context.selectedMenu === MenuList.ACTIVITY_LINES
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_drag_handle} className="mr-3" />
                    Linee di attivit&agrave;
                  </li>
                </Link>
                <Link to="/activitysteps">
                  <li
                    className={
                      context.selectedMenu === MenuList.ACTIVITY_STEPS
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_format_align_justify} className="mr-3" />
                    Fasi di attivit&agrave;
                  </li>
                </Link>
                <Link to="/permittypes">
                  <li
                    className={
                      context.selectedMenu === MenuList.PERMIT_TYPES
                        ? "active"
                        : ""
                    }
                  >
                    <Icon icon={ic_child_friendly} className="mr-3" />
                    Tipologie di permessi
                  </li>
                </Link>
              </ul>
            </Accordion.Collapse>
          ) : (
            ""
          )}
        </Accordion>

        {!reports_authorized && is_approver ? (
          <Link to="/reports/costcentersusersactivities">
            <li
              className={
                context.selectedMenu === MenuList.RPT_COSTCENTERUSERS
                  ? "active"
                  : ""
              }
            >
              <Icon icon={ic_people} className="mr-3" />
              Orari risorse umane
            </li>
          </Link>
        ) : (
          ""
        )}

        {timesheet_authorized || users_authorized ? (
          <Link to="/overtime">
            <li
              className={
                context.selectedMenu === MenuList.OVERTIME ? "active" : ""
              }
            >
              <Icon icon={ic_av_timer} size={16} className="mr-3" />
              Straordinari
            </li>
          </Link>
        ) : (
          ""
        )}
        {timesheet_authorized ? (
          <Link to="/timesheet">
            <li
              className={
                context.selectedMenu === MenuList.ARCHIVIO_TIMESHEET
                  ? "active"
                  : ""
              }
            >
              <Icon icon={archive} size={16} className="mr-3" />
              Archivio timesheet
            </li>
          </Link>
        ) : (
          ""
        )}

        {timesheet_authorized ? (
          <Link
            to={`/monthtimesheet/${new Date().getFullYear()}/${
              new Date().getMonth() + 1
            }`}
          >
            <li
              className={
                context.selectedMenu === MenuList.TIMESHEET ? "active" : ""
              }
            >
              <Icon icon={ic_date_range} size={16} className="mr-3" />
              Timesheet
            </li>
          </Link>
        ) : (
          ""
        )}
      </ul>
      {/* icone per gli short cut */}
      <div className="fixed-bottom d-flex justify-content-around align-items-center footer">
        <Link to="/" title="Dashboard">
          <Icon icon={ic_dashboard} size={22} className="mr-2 ml-2" />
        </Link>
        {timesheet_authorized && (
          <Link to="/timesheet" title="Timesheet">
            <Icon icon={ic_date_range} size={22} className="mr-2 ml-2" />
          </Link>
        )}
        {users_authorized && (
          <Link to="/users" title="Risorse umane">
            <Icon icon={ic_people} size={22} className="mr-2 ml-2" />
          </Link>
        )}
        {customers_authorized && (
          <Link to="/customers" title="Clienti">
            <Icon icon={ic_userTie} size={22} className="mr-2 ml-2" />
          </Link>
        )}
        {orders_authorized && (
          <Link to="/orders" title="Commesse">
            <Icon icon={ic_wrench} size={22} className="mr-2 ml-2" />
          </Link>
        )}
        <Link to="/suborders" title="Deliverable">
          <Icon icon={ic_folder_open} size={22} className="mr-2 ml-2" />
        </Link>
      </div>
    </div>
  );
}
