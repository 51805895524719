/*
	componente per l'impostazione del numero di ore da assegnare a una risorsa
	viene utilizzato nel componente WeekTable
	è possible inserire il valore utilizzando le freccette, scrivendolo direttamente
	oppure con doppio click, che causa l'impostazione del massimo di ore libere per quella data
	se per quella data non ci sono ore libere il compomente è disabilitato (readonly)
	è possibile impostare un size "sm" | "lg" | "xl" 
	se il size non è impostato viene utilizzato quello di default lg
	la modifica del valore fa scattare la funzione ChangeEvent passata come proprietà
	dal componente padre, che onsente al padre di gestire la modifica sulla lista di elementi
	ricevendo la data e il valore (numero di ore)
*/
import React, {
	useState,
	useEffect,
	FocusEvent,
	MouseEvent,
	ChangeEvent,
	CSSProperties,
} from "react";
import {ReadOnlyCell} from "./ReadOnlyCell";

export interface IEditingCell {
	key?: string;
	value: number;
	enabled?: boolean;
	max: number;
	size?: "sm" | "lg" | "xl" | undefined;
	onChange(currentdate: Date, hours: number): void;
	currentDate: Date;
}

export function EditingCell(props: IEditingCell) {

	const [size, setSize] = useState<number>(32);
	const [fontSize, setFontSize] = useState<number>(12);
	const [inputPadding, setInputPadding] = useState<number>(12);
	const [enabled, setEnabled] = useState<boolean>(false);
	const [bgColor, setBgColor] = useState<string>("");

	//imposta i valori per lo stile del componente
	useEffect(() => {
		switch (props.size) {
			case "sm":
				setSize(28);
				setFontSize(10);
				setInputPadding(8);
				break;
			case "lg":
				setSize(36);
				setFontSize(12);
				setInputPadding(10);
				break;
			case "xl":
				setSize(52);
				setFontSize(14);
				setInputPadding(14);
				break;
			default:
				setSize(36);
				setFontSize(12);
				setInputPadding(10);
				break;
		}
		setBgColor(!props.enabled ? "#eee" : "#ddd");
	}, [props.value, props.size, props.enabled]);

	useEffect(() => {
		const cellEnabled: boolean = props.enabled ? props.enabled : false;
		setEnabled(cellEnabled);
	}, [props.enabled]);

	const inputStyle: CSSProperties = {
		border: "none",
		width: size + "px",
		height: size + "px",
		textAlign: "center",
		fontSize: fontSize + "pt",
		// paddingTop: padding,
		paddingLeft: inputPadding,
		color: "gray",
		backgroundColor: bgColor,
		marginTop: "0px",
	};

	//evento onchange del componente, quando cambia il valore 
	//controlla che non sia superio al max consentito
	//e lo invia alla funzione impostata com prop dal contenitore
	function onChange(e: ChangeEvent<HTMLInputElement>) {
		const d: string | undefined = e.currentTarget
			.getAttribute("data-currentdate")
			?.toString();
		let hours: number = Number(e.target.value);

		if (hours > props.max) {
			hours = props.max;
		}

		if (d) {
			const currendDate: Date = new Date(d);
			props.onChange(currendDate, hours);
		}
	}

	//sul double click viene impostato il valore max consentito 
	//(ore disponibili per quella data)
	//e inviato al contenitore con la funzione onChange
	function onDoubleClick(e: MouseEvent<HTMLInputElement>) {
		const d: string | undefined = e.currentTarget
			.getAttribute("data-currentdate")
			?.toString();
		const hours: number = props.max;

		if (d) {
			const currendDate: Date = new Date(d);
			props.onChange(currendDate, hours);
		}
	}
	//seleziona il valore quando acquisisce il focus
	function onFocus(e: FocusEvent<HTMLInputElement>) {
		if (!enabled) {
			e.target.blur();
		} else {
			e.target.select();
		}
	}

	return (
		(enabled) ?
		<div style={{maxWidth: "36px"}}>
			<input
				key={props.key}
				style={inputStyle}
				type="Number"
				min="0"
				max={props.max}
				value={props.value}
				onChange={onChange}
				onFocus={onFocus}
				data-currentdate={props.currentDate}
				onDoubleClick={onDoubleClick}
			/>
		</div>:
			<ReadOnlyCell maxValue={0} value={props.value} size={props.size} />
		
	);
}
