/*
	barra del titolo
	con bread crumb composta da url base (dashboard)
	eventuali url secondari
	e nome della pagina corrente
*/

import React from "react";
import { Link } from "react-router-dom";

export interface IAppProps {
	title: string;
	links?: link[];
}
export interface link {
	title: string;
	to: string;
}

export default function TitleBar(props: IAppProps) {
	if (props.title !== "Dashboard") {
		return (
			<div className={"custom-breadcrumb"}>
				<Link to="/" >Dashboard</Link><span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
				{props.links && props.links.map((item, i) => (
					<React.Fragment key={"frgmnt" + i}>
						<Link to={item.to}>{item.title}</Link>
						<span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
					</React.Fragment>
				))}
				<label className={"custom-breadcrumb-active"}>{props.title}</label>
			</div>
		);
	}
	else {
		return (
			<div className={"custom-breadcrumb"}>
				<label className={"custom-breadcrumb-active"}>{props.title}</label>
			</div>
		);
	}
}
