import { User, UserList, UserProfileType, UserContractType, UserCostCenter, UserAuthorizationType, UserMonthlyCost, Customer, IDValue } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";
import YearNavigator from "../components/views/YearlyTimesheet/Components/YearNavigator";

const SVC = new CommonServices();

export default class UserServices {

	//elenco completo
	async getUsers() {
		return axios.get<User[]>(`${await SVC.getApiBaseUri()}/api/users`, { headers: await SVC.setAzureAuth() });
	}

	//elenco completo per la lista (ha due campi calcolati per profili e centri di costo)
	async getUsersList(completeList: boolean) {
		const params = {
			completeList: completeList
		};
		return axios.get<UserList[]>(`${await SVC.getApiBaseUri()}/api/users/list`, { params, headers: await SVC.setAzureAuth() });
	}

	//singolo elemento
	async getUser(id: number) {
		const params = {
			id: id
		};
		return axios.get<User>(`${await SVC.getApiBaseUri()}/api/users`, { params, headers: await SVC.setAzureAuth() });
	}

	//update user
	async UserUpdate(user: User) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/users`, user, { headers: await SVC.setAzureAuth() });
	}

	//update user preferences
	async UserPreferencesUpdate(user: User) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/users/PreferencesUpdate`, user, { headers: await SVC.setAzureAuth() });
	}

	//  USER PROFILES
	async UserProfileTypesUpdate(userID: number, profileTypes: UserProfileType[]) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/profiletypes/${userID}/profiles`, profileTypes, { headers: await SVC.setAzureAuth() });
	}

	//  USER CONTRACTS

	// contratti dell'utente
	async getUserContractTypesList(userID: number) {
		return axios.get<UserContractType[]>(`${await SVC.getApiBaseUri()}/api/contracttypes/${userID}/contracts`, { headers: await SVC.setAzureAuth() });
	}

	//USER AUTHORIZATION TYPES
	async UserAuthorizationTypesUpdate(userID: number, authorizationType: UserAuthorizationType[]) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/users/${userID}/authorizations`, authorizationType, { headers: await SVC.setAzureAuth() });
	}

	//USER COST CENTERS
	async UserCostCentersUpdate(userID: number, costCenters: UserCostCenter[]) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/CostCenters/${userID}/centers`, costCenters,  {headers: await SVC.setAzureAuth() });
	}

	

	//USER MONTHLY COST
	async GetUsersMonthlyCost(year: number, month: number) {
		const params = {
			Year: year,
			Month: month			
		};
		return axios.get<UserMonthlyCost[]>(`${await SVC.getApiBaseUri()}/api/users/monthlycost`, {params, headers: await SVC.setAzureAuth() });
	}

	async UserMonthlyCostUpdate(userMonthlyCost: UserMonthlyCost) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/users/MonthlyCostUpdate`, userMonthlyCost,  {headers: await SVC.setAzureAuth() });
	}

	//clienti della risorsa corrente
	async GetUserCustomers() {
		return axios.get<IDValue[]>(`${await SVC.getApiBaseUri()}/api/users/customers`, {headers: await SVC.setAzureAuth() });
	}

	//DOWNLOAD
	async Download(file: string) {
		axios({
			url: `${await SVC.getApiBaseUri()}/api/Download/${file}`,
			method: 'GET',
			responseType: 'blob',
			headers: await SVC.setAzureAuth()
		  }).then((response) => {
			 const url = window.URL.createObjectURL(new Blob([response.data]));
			 const link = document.createElement('a');
			 link.href = url;
			 link.setAttribute('download',  file + '.pdf');
			 document.body.appendChild(link);
			 link.click();
		  });
	}
	

	//update
	async UserContractTypesUpdate(userID: number, contractTypes: UserContractType[]) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/contracttypes/${userID}/contracts`, contractTypes, { headers: await SVC.setAzureAuth() });
	}

	async getUserCostCentersListAll(userID: number) {
		return axios.get<UserCostCenter[]>(`${await SVC.getApiBaseUri()}/api/costcenters/${userID}/centersall`, { headers: await SVC.setAzureAuth() });
	}
	async getUserProfileTypeList(userID: number) {
		return axios.get<UserProfileType[]>(`${await SVC.getApiBaseUri()}/api/profiletypes/${userID}/profiles`, { headers: await SVC.setAzureAuth() });
	}

	//registra accesso 
	async insertLogin(AzureId: string) {
		const params = {
			AzureId: AzureId
		};
		return axios.get(`${await SVC.getApiBaseUri()}/api/users/login`, { params, headers: await SVC.setAzureAuth() });
	}

}