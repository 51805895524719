import React, { useContext, useEffect, useState, MouseEvent } from "react";
import { IOrderTimesheet, ReportColumn } from "../../../models/reports";
import ReportServices from "../../../api/ReportServices";
import { Maincontext } from "../../maincontext";
import {
  IOrderTimesheetSearhParams,
  MenuList,
  SelectItemsListTypes,
  SelectListItem,
} from "../../../models/models";
import TitleBar from "../../common/TitleBar";
import Select from "react-select";
import SelectItemServices from "../../../api/SelectItemServices";
import {
  ListToArray,
  selectFilter,
  thousandSeparator,
} from "../../../core/Utilities";
import { Button, Col, Form } from "react-bootstrap";
import {
  FIRST_DAY_OF_WEEK,
  LABELS,
  MONTHS,
  TODAY_LABEL,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  formatDate,
} from "../../../models/locale";
import Icon from "react-icons-kit";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { fileExcel } from "react-icons-kit/icomoon/fileExcel";
import { fileText2 } from "react-icons-kit/icomoon/fileText2";
import { refresh } from "react-icons-kit/fa/refresh";
import ReactExport from "react-export-excel";
import { CSVLink } from "react-csv";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "../../common/pdf/PdfDocument";
import DayPickerInput from "react-day-picker/DayPickerInput";

export default function OrdersUsersWorkingHours() {
  interface iTotals {
    hours: number;
    overtime: number;
    nigthovertime: number;
    total: number;
    days: number;
  }

  const language = "it";
  const dateFormat = "DD/MM/YYYY";

  const defParams: IOrderTimesheetSearhParams = {
    orderID: 0,
    fromDate: new Date(),
    toDate: new Date(),
  };

  const defTotals: iTotals = {
    hours: 0,
    overtime: 0,
    nigthovertime: 0,
    total: 0,
    days: 0,
  };

  const [params, setParams] = useState<IOrderTimesheetSearhParams>(defParams);
  const [totals, setTotals] = useState<iTotals>(defTotals);

  const context = useContext(Maincontext);
  const SVC = new ReportServices();
  const ISVC = new SelectItemServices();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const icon_size: number = 22;

  const [ordersList, setOrdersList] = useState<SelectListItem[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<SelectListItem>();
  const [selectedYear, setSelectedYear] = useState<SelectListItem>();
  const [selectedMonth, setSelectedMonth] = useState<SelectListItem>();

  const [list, setList] = useState<IOrderTimesheet[]>([]);
  const [rows, setRows] = useState<any[][]>([]);
  const [totalWidth, setTotalWidth] = useState<number>(0);

  const columns: ReportColumn[] = [
    { columnName: "Cod. commessa", columnValue: "OrderCode", columnWidth: 1 },
    { columnName: "Commessa", columnValue: "OrderName", columnWidth: 2 },
    { columnName: "Cliente", columnValue: "CustomerName", columnWidth: 2 },
    { columnName: "Risorsa", columnValue: "UserFullName", columnWidth: 2 },
    {
      columnName: "Data intervento",
      columnValue: "TimeSheetDate",
      columnWidth: 1,
    },
    {
      columnName: "Ordinario min.",
      columnValue: "Minutes",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Ordinario ore",
      columnValue: "Hours",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Straord. min.",
      columnValue: "OvertimeMinutes",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Straord. ore",
      columnValue: "OvertimeHours",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Notturno min.",
      columnValue: "OvertimeNightMinutes",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Notturno",
      columnValue: "OvertimeNightHours",
      columnWidth: 1,
      textAlign: "right",
    },
    {
      columnName: "Affiancamento",
      columnValue: "Support",
      columnWidth: 1,
    },
    {
      columnName: "Note",
      columnValue: "UserNote",
      columnWidth: 8,
    },
  ];

  useEffect(() => {
    let total: number = 0;
    columns.forEach((element) => {
      total += element.columnWidth;
    });

    GetOrdersList();
    ResetParams();

    context.setContext({
      ...context,
      selectedMenu: MenuList.RPT_ORDERUSERSWORKINGHOURS,
    });
  }, []);

  useEffect(() => {
    SetTotals();
  }, [list]);

  useEffect(() => {
    GetReport();
  }, [params]);

  function GetOrdersList() {
    ISVC.getSelectItemList(SelectItemsListTypes.ORDERS, 0)
      .then((l) => {
        if (l.data) {
          let item: SelectListItem = {
            Text: "Seleziona una commessa",
            Value: "0",
          };
          let items: SelectListItem[] = l.data;
          items.unshift(item);
          setOrdersList(items);
        }
      })
      .catch((e) => {
        context.setContext({
          ...context,
          showAlert: true /* showAttesa: false, */,
          messaggioAlert: "Errore di caricamento ORDERS: " + e,
          titoloAlert: "Errore",
        });
      })
      .finally(() => {});
  }

  function GetReport() {
    if (params.orderID === 0) {
      setList([]);
    } else {
      SVC.GetTimesheetsByOrderAndDate(params)
        .then((l) => {
          if (l.data) {
            setList(l.data);
            console.log(l.data);
          }
        })
        .catch((e) => {
          context.setContext({
            ...context,
            showAlert: true /* showAttesa: false, */,
            messaggioAlert: "Errore di caricamento ore lavorate: " + e,
            titoloAlert: "Errore",
          });
        })
        .finally(() => {});
    }
  }

  const getColumnWidth = (index: number): string => {
    const w = columns[index].columnWidth;
    if (totalWidth > 0) {
      const retVal = (100 / totalWidth) * w;
      return retVal.toString() + "%";
    } else {
      return "auto";
    }
  };

  function changeOrderID(v: any, i: any) {
    const OrderID: number = Number(v.Value);
    setSelectedOrder(v);

    setParams({
      ...params,
      orderID: OrderID,
    });
  }

  function ResetParams() {
    let item: SelectListItem = { Text: "Seleziona una commessa", Value: "0" };
    setSelectedOrder(item);
    setParams(defParams);
    setList([]);
  }

  function onReset(e: MouseEvent<HTMLButtonElement>) {
    ResetParams();
  }

  const SetTotals = () => {
    if (list.length > 0) {
      const list_rows: any[][] = ListToArray(list);
      setRows(list_rows);
    }
    //calcolo totali
    let t: iTotals = defTotals;
    list.forEach((item) => {
      t.hours += item.Hours;
      t.overtime += item.OvertimeHours;
      t.nigthovertime += item.OvertimeNightHours;
    });
    t.total = t.hours + t.overtime + t.nigthovertime;
    t.days = Number((t.total / 8).toFixed(0));
    setTotals(t);
  };
  return (
    <div className="Container">
      <TitleBar title="Ore risorse commessa" />

      <div>
        <Form className="form-search-list">
          <Form.Row>
            <Col sm={12}>
              <h3>Ore risorse commessa</h3>
            </Col>
            <Col sm={6}>
              <label>Commessa</label>
              <Select
                filterOption={selectFilter}
                value={selectedOrder}
                options={ordersList}
                isSearchable
                onChange={changeOrderID}
                getOptionLabel={(c) => c.Text}
                getOptionValue={(c) => c.Value}
              />
            </Col>
            <Col sm={2}>
              <div className="form-group col">
                <label className="form-label">Dal giorno</label>
                <DayPickerInput
                  dayPickerProps={{
                    //className: "calendar-up",
                    month: new Date(params.fromDate),
                    todayButton: TODAY_LABEL[language],
                    locale: language,
                    months: MONTHS[language],
                    weekdaysLong: WEEKDAYS_LONG[language],
                    weekdaysShort: WEEKDAYS_SHORT[language],
                    firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
                    labels: LABELS[language],
                  }}
                  placeholder={"Non impostata"}
                  format={dateFormat}
                  value={new Date(params.fromDate).toLocaleDateString()}
                  onDayChange={(day) => setParams({ ...params, fromDate: day })}
                  inputProps={{
                    readonly: "readonly",
                  }}
                  formatDate={(day) => formatDate(day)}
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="form-group col">
                <label className="form-label">Al giorno</label>
                <DayPickerInput
                  dayPickerProps={{
                    //className: "calendar-up",
                    month: new Date(params.toDate),
                    todayButton: TODAY_LABEL[language],
                    locale: language,
                    months: MONTHS[language],
                    weekdaysLong: WEEKDAYS_LONG[language],
                    weekdaysShort: WEEKDAYS_SHORT[language],
                    firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
                    labels: LABELS[language],
                  }}
                  placeholder={"Non impostata"}
                  format={dateFormat}
                  value={new Date(params.toDate).toLocaleDateString()}
                  onDayChange={(day) => setParams({ ...params, toDate: day })}
                  inputProps={{
                    readonly: "readonly",
                  }}
                  formatDate={(day) => formatDate(day)}
                />
              </div>
            </Col>
            <Col md={2} className="pt-4">
              <Button
                variant={"link"}
                style={{ width: "auto" }}
                className="btn-sm mb-8 h-100 btn-context mr-3"
                onClick={onReset}
              >
                <Icon icon={refresh} size="16" className="mr-1" />
                Reset
              </Button>
              {list.length > 0 ? (
                <>
                  <div title="Esporta in excel" className="d-inline">
                    <ExcelFile
                      filename={"OreLavorateCommesse"}
                      element={
                        <div
                          className="d-inline mr-1"
                          style={{ cursor: "pointer" }}
                        >
                          <Icon
                            icon={fileExcel}
                            className="text-success"
                            size={icon_size}
                          />
                        </div>
                      }
                    >
                      <ExcelSheet data={list} name="Ore_Lavorate_Commessa">
                        {columns.map(function (item: ReportColumn, i) {
                          return (
                            <ExcelColumn
                              key={"xlsc_" + i.toString()}
                              label={item.columnName}
                              value={item.columnValue}
                            ></ExcelColumn>
                          );
                        })}
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                  <div title="Esporta in csv" className="d-inline mr-1">
                    <CSVLink data={rows} filename={"OreLavorateCommesse.csv"}>
                      <Icon
                        icon={fileText2}
                        className="text-warning"
                        size={icon_size}
                      />
                    </CSVLink>
                  </div>
                  {/* <div title="Esporta in pdf" className="d-inline">
										<PDFDownloadLink
											document={<PdfDocument
												title="OreLavorateCommesse"
												columns={columns}
												rows={rows} />}
											fileName={"OreLavorateCommesse.pdf"}>
											{({ blob, url, loading, error }) => (loading ?
												<Icon icon={filePdf} className="text-danger" size={icon_size} />
												:
												<Icon icon={filePdf} className="text-danger" size={icon_size} />)}
										</PDFDownloadLink>
									</div> */}
                </>
              ) : (
                ""
              )}
            </Col>
          </Form.Row>
        </Form>
        <div className="p-3">
          <div className="form-row">
            <div className="col-md-12 m-0">
              <table className="table">
                <thead>
                  <tr>
                    {columns.map(function (item: ReportColumn, i) {
                      return (
                        <th
                          key={"th_" + i.toString()}
                          style={{
                            width: getColumnWidth(i),
                            textAlign: item.textAlign ? item.textAlign : "left",
                          }}
                        >
                          {item.columnName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {list
                    ? list.map(function (item, x) {
                        return (
                          <tr key={"tr_" + x.toString()}>
                            <td>{item.OrderCode}</td>
                            <td>{item.OrderName}</td>
                            <td>{item.CustomerName}</td>
                            <td>{item.UserFullName}</td>
                            <td>
                              {new Date(
                                item.TimeSheetDate
                              ).toLocaleDateString()}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.Minutes)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.Hours)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.OvertimeMinutes)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.OvertimeHours)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.OvertimeNightMinutes)}
                            </td>
                            <td className="text-right pr-2">
                              {thousandSeparator(item.OvertimeNightHours)}
                            </td>
                            <td>{item.Support ? "Affiancamento" : ""}</td>
                            <td>{item.UserNote}</td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>

            <div className="text-right w-100">
              <table
                className="table table-sm table-bordered float-right"
                style={{ width: "180px" }}
              >
                <tbody>
                  <tr>
                    <td className="bg-light">Ore ordinarie</td>
                    <td className="text-right">
                      {thousandSeparator(totals.hours)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Straordinari</td>
                    <td className="text-right">
                      {thousandSeparator(totals.overtime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Straordinari notturni</td>
                    <td className="text-right">
                      {thousandSeparator(totals.nigthovertime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Ore totali</td>
                    <td className="text-right">
                      {thousandSeparator(totals.total)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Giorni</td>
                    <td>{thousandSeparator(totals.days)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
