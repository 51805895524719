import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
//Icone
import { Icon } from "react-icons-kit";
import { exit } from "react-icons-kit/icomoon/exit";
import { authProvider } from "../../authProvider";
import AzureADServices from "../../api/AzureADServices";
import "../../styles/menu.css";
import { Log } from "../../core/Utilities";

const SVC = new AzureADServices();
export default function UserInfo() {
	const initialsMatches = authProvider.getAccountInfo()?.account.name.match(/\b\w/g) || [];
	const initials = ((initialsMatches.shift() || "") + (initialsMatches.pop() || "")).toUpperCase();
	const [srcProfilePhoto, setSrcProfilePhoto] = useState("");
	const [profileInitials, setProfileInitials] = useState(initials);
	useEffect(() => {
		srcProfilePhoto === "" &&
			(async () => {
				try {
					const srcPhoto = await SVC.getProfilePhoto();
					if (srcPhoto.data === "photo not found") throw new Error("foto profilo non trovata.");
					setSrcProfilePhoto(srcPhoto.data);
					setProfileInitials("");
				} catch (error) {
					console.log(error);
				}
			})();
	});
	return (
		<div style={{ width: "max-content" }}>
			<div className="row m-0">
				<div className="col-2 pt-1">
					<div className="profile-photo" style={{ backgroundImage: `url(${srcProfilePhoto})` }}>{profileInitials}</div>
				</div>
				<div className="col-10 pt-0">
					<div className="p-2 text-right">
						<strong>{authProvider.getAccountInfo()?.account.name}</strong>
						<small style={{ display: "block", fontSize: "70%" }}>{authProvider.getAccountInfo()?.account.userName}</small>
						<NavDropdown.Divider />
						<NavDropdown.Item onClick={authProvider.logout} className="p-0"><Icon icon={exit} className="sub-menu-icon" />Disconnetti</NavDropdown.Item>
					</div>
				</div>
			</div>
		</div>
	);
}