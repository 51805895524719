import React, { useContext, useEffect, useState } from "react";
import { Maincontext } from "../maincontext";
import { MenuList } from "../../models/models";
import { Button, Spinner } from "react-bootstrap";

const centeredItem = {
	color: "#0078d4",
	fontSize: "16px",
	height: "100vh",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	zIndex: 1
} as React.CSSProperties;

const divWrapperStyle = {
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	position: "fixed"
} as React.CSSProperties;

export default function SessionExpired() {
	
	const context = useContext(Maincontext);
	const [loadingNewSession, setLoadingNewSession] = useState<Boolean>(false);

	useEffect(() => {	

		context.setContext({...context, selectedMenu: MenuList.UNDEFINED });
		(document.querySelector(".menu-laterale") as HTMLElement).style.display = "none";
		(document.querySelector(".navbar-nav") as HTMLElement).style.display = "none";	
		(document.querySelector(".principale") as HTMLElement).style.marginLeft = "0";

	}, []);	

	return (
		<div style={divWrapperStyle}>
			<div style={centeredItem}>
				<div className="text-center">
					{ !loadingNewSession && 
						<>
							<div className="mb-2">
								Sessione scaduta
								<br />
								<div className="mt-2">
									<Button className="bg-primary" onClick={() => { setLoadingNewSession(true); window.location.href= "/"; } }>Apri nuova sessione</Button>
								</div>					
							</div>
						</>
					}
					{ loadingNewSession && 
						<>
							<Spinner animation="border" />
							<br />
							Apertura nuova sessione...	 
						</>
					}
				</div>
			</div>
		</div>
	);
}