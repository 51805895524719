import { TimeSheetMonth, UserDayTimeSheet, HistoricalTimeSheetMonthState, MonthStates, TimeSheetApprovingMonth, TimeSheetApprovedMonth, DayAssignedWorkingHours, QuickAssignmentParameters, AvailableUser, AvailableUsersParameters, UserTimeSheetMonth, UserTimeSheetYearMonth } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class TimeSheetServices {

	async getTimeSheetYear(year: number) {
		return axios.get<TimeSheetMonth[]>(`${await SVC.getApiBaseUri()}/api/timesheet/${year}`, { headers: await SVC.setAzureAuth() });
	}
	async getHistoricalMonthState(timesheetMonthID: number) {
		return axios.get<HistoricalTimeSheetMonthState[]>(`${await SVC.getApiBaseUri()}/api/timesheet/historicalmonthstate/${timesheetMonthID}`, { headers: await SVC.setAzureAuth() });
	}

	async getUserDayTimeSheet(day: Date, userID: number) {

		let params: object;

		if (userID != 0){
			params = {
				timeSheetDate: day.toISOString(),
				UserID: userID
			};
		}
		else{
			params = {
				timeSheetDate: day.toISOString()
			};
		}
		return axios.get<UserDayTimeSheet>(`${await SVC.getApiBaseUri()}/api/TimeSheet/userdaytimesheet`, { params, headers: await SVC.setAzureAuth() });
	}

	async UserDayTimeSheetUpdate(userDayTimeSheet: UserDayTimeSheet, userID: number) {

		if (userID == 0){
		return axios.post(`${await SVC.getApiBaseUri()}/api/TimeSheet`, userDayTimeSheet, { headers: await SVC.setAzureAuth() });
		}
		else{
			userDayTimeSheet.UserID = userID;
			return axios.post(`${await SVC.getApiBaseUri()}/api/TimeSheet/userdaytimesheetupdate`, userDayTimeSheet, { headers: await SVC.setAzureAuth() });
		}
	}

	async TimeSheetMonthStateChange(timeSheetMonthID: number, note: string, montState: MonthStates) {
		const params = {
			TimeSheetMonthID: timeSheetMonthID,
			note: note,
			MontState: montState
		};
		return axios.get(`${await SVC.getApiBaseUri()}/api/TimeSheet/monthstatechange`,  {params, headers: await SVC.setAzureAuth() });
	}

	async TimeSheetMonthSendToApprover(timeSheetMonthID: number, note: string, montState: MonthStates, userID: number) {
		const params = {
			TimeSheetMonthID: timeSheetMonthID,
			note: note,
			MontState: montState,
			UserID: userID
		};
		return axios.get(`${await SVC.getApiBaseUri()}/api/TimeSheet/usermonthstatechange`,  {params, headers: await SVC.setAzureAuth() });
	}

	async TimeSheetMonthReturnToUser(timeSheetMonthID: number, note: string) {
		const params = {
			TimeSheetMonthID: timeSheetMonthID,
			note: note
		};
		return axios.get(`${await SVC.getApiBaseUri()}/api/TimeSheet/monthreturntouser`,  {params, headers: await SVC.setAzureAuth() });
	}
	async GetTimeSheetApprovingMonths() {
		return axios.get<TimeSheetApprovingMonth[]>(`${await SVC.getApiBaseUri()}/api/TimeSheet/approvingmonths`,  {headers: await SVC.setAzureAuth() });
	}
	async GetTimeSheetApprovedMonths(year: number, month: number, approverID: number, approved: boolean) {
		const params = {
			Year: year,
			Month: month,
			ApproverID: approverID,
			Approved: approved
		};
		return axios.get<TimeSheetApprovedMonth[]>(`${await SVC.getApiBaseUri()}/api/TimeSheet/approvedmonths`,  {params, headers: await SVC.setAzureAuth() });
	}
	async GetDayAssignedWorkingHours() {
		return axios.get<DayAssignedWorkingHours[]>(`${await SVC.getApiBaseUri()}/api/TimeSheet/dayassignedworkinghours`,  {headers: await SVC.setAzureAuth() });
	}
	async GetUserDayAssignedWorkingHours(userID: number) {
		const params = {
			UserID: userID
		};
		return axios.get<DayAssignedWorkingHours[]>(`${await SVC.getApiBaseUri()}/api/TimeSheet/userdayassignedworkinghours`,  {params, headers: await SVC.setAzureAuth() });
	}
	async GetAvailableUsers(params: AvailableUsersParameters) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/TimeSheet/AvailableUsers`, params, { headers: await SVC.setAzureAuth() });
	}

	async GetUserMonthTimesheet(year: number, month: number) {
		return axios.get<UserTimeSheetMonth>(`${await SVC.getApiBaseUri()}/api/TimeSheet/${year}/${month}`, { headers: await SVC.setAzureAuth() });
	}
	
	async GetMonthTimesheetFromID(monthTimesheetID: number) {
		const params = {
			monthTimesheetID: monthTimesheetID
		};
		return axios.get<UserTimeSheetMonth>(`${await SVC.getApiBaseUri()}/api/TimeSheet/monthfromid`, {params, headers: await SVC.setAzureAuth() });
	}

	async TimeSheetMonthAuthorized(monthTimesheetID: number) {
		const params = {
			monthTimesheetID: monthTimesheetID
		};
		return axios.get<boolean>(`${await SVC.getApiBaseUri()}/api/TimeSheet/authorized`, {params, headers: await SVC.setAzureAuth() });
	}

	async GetUserYearTimesheet(year: number) {
		return axios.get<UserTimeSheetYearMonth[]>(`${await SVC.getApiBaseUri()}/api/TimeSheet/year/${year}`, { headers: await SVC.setAzureAuth() });
	}

	async IsTimesheetMonthComplete(year: number, month: number, userID: number) {
		const params = {
			Year: year,
			Month: month,
			UserID: userID
		};
		return axios.get<boolean>(`${await SVC.getApiBaseUri()}/api/TimeSheet/IsTimesheetMonthComplete`,  {params, headers: await SVC.setAzureAuth() });
	}

	async GetLastTimesheetDates() {
		return axios.get<Date[]>(`${await SVC.getApiBaseUri()}/api/TimeSheet/LastTimesheetDates`, { headers: await SVC.setAzureAuth() });
	}
	

}