//  componente modale utilizzato per ospitare i controlli di tipo dettaglio
//  implementa i tasti annulla (chiude il pannello) e salva, che esegue la funzione save
//  con richiesta conferma (popover)

import React, { useContext, useState, ReactElement, useEffect } from "react";
import { Button, Modal, Popover, Alert } from "react-bootstrap";
import { Maincontext } from "../maincontext";
import { SaveDetailObject } from "../../core/Utilities";
//Icone
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import SaveButton from "./SaveButton";

export interface IDettaglioProps {
	title: string; //titolo del pannello posizionato nell"HEADER del MODAL
	body: ReactElement; //componente visualizzato nel BODY del MODAL
	size?: "sm" | "lg" | "xl" | undefined; //dimensioni del MODAL
	obj: any;
}

export default function DetailPanel<T>(props: IDettaglioProps) {

	const context = useContext(Maincontext);

	//alertMessage è il messaggio che compare accanto ai tasti salva e annulla
	//ad esempio in caso di validazione dei dati fallita
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [showPopover, setShowPopover] = useState(false);

	useEffect(() => {
		setAlertMessage("");
	}, []);

	//funzione che salva l'elemento del dettaglio
	const save = () => {
		setShowPopover(false); //nascond eil popover
		//salva i dati
		SaveDetailObject(context.objTypeSelected, context).then((d) => {
			if (d !== undefined && d.data !== "") {
				setAlertMessage(d.data);
			}
			else {
				closeDialog();
			}
		}).catch((e: React.SetStateAction<string>) => {
			setAlertMessage("Errore del server");
		}).finally(() => {
			context.setContext({ ...context, /*showAttesa: false,*/ refreshList: !context.refreshList });
		});
	};

	//chiude il pannello
	const closeDialog = () => {
		context.setContext({ ...context, showDetail: false });
		context.showDetail = false;
		setShowPopover(false);
	};

	//chiude il popover di richiesta conferma
	const closePopover = () => {
		setShowPopover(false);
	};

	//disabilita il pulsante SALVA
	function disabledButton(b: boolean) {
		if (b)
			return <Button variant="primary" size="sm" onClick={() => setShowPopover(true)}>Salva</Button>;
		else
			return <Button variant="primary" size="sm" disabled>Salva</Button>;
	}

	//pannello per la richiesta di conferma
	const popover = (
		<Popover id="popover-basic" className={showPopover ? "" : "d-none"}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				Salvare i dati?<br /><br />
				<Button variant="outline-secondary" className="btn-sm" onClick={closePopover}><Icon icon={ic_close} /></Button>
				&nbsp;
				<Button variant="outline-primary" className="btn-sm" onClick={save}><Icon icon={ic_check} /></Button>
			</Popover.Content>
		</Popover>
	);

	//render del componente
	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={props.size}
				className={context.hiddenDetail ? "d-none" : ""}
				show={context.showDetail}
				onHide={() => closeDialog()}
				onShow={() => {
					setAlertMessage("");
					setShowPopover(false);
				}
				}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title>
						{props.title}<br />
						<h5>{context.objectName}</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.body}
				</Modal.Body>
				<Modal.Footer>
					<Alert variant={alertMessage === "" ? undefined : "warning"}
						className={context.panelReadonly !== true ? "" : "d-none"}>
						{alertMessage}
					</Alert>
					<Button variant="secondary"
						className={context.panelReadonly !== true ? "" : "d-none"}
						size="sm"
						onClick={() => closeDialog()}>Annulla</Button>
						
					<SaveButton
						hidden={context.panelReadonly}
						disabled={!context.saveEnabled}
						popover={popover}
						popoverFunction = {setShowPopover}
						saveFunction = {save}
					/>
					<Button variant="secondary" className={context.panelReadonly === true ? "" : "d-none"}
						size="sm"
						onClick={() => closeDialog()}>Chiudi</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
