import React, { useState, useEffect, CSSProperties } from "react";
import { MonthStates } from "../../../../models/models";

export interface IMonthStatusLedProps {
	MonthState?: MonthStates;
	Size?: "sm" | "lg" | "xl" | undefined;
}

export default function MonthStatusLed (props: IMonthStatusLedProps) {
	
	const [size, setSize] = useState<number>(16);
	const [bgColor, setBgColor] = useState<string>("transparent");
	const [borderColor, setBorderColor] = useState<string>("gray");
	const [status, setStatus] = useState<string>("");	

	useEffect(() => {
		switch (props.Size) {
			case "sm":
				setSize(12);
				break;
			case "lg":
				setSize(16);
				break;
			case "xl":
				setSize(20);
				break;
			default:
				setSize(16);
				break;
		}		
	}, [props.Size]);

	useEffect(() => {
		switch (props.MonthState) {
			case MonthStates.COMPILAZIONE:
				setBgColor("silver");
				setBorderColor("silver");
				setStatus("Compilazione");			
				break;
			case MonthStates.RICHIESTA_APPROVAZIONE:
				setBgColor("gold");
				setBorderColor("gold");
				setStatus("Richiesta approvazione");			
				break;
			case MonthStates.APPROVATO:
				setBgColor("mediumseagreen");
				setBorderColor("mediumseagreen");
				setStatus("Approvato");		
				break;
			case MonthStates.RIFIUTATO:
				setBgColor("crimson");
				setBorderColor("crimson");
				setStatus("Rifiutato");		
				break;
			case MonthStates.RITIRATO:
				setBgColor("orange");
				setBorderColor("orange");
				setStatus("Ritirato");		
				break;				
			default:
				setBgColor("transparent");
				setBorderColor("silver");
				setStatus("");		
				break;
		}
		
	}, [props.MonthState]);

	const componentStyle: CSSProperties = {
		display: "inline-block",
		width: size + "px",
		height: size + "px",
		backgroundColor: bgColor,
		borderRadius: "50%",
		border: "1px solid " + borderColor
	};

	return <div style={componentStyle} title={status}></div>;

}