import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { LogLevel, Logger } from "msal";

const logger = new Logger(
	(logLevel, message, containsPii) => {
		console.log("[MSAL]", message);
	},
	{
		level: LogLevel.Warning,
		piiLoggingEnabled: false
	}
);

export const authProvider = new MsalAuthProvider(
	{
		auth: {
			authority: "https://login.microsoftonline.com/common",
			clientId: "756c50a6-966b-4761-b312-2073ecd4601b",
			postLogoutRedirectUri: window.location.origin,
			redirectUri: window.location.origin,
			validateAuthority: true,

			// After being redirected to the "redirectUri" page, should user
			// be redirected back to the Url where their login originated from?
			navigateToLoginRequestUrl: true
		},
		// Enable logging of MSAL events for easier troubleshooting.
		// This should be disabled in production builds.
		system: {
			logger: logger as any
		},
		cache: {
			cacheLocation: "sessionStorage",
			storeAuthStateInCookie: false
		}
	},
	{
		scopes: [
			"openid",
			"profile",
			"User.Read"/*,
      "Mail.Read",
      "User.Read.All",
      "Mail.ReadWrite",
      "Directory.Read.All",
      "Group.ReadWrite.All"*/
		]
	},
	{
		loginType: LoginType.Redirect,
		// When a token is refreshed it will be done by loading a page in an iframe.
		// Rather than reloading the same page, we can point to an empty html file which will prevent
		// site resources from being loaded twice.
		tokenRefreshUri: window.location.origin + "/auth.html"
	}
);