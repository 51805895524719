/*
	gestione delle url dell'applicazione
*/
import * as React from "react";
import { Switch, Route } from "react-router-dom"; //libreria di routing
//import { Col } from "react-bootstrap";
//views
import UsersList from "../views/UsersList";
import Dashboard from "../views/Dashboard";
import WorkingHoursTypesList from "../views/WorkingHoursTypesList";
import CostCentersList from "../views/CostCentersList";
import ProfileTypesList from "../views/ProfileTypesList";
import ContractTypesList from "../views/ContractTypeList";
import CustomersList from "../views/CustomersList";
import Test from "../views/Test";
import OrdersList from "../views/OrdersList";
import SubOrdersList from "../views/SubOrder/SubOrdersList";
import ActivityLines from "../views/ActivityLinesList";
import PermitTypes from "../views/PermitTypesList";
import ActivitySteps from "../views/ActivityStepsList";
import SubOrderView from "../views/SubOrder/SubOrderView";
import TimesheetYear from "../views/YearlyTimesheet/TimesheetYear";
import ApprovingTimesheets from "../views/ApprovingTimesheets";
import { IsAuthorized } from "../../core/Utilities";
import { ContentTypes } from "../../models/models";
import { useContext } from "react";
import { UserContext } from "../maincontext";
import Unauthorized from "../views/Unauthorized";
import ApprovedTimesheets from "../views/ApprovedTimesheets";
import UserView from "../views/UserView";
import CostCentersUsers from "../views/reports/CostCentersUsers";
import OrdersReport from "../views/reports/Order/OrdersReport";
import OrderReportPage from "../views/reports/Order/OrderReportPage";
import SubOrderUsers from "../views/reports/SubOrderUsers";
import SessionExpired from "../views/SessionExpired";
import MonthTimeSheet from "../views/MonthTimeSheet";
import OvertimeList from "../views/OvertimeList";
import CostCentersUsersActivities from "../views/reports/CostCentersUsersActivities";
import MonthTimesheetReport from "../views/reports/MonthTimeSheetReport";
import OrdersWorkingHours from "../views/reports/OrdersWorkingHours";
import WorkingTimesheets from "../views/WorkingTimesheets";
import UsersMonthlyCost from "../views/UsersMonthlyCost";
import CustomersUsersMonthlyCost from "../views/CustomersUsersMonthlyCost";
import CustomersMonthlyCost from "../views/CustomersMonthlyCost";
import SubOrdersMonthlyWorkedHours from "../views/SubOrdersMonthlyWorkedHours";
import SubOrderDailyWorkedHours from "../views/charts/SubOrderDailyWorkedHours";
import OrderDailyWorkedHours from "../views/charts/OrderDailyWorkedHours";
import OrderUsersWorkedHours from "../views/charts/OrderUsersWorkedHours";
import UsersWorkingHours from "../views/reports/UsersWorkingHours";
import OrdersUsersWorkingHours from "../views/reports/OrdersUsersWorkingHours";

export interface IRoutesProps {
}

export default function Routes(props: IRoutesProps) {

	const userContext = useContext(UserContext);

	const customers_authorized: boolean = IsAuthorized(ContentTypes.CUSTOMERS);
	//const orders_authorized: boolean = IsAuthorized(ContentTypes.ORDERS);
	const support_registries_authorized: boolean = IsAuthorized(ContentTypes.SUPPORT_REGISTRIES);
	const timesheet_authorized: boolean = IsAuthorized(ContentTypes.TIMESHEETS);
	const users_authorized: boolean = IsAuthorized(ContentTypes.USERS);
	const reports_authorized: boolean = IsAuthorized(ContentTypes.REPORTS);
	const economic_data_authorized: boolean = IsAuthorized(ContentTypes.ECONOMIC_DATA);
	const is_approver: boolean = userContext.Me?userContext.Me.IsApprover: false;
	return (
		<div className="principale">
			<Switch>
				<Route path="/orders">
					{/* {orders_authorized && <OrdersList expired={false} />} */}
					<OrdersList expired={false} />
				</Route>
				<Route path="/expiredorders">
					{/* {orders_authorized && <OrdersList expired={true} />} */}
					<OrdersList expired={true} />
				</Route>
				<Route path="/suborders">
					<SubOrdersList expired={false} />
				</Route>
				<Route path="/expiredsuborders">
					<SubOrdersList expired={true} />
				</Route>
				{/* route con parametro */}
				<Route exact path="/suborder/:id" component={SubOrderView} />
				<Route path="/customers">
					{(customers_authorized && <CustomersList />) || <Unauthorized />}
				</Route>
				<Route path="/users">
					{(users_authorized && <UsersList />) || <Unauthorized />}
				</Route>
				<Route exact path="/user/:id" component={UserView} />
				<Route path="/costcenters">
					{(support_registries_authorized && <CostCentersList />) || <Unauthorized />}
				</Route>
				<Route path="/profiles">
					{(support_registries_authorized && <ProfileTypesList />) || <Unauthorized />}
				</Route>
				<Route path="/activitylines">
					{(support_registries_authorized && <ActivityLines />) || <Unauthorized />}
				</Route>
				<Route path="/permittypes">
					{(support_registries_authorized && <PermitTypes />) || <Unauthorized />}
				</Route>
				<Route path="/activitysteps">
					{(support_registries_authorized && <ActivitySteps />) || <Unauthorized />}
				</Route>
				<Route path="/contracttypes">
					{(support_registries_authorized && <ContractTypesList />) || <Unauthorized />}
				</Route>
				<Route path="/workinghourstypes">
					{(support_registries_authorized && <WorkingHoursTypesList />) || <Unauthorized />}
				</Route>
				<Route path="/timesheets">
					{(timesheet_authorized && <div>Timesheets</div>) || <Unauthorized />}
				</Route>
				<Route exact path="/monthtimesheet/:year/:month" component={MonthTimeSheet} />
				<Route exact path="/monthtimesheet/:id" component={MonthTimeSheet} />
				<Route path="/overtime">
					{((timesheet_authorized || users_authorized) && <OvertimeList />) || <Unauthorized />}
				</Route>
				<Route path="/timesheet">
					{(timesheet_authorized && <TimesheetYear />) || <Unauthorized />}
				</Route>				
				<Route path="/approvazioni">
					{(userContext.Me?.IsApprover && <ApprovingTimesheets />) || <Unauthorized />}
				</Route>
				<Route path="/approvedtimesheets">
					{(reports_authorized && <ApprovedTimesheets />) || <Unauthorized />}
				</Route>
				<Route path="/workingtimesheets">
					{(reports_authorized && <WorkingTimesheets />) || <Unauthorized />}
				</Route>
				<Route path="/costcentersusers">
					{(reports_authorized && <CostCentersUsers />) || <Unauthorized />}
				</Route>
				<Route path="/ordersreport">
					{(reports_authorized && <OrdersReport />) || <Unauthorized />}
				</Route>
				<Route path="/ordersworkinghoursreport">
					{(reports_authorized && <OrdersWorkingHours />) || <Unauthorized />}
				</Route>
				<Route path="/usersworkinghoursreport">
					{(reports_authorized && <UsersWorkingHours />) || <Unauthorized />}
				</Route>
				<Route path="/ordersusersworkinghoursreport">
					{(reports_authorized && <OrdersUsersWorkingHours />) || <Unauthorized />}
				</Route>				
				<Route path="/usersmonthlycost">
					{(economic_data_authorized && <UsersMonthlyCost />) || <Unauthorized />}
				</Route>	
				<Route path="/customersusersmonthlycost">
					{(economic_data_authorized && <CustomersUsersMonthlyCost />) || <Unauthorized />}
				</Route>
				<Route path="/customersmonthlycost">
					{(economic_data_authorized && <CustomersMonthlyCost />) || <Unauthorized />}
				</Route>	
				<Route path="/subordersmonthlyworkedhours">
					{(economic_data_authorized && <SubOrdersMonthlyWorkedHours />) || <Unauthorized />}
				</Route>		
				<Route exact path="/reports/order/:id">
					<OrderReportPage />
				</Route>
				<Route exact path="/reports/suborderusers/:id">
					<SubOrderUsers />
				</Route>
				<Route exact path="/reports/monthtimesheet/:id">
					<MonthTimesheetReport />
				</Route>
				<Route exact path="/reports/OrderUsersWorkedHours/:id">
					<OrderUsersWorkedHours />
				</Route>				
				<Route exact path="/reports/OrderDailyWorkedHours/:id">
					<OrderDailyWorkedHours />
				</Route>
				<Route exact path="/reports/SubOrderDailyWorkedHours/:id">
					<SubOrderDailyWorkedHours />
				</Route>
				<Route exact path="/reports/costcentersusersactivities">
					{((reports_authorized || is_approver) && <CostCentersUsersActivities />) || <Unauthorized />}
				</Route>
				<Route path="/unauthorized">
					<Unauthorized />
				</Route>	
				<Route path="/expired">
					<SessionExpired />
				</Route>			
				<Route path="/test">
					<Test />
				</Route>
				<Route path="/">
					<Dashboard />
				</Route>
			</Switch>
		</div>
	);
}