import React, { useState, useEffect, MouseEvent } from "react";
import { Popover, Button } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";

export interface IAppProps {
	message: string;
	saveFunction?(e: MouseEvent<HTMLButtonElement>): any;
	show: boolean;
	x: number;
	y: number;
	e?: MouseEvent<HTMLButtonElement>;
}

export default function ConfirmPopOver(props: IAppProps) {
	const [showPopover, setShowPopover] = useState(false);
	const [saveFunction, setSaveFunction] = useState(() => (e: any) => { });
	useEffect(() => {
		setShowPopover(props.show);
		setSaveFunction(props.saveFunction!);
	}, [props.show])
	return (
		<Popover id="popover-confirm" className={showPopover ? "" : "d-none"} placement="left" style={{ position: "fixed", top: props.y, left: props.x }}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				{props.message}<br /><br />
				<Button variant="warning" onClick={() => setShowPopover(false)}><Icon icon={ic_close} /></Button>
        		&nbsp;
        		<Button variant="primary" onClick={() => {
					debugger;
					if (saveFunction && props.e) {
						saveFunction(props.e);
					}
					setShowPopover(false);

				}}><Icon icon={ic_check} /></Button>

			</Popover.Content>
		</Popover>
	);
}
