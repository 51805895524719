import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { User, SelectListItem, SelectItemsListTypes } from "../../models/models";
import UserServices from "../../api/UserServices";
import { Form } from "react-bootstrap";
import Select from "react-select";
import SelectItemServices from "../../api/SelectItemServices";
import { getIndexSelectedById } from "../../core/Utilities";
import Info from "../common/Info";

export interface IUserDetail {
}
export default function UserDetail(props: IUserDetail) {

	const context = useContext(Maincontext);
	const { handleSubmit } = useForm();
	const SVC = new UserServices();
	const ISVC = new SelectItemServices();

	let id = 0;

	if (context.objSelected) {
		id = context.objSelected.UserID as number;
	}

	const [usersList, setUsersList] = useState<SelectListItem[] | []>([]);
	const [workingHoursTypesList, setWorkingHoursTypesList] = useState<SelectListItem[] | []>([]);
	const [selectedWorkingHoursType, setSelectedWorkingHoursType] = useState<SelectListItem | undefined>(undefined);
	const [selectedApproverID, setSelectedApproverID] = useState<SelectListItem | undefined>(undefined);

	const defUser: User = {
		UserID: 0,
		FirstName: "",
		LastName: "",
		Language: "",
		CreationDate: new Date(),
		Enabled: true,
		SuperAdmin: false,
		EmailAddress: "",
		ApproverFullName: "",
		ProfilePhoto: "",
		IsApprover: false,
		FullName: "",
		Initials: "",
		UserAuthorizationTypes: [],
		UserProfileTypes: [],
		UserContractTypes: [],
		UserCostCenters: [],
		UserActivityLines: [],
		UserLogins: [],
		WorkingHoursTypeID: 0,
		WorkingHoursTypeName: "",
		Available: false,
		DefaultApprover: true,
		ConfirmButton: true,
		RecentActivitiesList: true,
		TimesheetDuplicated: true
	};

	const [user, setUser] = useState<User>(defUser);

	useEffect(() => {
		GetUsersList();
	}, []);

	useEffect(() => {

		setSelectedApproverID((
			usersList[
			getIndexSelectedById(
				usersList,
				user.ApproverID!
			)
			]
		));

		setSelectedWorkingHoursType((
			workingHoursTypesList[
			getIndexSelectedById(
				workingHoursTypesList,
				user.WorkingHoursTypeID!
			)
			]
		));


	}, [workingHoursTypesList, user]);

	function GetWorkingHoursTypesList() {

		ISVC.getSelectItemList(SelectItemsListTypes.WORKING_HOURS_TYPE, 0).then((l) => {
			if (l.data) {
				setWorkingHoursTypesList(l.data);
				if (id) {
					GetItem();
				}
				else {
					const WorkingHoursTypeID: number = Number(l.data[0].Value);
					setUser({ ...user, WorkingHoursTypeID: WorkingHoursTypeID });
					context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
				}
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore caricamento tipologie orari di lavoro: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});

	}

	function GetUsersList() {

		ISVC.getSelectItemList(SelectItemsListTypes.USERS, 0).then((l) => {
			if (l.data) {
				setUsersList(l.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore caricamento tipologie orari di lavoro: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			GetWorkingHoursTypesList();
		});

	}

	function GetItem() {

		SVC.getUser(id).then((d) => {
			if (d.data) {
				setUser(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: Users" + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});

		context.objectName = context.objSelected.FullName;
	}

	//validazione del form
	const isValid = (): boolean => {
		return user.WorkingHoursTypeID! >= 0;
	};

	function setContext() {

		const valid = isValid();
		if (valid) {
			context.objSelected = user;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setEnabled(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
		user.Enabled = enabled;
		setContext();
	}
	function setAvailable(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
		user.Available = enabled;
		setContext();
	}
	function setWorkingHoursTypeID(v: any, i: any) {
		const workingHoursTypeID: number = Number(v.Value);
		setSelectedWorkingHoursType(v);
		user.WorkingHoursTypeID = workingHoursTypeID;
		setContext();
	}
	function setApproverID(v: any, i: any) {
		const approverID: number = Number(v.Value);
		setSelectedApproverID(v);
		user.ApproverID = approverID;
		setContext();
	}
	function DefaultApproverChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setUser({
			...user, DefaultApprover: checked
		});
		setContext();
	}
	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				{/* <div className="form-row">
					<div className="form-group col">
						<label className="form-label">Nome</label>
						<label className="form-control" key="FirstName">{user.FirstName}</label>
					</div>
					<div className="form-group col">
						<label className="form-label">Cognome</label>
						<label className="form-control" key="FirstName">{user.LastName}</label>
					</div>
				</div> */}
				<div className="form-row">
					<div className="form-group col">
						<Form.Check
							id="chkEnabled"
							type="switch"
							label={"Abilitato"}
							onChange={setEnabled}
							checked={user.Enabled}
							className="float-left mr-2"
						/>

						<Form.Check
							id="chkAvailable"
							type="switch"
							label={"Reperibile"}
							onChange={setAvailable}
							checked={user.Available}
							className="float-left"
						/>

					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Orario di lavoro</label>
						<Select
							value={selectedWorkingHoursType}
							options={workingHoursTypesList}
							isSearchable
							onChange={setWorkingHoursTypeID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<Form.Check
							id="chkStartDateMandatory"
							type="switch"
							label="Approvatore di default"
							defaultChecked={user.DefaultApprover}
							style={{ display: "inline" }}
							onChange={DefaultApproverChange}
						/><Info placement="auto" text="L'approvatore di default è il responsabile del centro di costo di appartenenza" />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Approvatore personalizzato</label>
						<Select
							value={selectedApproverID}
							options={usersList}
							isSearchable
							onChange={setApproverID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
							isDisabled={user.DefaultApprover}
						/>
					</div>
				</div>
			</form>
		</div>
	);
}