import React, { useContext, useEffect, useState } from "react";
import { Maincontext, UserContext } from "../../maincontext";

import TimeSheetServices from "../../../api/TimeSheetServices";
import { formatDate, formatDateTime } from "../../../models/locale";

export interface IUserTimesheetsWidget {

}

export default function UserTimesheetsWidget(props: IUserTimesheetsWidget) {
	const SVC = new TimeSheetServices();
	const UserInfo = useContext(UserContext);
	const context = useContext(Maincontext);
	const [list, setList] = useState<Date[]>([]);

	useEffect(() => {

		GetLastTimesheetDates();

	}, []);

	function GetLastTimesheetDates() {

		SVC.GetLastTimesheetDates().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento ultimi timesheet: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	return (
		<div className="widget p-2 border-0">
			<table className="table table-sm">
				<thead>
					<tr>
						<th>Ultimi timesheet compilati</th>
					</tr>
				</thead>
				<tbody>
					{
						list.map(function (item, i) {
							return (
							<tr key={"tr_" + i.toString()}>
								<td>
									{formatDate(item)}
								</td>
							</tr>
							)
						})
					}
				</tbody>
			</table>
		</div>
	);
}

