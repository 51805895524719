import React, { useContext, useState, useEffect } from "react";
import { UserContext, Maincontext } from "../../maincontext";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import Icon from "react-icons-kit";
import { Table, Button } from "react-bootstrap";
import { DayAssignedWorkingHours } from "../../../models/models";
import TimeSheetServices from "../../../api/TimeSheetServices";

export interface IUserDayAssignedWorkingHours {
	UserID?: number;
}

export default function UserDayAssignedWorkingHours(props: IUserDayAssignedWorkingHours) {
	const context = useContext(Maincontext);
	const UserInfo = useContext(UserContext);
	const SVC = new TimeSheetServices();
	const [list, setList] = useState<DayAssignedWorkingHours[]>([]);
	let count: number = 0;

	useEffect(() => {
		if (props.UserID) {
			GetList();
		}
		else {
			GetListCurrentUser();
		}

	}, [props.UserID]);

	function GetListCurrentUser() {
		SVC.GetDayAssignedWorkingHours().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento assegnazioni: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function GetList() {
		SVC.GetUserDayAssignedWorkingHours(props.UserID!).then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento assegnazioni: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	return (
		<>
			{list.length > 0 ?
				<div className="widget" >
					<h6 className="title">
						<Icon icon={ic_date_range} className="mr-1" size={20} />
						<span style={{ verticalAlign: "middle" }}>Attività assegnate</span>
					</h6>
					<Table size="sm">
						<thead>
							<tr>
								<th>Cliente</th>
								<th>Commessa</th>
								<th>Deliverable</th>
								<th>Linea attività</th>
								<th>Fase</th>
								<th>Ore</th>
							</tr>
						</thead>
						<tbody>
							{list.map(function (item) {
								count += 1;
								return <tr key={"Asswh_tr_" + count}>
									<td key={"Asswh_cust_name_td" + count}>
										{item.CustomerName}
									</td>
									<td key={"Asswh_ord_name_td" + count}>
										{item.OrderName}
									</td>
									<td key={"Asswh_subord_name_td" + count}>
										{item.SubOrderName}
									</td>
									<td key={"Asswh_act_name_td" + count}>
										{item.ActivityLineName}
									</td>
									<td key={"Asswh_step_name_td" + count}>
										{item.ActivityStepName}
									</td>
									<td key={"Asswh_cust_name_td" + count}>
										{item.WorkingHours}
									</td>
								</tr>
							}
							)
							}
						</tbody>
					</Table>
				</div>
				:
				''
			}
		</>
	);
}

