
import en from '../i18n/en';
import it from '../i18n/it';
declare global {
	interface Window {
		i18nData: any;
		i18n: (key: string, params?: any) => () => string;
		changeLanguage: (lang: string) => void;
	}
}

const languages = {
	en,
	it
};
//lingua predefinita
window.i18nData = languages.en;

//cambio lingua
window.changeLanguage = (lang) => {
	console.log("Language changing: " + lang);
	if (lang == "en" || lang == "it")
		window.i18nData = languages[lang];
	else
		window.i18nData = languages.it;
}

window.i18n = (key: string, params?: any) => {
	if (params || params === 0) {
		let i18nKey = (window as any).i18nData[key];

		if (typeof params !== 'object') {
			i18nKey = i18nKey.replace('{0}', params);
		} else {
			for (let i = 0; i < params.length; i++) {
				i18nKey = i18nKey.replace(`{${i}}`, params[i]);
			}
		}

		return i18nKey;
	} else {
		return (window as any).i18nData[key];
	}
};