import axios from "axios";
import { User } from "../models/models";
import CommonServices from "./CommonServices";

const SVC = new CommonServices();
export default class AzureADServices {

	//API MS GRAPH
	async getProfilePhoto() {
		return axios.get<string>(`${await SVC.getApiBaseUri()}/api/graph/profile/photo`, { headers: await SVC.setAzureAuth() });
	}

	//restituisce l'utente fornendo il suo AzureID
	async getUserFromAAD(id: string) {
		const params = {
			id: id
		};
		return axios.get<User>(`${await SVC.getApiBaseUri()}/api/users/aad`, { params, headers: await SVC.setAzureAuth() });
	}

}