import { SubOrderStep, SubOrderStepsTreeView } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class SubOrderStepServices {

	async getSubOrderSteps(id: number) {
		const params = {
			id: id
		};
		return axios.get<SubOrderStep[]>(`${await SVC.getApiBaseUri()}/api/SubOrderSteps`, { params, headers: await SVC.setAzureAuth() });
	}

	async getSubOrderStep(id: number) {
		const params = {
			id: id
		};
		return axios.get<SubOrderStep>(`${await SVC.getApiBaseUri()}/api/SubOrderStep`, { params, headers: await SVC.setAzureAuth() });
	}

	async SubOrderStepUpdate(subOrderStep: SubOrderStep) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/SubOrderSteps`, subOrderStep, { headers: await SVC.setAzureAuth() });
	}

	async SubOrderStepDelete(subOrderStepID: number) {
		const params = {
			id: subOrderStepID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/subOrderSteps`,
			{ params, headers: await SVC.setAzureAuth() });
	}


	//elenco dati per popolare il SubOrderSteps/TreeView
	async getSubOrderStepsTreeView(suborederid: number) {
		const params = {
			id: suborederid
		};
		return axios.get<SubOrderStepsTreeView[]>(`${await SVC.getApiBaseUri()}/api/SubOrderSteps/TreeView`, { params, headers: await SVC.setAzureAuth() });
	}

}