import React, { useCallback } from "react";
import { Placement } from "react-bootstrap/Overlay";
import { OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";
import Icon from "react-icons-kit";
import {ic_info} from "react-icons-kit/md/ic_info";

export interface IInfoProps {
	size?: "16" | "24" | "32" | "48" | undefined;
	margin?: 0 | 1 | 2 | 3 | undefined;
	color?: "primary" |
			"secondary" |
			"ligth" |
			"dark" |
			"warning" |
			"danger" |
			"info" |
			"success" |
			"white" |
			undefined;
	placement: Placement;
	text: string;
}

export default function Info(props: IInfoProps) {

	const renderTooltip = useCallback((toolTipProps: TooltipProps) => {
		return (
			<Tooltip {...toolTipProps}>{props.text}</Tooltip>
		);
	}, [props.text]);

	const tooltip = (
			<Tooltip id={"tooltipID"}>{props.text}</Tooltip>
		);

	return (
		<OverlayTrigger	
		placement={props.placement}	
		overlay={tooltip}
		//overlay={renderTooltip} 
		>
			<Icon 
				icon={ic_info} 
				size={props.size || "16"} 
				className={
					`text-${props.color || "dark"} ml-${props.margin || "1"}`
				}  
			/>
		</OverlayTrigger>
	);

}