import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import ContractTypeServices from "../../api/ContractTypeServices";
import { ContractType, columnDetail, SelectedObjectTypes, MenuList, DataColumnTypes } from "../../models/models";
import ContractTypeDetail from "./ContractTypeDetail";
import TitleBar from "../common/TitleBar";


export interface IAppProps {
}

export default function ContractTypesList(props: IAppProps) {
	const context = useContext(Maincontext);
	const SVC = new ContractTypeServices();
	const [list, setList] = useState<ContractType[]>([]);

	//Dichiarazione della colonna di dettaglio che determina se una colonna viene visualizzata e in che posizione se non viene passato viene fatto il rendering dell'oggetto
	const columnDetails: columnDetail[] = [
		{ name: "ContractTypeName", text: "Tipologia di contratto", position: 1 },
		{ name: "CreationDate", text: "Data creazione", position: 2, type: DataColumnTypes.DATE },
	];

	//viene usato per aggiornare la lista
	useEffect(() => {
		GetList();
	}, [context.refreshList]);

	function GetList() {

		//context.setContext({ ...context, showAttesa: true });
		SVC.getContractTypesList().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Contract Types List: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: MenuList.CONTRACT_TYPES, });
		});
	}

	function onDelete(e: MouseEvent<HTMLButtonElement>) {
		const contractType: ContractType = context.objSelected;

		SVC.ContractTypeDelete(contractType).then((d) => {
			if (d.data !== "") {
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione tipo di Contratto";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			context.setContext({ ...context, showAttesa: false, refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			<TitleBar title="Tipologie di contratto" />

			<Lista obj={list} details={[
				{
					detail: <ContractTypeDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.CONTRACT_TYPE
				},
				{
					detail: <ContractTypeDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.CONTRACT_TYPE,
					isFunction: true,
					confirm: true,
					confirmMessage: "Eliminare la tipologia di contratto?",
					onClick: onDelete
				}
			]}
			columnDetails={columnDetails}
			addButton={true}
			addElement={<ContractTypeDetail />}
			addType={SelectedObjectTypes.CONTRACT_TYPE}
			addPanelSize={undefined}
			detailPosition={1}
			/>

		</div>
	);
}

