import { Order, OrderRemainingDays } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class OrderServices {

	//elenco completo di OrderS
	async getOrders(expired?: boolean) {
		const params = {
			scadute: expired
		};
		return axios.get<Order[]>(`${await SVC.getApiBaseUri()}/api/Orders`, { params, headers: await SVC.setAzureAuth() });
	}

	//elenco Orders attivi assegnati alla risorsa corrente
	async getUserActiveOrders(UserID?: number) {

		if (UserID) {
			const params = {
				UserID: UserID
			};

			return axios.get<Order[]>(`${await SVC.getApiBaseUri()}/api/Orders/UserActiveOrders`, { params, headers: await SVC.setAzureAuth() });
		}
		else {
			return axios.get<Order[]>(`${await SVC.getApiBaseUri()}/api/Orders/UserActiveOrders`, { headers: await SVC.setAzureAuth() });
		}
	}
	//elenco Orders attivi che scadono nei prossimi 30 giorni
	async getUserDeadlineActiveOrders(UserID?: number) {

		if (UserID) {
			const params = {
				UserID: UserID
			};

			return axios.get<Order[]>(`${await SVC.getApiBaseUri()}/api/Orders/UserDeadlineActiveOrders`, { params, headers: await SVC.setAzureAuth() });
		}
		else {
			return axios.get<Order[]>(`${await SVC.getApiBaseUri()}/api/Orders/UserDeadlineActiveOrders`, { headers: await SVC.setAzureAuth() });
		}
	}
	//restituisce un Order passando l'id
	async getOrder(id: number) {
		const params = {
			id: id
		};
		return axios.get<Order>(`${await SVC.getApiBaseUri()}/api/Orders`, { params, headers: await SVC.setAzureAuth() });
	}

	//insert/update di un Order
	async OrderUpdate(Order: Order) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/Orders`, Order, { headers: await SVC.setAzureAuth() });
	}

	//eliminaizone del Order passato per argomento
	async OrderDelete(Order: Order) {
		const params = {
			id: Order.OrderID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/Orders`,
			{ params, headers: await SVC.setAzureAuth() });
	}

	async GetOrdersRemainingDays(AlertDays: number) {

		return axios.get<OrderRemainingDays[]>(`${await SVC.getApiBaseUri()}/api/TimeSheet/OrdersRemainingDays/${AlertDays}`, { headers: await SVC.setAzureAuth() });
	}
}