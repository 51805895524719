import { OverTime } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class OverTimeServices {

	//elenco completo di OverTime
	async getOverTimes (completeList: boolean) {
		const params = {
			completeList: completeList
		};
		return axios.get<OverTime[]>(`${await SVC.getApiBaseUri()}/api/overtimes`, { params, headers: await SVC.setAzureAuth() });
	}

	//inserisce una richiesta di straordinari
	async overTimeInsert(overTime: OverTime) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/overtimes`, overTime, { headers: await SVC.setAzureAuth() });
	}

	//modifica lo stato della richiesta
	async overTimeStateChange(overTimeID: number, overTimeStateID: number, approverNote: string) {
		const params = {
			OverTimeID: overTimeID,
			OverTimeStateID: overTimeStateID,
			ApproverNote: approverNote
		};
		return axios.get(`${await SVC.getApiBaseUri()}/api/overtimes/OverTimeStateChange`, { params, headers: await SVC.setAzureAuth() });
	}
}