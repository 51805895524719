import React from "react";
import { UserProvider } from "./components/maincontext"; //provider che fornisce le variabili comuni a tutte i components contenuti istanziabile con il usecontext
import SplashScreen from "./components/SplashScreen";
import "./api/LocalizationService";
import { AppInsightsContext  } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from "./core/AppInsights/AppInsights";

export default function App() {
	return (
		<AppInsightsContext.Provider value={reactPlugin}>
		<UserProvider>
			<SplashScreen />
		</UserProvider>
		</AppInsightsContext.Provider>
	);
}