import React from "react";
export interface IOrderWorkedPercentage {
	OrderWorkedDays: number,
	OrderWorkingDays: number
}

export default function OrderWorkedPercentageTableRow(props: IOrderWorkedPercentage) {

	const percNum = (props.OrderWorkedDays / props.OrderWorkingDays) * 100;
	const percStr = !isNaN(percNum) && percNum !== Infinity ? percNum.toFixed(2).replace(".",",").replace(",00","") : "N/A";

	return (
		<tr className="font-weight-bold">
			<td colSpan={2} className="nowrap">Percentuale lavoro svolto</td>
			<td className="text-right">{percStr}%</td>
		</tr>
	);

}