import React, { useContext, useState, useEffect, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { MultiSelectItemsListTypes, SelectListItem, UserProfileType } from "../../models/models";
import { MultiSelect } from "../common/MultiSelect";
//import UserServices from "../../api/UserServices";
import SelectItemServices from "../../api/SelectItemServices";

export interface IUserProfilesList {
}

export default function UserProfilesList(props: IUserProfilesList) {
	const context = useContext(Maincontext);
	const { handleSubmit } = useForm();
	const userID = context.objSelected.UserID as number;
	//const SVC = new UserServices();
	const ISVC = new SelectItemServices();

	const [profilesList, setProfileList] = useState<SelectListItem[] | []>([]);
	useEffect(() => {
		GetList();
		context.objectName = context.objSelected.FullName;
	}, []);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		ISVC.getMlutiSelectItemList(MultiSelectItemsListTypes.USER_PROFILE_TYPES, userID).then((d) => {
			if (d.data) {
				setProfileList(d.data);
				context.parentID = userID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento: USER_PROFILE_TYPES" + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}

	const isValid = (): boolean => {
		return true;
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = profilesList;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setChecked(e: ChangeEvent<HTMLInputElement>) {

		const checked: boolean = Boolean(e.target.checked);
		const profileTypeID = e.currentTarget.getAttribute("data-id");
		profilesList.forEach(function (item: SelectListItem) {

			if (item.Value === profileTypeID) {
				item.Selected = checked;
			}
		});

		setContext();

	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<MultiSelect items={profilesList} onChange={setChecked} columns={2} />
					</div>
				</div>
			</form>
		</div>
	);
}

