/*
	componente per la gestione del numero di ore già assegnate a una risorsa
	visualizza il numero di giorni massimo impostato nella prop range
	implementa le frecce di scorrimento
	la tamella è composta da tre righe:
	la prima mostra la data, in rosso i sabati e le domeniche
	la seconda una serie di celle con il componente ReadOnlyCell per visualizzare le tore occupate
	la terza ospita i componenti EditingCell per assegnare le ore
*/

import React, { useState, useEffect, MouseEvent } from "react";
import { AssignedWorkingHours } from "../../../models/models";
import { ReadOnlyCell } from "../../common/WeekRange/ReadOnlyCell";
import { MONTHS_SHORT, WEEKDAYS_SHORT } from "../../../models/locale";
import { EditingCell } from "../../common/WeekRange/EditingCell";
import { ic_keyboard_arrow_left } from "react-icons-kit/md/ic_keyboard_arrow_left";
import { ic_keyboard_arrow_right } from "react-icons-kit/md/ic_keyboard_arrow_right";
import { ic_lock } from "react-icons-kit/md/ic_lock";
import { wrench } from "react-icons-kit/icomoon/wrench";
import Icon from "react-icons-kit";
import { addDays } from "../../../core/Utilities";
import Info from "../../common/Info";

export interface IWeekTable {
	key?: string;
	daysList: AssignedWorkingHours[]; //array di oggetti AssignedWorkingHours
	onDayHoursChange(currentDate: Date, hours: number): void; //cambia il valore di un giorno
	range: number; //numero di elementi da visualizzare
	startDate?: Date; //data iniziale (prima cella)
}

export function WeekTable(props: IWeekTable) {

	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date());

	const [startNumberDate, setStartNumberDate] = useState<number>(0);
	const [endNumberDate, setEndNumberDate] = useState<number>(0);

	const [minDate, setMinDate] = useState<Date>(new Date());
	const [maxDate, setMaxDate] = useState<Date>(new Date());
	const [totalHours, setTotalHours] = useState<number>(0);
	const [workingHoursList, setWorkingHoursList] = useState<AssignedWorkingHours[]>(props.daysList);

	useEffect(() => {
		if (props.daysList) {

			setWorkingHoursList(props.daysList);

			if (props.daysList[0]) {
				const minD: Date = new Date(props.daysList[0].WorkingHoursDate);
				const maxD: Date = new Date(props.daysList[props.daysList.length - 1].WorkingHoursDate);
				setMinDate(minD);
				setMaxDate(maxD);
			}

			const totalH: number = getTotalHours();
			setTotalHours(totalH);
		}

	}, [props.daysList]);

	useEffect(() => {

		const sDate: Date = new Date(props.startDate ? props.startDate : new Date());
		const eDate: Date = addDays(new Date(sDate), props.range - 1);

		setStartDate(sDate);
		setEndDate(eDate);
		setStartNumberDate(new Date(sDate).setHours(0, 0, 0));
		setEndNumberDate(new Date(eDate).setHours(0, 0, 0));

	}, [props.startDate]);

	const getTotalHours = (): number => {
		let totalH: number = 0;
		props.daysList.forEach(function (e: AssignedWorkingHours) {
			totalH += e.AssignedWorkingHours;
		});
		return totalH;
	};

	//nome breve del mese
	const monthName = (data: Date): string => {

		const month_name = MONTHS_SHORT["it"][data.getMonth()];
		return month_name;
	};

	//nome breve giorno settimana
	const dayName = (data: Date): string => {

		const day_name = WEEKDAYS_SHORT["it"][data.getDay()];
		return day_name;
	};

	//evento di spostamento indietro
	function onPrevWeek(e: MouseEvent<HTMLButtonElement>) {

		if (startDate > minDate) {
			const sDate = addDays(startDate, -props.range);
			setStartDate(sDate);
			const eDate = addDays(sDate, props.range - 1);
			setEndDate(eDate);

			setStartNumberDate(new Date(sDate).setHours(0, 0, 0));
			setEndNumberDate(new Date(eDate).setHours(0, 0, 0));
		}
	}
	//evento di spostamento in avanti
	function onNextWeek(e: MouseEvent<HTMLButtonElement>) {


		if (endDate < maxDate) {

			const eDate = addDays(endDate, props.range);
			setEndDate(eDate);
			const sDate = addDays(eDate, -(props.range - 1));
			setStartDate(sDate);

			setStartNumberDate(new Date(sDate).setHours(0, 0, 0));
			setEndNumberDate(new Date(eDate).setHours(0, 0, 0));
		}
	}

	//stile delle celle con la data
	const labelStyle = {
		fontSize: "10pt",
		lineHeight: 0.9,
		margin: 0,
		paddingTop: 0,
		paddingBottom: 8,
	};

	const dateClassName = (day: AssignedWorkingHours): string => {

		if (day.IsHoliday) {
			return "text-danger border border-danger";
		}
		else if (day.IsSaturday || day.IsSunday) {
			return "text-danger";
		}
		else {
			return "text-dark";
		}
	};


	return (
		<div /* className={props.daysList.length==0? "d-none": ""} */>
			<table style={{ margin: "auto" }}>
				<tr>
					<td className="text-center" title={startDate.toLocaleDateString()}>
						<button className={startDate > minDate ? "btn btn-link btn-sm p-0" : "btn btn-link btn-sm disabled p-0"} onClick={onPrevWeek} >
							<Icon icon={ic_keyboard_arrow_left} size={32} />
						</button>
					</td>
					{workingHoursList.map(
						function (item, i) {

							const nDate: number = new Date(item.WorkingHoursDate).setHours(0, 0, 0);
							const newDate: Date = new Date(item.WorkingHoursDate);
							if (nDate >= startNumberDate && nDate <= endNumberDate) {
								return (
									<td className={"text-center text-lowercase"} >
										<p className={dateClassName(item)}
											style={labelStyle}>{dayName(newDate)}<br />{newDate.getDate()}<br />{monthName(newDate)}</p>
									</td>
								);
							}
							return null;
						}
					)}
					<td title={endDate.toLocaleDateString()}>
						<button
							className={endDate < maxDate ? "btn btn-link btn-sm p-0" : "btn btn-link btn-sm disabled p-0"}
							onClick={onNextWeek}>
							<Icon icon={ic_keyboard_arrow_right} size={32} />
						</button>
					</td>
				</tr>
				<tr>
					<td className={"text-center"}>
						<Info text="Ore di lavoro della risorsa" placement="auto" />
					</td>
					{workingHoursList.map(
						function (item, i) {
							const nDate: number = new Date(item.WorkingHoursDate).setHours(0, 0, 0);
							const newDate: Date = new Date(item.WorkingHoursDate);
							if (nDate >= startNumberDate && nDate <= endNumberDate) {
								return (
									<td className={"text-center"}>
										<ReadOnlyCell key={"uwhcell" + i}
											maxValue={0}
											value={item.UserWorkingHours}
											fixedBgColor={true}
											size={"xl"} />
									</td>
								);
							}
							return null;
						}
					)}
					<td></td>
				</tr>
				<tr>
					<td className={"text-center"}>
						<Info text="Ore assegnate per altre attività" placement="auto" />
					</td>
					{workingHoursList.map(
						function (item, i) {
							const nDate: number = new Date(item.WorkingHoursDate).setHours(0, 0, 0);
							const newDate: Date = new Date(item.WorkingHoursDate);
							if (nDate >= startNumberDate && nDate <= endNumberDate) {
								return (
									<td className={"text-center"}>
										<ReadOnlyCell key={"readolny" + i}
											maxValue={item.UserWorkingHours}
											value={item.WorkingHours} size={"xl"} />
									</td>
								);
							}
							return null;
						}
					)}
					<td></td>
				</tr>
				<tr>
					<td className={"text-center"}>
						<Info text="Ore che si stanno assegnando" placement="auto" />
					</td>
					{workingHoursList.map(
						function (item, i) {
							const nDate: number = new Date(item.WorkingHoursDate).setHours(0, 0, 0);
							const newDate: Date = new Date(item.WorkingHoursDate);
							if (nDate >= startNumberDate && nDate <= endNumberDate) {
								return (
									<td className={"text-center"}>
										<EditingCell
											currentDate={newDate}
											onChange={props.onDayHoursChange}
											max={24 - item.WorkingHours}
											key={"edcell" + i}
											value={item.AssignedWorkingHours}
											size={"xl"}
											enabled={item.WorkingHours < 24}
										/>
									</td>
								);
							}
							return null;
						})}
					<td></td>
				</tr>
			</table>
		</div>
	);
}