import React, { useContext, useEffect, useState } from 'react';
import ReportServices from '../../../api/ReportServices';
import OrderServices from '../../../api/OrderServices';
import { Maincontext } from '../../maincontext';

import { useParams } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import { SubOrder } from "../../../models/models";
import { DailyWorkingWorkedHoursItem, TimeSpanTypes } from "../../../models/reports";
import { formatDateToString } from '../../../core/Utilities';
import { Col, Form } from 'react-bootstrap';
import TimeSpanSelect from '../../common/TimeSpanSelect';

export default function OrderDailyWorkedHours() {
	const SVC = new ReportServices();
	const SSVC = new OrderServices();
	const context = useContext(Maincontext);
	let params: any = useParams();
	const [reportData, setReportData] = useState<DailyWorkingWorkedHoursItem[]>([]);
	const [order, setOrder] = useState<SubOrder>();
	const [type, setType] = useState<TimeSpanTypes>(TimeSpanTypes.LAST_30_DAYS);

	const defState = {
		labels: [],
		datasets: [
			{
				label: "Ore previste",
				backgroundColor: '#ffffff',
				borderColor: '#0078d4',
				borderWidth: 2,
				data: []
			},
			{
				label: "Ore lavorate",
				backgroundColor: '#ffffff',
				borderColor: '#bb8888',
				borderWidth: 2,
				data: []
			}
		]
	}

	const defOptions = {
		maintainAspectRatio: false,
		title: {
			display: true,
			text: '',
			fontSize: 20
		},
		legend: {
			display: true,
			position: 'right'
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}

	const [chartState, setChartState] = useState<any>(defState);
	const [chartOptions, setChartOptions] = useState<any>(defOptions);

	useEffect(() => {

		(document.querySelector(".menu-laterale") as HTMLElement).style.display = "none";
		(document.querySelector(".navbar-nav") as HTMLElement).style.display = "none";
		(document.querySelector(".principale") as HTMLElement).style.marginLeft = "0";
		GetOrder();
		GetList();
	}, []);

	useEffect(() => {
		GetList();
	}, [type]);


	useEffect(() => {

		const labels = reportData.map(dati => formatDateToString(dati.WorkingDate));
		chartState.datasets[0].data = reportData.map(dati => dati.WorkingHours);
		chartState.datasets[1].data = reportData.map(dati => dati.WorkedHours);
		
		setChartState({
			...chartState, labels: labels
		});

	}, [reportData]);

	function GetOrder() {
		var orderID: number = params.id;
		SSVC.getOrder(orderID).then((d) => {
			if (d.data) {
				chartOptions.title.text = d.data.OrderName + ": grafico incrementale delle lavorazioni";
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento dati: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}
	function GetList() {
		var orderID: number = params.id;

		SVC.GetRptOrderDailyWorkedHours(orderID, type).then((d) => {
			if (d.data) {
				setReportData(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento dati: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	return (
		<>
			<Form className="form-search-list">
				<Form.Row>
					<Col md={3}>
						<TimeSpanSelect onChangeValue={(type:TimeSpanTypes) => setType(type)} />
					</Col>
				</Form.Row>
			</Form>
			<div className="p-2 border-0">
				<Line
					data={chartState}
					options={chartOptions}
					height={600}
				/>
			</div>
		</>
	)

}