import React, { useContext, useEffect, useState, ChangeEvent, MouseEvent } from "react";
import { Maincontext, UserContext } from "../maincontext";
import UserServices from "../../api/UserServices";
import { Alert, Button, Form, Modal, Popover } from "react-bootstrap";
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import { User } from "../../models/models";
import SaveButton from "../common/SaveButton";

export interface IUserDetail {
}
export default function UserDetail(props: IUserDetail) {

	const context = useContext(Maincontext);
	const userContext = useContext(UserContext);
	const SVC = new UserServices();

	const [showPopover, setShowPopover] = useState(false);
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

	const [confirmButton, setConfirmButton] = useState<boolean>(userContext.Me!.ConfirmButton);
	const [timesheetDuplicated, setTimesheetDuplicated] = useState<boolean>(userContext.Me!.TimesheetDuplicated);
	const [recentActivitiesList, setRecentActivitiesList] = useState<boolean>(userContext.Me!.RecentActivitiesList);

	useEffect(() => {
		//GetUsersList();
	}, []);


	function setEnabled(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
	}

	const closeDialog = () => {

		setSaveEnabled(false);
		setAlertMessage("");
		setShowPopover(false);

		//reset controlli

		context.setContext({ ...context, showUserPreferencestepDetail: false });

	};

	function Save(e: MouseEvent<HTMLButtonElement>) {
		setShowPopover(false);

		let user: User = userContext.Me!;
		user.ConfirmButton = confirmButton;
		user.RecentActivitiesList = recentActivitiesList;
		user.TimesheetDuplicated = timesheetDuplicated;


		//salva i dati
		SVC.UserPreferencesUpdate(user).then((d) => {
			if (d !== undefined && d.data !== "") {
				setAlertMessage(d.data);
			}
			else {
				userContext.setContext({
					...userContext,
					Me: user
				}
				);
				closeDialog();
			}
		}).catch((e: React.SetStateAction<string>) => {
			setAlertMessage("Errore del server");
		}).finally(() => {
		});

	};

	const closePopover = () => {
		setShowPopover(false);
	};

	function ConfirmButtonChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setConfirmButton(checked);
		setSaveEnabled(true);
	}

	function RecentActivitiesListChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setRecentActivitiesList(checked);
		setSaveEnabled(true);
	}

	function TimesheetDuplicatedChange(e: ChangeEvent<HTMLInputElement>) {
		const checked = Boolean(e.target.checked);
		setTimesheetDuplicated(checked);
		setSaveEnabled(true);
	}

	const popover = (
		<Popover id="popover-basic" className={showPopover ? "" : "d-none"}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				Salvare i dati?<br /><br />
				<Button variant="outline-secondary" className="btn-sm" onClick={closePopover}><Icon icon={ic_close} /></Button>
          		&nbsp;
				<Button variant="outline-primary" className="btn-sm" onClick={Save}><Icon icon={ic_check} /></Button>
			</Popover.Content>
		</Popover>
	);

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={"lg"}
				show={context.showUserPreferencestepDetail}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title className="fluid">
						Preferenze
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Check
						key={"chkConfirmButton"}
						id={"chkConfirmButton"}
						type="switch"
						label={"Richiesta conferma"}
						defaultChecked={confirmButton}
						onChange={ConfirmButtonChange}
					/>
					<Alert variant={confirmButton ? "primary" : "light"}>
						Attiva la richiesta di conferma. In alcuni casi,
						come ad esempio l'eliminazione di dati,
						la richiesta di conferma non può essere disattivata.
					</Alert>
					<Form.Check
						key={"chkRecentActivitiesList"}
						id={"chkRecentActivitiesList"}
						type="switch"
						label={"Lista attività recenti"}
						defaultChecked={recentActivitiesList}
						onChange={RecentActivitiesListChange}
					/>
					<Alert variant={recentActivitiesList ? "primary" : "light"}>
						Attiva la funzionalità di visualizzazione della lista
						attività recenti nell'elenco attività non pianificate
						durante la compilaizone del timesheet.
  					</Alert>
					<Form.Check
						key={"chkTimesheetDuplicated"}
						id={"chkTimesheetDuplicated"}
						type="switch"
						label={"Timesheet precompilato"}
						defaultChecked={timesheetDuplicated}
						onChange={TimesheetDuplicatedChange}
					/>
					<Alert variant={timesheetDuplicated ? "primary" : "light"}>
						Attiva la funzionalità di duplicazione quando si compilazione
						un nuovo giorno del timesheet. In queso modo verrànno proposte
						le stesse voci del giorno più recente ad esclusione delle attività
						pianificate.
  					</Alert>
				</Modal.Body>
				<Modal.Footer>
					<Alert variant={alertMessage === "" ? undefined : "warning"}>
						{alertMessage}
					</Alert>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => closeDialog()}>
						Annulla
				</Button>
					<SaveButton
						disabled={!saveEnabled}
						popover={popover}
						popoverFunction={setShowPopover}
						saveFunction={Save}
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
}