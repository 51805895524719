import React, { useContext, useEffect, useState, ChangeEvent} from "react";
import { useForm } from "react-hook-form";
import { Maincontext, UserContext } from "../maincontext";
import Select from "react-select";
import { Order, SelectItemsListTypes, SelectListItem, ContentTypes } from "../../models/models";
import OrderServices from "../../api/OrderServices";
import SelectItemServices from "../../api/SelectItemServices";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { getIndexSelectedById, selectFilter, IsAuthorized } from "../../core/Utilities";
import "react-day-picker/lib/style.css";
import { formatDate, MONTHS, FIRST_DAY_OF_WEEK, LABELS, WEEKDAYS_LONG, WEEKDAYS_SHORT, TODAY_LABEL } from "../../models/locale";

export default function OrderDetail() {
	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();
	const language = "it";
	const dateFormat = "DD/MM/YYYY";
	const SVC = new OrderServices();
	const ISVC = new SelectItemServices();
	const orders_authorized: boolean = IsAuthorized(ContentTypes.ORDERS);

	let id = 0;
	if (context.objSelected) {
		id = context.objSelected.OrderID as number;
	}

	const defOrder: Order = {
		OrderID: 0,
		CustomerID: 0,
		Internal: false,
		UserID: 0,
		OrderName: "",
		OrderCode: "",
		SalesForceID: "",
		OrderWorkingDays: 0,
		OrderDescription: "",
		OrderNote: "",
		WorkingDays: 0,
		CustomerName: "",
		Manager: "",
		StartDate: new Date(),
		EndDate: new Date(),
		SubOrdersCount: 0,
		SubOrdersAssignedWorkingDays: 0,
		CreationDate: new Date(),
		AvailableDays: 0
	};

	const [Order, setOrder] = useState<Order>(defOrder);
	const [customerList, setCustomerList] = useState<SelectListItem[]>([]);
	const [userList, setUserList] = useState<SelectListItem[]>([]);
	const [selectedCustomer, setSelectedCustomer] = useState<SelectListItem | undefined>(undefined);
	const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(undefined);

	useEffect(() => {
		GetCustomerList();
	}, []);

	useEffect(() => {
		if (id !== 0) {
			setSelectedUser(userList[getIndexSelectedById(userList, context.objSelected!.UserID)])
		}
	}, [userList]);

	useEffect(() => {
		if (id !== 0) {
			setSelectedCustomer(customerList[getIndexSelectedById(customerList, context.objSelected!.CustomerID)])
		}
	}, [customerList]);


	function GetItem() {
		SVC.getOrder(id).then((d) => {
			if (d.data) {
				setOrder(d.data);
				context.objectName = context.objSelected.OrderName;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Order: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}

	function GetCustomerList() {
		ISVC.getSelectItemList(SelectItemsListTypes.CUSTOMERS, Order.CustomerID).then((l) => {
			if (l.data) {
				if (id === 0) {
					setSelectedCustomer(l.data[0]);
				}
				setCustomerList(l.data);
				const customerID: number = Number(l.data[0].Value);
				Order.CustomerID = customerID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Customers: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			GetUserList();
		});
	}

	function GetUserList() {
		ISVC.getSelectItemList(SelectItemsListTypes.USERS, Order.UserID).then((l) => {
			if (l.data) {
				setSelectedUser(l.data[0]);
				if (id === 0) {
					setSelectedUser(l.data[0]);
				}
				setUserList(l.data);
				const userID: number = Number(l.data[0].Value);
				Order.UserID = userID;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Users: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			if (id) {
				GetItem();
			}
			else {
				context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
			}
		});
	}


	//validazione del form
	const isValid = (): boolean => {
		return Order.OrderName !== "" && Order.OrderCode !== "";
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = Order;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setName(e: ChangeEvent<HTMLInputElement>) {
		const name: string = e.target.value;
		Order.OrderName = name;
		setContext();
	}

	function setCode(e: ChangeEvent<HTMLInputElement>) {
		const code: string = e.target.value;
		Order.OrderCode = code;
		setContext();
	}

	function setSalesForceID(e: ChangeEvent<HTMLInputElement>) {
		const salesForceID: string = e.target.value;
		Order.SalesForceID = salesForceID;
		setContext();
	}

	function setDescription(e: ChangeEvent<HTMLTextAreaElement>) {
		const description: string = e.target.value;
		Order.OrderDescription = description;
		setContext();
	}

	function setNote(e: ChangeEvent<HTMLTextAreaElement>) {
		const note: string = e.target.value;
		Order.OrderNote = note;
		setContext();
	}

	function setCustomerID(v: any, i: any) {
		const customerID: number = Number(v.Value);
		setSelectedCustomer(v);
		Order.CustomerID = customerID;
		setContext();
	}

	function setUserID(v: any, i: any) {
		const userID: number = Number(v.Value);
		setSelectedUser(v);
		Order.UserID = userID;
		setContext();
	}

	function setWorkingDays(e: ChangeEvent<HTMLInputElement>) {
		const days: string = e.target.value;
		Order.WorkingDays = Number(days);
		setContext();
	}
	function setAvailableDays(e: ChangeEvent<HTMLInputElement>) {
		const days: string = e.target.value;
		Order.AvailableDays = Number(days);
		setContext();
	}
	function setOrderWorkingDays(e: ChangeEvent<HTMLInputElement>) {
		const days: string = e.target.value;
		Order.OrderWorkingDays = Number(days);
		setContext();
	}

	function setStartDate(day: Date) {
		if (day !== undefined) {
			Order.StartDate = day;
			setContext();
		}
	}

	/* 	function setStartDateString(e: FocusEvent<HTMLDivElement>) {
		const day: string = e.target.innerText;
	} */

	function setEndDate(day: Date) {
		if (day !== undefined) {
			Order.EndDate = day;
			setContext();
		}
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Cliente</label>

						<Select
							filterOption={selectFilter}
							value={selectedCustomer}
							options={customerList}
							isSearchable
							onChange={setCustomerID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
							isDisabled={!orders_authorized}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-6">
						<label className="form-label">Codice commessa</label>
						<input className="form-control" name="OrderCode"
							onChange={setCode}
							readOnly={!orders_authorized}
							defaultValue={Order.OrderCode} ref={register({ required: true, maxLength: 20 })} />
					</div>
					<div className="form-group col-6">
						<label className="form-label">Protocollo Sales Force</label>
						<input className="form-control" name="SalesForceID"
							onChange={setSalesForceID}
							//onKeyPress={deleteSalesForceID}
							readOnly={!orders_authorized}
							defaultValue={Order.SalesForceID} ref={register({ required: true, maxLength: 20 })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Nome commessa</label>
						<input className="form-control" name="OrderName"
							onChange={setName}
							readOnly={!orders_authorized}
							defaultValue={Order.OrderName} ref={register({ required: true, maxLength: 100 })} />
					</div>
					<div className="form-group col">
						<label className="form-label">Responsabile</label>
						<Select
							filterOption={selectFilter}
							value={selectedUser}
							options={userList}
							isSearchable
							onChange={setUserID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
							isDisabled={!orders_authorized}
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Data inizio</label>
						<DayPickerInput
							dayPickerProps={{
								//className: "calendar-up",
								month: new Date(Order.StartDate),
								todayButton: TODAY_LABEL[language],
								locale: language,
								months: MONTHS[language],
								weekdaysLong: WEEKDAYS_LONG[language],
								weekdaysShort: WEEKDAYS_SHORT[language],
								firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
								labels: LABELS[language]
							}}
							placeholder={"Non impostata"}
							format={dateFormat}
							value={new Date(Order.StartDate).toLocaleDateString()}
							onDayChange={day => setStartDate(day)}
							inputProps={{
								readonly: "readonly"
							}}
							formatDate={day => formatDate(day)}
						/>
					</div>
					<div className="form-group col">
						<label className="form-label">Data fine</label>
						<DayPickerInput
							dayPickerProps={{
								//className: "calendar-up",
								month: new Date(Order.EndDate),
								todayButton: TODAY_LABEL[language],
								locale: language,
								months: MONTHS[language],
								weekdaysLong: WEEKDAYS_LONG[language],
								weekdaysShort: WEEKDAYS_SHORT[language],
								firstDayOfWeek: FIRST_DAY_OF_WEEK[language],
								labels: LABELS[language]
							}}
							placeholder={"Non impostata"}
							format={dateFormat}
							value={new Date(Order.EndDate).toLocaleDateString()}
							onDayChange={day => setEndDate(day)}
							inputProps={{
								readonly: "readonly"
							}}
							formatDate={day => formatDate(day)}
						/>
					</div>
					<div className="form-group col">
						<label className="form-label">Giornate di lavoro</label>
						<input type="number" min="0" step="1" className="form-control" name="OrderWorkingDays"
							onChange={setOrderWorkingDays}
							value={Order.OrderWorkingDays}
							ref={register({ required: true })} />
					</div>
					<div className="form-group col">
						<label className="form-label">Giornate pianificate</label>
						<input type="number" min="0" step="1" className="form-control" name="WorkingDays"
							onChange={setWorkingDays}
							value={Order.WorkingDays}
							ref={register({ required: true })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-9">
						<label className="form-label">Descrizione</label>
						<textarea className="form-control" name="OrderDescription"
							onChange={setDescription}
							defaultValue={Order.OrderDescription} />
					</div>
					<div className="form-group col-3">
						<label className="form-label">Giornate reperibilità</label>
						<input type="number" min="0" step="1" className="form-control" name="WorkingDays"
							onChange={setAvailableDays}
							value={Order.AvailableDays}
							ref={register({ required: true })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Note</label>
						<textarea className="form-control" name="OrderNote"
							onChange={setNote}
							defaultValue={Order.OrderNote} />
					</div>
				</div>

			</form>
		</div>
	);
}
