import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import PdfDocument from './pdf/PdfDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";
import { ReportColumn } from '../../models/reports';
import TitleBar from './TitleBar';
import Icon from 'react-icons-kit';
import { filePdf } from 'react-icons-kit/icomoon/filePdf';
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel';
import { fileText2 } from 'react-icons-kit/icomoon/fileText2';
import { refresh } from "react-icons-kit/fa/refresh";
import { ArrayFiltred, ListToArray } from '../../core/Utilities';
import { Button, Col, Form } from 'react-bootstrap';

export interface IListReport {
	title: string,
	columns: ReportColumn[],
	//rows: any[][],
	list: any[]
}

export default function ListReport(props: IListReport) {

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	const icon_size: number = 22;

	const [totalWidth, setTotalWidth] = useState<number>(0);
	const [rows, setRows] = useState<any[][]>([]);
	const [filterValue, setFilterValue] = useState<string>("");
	const [filteredList, setFiltredList] = useState<any[]>([]);

	//calcola il totale della larghezza 
	//da suddividere proporzionalmente per colonna
	useEffect(() => {
		let total: number = 0;
		props.columns.forEach(element => {
			total += element.columnWidth;
		});
		setTotalWidth(total);
	}, [props.columns]);

	//crea la lista di righe come array di array
	useEffect(() => {
		const r: any[][] = ListToArray(props.list);
		setRows(r);
		setFiltredList(props.list);
	}, [props.list]);

	const getColumnWidth = (index: number): string => {
		const w = props.columns[index].columnWidth;
		if (totalWidth > 0) {
			const retVal = 100 / totalWidth * w;
			return retVal.toString() + "%";
		}
		else { return "auto"; }
	}

	function onReset(e: MouseEvent<HTMLButtonElement>) {
		setFilterValue("");
		Reset();
	}

	function onFilterChange(e: ChangeEvent<HTMLInputElement>) {

		const value: string = e.target.value;
		setFilterValue(value);
		filterData(value);
	}

	function filterData(value: string) {

		if (value.length > 0) {
			let filtred: any[] = ArrayFiltred(props.list, value);
			const r: any[][] = ListToArray(filtred);
			setRows(r);
			setFiltredList(filtred);
		}
		else if (value.length == 0) {
			Reset();
		}
	}

	function Reset() {
		const r: any[][] = ListToArray(props.list);
		setRows(r);
		setFiltredList(props.list);
	}

	return (
		<div>
			<TitleBar title={props.title} />

			<Form className="form-search-list">
				<Form.Row>
					<Col>
						<input type="text"
							onChange={onFilterChange}
							className="form-control"
							value={filterValue}
							placeholder="Cerca..."
						/>
					</Col>
					<Col md={9} className="pt-1">
						<Button
							variant={"link"}
							style={{ width: "auto" }}
							className="btn-sm mb-8 h-100 btn-context mr-3"
							onClick={onReset}
						><Icon icon={refresh} size="16" className="mr-1" />Reset</Button>
						{rows.length > 0 ?
							<>
								<div title="Esporta in excel" className="d-inline">
									<ExcelFile filename={props.title} element={
										<div className="d-inline mr-1" style={{ "cursor": "pointer" }}>
											<Icon icon={fileExcel} className="text-success" size={icon_size} />
										</div>}
									>
										<ExcelSheet
											data={filteredList}
											name={props.title}>
											{props.columns.map(function (item: ReportColumn, i) {
												return <ExcelColumn
													key={"xlsc_" + i.toString()}
													label={item.columnName}
													value={item.columnValue}
												>
												</ExcelColumn>
											}
											)}
										</ExcelSheet>
									</ExcelFile>
								</div>
								<div title="Esporta in csv" className="d-inline mr-1">
									<CSVLink
										data={rows}
										filename={props.title + ".csv"}>
										<Icon icon={fileText2} className="text-warning" size={icon_size} />
									</CSVLink>
								</div>
								<div title="Esporta in pdf" className="d-inline">
									<PDFDownloadLink document={<PdfDocument
										title="Risorse per centro di costo"
										columns={props.columns}
										rows={rows} />} fileName={props.title + ".pdf"}>
										{({ blob, url, loading, error }) => (loading ?
											<Icon icon={filePdf} className="text-danger" size={icon_size} />
											:
											<Icon icon={filePdf} className="text-danger" size={icon_size} />)}
									</PDFDownloadLink>
								</div>
							</>
							:
							''
						}
					</Col>
				</Form.Row>
			</Form>
			<div className="p-3">
				<div className="form-row">
					<div className="col-md-12 m-0">
						<table className="table">
							<thead>
								<tr>
									{props.columns.map(function (item: ReportColumn, i) {
										return <th
											key={"th_" + i.toString()}
											style={{ width: getColumnWidth(i) }}>
											{item.columnName}
										</th>
									}
									)}
								</tr>
							</thead>
							<tbody>
								{rows.map(function (item, x) {
									return <tr key={"tr_" + x.toString()}>
										{item.map(function (celtext, i) {
											return <td key={"td_" + x.toString() + "_" + i.toString()} style={{ width: getColumnWidth(i) }}>{celtext}</td>
										}
										)}
									</tr>
								}
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}