import React, { useState, useEffect, useContext } from "react";
import { Maincontext } from "../../maincontext";
import { Modal, Button} from "react-bootstrap";
import ReportServices from "../../../api/ReportServices";
import { UserWSubOrderSummaryItem } from "../../../models/reports";
import { minutesToDays, thousandSeparator } from "../../../core/Utilities";

export interface ISubOrdersSummaryPanel {
	orderID: number;
	orderName: string;
	show: boolean;
	closeDialog(): void;
}

export default function SubOrdersSummaryPanel(props: ISubOrdersSummaryPanel) {
	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const [list, setList] = useState<UserWSubOrderSummaryItem[]>([]);
	const [filteredList, setFilteredList] = useState<UserWSubOrderSummaryItem[]>([]);
	const [showPanel, setShowPanel] = useState<boolean>(false);

	useEffect(() => {	
		if(props.orderID != 0)	{			
		GetList();
		}
	}, [props.orderID]);


	function GetList() {

		SVC.GetRptSubOrderSummary(props.orderID).then((d) => {
			if (d.data) {
				setList(d.data);
				setFilteredList(d.data);
				setShowPanel(true);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento commesse: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function DataFilter(value: string) {

		if (value.length == 0) {
			setFilteredList(list);
		}
		else if (value.length > 2) {
			setFilteredList(list.filter(function (item) {
				return item.SubOrderName.toLowerCase().indexOf(value.toLowerCase()) > -1;
			}))
		}
	}

	function onFindChange(event: React.ChangeEvent<HTMLInputElement>): void {
		DataFilter(event.currentTarget.value);
	}

	function closeDialog(){
		setShowPanel(false);
	}

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={"xl"}
				show={showPanel}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title className="fluid">
						<h4>{props.orderName}</h4>
						<h6>Deliverables attivi per i propri profili</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<div className="widget border-0" >
			{/* <h6 className={filteredList.length > 0 ? "title" : "title"}>
				
				<input
					className="find ml-2"
					type="text"
					placeholder="Cerca..."
					onChange={onFindChange}
				/>
			</h6> */}
			{filteredList.length > 0 ?
				<table className="table table-sm table-list">
					<thead>
						<tr>
							<th>Deliverable</th>
							<th className="date-width">Data inizio</th>
							<th className="date-width">Data fine</th>
							<th className="date-width text-right">Giornate</th>
							<th className="date-width text-right">Lavorate</th>
							<th className="date-width text-right">Tot lavorate</th>
							<th className="date-width text-right">Residuo</th>
						</tr>
					</thead>
					<tbody>
						{filteredList.map(function (item) {
							return <tr key={"Ord_tr_" + item.SubOrderID}>
								<td>
									{item.SubOrderName}
								</td>
								<td>
									{new Date(item.StartDate).toLocaleDateString()}
								</td>
								<td>
									{new Date(item.EndDate).toLocaleDateString()}
								</td>
								<td className="text-right">
									{thousandSeparator(item.WorkingDays)}
								</td>
								<td className="text-right">
									{minutesToDays(item.UserWorkedMinutes)}
								</td>
								<td className="text-right">
									{minutesToDays(item.TotalWorkedMinutes)}
								</td>
								<td className="text-right">
									{minutesToDays((item.WorkingDays * 8 * 60) - item.TotalWorkedMinutes)}
								</td>
							</tr>;
						}
						)
						}
					</tbody>
				</table >
				: <span>Nessun deliverable attivo</span>
			}
		</div >
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => closeDialog()}>
						Chiudi
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}