import React, { useContext, useEffect, useState } from "react";
import { Maincontext, UserContext } from "../../maincontext";
import { ic_drag_handle } from 'react-icons-kit/md/ic_drag_handle'
import Icon from "react-icons-kit";
import { Table } from "react-bootstrap";
import { UserActivityLine, UserAuthorizationType, UserAuthorizationTypes } from "../../../models/models";
import UserServices from "../../../api/UserServices";

export interface IUserAuthorizationsWidget {
	list: UserAuthorizationType[]
}

export default function UserAuthorizationsWidget(props: IUserAuthorizationsWidget) {
	const SVC = new UserServices();
	const UserInfo = useContext(UserContext);
	const context = useContext(Maincontext);

	return (
		<div className="widget p-2 border-0">
			<h6 className={"title"}>
				{/* <Icon icon={ic_drag_handle} className="mr-1" size={20} /> */}
				<span style={{ verticalAlign: "middle" }}>Permessi</span>
			</h6>
			{
				props.list.map(function (item: UserAuthorizationType) {
					return <div key={"div_" + item.AuthorizationTypeID}>
						{item.AuthorizationTypeName}
					</div>
				}
				)
			}
		</div>
	);
}

