import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import { ContractType } from "../../models/models";
import ContractTypeServices from "../../api/ContractTypeServices";

export interface IContractTypeDetail {
}

export default function ContractTypeDetail(props: IContractTypeDetail) {
	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();
	const SVC = new ContractTypeServices();
	let id = 0;
	if (context.objSelected) {
		id = context.objSelected.ContractTypeID as number;
	}
	const defContractType: ContractType = {
		"ContractTypeID": 0,
		"ContractTypeName": "",
		"CreationDate": new Date(),
	};

	const [contractType, setContractType] = useState<ContractType>(defContractType);

	useEffect(() => {
		if (id) {
			GetItem();
		}
		else {
			context.setContext({ ...context, /*showAttesa: false*/ hiddenDetail: false });
		}
	}, []);

	function GetItem() {
		SVC.getContractType(id).then((d) => {
			if (d.data) {
				setContractType(d.data);
				context.objectName = context.objSelected.ContractTypeName;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Contract Type: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}
	//validazione del form
	const isValid = (): boolean => {
		return contractType.ContractTypeName !== "";
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = contractType;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setName(e: ChangeEvent<HTMLInputElement>) {
		const name: string = e.target.value;
		contractType.ContractTypeName = name;
		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Tipologia di contratto</label>
						<input className="form-control" name="ContractTypeName"
							defaultValue={contractType.ContractTypeName}
							onChange={setName}
							ref={register({ required: true, maxLength: 50 })} />
					</div>
				</div>
			</form>
		</div>
	);
}
