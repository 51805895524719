import React, { useContext, MouseEvent, useState, useEffect, ChangeEvent, FocusEvent } from "react";
import { SubOrderStep, SelectListItem } from "../../../models/models";
import { Maincontext } from "../../maincontext";
import SubOrderStepServices from "../../../api/SubOrderStepServices";
import SelectItemServices from "../../../api/SelectItemServices";
import { selectFilter, getItemByValue } from "../../../core/Utilities";
import Select from "react-select";
import { Modal, Button, Popover, Alert } from "react-bootstrap";//Icone
import { Icon } from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import Info from "../../common/Info";
import SaveButton from "../../common/SaveButton";

export interface ISubOrderStepDetailProps {
	subOrderID: number;
	subOrderWorkingHours: number;
	subOrderStepsWorkingHours: number;
	subOrderStepID: number;
	activityLineID: number;
	subOrderName: string;
	activityLineName: string;
}

export default function SubOrderStepDetail(props: ISubOrderStepDetailProps) {

	const context = useContext(Maincontext);
	const SVC = new SubOrderStepServices();
	const ISVC = new SelectItemServices();

	const subOrderStepDef: SubOrderStep = {
		SubOrderStepID: 0,
		SubOrderID: props.subOrderID,
		ActivityStepID: 0,
		ActivityLineID: props.activityLineID,
		WorkingHours: 0,
		CreationDate: new Date(),
		ActivityStepName: ""
	};

	const [subOrderStep, setSubOrderStep] = useState<SubOrderStep>(subOrderStepDef);
	const [activityStepList, setActivityStepList] = useState<SelectListItem[]>([]);
	const [selectedActivityStep, setSelectedActivityStep] = useState<SelectListItem | undefined>(undefined);
	const [showPopover, setShowPopover] = useState(false);
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
	const [hiddenDetail, setHiddenDetail] = useState<boolean>(true);
	const [suborderStepWorkingHours, setSuborderStepWorkingHours] = useState<number>(0);
	const [maxWorkingHours, setMaxWorkingHours] = useState<number>(0);

	useEffect(() => {

		if (context.showSubOrderStepDetail == true) {
			setHiddenDetail(true);
			setSaveEnabled(false);
			setAlertMessage("");
			setShowPopover(false);

			setSubOrderStep({
				...subOrderStep,
				SubOrderStepID: props.subOrderStepID,
				SubOrderID: props.subOrderID,
				ActivityLineID: props.activityLineID
			});

			subOrderStep.SubOrderStepID = props.subOrderStepID;
			subOrderStep.ActivityLineID = props.activityLineID;

			if (props.subOrderStepID !== 0) {
				GetItem();
			}
			else if (props.subOrderID > 0) {
				GetActivityStepList();

				//imposta il valore massimo di ore assegnabili
				const max = (
					props.subOrderWorkingHours
					- props.subOrderStepsWorkingHours);
				setMaxWorkingHours(max);
			}
		}

	}, [context.showSubOrderStepDetail]);


	useEffect(() => {

		setContext();

	}, [subOrderStep]);

	function GetActivityStepList() {

		ISVC.getSubOrderActivityStepsItemList(props.subOrderID).then((l) => {
			setHiddenDetail(true);
			if (l.data.length > 0) {
				setActivityStepList(l.data);

				if (props.subOrderStepID === 0) { //new item
					setSelectedActivityStep(l.data[0]);
					const activityStepID: number = Number(l.data[0].Value);
					setSubOrderStep({ ...subOrderStep, ActivityStepID: activityStepID, WorkingHours: 0 });
				}
				setHiddenDetail(false);
			}
			else {
				context.setContext({
					...context,
					showSubOrderStepProfileUserDetail: false,
					showSubOrderStepDetail: false,
					showAlert: true,
					messaggioAlert: "Nessuna fase di attività è disponibile.",
					titoloAlert: "Attenzione!"
				});
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento ActivityStepList: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function GetItem() {
		SVC.getSubOrderStep(props.subOrderStepID).then((l) => {
			if (l.data) {
				setSubOrderStep(l.data);
				setSuborderStepWorkingHours(l.data.WorkingHours);

				//imposta il valore massimo di ore assegnabili
				const max = (props.subOrderWorkingHours
					- props.subOrderStepsWorkingHours
					+ l.data.WorkingHours);

				setMaxWorkingHours(max);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento SubOrderStep: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			setHiddenDetail(false);
		});
	}

	function Save(e: MouseEvent<HTMLButtonElement>) {
		setShowPopover(false);
		subOrderStep.SubOrderID = props.subOrderID;
		SVC.SubOrderStepUpdate(subOrderStep).then((d) => {
			if (d !== undefined && d.data !== "") {
				setAlertMessage(d.data);
			}
			else {
				context.setContext({ ...context, refreshList: !context.refreshList });
				closeDialog();
			}
		}).catch((e: React.SetStateAction<string>) => {
			setAlertMessage("Errore del server");
		}).finally(() => {
		});
	};

	//validazione del form
	const isValid = (): boolean => {

		return subOrderStep.ActivityStepID !== 0
			&& props.subOrderID !== 0
			&& subOrderStep.WorkingHours > 0;
	};

	function setContext() {
		const valid = isValid();
		setSaveEnabled(valid);
	}

	function setActivityStepID(v: any, i: any) {
		const activityStepID: number = Number(v.Value);
		setSelectedActivityStep(v);
		//subOrderStep.ActivityStepID = activityStepID;
		setSubOrderStep({
			...subOrderStep,
			ActivityStepID: activityStepID
		});
		setContext();
	}

	function setWorkingHours(e: ChangeEvent<HTMLInputElement>) {
		let hours: number = Number(e.target.value);
		if (hours > maxWorkingHours) {
			hours = maxWorkingHours;
		}
		setSubOrderStep({
			...subOrderStep,
			WorkingHours: hours
		});
		//setContext();
	}


	function setWorkingHDays(e: ChangeEvent<HTMLInputElement>) {
		let days: number = Number(e.target.value);
		if (days > maxWorkingHours / 8) {
			days = maxWorkingHours / 8;
		}
		const hours = days * 8;
		setSubOrderStep({
			...subOrderStep,
			WorkingHours: Number(hours)
		});
		//setContext();
	}

	//sul double click viene impostato il valore max consentito 
	//e inviato al contenitore con la funzione onChange
	function onDoubleClick(e: MouseEvent<HTMLInputElement>) {

		const hours: number = props.subOrderWorkingHours
			+ suborderStepWorkingHours
			- props.subOrderStepsWorkingHours;

		setSubOrderStep({
			...subOrderStep,
			WorkingHours: hours
		});

		//setContext();
	}

	function onFocus(e: FocusEvent<HTMLInputElement>) {
		e.target.select();
	}

	const closeDialog = () => {

		setHiddenDetail(true);
		setSaveEnabled(false);
		setAlertMessage("");
		setShowPopover(false);
		setSuborderStepWorkingHours(0);

		context.setContext({ ...context, showSubOrderStepDetail: false });

	};

	const closePopover = () => {
		setShowPopover(false);
	};

	const popover = (
		<Popover id="popover-basic" className={showPopover ? "" : "d-none"}>
			<Popover.Title as="h3">Conferma</Popover.Title>
			<Popover.Content>
				Salvare i dati?<br /><br />
				<Button variant="outline-secondary" className="btn-sm" onClick={closePopover}><Icon icon={ic_close} /></Button>
          		&nbsp;
				<Button variant="outline-primary" className="btn-sm" onClick={Save}><Icon icon={ic_check} /></Button>
			</Popover.Content>
		</Popover>
	);

	return (
		<div>
			<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size={"lg"}
				className={hiddenDetail ? "d-none" : ""}
				show={context.showSubOrderStepDetail}
				backdrop={"static"}
			>
				<Modal.Header>
					<Modal.Title className="fluid">

						<div className="form-row">
							<div className="col-7">

								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<td className="bg-light">Deliverable
												</td>
											<td className="p-1">{props.subOrderName}</td>
										</tr>
										<tr>
											<td className="bg-light">Linea di attività</td>
											<td className="p-1">{props.activityLineName}</td>
										</tr>
										<tr>
											<td className="bg-light">Fase di attività</td>
											<td className="p-1">{props.subOrderStepID === 0 ?
												"Nuova fase" : subOrderStep.ActivityStepName}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-5 text-rigth">
								<table className="table table-sm table-bordered">
									<tbody>
										<tr>
											<th className="bg-light">Descrizione</th>
											<th className="text-right p-1 bg-light">Giornate</th>
											<th className="text-right p-1 bg-light">Ore</th>
										</tr>
										<tr>
											<td>Pianificate deliverable
											<Info
													color="secondary"
													placement="auto"
													text="Totale pianificate per il deliverable"
												/>
											</td>
											<td className="text-right p-1">
												{props.subOrderWorkingHours / 8}
											</td>
											<td className="text-right p-1">
												{props.subOrderWorkingHours}
											</td>
										</tr>
										<tr>
											<td>Pianificate fasi
											<Info
													color="secondary"
													placement="auto"
													text="Totale pianificate per tutte le altre fasi del deliverable"
												/>
											</td>
											<td className="text-right p-1">
												{(props.subOrderStepsWorkingHours -
													suborderStepWorkingHours) / 8}
											</td>
											<td className="text-right p-1">
												{props.subOrderStepsWorkingHours -
													suborderStepWorkingHours}
											</td>
										</tr>
										<tr>
											<td>Disponibili
											<Info
													color="secondary"
													placement="auto"
													text="Non ancora pianificate nelle altre fasi del deliverable"
												/>
											</td>
											<td className="text-right p-1">
												{(props.subOrderWorkingHours
													+ suborderStepWorkingHours
													- props.subOrderStepsWorkingHours) / 8}
											</td>
											<td className="text-right p-1">
												{(props.subOrderWorkingHours
													+ suborderStepWorkingHours
													- props.subOrderStepsWorkingHours)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-row">
						<div className="form-group col">
							{props.subOrderStepID === 0 ?
								<div>
									<label className="form-label">Fase di attività</label>
									<Select
										filterOption={selectFilter}
										value={selectedActivityStep}
										options={activityStepList}
										isSearchable
										onChange={setActivityStepID}
										getOptionLabel={c => c.Text}
										getOptionValue={c => c.Value}
									/>
								</div>
								:
								<h5>{subOrderStep.ActivityStepName}</h5>}
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col-3">
							<label className="form-label">Giornate di lavoro</label>
							<Info
								placement={"right"}
								text={"Il numero massimo di giornate assegnabile è "
									+ maxWorkingHours / 8}
							/>
							<input type="number"
								min="0"
								max={maxWorkingHours}
								step="1"
								className="form-control"
								name="WorkingDays"
								onChange={setWorkingHDays}
								onFocus={onFocus}
								onDoubleClick={onDoubleClick}
								value={subOrderStep.WorkingHours / 8}
							/>
						</div>
						<div className="form-group col-3">
							<label className="form-label">Ore di lavoro</label>
							<Info
								placement={"right"}
								text={"Il numero massimo di ore assegnabile è "
									+ maxWorkingHours}
							/>
							<input type="number"
								min="0"
								step="1"
								className="form-control"
								name="WorkingDays"
								onChange={setWorkingHours}
								onFocus={onFocus}
								onDoubleClick={onDoubleClick}
								value={subOrderStep.WorkingHours}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Alert variant={alertMessage === "" ? undefined : "warning"}>
						{alertMessage}
					</Alert>
					<Button
						variant="secondary"
						size="sm"
						onClick={() => closeDialog()}>
						Annulla
					</Button>
					<SaveButton
						disabled={!saveEnabled}
						popover={popover}
						popoverFunction={setShowPopover}
						saveFunction={Save}
					/>
				</Modal.Footer>
			</Modal>
		</div>
	);
}