import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Maincontext } from "../maincontext";
import Select from "react-select";
import { CostCenter, SelectItemsListTypes, SelectListItem } from "../../models/models";
import CostCenterServices from "../../api/CostCenterServices";
import SelectItemServices from "../../api/SelectItemServices";
import { Form } from "react-bootstrap";
import { getIndexSelectedById, selectFilter } from "../../core/Utilities";

export interface ICostCenterDetail {

}

export default function CostCenterDetail(props: ICostCenterDetail) {
	const context = useContext(Maincontext);
	const { register, handleSubmit } = useForm();

	const SVC = new CostCenterServices();
	const ISVC = new SelectItemServices();
	let id = 0;
	if (context.objSelected) {
		id = context.objSelected.CostCenterID as number;
	}

	const defCostCenter: CostCenter = {
		"CostCenterID": 0,
		"CostCenterName": "",
		"CreationDate": new Date(),
		"UserID": 0,
		"Manager": "",
		"Enabled": true
	};

	const [costCenter, setCostCenter] = useState<CostCenter>(defCostCenter);
	const [userList, setUserList] = useState<SelectListItem[] | []>([]);
	const [selectedUser, setSelectedUser] = useState<SelectListItem | undefined>(undefined);

	useEffect(() => {
		GetUserList();

	}, []);

	useEffect(() => {
		if (context.objSelected) {
			setSelectedUser(userList[getIndexSelectedById(userList, context.objSelected!.UserID)]);
		}
	}, [userList]);


	function GetItem() {
		SVC.getCostCenter(id).then((d) => {
			if (d.data) {
				setCostCenter(d.data);
				context.objectName = context.objSelected.CostCenterName;
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Cost Center: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
		});
	}

	function GetUserList() {
		ISVC.getSelectItemList(SelectItemsListTypes.USERS, costCenter.UserID).then((l) => {
			if (l.data) {
				setUserList(l.data);
				setSelectedUser(l.data[0]);
				if (id) {
					GetItem();
				}
				else {
					const userID: number = Number(l.data[0].Value);
					setCostCenter({ ...costCenter, UserID: userID });
					context.setContext({ ...context, /* showAttesa: false, */ hiddenDetail: false });
				}
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento Users: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	//validazione del form
	const isValid = (): boolean => {
		return costCenter.CostCenterName !== "";
	};

	function setContext() {
		const valid = isValid();
		if (valid) {
			context.objSelected = costCenter;
		}
		context.setContext({ ...context, saveEnabled: valid });
	}

	function setEnabled(e: ChangeEvent<HTMLInputElement>) {
		const enabled = Boolean(e.target.checked);
		costCenter.Enabled = enabled;
		setContext();
	}

	function setName(e: ChangeEvent<HTMLInputElement>) {

		const name: string = e.target.value;
		costCenter.CostCenterName = name;
		setContext();

	}

	//   function getIndexSelectedById(items: SelectListItem[], v: number): number {
	//     let ret = -1;
	//     items.forEach((c, i) => {
	//       if (parseInt(c.Value) == v) {
	//         ret = i
	//       }
	//     })
	//     return ret;
	//   }

	function setUserID(v: any, i: any) {
		const userID: number = Number(v.Value);
		setSelectedUser(v);
		costCenter.UserID = userID;
		setContext();
	}

	return (
		<div>
			<form onSubmit={handleSubmit(() => { alert("ok"); })}>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Centro di costo</label>
						<input className="form-control" name="CostCenterName"
							onChange={setName}
							defaultValue={costCenter?.CostCenterName} ref={register({ required: true, maxLength: 50 })} />
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<label className="form-label">Responsabile</label>
						<Select
							filterOption={selectFilter}
							value={selectedUser}
							options={userList}
							isSearchable
							onChange={setUserID}
							getOptionLabel={c => c.Text}
							getOptionValue={c => c.Value}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col">
						<Form.Check
							id="chkEnabled"
							type="switch"
							label={"Abilitato"}
							checked={costCenter.Enabled}
							onChange={setEnabled} />
					</div>
				</div>
			</form>
		</div >
	);
}
