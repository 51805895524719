import React, { useContext, useState, useEffect, MouseEvent } from "react";
import { Maincontext } from "../maincontext";
import Lista from "../common/List";
import WorkingHoursTypeServices from "../../api/WorkingHoursTypeServices";
import { WorkingHoursType, columnDetail, SelectedObjectTypes, MenuList, DataColumnTypes } from "../../models/models";
import TitleBar from "../common/TitleBar";
import WorkingHoursTypeDetail from "./WorkingHoursTypeDetail";

export interface IAppProps {
}

export default function WorkingHoursTypesList(props: IAppProps) {
	const context = useContext(Maincontext);
	const SVC = new WorkingHoursTypeServices();
	const [list, setList] = useState<WorkingHoursType[]>([]);

	//Dichiarazione della colonna di dettaglio che determina se una colonna viene visualizzata e in che posizione se non viene passato viene fatto il rendering dell'oggetto
	const columnDetails: columnDetail[] = [
		{ name: "WorkingHoursTypeName", text: "Orario di lavoro", position: 1 },		
		{ name: "Monday", text: "Lunedì", position: 2, type: DataColumnTypes.HH_MM },
		{ name: "Tuesday", text: "Martedì", position: 3, type: DataColumnTypes.HH_MM },
		{ name: "Wednesday", text: "Mercoledì", position: 4, type: DataColumnTypes.HH_MM },
		{ name: "Thursday", text: "Giovedì", position: 5, type: DataColumnTypes.HH_MM },
		{ name: "Friday", text: "Venerdì", position: 6, type: DataColumnTypes.HH_MM },
		{ name: "Saturday", text: "Sabato", position: 7, type: DataColumnTypes.HH_MM },
		{ name: "Sunday", text: "Domenica", position: 8, type: DataColumnTypes.HH_MM },
		{ name: "Holiday", text: "Festività", position: 9, type: DataColumnTypes.HH_MM },
		{ name: "CreationDate", text: "Data creazione", position: 10, type: DataColumnTypes.DATE },
	];

	useEffect(() => {
		GetList();
	}, [context.refreshList]);

	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.getWorkingHoursTypes().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento WorkingHoursTypes: " + e, titoloAlert: "Errore" });
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ selectedMenu: MenuList.WORKING_HOURS_TYPES, });
		});
	}

	function onDelete(e: MouseEvent<HTMLButtonElement>) {

		const workingHoursType: WorkingHoursType = context.objSelected;

		SVC.WorkingHoursTypeDelete(workingHoursType).then((d) => {
			if (d.data !== "") {
				context.messaggioAlert = d.data;
				context.titoloAlert = "Eliminazione Tipo di Orario di Lavoro";
				context.showAlert = true;
			}
		}).catch((e) => {
			context.messaggioAlert = "Si è verificato un errore del server";
			context.titoloAlert = "Errore";
			context.showAlert = true;
		}).finally(() => {
			context.setContext({ ...context, /* showAttesa: false, */ refreshList: !context.refreshList });
		});
	}

	return (
		<div>
			<TitleBar title="Orari di lavoro" />
			<Lista obj={list} details={[
				{
					detail: <WorkingHoursTypeDetail />,
					button: "edit",
					titoloDettaglio: "Modifica",
					objTypeSelected: SelectedObjectTypes.WORKING_HOURS_TYPE
				},
				{
					detail: <WorkingHoursTypeDetail />,
					button: "delete",
					titoloDettaglio: "Elimina",
					objTypeSelected: SelectedObjectTypes.WORKING_HOURS_TYPE,
					isFunction: true,
					confirm: true,
					confirmMessage: "Eliminare la tipologia di orario di lavoro?",
					onClick: onDelete
				}
			]}
			columnDetails={columnDetails}
			addButton={true}
			addElement={<WorkingHoursTypeDetail />}
			addType={SelectedObjectTypes.WORKING_HOURS_TYPE}
			addPanelSize={undefined}
			detailPosition={1}
			/>
		</div>
	);
}
