/*
	menu principale nell'intestazione dell'applicazione
*/

import { Maincontext } from "../maincontext";
import React, { useContext, useEffect, useState } from "react";
import { Navbar, NavDropdown, Nav, NavItem, Form, Badge } from "react-bootstrap";

//Icone
import { Icon } from "react-icons-kit";
import { ic_file_download } from "react-icons-kit/md/ic_file_download";
import { ic_developer_board } from "react-icons-kit/md/ic_developer_board";
import { ic_settings } from "react-icons-kit/md/ic_settings";
import { ic_notifications } from "react-icons-kit/md/ic_notifications";
import { ic_info } from "react-icons-kit/md/ic_info";
import { ic_account_circle } from "react-icons-kit/md/ic_account_circle";
import { ic_check } from "react-icons-kit/md/ic_check";
import { iconaMenu } from "./IconaMenu";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import { ic_next_week } from 'react-icons-kit/md/ic_next_week'
import UserServices from "../../api/UserServices";

import { IsAuthorized, sleep } from "../../core/Utilities";
import UserInfo from "./UserInfo";
import { UserContext } from "../maincontext";
import { useHistory } from "react-router-dom";
import { ContentTypes, UserNotification, UserNotificationType } from "../../models/models";
import UserPreferences from "../views/UserPreferences";
import UserNotificationServices from "../../api/UserNotificationServices";
import { toast, ToastContainer } from "react-toastify";

import TechnicalInterventionDialog from "../views/reports/TechnicalInterventionDialog";

export default function MainMenu() {

	const SVC = new UserNotificationServices();
	const context = useContext(Maincontext);
	const users_authorized: boolean = IsAuthorized(ContentTypes.TIMESHEETS);
	const customers_authorized: boolean = IsAuthorized(ContentTypes.CUSTOMERS) || IsAuthorized(ContentTypes.COMMERCIAL_DATA);
	const technical_intervention_authorized: boolean = IsAuthorized(ContentTypes.TECHNICAL_INTERVENTIONS);
	const USVC = new UserServices();
	const userContext = useContext(UserContext);
	const history = useHistory();
	const urlParams = new URLSearchParams(window.location.search);


	let noToolBar: Boolean = false;
	let sessionExpired: Boolean = false;
	if (urlParams.has("toolbar") && urlParams.get("toolbar") === "no") {
		noToolBar = true;
	}
	if (window.location.pathname === "/expired") {
		sessionExpired = true;
	}

	const [currentNotificationID, SetCurrentNotificationID] = useState<number>(0);

	const [menuNotificationsCount, setMenuNotificationsCount] = useState<number>(0);
	const [menuNotifications, setMenuNotifications] = useState<UserNotificationType[]>([]);
	const [technicalInterventionVisible, SetTechnicalInterventionVisible] = useState<boolean>(false);


	//const [toastNotifications, setToastNotifications] = useState<UserNotificationType[]>([]);

	useEffect(() => {

		GetMenuNotifications();
		GetToastNotifications();

	}, []);

	useEffect(() => {

		let total: number = 0;

		menuNotifications.forEach(function (e: UserNotificationType, i: number) {
			total += e.UserNotifications.length;
		});

		setMenuNotificationsCount(total);

	}, [menuNotifications]);


	function GetMenuNotifications() {
		SVC.GetMenuUserNotifications().then((d) => {
			if (d.data) {
				setMenuNotifications(d.data);
			}
			else {
				setMenuNotifications([]);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento notifiche menu: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function GetToastNotifications() {
		SVC.GetToastUserNotifications().then((d) => {
			if (d.data) {
				d.data.forEach(function (e: UserNotificationType, i: number) {
					toastNotify(e);
				});
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento notifiche toast: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function SetUserNotificationMenuRead(userNotificationID: number) {
		SVC.SetUserNotificationMenuRead(userNotificationID).then((d) => {
			if (d.data != "") {
				context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore salvataggio lettura notifica: " + d.data, titoloAlert: "Errore" });
			}
			else {
				GetMenuNotifications();
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento notifiche menu: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function toastNotify(userNotificationType: UserNotificationType) {

		userNotificationType.UserNotifications.forEach(function (e: UserNotification, i: number) {

			notify(
				userNotificationType.UserNotificationTypeDescription,
				e.UserNotificationID,
				e.UserNotificationDescription
			);

			sleep(5000);
		});


	}

	const notify = (title: string, id: number, text: string) => toast.info(
		<div>
			<strong>{title}</strong>
			<hr />
			{text}
		</div>,
		{
			delay: 10000,
			onClose: () => SetUserNotificationToastRead(id)
		}
	);


	function SetUserNotificationToastRead(userNotificationID: number) {
		SVC.SetUserNotificationToastRead(userNotificationID).then((d) => {
			if (d.data != "") {
				context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore salvataggio lettura notifica: " + d.data, titoloAlert: "Errore" });
			}
			else {

			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento notifiche menu: " + e, titoloAlert: "Errore" });
		}).finally(() => {
		});
	}

	function showUserPreferences() {
		context.setContext({
			...context,
			showUserPreferencestepDetail: true
		});
	}

	// window.onclick = function(event: any) {
	// 	SetMenuOpened(false);
	//   }

	return (
		<div className="title-header">
			<Navbar bg="primary" expand="sm" className="fixed-top py-0 pl-3">
				<Navbar.Brand
					onClick={() => {
						if (!noToolBar && !sessionExpired) {
							history.push("/dashboard");
						}
					}}
					className="text-white d-flex"
					style={{ cursor: noToolBar || sessionExpired ? "default" : "pointer", padding: "0" }}
				>
					<Icon icon={ic_developer_board} size={32} className="mr-3 d-flex" />
					<span className="mr-3 d-flex">Timesheet 2.0</span>
					<span className="user-fullname">{userContext.Me?.FullName}</span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-nav" />
				<Navbar.Collapse id="navbar-nav" className="justify-content-end">
					<Nav>
						<NavDropdown alignRight title={iconaMenu(ic_file_download)} id="basic-nav-dropdown">
							<NavDropdown.Item href="#" disabled={true}><b>Documenti</b></NavDropdown.Item>
							{technical_intervention_authorized &&
								<NavDropdown.Item href="#" onClick={() => SetTechnicalInterventionVisible(true)}>
									<Icon icon={ic_next_week} className="text-info mr-2" />
									Interventi tecnici</NavDropdown.Item>
							}
						</NavDropdown>
						<NavItem
							className="nav-item-single"
							onClick={() => showUserPreferences()}
							title="Preferenze"
						>
							{iconaMenu(ic_settings)}
						</NavItem>
						{menuNotifications.length > 0 ?
							<>
								<NavDropdown alignRight title={iconaMenu(ic_notifications)} id="basic-nav-dropdown">
									<table className="table table-sm table-notifications">
										{
											menuNotifications.map(function (notType: UserNotificationType, i) {
												return <>
													<tr>
														<th colSpan={2}>
															{notType.UserNotificationTypeDescription}
														</th>
													</tr>
													{notType.UserNotifications.map(function (notification: UserNotification, i) {
														return <tr
															onMouseOver={() => SetCurrentNotificationID(notification.UserNotificationID)}
															onMouseLeave={() => SetCurrentNotificationID(0)}>
															<td>{notification.UserNotificationDescription}</td>
															<td className="text-right td-button" title="Segna come già letto">
																<Icon icon={ic_check}
																	onClick={() => SetUserNotificationMenuRead(notification.UserNotificationID)}
																	className={currentNotificationID == notification.UserNotificationID ? "text-secondary" : "text-light"} />
															</td>
														</tr>
													})
													}
												</>
											})
										}
									</table>
								</NavDropdown>
								{menuNotificationsCount > 0 ?
									<Badge pill variant="warning" className="badge">{menuNotificationsCount}</Badge>
									:
									''
								}
							</>
							:
							''
						}
						<NavDropdown alignRight title={iconaMenu(ic_info)} id="basic-nav-dropdown">
							<NavDropdown.Item href="#" disabled={true}><b>Manuali operativi</b></NavDropdown.Item>
							{users_authorized &&
								<NavDropdown.Item href="#" onClick={() => USVC.Download("ManualeCompilazioneTS")}><Icon icon={filePdf} className="text-danger" /> Compilazione timesheets</NavDropdown.Item>
							}
						</NavDropdown>
						<NavDropdown alignRight title={iconaMenu(ic_account_circle)} id="basic-nav-dropdown" >
							<UserInfo />
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<UserPreferences />
			<ToastContainer
				position="bottom-right"
				autoClose={10000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<TechnicalInterventionDialog
				visible={technicalInterventionVisible}
				closeModal={() => SetTechnicalInterventionVisible(false)}
			/>
		</div>
	);
}