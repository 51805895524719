import { SubOrder } from "../models/models";
import CommonServices from "./CommonServices";
import axios from "axios";

const SVC = new CommonServices();

export default class SubOrderServices {

	//elenco completo di SubOrders, attivi o scaduti
	async getSubOrders(expired?: boolean) {
		const params = {
			scadute: expired
		};
		return axios.get<SubOrder[]>(`${await SVC.getApiBaseUri()}/api/SubOrders`, { params, headers: await SVC.setAzureAuth() });
	}


	//elenco SubOrders attivi assegnati alla risorsa corrente

	async getUserActiveSubOrders(UserID?: number) {

		if (UserID) {
			const params = {
				UserID: UserID
			};

			return axios.get<SubOrder[]>(`${await SVC.getApiBaseUri()}/api/SubOrders/UserActiveSubOrders`, { params, headers: await SVC.setAzureAuth() });
		}
		else {
			return axios.get<SubOrder[]>(`${await SVC.getApiBaseUri()}/api/SubOrders/UserActiveSubOrders`, { headers: await SVC.setAzureAuth() });
		}
	}
	//elenco SubOrders attivi che scadono nei prossimi 30 giorni
	async getUserDeadlineActiveSubOrders(UserID?: number) {

		if (UserID) {
			const params = {
				UserID: UserID
			};

			return axios.get<SubOrder[]>(`${await SVC.getApiBaseUri()}/api/SubOrders/UserDeadlineActiveSubOrders`, { params, headers: await SVC.setAzureAuth() });
		}
		else {
			return axios.get<SubOrder[]>(`${await SVC.getApiBaseUri()}/api/SubOrders/UserDeadlineActiveSubOrders`, { headers: await SVC.setAzureAuth() });
		}
	}

	async getSubOrdersByOrderID(id: number) {
		const params = {
			OrderID: id
		};
		return axios.get<SubOrder[]>(`${await SVC.getApiBaseUri()}/api/SubOrders/SubOrdersByOrderID`, { params, headers: await SVC.setAzureAuth() });
	}

	//restituisce un SubOrder passando l'id, solo se è il responsabile del suborder
	async getSubOrder(id: number) {
		const params = {
			id: id
		};
		return axios.get<SubOrder>(`${await SVC.getApiBaseUri()}/api/SubOrders`, { params, headers: await SVC.setAzureAuth() });
	}

	//restituisce un SubOrder passando l'id, solo se è il responsabile del suborder o della commessa
	async getSubOrderData(id: number) {
		const params = {
			id: id
		};
		return axios.get<SubOrder>(`${await SVC.getApiBaseUri()}/api/SubOrders/suborder`, { params, headers: await SVC.setAzureAuth() });
	}

	//insert/update di un Order
	async SubOrderUpdate(subOrder: SubOrder) {
		return axios.post(`${await SVC.getApiBaseUri()}/api/SubOrders`, subOrder, { headers: await SVC.setAzureAuth() });
	}

	//eliminaizone del Order passato per argomento
	async SubOrderDelete(subOrder: SubOrder) {
		const params = {
			id: subOrder.SubOrderID
		};
		return axios.delete<string>(`${await SVC.getApiBaseUri()}/api/subOrders`,
			{ params, headers: await SVC.setAzureAuth() });
	}

}