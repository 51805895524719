import React, { useContext, useEffect, useState } from "react";
import { Bar, Pie } from 'react-chartjs-2';
import { ValueForItem } from "../../../models/charts";
import ChartsServices from "../../../api/ChartsServices";
import { Maincontext } from "../../maincontext";

export interface IItemValuesBarChart {
	List: ValueForItem[],
	Label:string
}

export default function ItemValuesBarChart(props: IItemValuesBarChart) {

	const context = useContext(Maincontext);

	const defState = {
		labels: [],
		datasets: [
			{
				label: props.Label,
				backgroundColor: '#e8e8e8',
				borderColor: '#0078d4',
				borderWidth: 1,
				data: []
			}
		]
	}

	const defOptions = {
		title: {
			display: true,
			text: '',
			fontSize: 20
		},
		legend: {
			display: false,
			position: 'right'
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}

	const [chartState, setChartState] = useState<any>(defState);
	const [chartOptions, setChartOptions] = useState<any>(defOptions);


	useEffect(() => {
		const labels = props.List.map(dati => dati.ItemName);
		const data = props.List.map(dati => dati.ItemValue);
		chartState.datasets[0].data = data;
		setChartState({
			...chartState, labels: labels
		});

	}, [props.List]);

	useEffect(() => {
		chartOptions.title.text=props.Label;
	}, [props.Label]);


	return (
		<div className="bg-light p-2">
			<Bar
				data={chartState}
				options={chartOptions}
			/>
		</div>
	);
}

