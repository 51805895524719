import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import { authProvider } from "../../authProvider";
import { Button } from "react-bootstrap";

export default function SessionAlert() {

	const [showAlert, setShowAlert] = useState<boolean | undefined>();
	const [tokenExpiration, setTokenExpiration] = useState(authProvider.getAccountInfo()?.account.idToken.exp || "");
	const [loadingNewSession, setLoadingNewSession] = useState<Boolean>(false);

	const divStyle = {
		backgroundColor: "white",
		opacity: "0.7",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		position: "absolute",
		zIndex: 2001		
	} as React.CSSProperties;

	const centeredItem = {
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 2002,
		position: "relative"
	} as React.CSSProperties;

	const divExtendSession = {
		borderRadius: "5px",
		backgroundColor: "#fff",
		boxShadow: "0 0 5px #0007",
		padding: "20px",
		textAlign: "center"
	} as React.CSSProperties;

	const divWrapperStyle = {
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		position: "fixed",
		zIndex: 2000,
		display: showAlert ? "block" : "none"
	} as React.CSSProperties;

	const showAlertHandler = () => {
		if(parseInt((+ new Date()).toString()) > new Date(parseInt(tokenExpiration)*1000).getTime()) {			
			setShowAlert(true);	
			document.removeEventListener("mousemove", showAlertHandler);								
		}
	};

	if (showAlert === undefined) {
		document.addEventListener("mousemove", showAlertHandler);
	}

	return (
		<div>
			{showAlert && 	
				<>	
					<div style={divWrapperStyle}>
						<div style={divStyle}>
						</div>
						<div style={centeredItem}>
							<div style={divExtendSession}>
								{ !loadingNewSession && 
									<>
										<div className="mb-2">
											Sessione scaduta
											<br />
											<div className="mt-2">
												<Button className="bg-primary" onClick={async() => { 
													setLoadingNewSession(true); 
													const idToken = await authProvider.getIdToken();
													setTokenExpiration(idToken.idToken.expiration);
													setShowAlert(undefined); 
													setLoadingNewSession(false);
												 }}>Estendi la sessione</Button>
											</div>					
										</div>
									</>
								}
								{ loadingNewSession && 
									<>
										<Spinner animation="border" />
										<div>Estendo la sessione...</div>											
									</>
								}
							</div>			
						</div>
					</div>
				</>
			}
		</div>
	);	
}