import React, { useContext, useEffect, useState } from 'react';
import { ReportColumn, RptCostCentersUsers } from '../../../models/reports';
import ReportServices from '../../../api/ReportServices';
import { Maincontext } from '../../maincontext';
import ListReport from '../../common/ListReport';
import { MenuList } from '../../../models/models';


export default function CostCentersUsers() {

	const context = useContext(Maincontext);
	const SVC = new ReportServices();
	const [list, setList] = useState<RptCostCentersUsers[]>([]);

	const columns: ReportColumn[] = [
		{ columnName: 'Centro di costo', columnValue: 'CostCenterName', columnWidth: 2 },
		{ columnName: 'Responsabile', columnValue: 'CostCenterManagerFullName', columnWidth: 2 },
		{ columnName: 'Risorsa', columnValue: 'UserFullName', columnWidth: 2 },
		{ columnName: '%Appartenenza', columnValue: 'Percentage', columnWidth: 1 }
	]

	useEffect(() => {
		GetList();
		context.setContext({
			...context,
			selectedMenu: MenuList.RPT_COSTCENTERSUSERS
		});
	}, []);



	function GetList() {
		//context.setContext({ ...context, showAttesa: true });
		SVC.GetCostCentersUser().then((d) => {
			if (d.data) {
				setList(d.data);
			}
		}).catch((e) => {
			context.setContext({ ...context, showAlert: true, messaggioAlert: "Errore di caricamento centri di costo: " + e, titoloAlert: "Errore" });
		}).finally(() => {

		});
	}

	return (
		<div>
			<ListReport
				title="Risorse per centro di costo"
				columns={columns}
				list={list} />
		</div>
	);
}