import React, { useContext, useState, useEffect } from "react";
import { UserContext, Maincontext } from "../../maincontext";
import { wrench as ic_wrench } from "react-icons-kit/icomoon/wrench";
import Icon from "react-icons-kit";
import { Table, Button } from "react-bootstrap";
import OrderServices from "../../../api/OrderServices";
import { Order, OrderRemainingDays } from "../../../models/models";

export default function OrdersRemaingDaysWidget() {
  const context = useContext(Maincontext);
  const UserInfo = useContext(UserContext);
  const [alertDays, setAlertDays] = useState<number>(20);
  const SVC = new OrderServices();
  const [list, setList] = useState<OrderRemainingDays[]>([]);

  useEffect(() => {
    GetList();
  }, [alertDays]);

  useEffect(() => {
    console.log(list);
  }, [list]);

  function GetList() {
    SVC.GetOrdersRemainingDays(alertDays)
      .then((d) => {
        if (d.data) {
          setList(d.data);
        }
      })
      .catch((e) => {
        context.setContext({
          ...context,
          showAlert: true,
          messaggioAlert: "Errore di caricamento commesse: " + e,
          titoloAlert: "Errore",
        });
      })
      .finally(() => {});
  }

  return (
    <div className="widget border-0">
      <h6 className={list.length > 0 ? "title" : "title"}>
        <Icon icon={ic_wrench} className="mr-1" size={20} />
        Giornate rimanenti commessa
        <select
          style={{ marginLeft: "16px" }}
          value={alertDays}
          onChange={(e) => setAlertDays(Number(e.target.value))}
        >
          <option value="10">10%</option>
          <option value="20">20%</option>
          <option value="30">30%</option>
          <option value="50">50%</option>
          <option value="100">100%</option>
        </select>
      </h6>
      {list.length > 0 ? (
        <Table size="sm">
          <thead>
            <tr>
              <th>Cod. commess</th>
              <th>Commessa</th>
              <th>Cliente</th>
              <th className="text-right">Previste</th>
              <th className="text-right">Lavorate</th>
              <th className="text-right">Rimanenti</th>
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
            {list.map(function (item, index) {
              return (
                <tr key={"Ord_rem_d_tr_" + index}>
                  <td key={"Ord_ccode_td_" + index}>{item.OrderCode}</td>
                  <td key={"Ord_name_td_" + index}>{item.OrderName}</td>
                  <td key={"Ord_custname_td_" + index}>{item.CustomerName}</td>
                  <td className="text-right" key={"Ord_wkng_td_" + index}>
                    {item.OrderWorkingDays}
                  </td>
                  <td className="text-right" key={"Ord_wked_td_" + index}>
                    {item.OrderWorkedDays}
                  </td>
                  <td className="text-right" key={"Ord_rem_td_" + index}>
                    {item.OrderRemainDays}
                  </td>
                  <td className="text-right" key={"Ord_remp_td_" + index}>
                    {item.OrderRemainDaysPerc}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <span>Nessuna commessa trovata</span>
      )}
    </div>
  );
}
